import React, { Component, useMemo, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

// import LeftSidebar from './components/header/LeftSidebar';
import TopHeader from "./components/header/TopHeader";
import FormsMap from "./components/scoring-forms/FormsMap";
import Pain from "./components/scoring-forms/shoulder-forms/pain/Pain";
import NotFoundPage from "./components/custom/notfoundpage/NotFoundPage";
import GeneralComplete from "./components/scoring-forms/general/complete/Complete";
import Function from "./components/scoring-forms/shoulder-forms/function/Function";
import Sacs from "./components/scoring-forms/shoulder-forms/sacs/Sacs";
import Wosi from "./components/scoring-forms/shoulder-forms/wosi/Wosi";
import Satisfaction from "./components/scoring-forms/shoulder-forms/satisfaction/Satisfaction";
import Complete from "./components/scoring-forms/shoulder-forms/complete/Complete";
import Result from "./components/scoring-forms/shoulder-forms/result/Result";
import AdultKneePain from "./components/scoring-forms/knee-forms/adult-knee-form/pain/AdultKneePain";
import AdultKneeSymptoms from "./components/scoring-forms/knee-forms/adult-knee-form/symptoms/AdultKneeSymptoms";
import AdultKneeFunction from "./components/scoring-forms/knee-forms/adult-knee-form/function/AdultKneeFunction";
import AdultKneeQualityOfLife from "./components/scoring-forms/knee-forms/adult-knee-form/quality-of-life/AdultKneeQualityOfLife";
import AdultKneeSatisfaction from "./components/scoring-forms/knee-forms/adult-knee-form/satisfaction/AdultKneeSatisfaction";
import NorwichPatellarInstability from "./components/scoring-forms/knee-forms/adult-knee-form/norwich-patellar/NorwichPatellarInstability";
import NorwichPatellarInstabilityPedia from "./components/scoring-forms/knee-forms/pedia-knee-form/nowwich-patellar/NorwichPatellarInstability";
import AdultKneeComplete from "./components/scoring-forms/knee-forms/adult-knee-form/complete/AdultKneeComplete";
import AdultKneeResult from "./components/scoring-forms/knee-forms/adult-knee-form/result/AdultKneeResult";

import PediaKneePain from "./components/scoring-forms/knee-forms/pedia-knee-form/pain/PediaKneePain";
import PediaKneeSymptoms from "./components/scoring-forms/knee-forms/pedia-knee-form/symptoms/PediaKneeSymptoms";
import PediaKneeFunction from "./components/scoring-forms/knee-forms/pedia-knee-form/function/PediaKneeFunction";
import PediaKneeQualityOfLife from "./components/scoring-forms/knee-forms/pedia-knee-form/quality-of-life/PediaKneeQualityOfLife";
import PediaKneeSatisfaction from "./components/scoring-forms/knee-forms/pedia-knee-form/satisfaction/PediaKneeSatisfaction";
import PediaKneeComplete from "./components/scoring-forms/knee-forms/pedia-knee-form/complete/PediaKneeComplete";
import PediaKneeResult from "./components/scoring-forms/knee-forms/pedia-knee-form/result/PediaKneeResult";

import HipPersonalData from "./components/scoring-forms/hip-forms/personaldata/HipPersonalData";
import HipPain from "./components/scoring-forms/hip-forms/pain/Pain";
import HipSymptoms from "./components/scoring-forms/hip-forms/symptoms/HipSymptoms";
import HipFunction from "./components/scoring-forms/hip-forms/function/HipFunction";
import HipQualityOfLife from "./components/scoring-forms/hip-forms/quality-of-life/HipQualityOfLife";
import HipSatisfaction from "./components/scoring-forms/hip-forms/satisfaction/HipSatisfaction";
import HipComplete from "./components/scoring-forms/hip-forms/complete/HipComplete";
import HipResult from "./components/scoring-forms/hip-forms/result/HipResult";

import FootAnklePain from "./components/scoring-forms/foot-ankle-form/pain/FootAnklePain";
import FootAnkleInstability from "./components/scoring-forms/foot-ankle-form/instability/FootAnkleInstability";
import FootAnkleOtherSymptoms from "./components/scoring-forms/foot-ankle-form/other-symptoms/FootAnkleOtherSymptoms";
import FootAnkleFunction from "./components/scoring-forms/foot-ankle-form/function/FootAnkleFunction";
import FootAnkleQualityOfLife from "./components/scoring-forms/foot-ankle-form/quality-of-life/FootAnkleQualityOfLife";
import FootAnkleSatisfaction from "./components/scoring-forms/foot-ankle-form/satisfaction/FootAnkleSatisfaction";
import FootAnkleComplete from "./components/scoring-forms/foot-ankle-form/complete/FootAnkleComplete";
import FootAnkleResult from "./components/scoring-forms/foot-ankle-form/result/FootAnkleResult";
import FootPersonalData from "./components/scoring-forms/foot-ankle-form/personaldata/FootPersonalData";
import Doctorfeedback from "./components/scoring-forms/foot-ankle-form/foot-ankle-doctorfeedback/Doctorfeedback";
import PersonalData from "./components/scoring-forms/shoulder-forms/personaldata/PersonalData";
import ConsentForm from "./components/consent/ConsentForm";
import HandPersonalData from "./components/scoring-forms/hand&wrist/personaldata/HandPersonalData";
import HandWristpain from "./components/scoring-forms/hand&wrist/pain/HandWristpain";
import HandWristSymptoms from "./components/scoring-forms/hand&wrist/symptoms/HandWristSymptoms";
import HandWristfunction from "./components/scoring-forms/hand&wrist/function/HandWristfunction";
import HandWristQQL from "./components/scoring-forms/hand&wrist/qol/HandWristQQL";
import HandWristSatisfaction from "./components/scoring-forms/hand&wrist/satisfaction/HandWristSatisfaction";
import HandWristComplete from "./components/scoring-forms/hand&wrist/complete/HandWristComplete";
import HandWristResult from "./components/scoring-forms/hand&wrist/result/HandWristResult";
import Cumberland from "./components/scoring-forms/foot-ankle-form/cumberland/Cumberland";

import "./App.css";
import "./css/style.css";
import ElbowPain from "./components/scoring-forms/elbow/pain/ElbowPain";
import ElbowFunction from "./components/scoring-forms/elbow/function/ElbowFunction";
import Symptoms from "./components/scoring-forms/elbow/symptoms/Symptoms";
import ElbowQualityOfLife from "./components/scoring-forms/elbow/quality-of-life/ElbowQualityOfLife";
import ElbowSatisfaction from "./components/scoring-forms/elbow/Satisfaction/ElbowSatisfaction";
import ElbowComplete from "./components/scoring-forms/elbow/complete/ElbowComplete";
import ElbowResultPage from "./components/scoring-forms/elbow/Result/ElbowResultPage";
import PatientAssessmentStep1 from "./components/scoring-forms/shoulder-forms/doctorefeedback/PatientAssessmentStep1";
import PatientAssessmentStep2 from "./components/scoring-forms/shoulder-forms/doctorefeedback/PatientAssessmentStep2";
import Doctorefeedback from "./components/scoring-forms/hip-forms/Hipdoctorfeedback/Doctorefeedback";
import Doctorefeedback2 from "./components/scoring-forms/hip-forms/Hipdoctorfeedback/Doctorefeedback2";
import ElbowFeedback from "./components/scoring-forms/elbow/doctorFeedback/ElbowFeedback";
import ElbowFeedbackStep2 from "./components/scoring-forms/elbow/doctorFeedback/ElbowFeedback-step2";
import KneeFeedback from "./components/scoring-forms/knee-forms/doctorFeedback/KneeFeedback";
import KneeFeedbackstep2 from "./components/scoring-forms/knee-forms/doctorFeedback/KneeFeedbackstep2";

import DoctorfeedbackStep2 from "./components/scoring-forms/foot-ankle-form/foot-ankle-doctorfeedback/DoctorfeedbackStep2";
import DoctorfeedbackStep3 from "./components/scoring-forms/foot-ankle-form/foot-ankle-doctorfeedback/DoctorfeedbackStep3";
import useAxios from "./axiosinstance";
import useAxiosUpload from "./axiosinstanceUpload";
import HandWristSurgeon from "./components/scoring-forms/hand&wrist/doctorfeedbackform/HandWristSurgeon";
import HandWristSurgeon2 from "./components/scoring-forms/hand&wrist/doctorfeedbackform/HandWristSurgeon2";
import ViewTreatmentForm from "./components/treatment-form/shoulder-treatment-form/ViewTreatmentForm";
import AdultKneeTreatmentForm from "./components/treatment-form/adultknee-treatment-form/AdultKneeTreatmentForm";
import PediaKneeTreatment from "./components/treatment-form/pediaknee-treatment-form/PediaKneeTreatment";
import HipTreatment from "./components/treatment-form/hip-treatment-form/HipTreatment";
import FootAnkleTreatment from "./components/treatment-form/foot-ankle-treatment-form/FootAnkleTreatment";
import ElbowTreatment from "./components/treatment-form/elbow-treatment-form/ElbowTreatment";
import HandWristTreatment from "./components/treatment-form/hand-wrist-treatment-form/HandWristTreatment";
import Bctq from "./components/scoring-forms/hand&wrist/BCTQ/HandWristBctq";
import HandWristUram from "./components/scoring-forms/hand&wrist/URAM/HandWristUram";
import ConsentForm_view from "./components/consent/ConsentForm_view";
import History from "./components/scoring-forms/general/history/History";
import Past from "./components/scoring-forms/general/past/Past";
import Social from "./components/scoring-forms/general/social/Social";
import DoctorefeedbackGen from "./components/scoring-forms/general/doctorfeedbackform/Doctorefeedback";
import ViewTreatmentFormGeneral from "./components/treatment-form/general-treatment-form/ViewTreatmentForm";
import AdultKneeFeedback from "./components/scoring-forms/knee-forms/adult-knee-form/doctorFeedback/KneeFeedback";
import AdultKneeFeedbackstep2 from "./components/scoring-forms/knee-forms/adult-knee-form/doctorFeedback/KneeFeedback";
import PediaKneeFeedback from "./components/scoring-forms/knee-forms/pedia-knee-form/doctorFeedback/KneeFeedback";
import PediaKneeFeedbackstep2 from "./components/scoring-forms/knee-forms/pedia-knee-form/doctorFeedback/KneeFeedbackstep2";
import FeelingInstability from "./components/scoring-forms/shoulder-forms/Feeling-of-instability/FeelingInstability";
import Instability from "./components/scoring-forms/shoulder-forms/Instability/Instability";
import MedicalTreatmentView from "./components/medical-form/MedicaltreatmentView";
import TreatmentHistory from "./components/Medical-treatment-form/history/History";
import TreatmentPast from "./components/Medical-treatment-form/past/Past";
import TreatmentSocial from "./components/Medical-treatment-form/social/Social";
import Unauthorized from "./components/unauthorizedpage/Unauthorized";
import ClinPersonalData from "./components/scoring-forms/shoulder-forms/personaldata/ClinPersonalData";
import ConsentFormShare from "./components/consent/ConsentFormShare";
function AppRouter() {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const axiosInstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();
  useEffect(() => {
    axiosInstance.current.get(`ping`).then((res) => {});
    axiosInstanceUpload.current.get(`ping`).then((res) => {});
  }, []);
  const [language, setLanguage] = useState("");
  const [ak_dc, setAkdc] = useState("");
  const Datapass = (e) => {
    setAkdc(e);
  };
  return (
    <>
      <div
        className=""
        style={{ height: "100%" }}
        dir={language === "ara" ? "rtl" : "ltr"}
      >
        <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
          {splitLocation[1] === "shoulder-treatment-form" ? (
            <TopHeader setLanguage={setLanguage} ak_dc={ak_dc} />
          ) : (
            <>
              <TopHeader setLanguage={setLanguage} ak_dc={ak_dc} />
            </>
          )}

          <div className={"content__main hide_menus"}>
            <Routes>
              <Route
                path="/consent-form/:akPt/:akDc/:akOu/:formId"
                element={<ConsentForm />}
              />
              <Route
                path="/consent-form-share/:akPt/:akDc/:akOu/:formId/:rId"
                element={<ConsentFormShare />}
              />
              {/* <Route
                path="/forms-map/:akPt/:akDc/:akOu"
                element={<FormsMap />}
              /> */}
              <Route
                path="/personal-data/:akPt/:akDc/:akOu/:formId"
                element={<ClinPersonalData Datapass={Datapass} />}
              />
              <Route
                path="/patient-personal-data/:akPt/:formId"
                element={<PersonalData Datapass={Datapass} />}
              />
              <Route path="/pain/:rId" element={<Pain />} />
              <Route path="/function/:rId" element={<Function />} />
              <Route path="/wosi/:rId" element={<Wosi />} />
              <Route path="/sacs/:rId" element={<Sacs />} />
              <Route path="/satisfaction/:rId" element={<Satisfaction />} />
              <Route path="/complete/:rId" element={<Complete />} />
              <Route
                path="/feeling-insta/:rId"
                element={<FeelingInstability />}
              />
              <Route path="/ac-insta/:rId" element={<Instability />} />
              <Route path="/result/:rId" element={<Result />} />

              <Route path="/adult-knee-pain/:rId" element={<AdultKneePain />} />
              <Route
                path="/adult-knee-symptoms/:rId"
                element={<AdultKneeSymptoms />}
              />
              <Route
                path="/adult-knee-function/:rId"
                element={<AdultKneeFunction />}
              />
              <Route
                path="/adult-knee-quality-of-life/:rId"
                element={<AdultKneeQualityOfLife />}
              />
              <Route
                path="/adult-knee-satisfaction/:rId"
                element={<AdultKneeSatisfaction />}
              />
              <Route
                path="/patellar-instability/:rId"
                element={<NorwichPatellarInstability />}
              />
              <Route
                path="/patellar-instability-pedia/:rId"
                element={<NorwichPatellarInstabilityPedia />}
              />
              <Route
                path="/adult-knee-complete/:rId"
                element={<AdultKneeComplete />}
              />
              <Route
                path="/adult-knee-result/:rId"
                element={<AdultKneeResult />}
              />
              <Route
                path="/shoulder-treatment-form/step-1/:rId"
                element={<PatientAssessmentStep1 />}
              />
              <Route
                path="/shoulder-treatment-form/step-2/:rId"
                element={<PatientAssessmentStep2 />}
              />

              <Route
                path="/hip-personal-data/:akPt/:akDc/:akOu/:formId"
                element={<HipPersonalData />}
              />
              <Route path="/hip-pain/:rId" element={<HipPain />} />
              <Route path="/hip-symptoms/:rId" element={<HipSymptoms />} />
              <Route path="/hip-function/:rId" element={<HipFunction />} />
              <Route
                path="/hip-quality-of-life/:rId"
                element={<HipQualityOfLife />}
              />
              <Route
                path="/hip-satisfaction/:rId"
                element={<HipSatisfaction />}
              />
              <Route path="/hip-complete/:rId" element={<HipComplete />} />
              <Route path="/hip-result/:rId" element={<HipResult />} />
              <Route path="/hip-feedback/:rId" element={<Doctorefeedback />} />
              <Route
                path="/hip-feedback-step-2/:rId"
                element={<Doctorefeedback2 />}
              />
              <Route path="/foot-ankle-pain/:rId" element={<FootAnklePain />} />
              <Route
                path="/foot-ankle-instability/:rId"
                element={<FootAnkleInstability />}
              />
              <Route path="/cumberland-ankle/:rId" element={<Cumberland />} />
              <Route
                path="/foot-ankle-other-symptoms/:rId"
                element={<FootAnkleOtherSymptoms />}
              />
              <Route
                path="/foot-ankle-function/:rId"
                element={<FootAnkleFunction />}
              />
              <Route
                path="/foot-ankle-quality-of-life/:rId"
                element={<FootAnkleQualityOfLife />}
              />
              <Route
                path="/foot-ankle-satisfaction/:rId"
                element={<FootAnkleSatisfaction />}
              />
              <Route
                path="/foot-ankle-complete/:rId"
                element={<FootAnkleComplete />}
              />
              <Route
                path="/foot-ankle-result/:rId"
                element={<FootAnkleResult />}
              />

              <Route path="/knee-feedback/:rId" element={<KneeFeedback />} />
              <Route
                path="/knee-feedback-step-2/:rId"
                element={<KneeFeedbackstep2 />}
              />

              <Route
                path="/adult-knee-feedback/:rId"
                element={<AdultKneeFeedback />}
              />
              <Route
                path="/adult-knee-feedback-step-2/:rId"
                element={<AdultKneeFeedbackstep2 />}
              />
              <Route
                path="/pedia-knee-feedback/:rId"
                element={<PediaKneeFeedback />}
              />
              <Route
                path="/pedia-knee-feedback-step-2/:rId"
                element={<PediaKneeFeedbackstep2 />}
              />

              <Route path="/pedia-knee-pain/:rId" element={<PediaKneePain />} />
              <Route
                path="/pedia-knee-symptoms/:rId"
                element={<PediaKneeSymptoms />}
              />
              <Route
                path="/pedia-knee-function/:rId"
                element={<PediaKneeFunction />}
              />
              <Route
                path="/pedia-knee-quality-of-life/:rId"
                element={<PediaKneeQualityOfLife />}
              />
              <Route
                path="/pedia-knee-satisfaction/:rId"
                element={<PediaKneeSatisfaction />}
              />
              <Route
                path="/pedia-knee-complete/:rId"
                element={<PediaKneeComplete />}
              />
              <Route
                path="/pedia-knee-result/:rId"
                element={<PediaKneeResult />}
              />
              {/* <Route path="/:akPt/:akDc/:akOu" element={<FormsMap />} /> */}
              <Route path="/*" element={<NotFoundPage />} />

              <Route
                path="/hand-and-wrist-personal-data/:akPt/:akDc/:akOu/:formId"
                element={<HandPersonalData />}
              />
              <Route
                path="/hand-and-wrist-pain/:rId"
                element={<HandWristpain />}
              />
              <Route
                path="/hand-wrist-symptoms/:rId"
                element={<HandWristSymptoms />}
              />
              <Route
                path="/hand-wrist-function/:rId"
                element={<HandWristfunction />}
              />
              <Route path="/hand-wrist-qol/:rId" element={<HandWristQQL />} />
              <Route
                path="/hand-wrist-satisfaction/:rId"
                element={<HandWristSatisfaction />}
              />
              <Route
                path="/hand-wrist-complete/:rId"
                element={<HandWristComplete />}
              />
              <Route
                path="/hand-wrist-result/:rId"
                element={<HandWristResult />}
              />
              <Route
                path="/hand-wrist-feedback/:rId"
                element={<HandWristSurgeon />}
              />
              <Route
                path="/hand-wrist-feedback-step-2/:rId"
                element={<HandWristSurgeon2 />}
              />
              <Route path="/hand-wrist-bctq/:rId" element={<Bctq />} />
              <Route path="/hand-wrist-uram/:rId" element={<HandWristUram />} />
              <Route
                path="/personal-data/:akPt/:akDc/:akOu/:formId"
                element={<PersonalData Datapass={Datapass} />}
              />
              <Route
                path="/Foot-ankle-personal-data/:akPt/:akDc/:akOu/:formId"
                element={<FootPersonalData />}
              />
              <Route path="/elbow-pain/:rId" element={<ElbowPain />} />
              <Route path="/elbow-function/:rId" element={<ElbowFunction />} />
              <Route path="/elbow-symptoms/:rId" element={<Symptoms />} />
              <Route path="/elbow-qol/:rId" element={<ElbowQualityOfLife />} />
              <Route
                path="/elbow-satisfaction/:rId"
                element={<ElbowSatisfaction />}
              />
              <Route path="/elbow-complete/:rId" element={<ElbowComplete />} />
              <Route path="/elbow-result/:rId" element={<ElbowResultPage />} />
              <Route path="/elbow-feedback/:rId" element={<ElbowFeedback />} />
              <Route
                path="/elbow-feedback-step2/:rId"
                element={<ElbowFeedbackStep2 />}
              />
              <Route
                path="/foot-doctor-feedback/:rId"
                element={<Doctorfeedback />}
              />
              <Route
                path="/foot-doctor-feedback-step-2/:rId"
                element={<DoctorfeedbackStep2 />}
              />
              <Route
                path="/foot-doctor-feedback-step-3/:rId"
                element={<DoctorfeedbackStep3 />}
              />

              <Route
                path="/shoulder-view-treatment-form/:rId"
                element={<ViewTreatmentForm />}
              />
              <Route
                path="/adultknee-view-treatment-form/:rId"
                element={<AdultKneeTreatmentForm />}
              />
              <Route
                path="/pediaknee-view-treatment-form/:rId"
                element={<PediaKneeTreatment />}
              />
              <Route
                path="/hip-view-treatment-form/:rId"
                element={<HipTreatment />}
              />
              <Route
                path="/foot-ankle-view-treatment-form/:rId"
                element={<FootAnkleTreatment />}
              />
              <Route
                path="/elbow-view-treatment-form/:rId"
                element={<ElbowTreatment />}
              />
              <Route
                path="/hand-wrist-view-treatment-form/:rId"
                element={<HandWristTreatment />}
              />
              <Route
                path="/consent-form-view/:rId/:pId"
                element={<ConsentForm_view />}
              />
              <Route path="/general-history/:rId" element={<History />} />
              <Route path="/general-past/:rId" element={<Past />} />
              <Route path="/general-social/:rId" element={<Social />} />
              <Route
                path="/general-treatment-view/:rId"
                element={<ViewTreatmentFormGeneral />}
              />
              <Route
                path="/general-feedback/:rId"
                element={<DoctorefeedbackGen />}
              />
              <Route
                path="/general-complete/:rId"
                element={<GeneralComplete />}
              />
              <Route
                path="/medical-treatment/:akpt"
                element={<MedicalTreatmentView />}
              />

              <Route
                path="/medical-history/:ptId"
                element={<TreatmentHistory />}
              />
              <Route path="/medical-past/:ptId" element={<TreatmentPast />} />
              <Route
                path="/medical-social/:ptId"
                element={<TreatmentSocial />}
              />
              <Route path="/un-authorized-access" element={<Unauthorized />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppRouter;

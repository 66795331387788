import React from "react";
import useTranslation from "../../customHooks/translations";
export default function HandWristPain({ handPain, AllData }) {
  const translations = useTranslation();
  console.log(handPain);
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>
          {translations.Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire}
        </h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} : {AllData.assessment_date}
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_hand_pain}
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q1}({translations.Right})
            </label>
            <p>{handPain.painTodayRating}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q1}({translations.Left})
            </label>
            <p>{handPain.painTodayRatingL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q2} ({translations.Right})
            </label>
            <p>{handPain.howBadThisMomentR}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q2} ({translations.Left})
            </label>
            <p>{handPain.howBadThisMomentL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q3} ({translations.Right})
            </label>
            <p>
              {handPain.painHandR === "4"
                ? translations.patient_hand_pain_q3_a5
                : handPain.painHandR === "3"
                ? translations.patient_hand_pain_q3_a4
                : handPain.painHandR === "2"
                ? translations.patient_hand_pain_q3_a3
                : handPain.painHandR === "1"
                ? translations.patient_hand_pain_q3_a2
                : handPain.painHandR === "0"
                ? translations.patient_hand_pain_q3_a1
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_pain_q3} ({translations.Left})
            </label>
            <p>
              {handPain.painHandL === "4"
                ? translations.patient_hand_pain_q3_a5
                : handPain.painHandL === "3"
                ? translations.patient_hand_pain_q3_a4
                : handPain.painHandL === "2"
                ? translations.patient_hand_pain_q3_a3
                : handPain.painHandL === "1"
                ? translations.patient_hand_pain_q3_a2
                : handPain.painHandL === "0"
                ? translations.patient_hand_pain_q3_a1
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from '../../../custom/result-circle-progress/ResultCircleProgress'
import Button from "../../../custom/button/Button";
import useTranslation from "../../../customHooks/translations"
import useAxios from "../../../../axiosinstance";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import Share from "../../Share";
import {
    updateElbowTotalScores,
    updateElbowScores,
    updateElbowPainData,
    updateElbowFunctionData,
    updateElbowQOLData,
    updateElbowSatisficationData,
    updateElbowFeedbackScores,
    updateElbowSymptomsData

} from "../../../../redux/actions/elbowAction";
import NameCustom from "../../../custom/name/NameCustom";
export default function ElbowResultPage() {
    const translation = useTranslation()
    const dispatch = useDispatch()
    const { rId } = useParams();
    let type=22;
    const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
    const [isLoading, setIsLoading] = useState(true);
    const axiosInstance = useAxios();
    const [isdoctorform, setisdoctorform] = useState(Number())
    const [totalScore] = useSelector((state) => state.elbowScore.TotalScores);
    const navigate = useNavigate();
    const [status, setStatus] = useState("");

    const save = () => {
        axiosInstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
            .then((res) => {
                // setInitialAssesment(res.data.data2[0].initial_assessment)
                dispatch(updateElbowPainData(res.data.data2[0].painScore));
                dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
                dispatch(updateElbowScores(res.data.data2[0].elbowPain));
                dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
                dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
                dispatch(
                    updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
                );
                dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
                dispatch(updateElbowTotalScores(res.data.data2[0].TotalScores));
                setisdoctorform(res.data.data2[0].is_doctor_form)
                setStatus(res.data.data2[0].status);
            }).catch((err) => {

                navigate('/un-authorized-access');

            });
    };

    useEffect(() => {
        save();
    }, []);
    const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
    const continueRedirect = (e) => {
        setDisplayConfirmationModal(true)
        // if (isdoctorform === 1) {

        //     window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
        // } else {

        //     window.location.href = process.env.REACT_APP_PATIENT_URL;
        // }
    };

    const continueRedirect2 = (e) => {
        window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
     
      };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const EQSELF_CARE = totalScore.EQwashing;
    const EQMobility = totalScore.EQproblem;
    const EQActivitie = totalScore.EQactivities;
    const EQDiscomfort = totalScore.EQdiscomfort;
    const EQAnxiety = totalScore.EQanxiety;
    const EQVAS = totalScore.EQpercentage;

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="result_screen mb_100">
                    <div className="panel">
                        <div className="panel-body p_25">
                            <div>
                                <NameCustom />
                                <h3 className="text-center f_22 mt_30">{translation.patient_elbow_results}</h3>
                                <p className="answer_txt">{translation.patient_elbow_results_text1}</p>
                            </div>
                            <div className="row result_scorebox">
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 ">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.patient_elbow_results_function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.QuickDASHDisabilityLFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 ">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.patient_elbow_results_function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.QuickDASHDisabilityRFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}


                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.QuickDASH_Work} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.QuickDashWorkRFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.QuickDASH_Work} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.QuickDashWorkLFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.QuickDASH_Sport} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.QuickDashSportRFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={true} title={translation.QuickDASH_Sport} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.QuickDashSportLFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.AsesPainSumF} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.AsesPainSumFL} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : ""}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.AsesFunctionRFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.AsesFunctionLFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Satisfaction} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.AsesRFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.ASES_E_Satisfaction} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.AsesLFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {/* {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.MEPS} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.MepsRFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.MEPS} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.MepsLFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )} */}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.HSS_Short} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.HssElbowPainRSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.HSS_Short} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.HssElbowPainLSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.OesPainRFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.OesPainLFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.OesFunRFSum} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Function} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.OesFunLFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Social} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.OesSocialRFSum} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.OES_Social} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.OesSocialLFSum} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.patient_elbow_results_pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} persent={totalScore.VASRateElbowFL * 10} value={totalScore.VASRateElbowFL} total="10" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : ""}

                                {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 ">
                                        <ResultCircleProgress min_score={0} reverse={false} title={translation.patient_elbow_results_pain} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} persent={totalScore.VASRateElbowF * 10} value={totalScore.VASRateElbowF} total="10" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : ""}

                                {/* {evlElbowPain === "1" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_elbow_results_self_evaluation} arm={translation.Right_Elbow} value={totalScore.HipperT} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : ""}
                                {evlElbowPain === "2" || evlElbowPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_elbow_results_self_evaluation} arm={translation.Left_Elbow} value={totalScore.HipperTL} total="100" date="22/09/2022" color="#78b776"  />
                                    </div>
                                ) : ""} */}

                                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={1}  reverse={true} title={translation.EQ_5D_5L_Mobility} type={translation.patient_elbow_results_self_evaluation} arm={translation.Elbow} hide={"1"} persent={EQMobility * 20} value={`Level ${EQMobility}`} total="5" date="22/09/2022" color="#78b776" />
                                </div>

                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={1}  reverse={true} title={translation.EQ_5D_5L_SELF_CARE} type={translation.patient_elbow_results_self_evaluation} arm={translation.Elbow} hide={"1"} persent={EQSELF_CARE * 20} value={`Level ${EQSELF_CARE}`} total="5" date="22/09/2022" color="#006DFF" />
                                </div>

                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={1}  reverse={true} title={translation.EQ_5D_5L_Usual_Activities} type={translation.patient_elbow_results_self_evaluation} arm={translation.Elbow} hide={"1"} persent={EQActivitie * 20} value={`Level ${EQActivitie}`} total="5" date="22/09/2022" color="#ff0000" />
                                </div>

                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={1}  reverse={true} title={translation.EQ_5D_5L_Pain_Discomfort} type={translation.patient_elbow_results_self_evaluation} arm={translation.Elbow} hide={"1"} persent={EQDiscomfort * 20} value={`Level ${EQDiscomfort}`} total="5" date="22/09/2022" color="#006DFF" />
                                </div>

                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={1}  reverse={true} title={translation.EQ_5D_5L_ANXIETY_DEPRESSION} type={translation.patient_elbow_results_self_evaluation} arm={translation.Elbow} hide={"1"} persent={EQAnxiety * 20} value={`Level ${EQAnxiety}`} total="5" date="22/09/2022" color="#78b776" />
                                </div> */}

                                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                    <ResultCircleProgress min_score={0} reverse={false} title={translation.patient_elbow_results_health_perception} type={translation.patient_elbow_results_self_evaluation} value={EQVAS} total="100" date="22/09/2022" color="#78b776" />
                                </div> */}

                            </div>
                            {status === "1" ? (
                                <div className="pt_70 pb_50 text-center">
                  <h6 className="mb_20 f_600">
                    {/* {translation.patient_shoulder_results_text2} */}
                  </h6>
                  <Button
                  onClick={continueRedirect2}
                  value={translation.Sign_out}
                  buttonStyle="btn_fill result_submit_btn"
                />
                 
                </div>
              ) : (
                            <div className="pt_70 pb_50 text-center">
                                {/* <h6 className="mb_20 f_600">{translation.patient_elbow_results_text4}</h6> */}
                                {/* <Button
                                    onClick={continueRedirect}
                                    value={translation.Sign_out}
                                    buttonStyle="btn_fill result_submit_btn"
                                /> */}
                                <Share
                                type={type}
                                rId={rId}
                                />
                            </div>

)}
                        </div>
                    </div>
                </div>
            )}
            <SubmitModel
     showModal={displayConfirmationModal}
     
     />
        </>
    )
}

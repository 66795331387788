import React, { useState, useEffect } from "react";
// import AssessmentHeader from "../../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
// import RadioButton from "../../../../custom/radio-button/RadioButton";
// import RadioSwitch from "../../../../custom/radio-switch/RadioSwitch";
import FullDropdown from "../../../../custom/dropdown/FullDropdown";
// import InputField from "../../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../../custom/assessment-widgets/AssessmentDegreeWidget";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  updateAdultKneeFunctionFieldScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeQolScore,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFieldFeedback,
  updateAdultKneeFeedback,
} from "../../../../../redux/actions/index";
import useTransition from "../../../../customHooks/translations";
import AdultkneeTab from "../../../tab-section/AdultkneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../../custom/toaster/CustomToaster";
import InputField from "../../../../custom/inputfield/InputField";
import RadioButton from "../../../../custom/radio-button/RadioButton";
import KneeDegree from "../../../../custom/assessment-widgets/KneeDegree";
import KneeRadioDegree from "../../../../custom/assessment-widgets/KneeRadioDegree";
import RadioSwitch from "../../../../custom/radio-switch/RadioSwitch";
import useAxios from "../../../../../axiosinstance";
import DoctorNameCustom from "../../../../custom/name/DoctorNameCustom";
export default function KneeFeedback() {
  const axiosInstance = useAxios();
  const translation = useTransition();
  const [gaitR, setGaitR] = useState("0");
  const [gaitL, setGaitL] = useState("0");
  const [anatomiccL, setAnatomicR] = useState("");
  const [anatomicccL, setAnatomicL] = useState("");
  const [anat, setLanatomicR] = useState("");
  const [anatt, setLanatomicL] = useState("");
  const [angleLr, setAngleR] = useState("");
  const [angleLrr, setAngleL] = useState("");
  const [langR, setLangleR] = useState("");
  const [langL, setLangleL] = useState("");
  const [fixeddR, setFixedR] = useState("");
  const [fixeddL, setFixedL] = useState("");
  const [LfixeddR, setLfixedR] = useState("");
  const [LfixedlL, setLfixedL] = useState("");
  const [lagsR, setLagR] = useState("");
  const [lagR, setLagL] = useState("");
  const [lagLR, setLlagR] = useState("");
  const [lagLL, setLlagL] = useState("");
  const [gaitLText, setgaitLText] = useState("");
  const [gaitRText, setgaitRText] = useState("");
  const [radiovalueR, setradiovalueR] = useState("");
  const [radiovalueL, setradiovalueL] = useState("");
  const [radioAngleR, setradioAngleR] = useState("");
  const [radioAngleL, setradioAngleL] = useState("");
  const [GaitRadioR, setGaitRadioR] = useState("");
  const [GaitRadioL, setGaitRadioL] = useState("");
  const [DeformityRText, setDeformityRText] = useState("");
  const [DeformityLText, setDeformityLText] = useState("");
  const [DegreeFixedR, setDegreeFixedR] = useState("");
  const [DegreeFixedL, setDegreeFixedL] = useState("");
  const [ExtensionR, setExtensionR] = useState("");
  const [ExtensionL, setExtensionL] = useState("");
  const [lFlexionR, setlFlexionR] = useState("");
  const [lFlexionL, setlFlexionL] = useState("");
  const [rFlexionR, setrFlexionR] = useState("");
  const [rFlexionL, setrFlexionL] = useState("");
  const [rExtensionL, setrExtensionL] = useState("");
  const [rExtensionR, setrExtensionR] = useState("");
  const [lExtensionL, setlExtensionL] = useState("");
  const [lExtensionR, setlExtensionR] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
   useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  const docFeedback1 = useSelector(
    (state) => state.adultKneeScore.doctorFeedback
  );
  const docFeedback = useSelector(
    (state) => state.adultKneeScore.doctorFeedback[0]
  );

  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setGaitR(docFeedback.gaitR);
    setGaitL(docFeedback.gaitL);
    setAnatomicR(docFeedback.anatomicR);
    setAnatomicL(docFeedback.anatomicL);
    setLanatomicR(docFeedback.anat);
    setLanatomicL(docFeedback.anatt);
    setAngleR(docFeedback.angleLr);
    setAngleL(docFeedback.angleLr);
    setLangleR(docFeedback.langR);
    setLangleL(docFeedback.langL);
    setFixedR(docFeedback.fixeddR);
    setFixedL(docFeedback.fixeddL);
    setLfixedR(docFeedback.LfixeddR);
    setLfixedL(docFeedback.LfixedlR);
    setLagR(docFeedback.lagsR);
    setLagL(docFeedback.lagL);
    setLlagR(docFeedback.lagLR);
    setLlagL(docFeedback.llagL);
    setgaitLText(docFeedback.gaitLText);
    setgaitRText(docFeedback.gaitRText);
    setradiovalueR(docFeedback.radiovalueR);
    setradiovalueL(docFeedback.radiovalueL);
    setradioAngleR(docFeedback.radioAngleR);
    setradioAngleL(docFeedback.radioAngleL);
    setGaitRadioR(docFeedback.GaitRadioR);
    setGaitRadioL(docFeedback.GaitRadioL);
    setDeformityRText(docFeedback.DeformityRText);
    setDeformityLText(docFeedback.DeformityLText);
    setDegreeFixedR(docFeedback.DegreeFixedR);
    setDegreeFixedL(docFeedback.DegreeFixedL);
    setExtensionR(docFeedback.ExtensionR);
    setExtensionL(docFeedback.ExtensionL);
    setlFlexionR(docFeedback.lFlexionR);
    setlFlexionL(docFeedback.lFlexionL);
    setrFlexionR(docFeedback.rFlexionR);
    setrFlexionL(docFeedback.rFlexionL);
    setrExtensionL(docFeedback.rExtensionL);
    setrExtensionR(docFeedback.rExtensionR);
    setlExtensionL(docFeedback.lExtensionL);
    setlExtensionR(docFeedback.lExtensionR);
  }, [docFeedback]);

  // const continueRedirect = (e) => {
  //   updateDate()
  //   navigate("/knee-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`);
  // };
  const continueRedirect = async () => {
    let response = await axiosInstance.current.patch(
      `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      navigate(
        "/knee-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`
      );
    }
  };
  const updateDate = async () => {
    let response = await axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        docFeedback1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };

  const handleGaitR = (e) => {
    setGaitR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "gaitR",
        value: e,
      })
    );
  };
  const handleGaitL = (e) => {
    setGaitL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "gaitL",
        value: e,
      })
    );
  };
  const anatomicR = (e) => {
    setAnatomicR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anatomicR",
        value: e,
      })
    );
  };
  const anatomicL = (e) => {
    setAnatomicL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anatomicL",
        value: e,
      })
    );
  };
  const LanatomicR = (e) => {
    setLanatomicR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anat",
        value: e,
      })
    );
  };
  const LanatomicL = (e) => {
    setLanatomicL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anatt",
        value: e,
      })
    );
  };
  const angleR = (e) => {
    setAngleR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "angleLr",
        value: e,
      })
    );
  };
  const angleL = (e) => {
    setAngleL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "angleLrr",
        value: e,
      })
    );
  };
  const LangleR = (e) => {
    setLangleR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "langR",
        value: e,
      })
    );
  };
  const LangleL = (e) => {
    setLangleL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "langL",
        value: e,
      })
    );
  };
  const fixedR = (e) => {
    setFixedR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "fixeddR",
        value: e,
      })
    );
  };
  const fixedL = (e) => {
    setFixedL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "fixeddL",
        value: e,
      })
    );
  };
  const LfixedR = (e) => {
    setLfixedR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "LfixeddR",
        value: e,
      })
    );
  };
  const LfixedL = (e) => {
    setLfixedL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "LfixedlR",
        value: e,
      })
    );
  };
  const lagrR = (e) => {
    setLagR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lagsR",
        value: e,
      })
    );
  };
  const lagL = (e) => {
    setLagL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lagR",
        value: e,
      })
    );
  };
  const LlagrR = (e) => {
    setLlagR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lagLR",
        value: e,
      })
    );
  };
  const LlagrL = (e) => {
    setLlagL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lagLL",
        value: e,
      })
    );
  };

  const handleGaitLText = (e) => {
    setgaitLText(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "gaitLText",
        value: e,
      })
    );
  };
  const handleGaitRText = (e) => {
    setgaitRText(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "gaitRText",
        value: e,
      })
    );
  };

  const RadioChange = (e) => {
    setradiovalueR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "radiovalueR",
        value: e,
      })
    );
  };
  const RadioChange1 = (e) => {
    setradiovalueL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "radiovalueL",
        value: e,
      })
    );
  };
  const RadioChangeAngleR = (e) => {
    setradioAngleR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "radioAngleR",
        value: e,
      })
    );
  };
  const RadioChangeAngleL = (e) => {
    setradioAngleL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "radioAngleL",
        value: e,
      })
    );
  };

  const RadioDegreeFixedR = (e) => {
    setDegreeFixedR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "DegreeFixedR",
        value: e,
      })
    );
  };
  const RadioDegreeFixedL = (e) => {
    setDegreeFixedL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "DegreeFixedL",
        value: e,
      })
    );
  };
  const RadioDegreeExtensionR = (e) => {
    setExtensionR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ExtensionR",
        value: e,
      })
    );
  };
  const RadioDegreeExtensionL = (e) => {
    setExtensionL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ExtensionL",
        value: e,
      })
    );
  };

  const LFlexionR = (e) => {
    setlFlexionR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lFlexionR",
        value: e,
      })
    );
  };
  const LFlexionL = (e) => {
    setlFlexionL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lFlexionL",
        value: e,
      })
    );
  };
  const RFlexionR = (e) => {
    setrFlexionR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rFlexionR",
        value: e,
      })
    );
  };
  const RFlexionL = (e) => {
    setrFlexionL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rFlexionL",
        value: e,
      })
    );
  };
  const LExtensionR = (e) => {
    setlExtensionR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lExtensionR",
        value: e,
      })
    );
  };
  const LExtensionL = (e) => {
    setlExtensionL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lExtensionL",
        value: e,
      })
    );
  };
  const RExtensionR = (e) => {
    setrExtensionR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rExtensionR",
        value: e,
      })
    );
  };
  const RExtensionL = (e) => {
    setrExtensionL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rExtensionL",
        value: e,
      })
    );
  };

  const handleGaitRadioR = (e) => {
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "GaitRadioR",
        value: e,
      })
    );
    setGaitRadioR(e);
  };
  const handleGaitRadioL = (e) => {
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "GaitRadioL",
        value: e,
      })
    );
    setGaitRadioL(e);
  };

  const handleDeformityRText = (e) => {
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "DeformityRText",
        value: e,
      })
    );
    setDeformityRText(e);
  };
  const handleDeformityLText = (e) => {
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "DeformityLText",
        value: e,
      })
    );
    setDeformityLText(e);
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateDate();
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  return (
    <>
      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50">
        {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
      </div>
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}
        <HeaderInnerBreadcrumb />

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {"Gait"}
          </h4>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <div className="mb_20">
                <RadioSwitch
                  labelLeft={"Normal"}
                  labelRight={"Abnormal"}
                  idLeft={"12"}
                  idRight={"13"}
                  name={"13"}
                  // title={translation.Joint_line_tenderness_L}
                  styleClass=""
                  value={GaitRadioR}
                  onChange={handleGaitRadioR}
                  placeholder="select"
                />
                {GaitRadioR === "1" && (
                  <>
                    <FullDropdown
                      data={[
                        { value: "0", label: "Normal" },
                        { value: "1", label: "Antalgic" },
                        { value: "2", label: "Stiff" },
                        { value: "3", label: "Varus thrust" },
                        { value: "4", label: "Hyperextension thrust" },
                        { value: "5", label: "Others" },
                      ]}
                      label={translation.Gait_Right}
                      styleClass=""
                      value={gaitR}
                      onChange={handleGaitR}
                    />
                    {gaitR == "5" && (
                      <InputField
                        value={gaitRText}
                        onChange={handleGaitRText}
                        placeholder={"Others"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="mb_20">
                <RadioSwitch
                  labelLeft={"Normal"}
                  labelRight={"Abnormal"}
                  idLeft={"14"}
                  idRight={"15"}
                  name={"15"}
                  // title={translation.Joint_line_tenderness_L}
                  styleClass=""
                  value={GaitRadioL}
                  onChange={handleGaitRadioL}
                  placeholder="select"
                />
                {GaitRadioL === "1" && (
                  <>
                    <FullDropdown
                      data={[
                        { value: "0", label: "Normal" },
                        { value: "1", label: "Antalgic" },
                        { value: "2", label: "Stiff" },
                        { value: "3", label: "Varus thrust" },
                        { value: "4", label: "Hyperextension thrust" },
                        { value: "5", label: "Others" },
                      ]}
                      label={translation.Gait_Left}
                      styleClass=""
                      value={gaitL}
                      onChange={handleGaitL}
                    />
                    {gaitL === "5" && (
                      <InputField
                        value={gaitLText}
                        onChange={handleGaitLText}
                        placeholder={"Others"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {"Gait Abnormality"}
          </h4>
          <div className="row mb_30">
          </div>
        </div> */}

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {"Alignment"}
          </h4>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <KneeDegree
                title={translation.Left_Anatomic_alignment_Based}
                valueR={anat}
                valueL={anatt}
                onChangeR={LanatomicR}
                onChangeL={LanatomicL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.L_Anatomic_Right}
                nameL={translation.L_Anatomic_Left}
                RadioChange={RadioChange1}
                radioname="2"
              />
            </div>

            <div className="col-md-6 col-sm-6">
              <KneeDegree
                title={translation.Right_Anatomic_alignment_Based}
                onChangeR={anatomicR}
                onChangeL={anatomicL}
                valueR={anatomiccL}
                valueL={anatomicccL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.Anatomic_Right}
                nameL={translation.Anatomic_Left}
                RadioChange={RadioChange}
                radioname="1"
              />
            </div>
          </div>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <KneeDegree
                title={translation.Left_Q_angle}
                valueR={angleLr}
                valueL={angleLrr}
                onChangeR={LangleR}
                onChangeL={LangleL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.L_Angle_Right}
                nameL={translation.L_Angle_Left}
                radioname="4"
                RadioChange={RadioChangeAngleL}
              />
            </div>

            <div className="col-md-6 col-sm-6">
              <KneeDegree
                title={translation.Right_Q_angle}
                valueR={angleLr}
                valueL={angleLrr}
                onChangeR={angleR}
                onChangeL={angleL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.Angle_Right}
                nameL={translation.Angle_Left}
                radioname="3"
                RadioChange={RadioChangeAngleR}
              />
            </div>
          </div>
        </div>
        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {"Contracture"}
          </h4>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <KneeRadioDegree
                title={translation.Left_Fixed_flexion_contracture}
                valueR={LfixeddR}
                onChangeR={LfixedR}
                onChangeL={LfixedL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.l_fixed_Right}
                nameL={translation.l_fixed_Left}
                radioSwitchR={"3"}
                radioSwitchL={"4"}
                RadioDegree={RadioDegreeFixedL}
              />
            </div>

            <div className="col-md-6 col-sm-6">
              <KneeRadioDegree
                title={translation.Right_Fixed_flexion_contracture}
                valueR={fixeddR}
                // valueL={}
                onChangeR={fixedR}
                onChangeL={fixedL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.fixed_Right}
                nameL={translation.fixed_Left}
                radioSwitchR={"1"}
                radioSwitchL={"2"}
                RadioDegree={RadioDegreeFixedR}
              />
            </div>
          </div>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <KneeRadioDegree
                title={translation.Left_Extension_lag}
                onChangeR={LlagrR}
                valueR={lagLR}
                // onChangeL={LlagrL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.L_lag_Right}
                nameL={translation.L_lag_left}
                radioSwitchR={"7"}
                radioSwitchL={"8"}
                RadioDegree={RadioDegreeExtensionL}
              />
            </div>

            <div className="col-md-6 col-sm-6">
              <KneeRadioDegree
                title={translation.Right_Extension_lag}
                valueR={lagsR}
                onChangeR={lagrR}
                // onChangeL={lagL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.lag_Right}
                nameL={translation.lag_left}
                radioSwitchR={"5"}
                radioSwitchL={"6"}
                RadioDegree={RadioDegreeExtensionR}
              />
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {"Other Deformity"}
          </h4>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <InputField
                value={DeformityRText}
                onChange={handleDeformityRText}
                placeholder={"Specify"}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <InputField
                value={DeformityLText}
                onChange={handleDeformityLText}
                placeholder={"Specify"}
              />
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title" id="acl">
            {translation.Range_of_Motion}
          </h4>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                title={translation.R_Flexion}
                valueR={lFlexionR}
                valueL={lFlexionL}
                onChangeR={LFlexionR}
                onChangeL={LFlexionL}
                labelR={translation.Active}
                labelL={translation.Passive}
                nameR={translation.L_lag_Right}
                nameL={translation.L_lag_left}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                title={translation.L_Flexion}
                valueR={rFlexionR}
                valueL={rFlexionL}
                onChangeR={RFlexionR}
                onChangeL={RFlexionL}
                labelR={translation.Active}
                labelL={translation.Passive}
              />
            </div>
          </div>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                title={translation.R_Extension}
                valueR={lExtensionR}
                valueL={lExtensionL}
                onChangeR={LExtensionR}
                onChangeL={LExtensionL}
                labelR={translation.Active}
                labelL={translation.Passive}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                title={translation.L_Extension}
                valueR={rExtensionR}
                valueL={rExtensionL}
                onChangeR={RExtensionR}
                onChangeL={RExtensionL}
                labelR={translation.Active}
                labelL={translation.Passive}
              />
            </div>
          </div>
        </div>

        {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6"></div>
            <div className="col-md-6 col-sm-6 text-end">
              <button
                className="next_btn btn_fill"
                onClick={() => handleSubmit()}
              >
                {translation.submit}
                <span>
                  <img src={nextIcon} alt="" />
                  {/* <img src={nextWhiteIcon} alt="" /> */}
                </span>
              </button>
            </div>
          </div>
        )}

        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              {/* <Link to="/patient-list" className="assm_back_btn">
     <img src={backIcon} alt="" />

     <span>{translation.back_button}</span>
   </Link>

     <span>Back</span>
   </Link> */}
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button className="next_btn btn_fill" onClick={continueRedirect}>
                {translation.next_button}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import React from "react";
import useTranslation from "../../customHooks/translations";

export default function ElbowPain({ elbowPain, AllData }) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>{translations.patient_elbow_results_self_evaluation}</h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} : {AllData.assessment_date}
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_elbow_pain}
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q1} ({translations.Right})
            </label>
            <p>
              {" "}
              {elbowPain.elbowPainR === "0"
                ? translations.patient_elbow_pain_q1_a1
                : elbowPain.elbowPainR === "1"
                ? translations.patient_elbow_pain_q1_a2
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q1} ({translations.Left})
            </label>
            <p>
              {" "}
              {elbowPain.elbowPainL === "0"
                ? translations.patient_elbow_pain_q1_a1
                : elbowPain.elbowPainL === "1"
                ? translations.patient_elbow_pain_q1_a2
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q2} ({translations.Right})
            </label>
            {elbowPain.decElbowPainR === "0"
              ? translations.patient_elbow_pain_q2_a5
              : elbowPain.decElbowPainR === "1"
              ? translations.patient_elbow_pain_q2_a4
              : elbowPain.decElbowPainR === "2"
              ? translations.patient_elbow_pain_q2_a3
              : elbowPain.decElbowPainR === "3"
              ? translations.patient_elbow_pain_q2_a2
              : elbowPain.decElbowPainR === "4"
              ? translations.patient_elbow_pain_q2_a1
              : ""}
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q2} ({translations.Left})
            </label>
            {elbowPain.decElbowPainL === "0"
              ? translations.patient_elbow_pain_q2_a5
              : elbowPain.decElbowPainL === "1"
              ? translations.patient_elbow_pain_q2_a4
              : elbowPain.decElbowPainL === "2"
              ? translations.patient_elbow_pain_q2_a3
              : elbowPain.decElbowPainL === "3"
              ? translations.patient_elbow_pain_q2_a2
              : elbowPain.decElbowPainL === "4"
              ? translations.patient_elbow_pain_q2_a1
              : ""}
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q3} ({translations.Right})
            </label>
            <p>{elbowPain.moment}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q3} ({translations.Left})
            </label>
            <p>{elbowPain.momentL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q4}({translations.Right})
            </label>
            <p>{elbowPain.rateElbow}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q4}({translations.Left})
            </label>
            <p>{elbowPain.rateElbowL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q5} ({translations.Right})
            </label>
            <p>{elbowPain.rateWorst}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q5} ({translations.Left})
            </label>
            <p>{elbowPain.rateWorstL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q6}({translations.Right})
            </label>
            <p>{elbowPain.rateRest}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q6}({translations.Left})
            </label>
            <p>{elbowPain.rateRestL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q7} ({translations.Right})
            </label>
            <p>{elbowPain.rateObj}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q7} ({translations.Left})
            </label>
            <p>{elbowPain.rateObjL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q8}({translations.Right})
            </label>
            <p>{elbowPain.rateMovements}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q8}({translations.Left})
            </label>
            <p>{elbowPain.rateMovementsL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q9}({translations.Right})
            </label>
            <p>{elbowPain.rateNight}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_elbow_pain_q9}({translations.Left})
            </label>
            <p>{elbowPain.rateNightL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>

                  <th>{translations.Right_Elbow}</th>

                  <th>{translations.Left_Elbow}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_elbow_pain_q10} </td>

                  <td>
                    {elbowPain.troubledR === "4"
                      ? translations.patient_elbow_pain_q10_a5
                      : elbowPain.troubledR === "3"
                      ? translations.patient_elbow_pain_q10_a4
                      : elbowPain.troubledR === "2"
                      ? translations.patient_elbow_pain_q10_a3
                      : elbowPain.troubledR === "1"
                      ? translations.patient_elbow_pain_q10_a2
                      : elbowPain.troubledR === "0"
                      ? translations.patient_elbow_pain_q10_a1
                      : ""}
                  </td>
                  <td>
                    {elbowPain.troubledL === "4"
                      ? translations.patient_elbow_pain_q10_a5
                      : elbowPain.troubledL === "3"
                      ? translations.patient_elbow_pain_q10_a4
                      : elbowPain.troubledL === "2"
                      ? translations.patient_elbow_pain_q10_a3
                      : elbowPain.troubledL === "1"
                      ? translations.patient_elbow_pain_q10_a2
                      : elbowPain.troubledL === "0"
                      ? translations.patient_elbow_pain_q10_a1
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>{translations.patient_elbow_pain_q11} </td>
                  <td>
                    {elbowPain.oftenR === "4"
                      ? translations.patient_elbow_pain_q11_a5
                      : elbowPain.oftenR === "3"
                      ? translations.patient_elbow_pain_q11_a4
                      : elbowPain.oftenR === "2"
                      ? translations.patient_elbow_pain_q11_a3
                      : elbowPain.oftenR === "1"
                      ? translations.patient_elbow_pain_q11_a2
                      : elbowPain.oftenR === "0"
                      ? translations.patient_elbow_pain_q11_a1
                      : ""}
                  </td>
                  <td>
                    {elbowPain.oftenL === "4"
                      ? translations.patient_elbow_pain_q11_a5
                      : elbowPain.oftenL === "3"
                      ? translations.patient_elbow_pain_q11_a4
                      : elbowPain.oftenL === "2"
                      ? translations.patient_elbow_pain_q11_a3
                      : elbowPain.oftenL === "1"
                      ? translations.patient_elbow_pain_q11_a2
                      : elbowPain.oftenL === "0"
                      ? translations.patient_elbow_pain_q11_a1
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

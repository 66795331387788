import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../../axiosinstance";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import {
  updateFootComberlandFieldScore,
  updateFootComberlandData,
  updateFootInstabilityData,
  updateFootAnklePainData,
  updateFootSymptomsData,
  updateFootAnkle,
  updateFootQolData,
  updateFootFunctionData,
  updateFootSatisfactionData,
  updateFootFeedbackfootankle,
  updateAllData,
} from "../../../../redux/actions/footAnkle";
// import Button from "../../custom/button/Button";
import useTranslation from "../../../customHooks/translations";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import ElbowFeedbackScoreRangeSlider from "../../../custom/elbow-doctorfeedback-score-range-slider/ElbowFeedbackScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";
function Cumberland() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const ankleChk = useSelector(
    (state) => state.footAnkle.instabilityScore[0].doesUneffectedR
  );
  const [isLoading, setIsLoading] = useState(true);
  const [descripR, setDescripR] = useState("0");
  const [descripL, setDescripL] = useState("0");
  // const [anklePain, setAnklePain] = useState("3");
  const [unstableR, setUnstableR] = useState("0");
  const [unstableL, setUnstableL] = useState("0");
  const [sharpR, setSharpR] = useState("0");
  const [sharpL, setSharpL] = useState("0");
  const [stairsR, setStairsR] = useState("0");
  const [stairsL, setStairsL] = useState("0");
  const [standR, setStandR] = useState("0");
  const [standL, setStandL] = useState("0");
  const [feelsR, setFeelsR] = useState("0");
  const [feelsL, setFeelsL] = useState("0");
  const [feelsankR, setFeelsankR] = useState("0");
  const [feelsankL, setFeelsankL] = useState("0");
  const [twistR, setTwistR] = useState("0");
  const [twistL, setTwistL] = useState("0");
  const [roleR, setRoleR] = useState("0");
  const [roleL, setRoleL] = useState("0");
  const [updateTotal, setUpdateTotal] = useState(false);
  const navigate = useNavigate();

  const handleDescripR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "descripR",
        value: e,
      })
    );
    setDescripR(e);
  };
  const handleDescripL = (e) => {
    setDescripL(e);
    dispatch(
      updateFootComberlandFieldScore({
        key: "descripL",
        value: e,
      })
    );
  };
  // const handleAnklePain = (e) => {
  //     setAnklePain(e);

  // }
  const handleUnstableR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "unstableR",
        value: e,
      })
    );
    setUnstableR(e);
  };
  const handleUnstableL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "unstableL",
        value: e,
      })
    );
    setUnstableL(e);
  };
  const handleSharpR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "sharpR",
        value: e,
      })
    );
    setSharpR(e);
  };
  const handleSharpL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "sharpL",
        value: e,
      })
    );
    setSharpL(e);
  };
  const handleStairsR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "stairsR",
        value: e,
      })
    );
    setStairsR(e);
  };
  const handleStairsL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "stairsL",
        value: e,
      })
    );
    setStairsL(e);
  };
  const handleStandR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "standR",
        value: e,
      })
    );
    setStandR(e);
  };
  const handleStandL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "standL",
        value: e,
      })
    );
    setStandL(e);
  };
  const handleFeelsR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "feelsR",
        value: e,
      })
    );
    setFeelsR(e);
  };
  const handleFeelsL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "feelsL",
        value: e,
      })
    );
    setFeelsL(e);
  };
  const handleFeelsankR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "feelsankR",
        value: e,
      })
    );
    setFeelsankR(e);
  };
  const handleFeelsankL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "feelsankL",
        value: e,
      })
    );
    setFeelsankL(e);
  };
  const handleTwistR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "twistR",
        value: e,
      })
    );
    setTwistR(e);
  };
  const handleTwistL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "twistL",
        value: e,
      })
    );
    setTwistL(e);
  };
  const handleRoleR = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "roleR",
        value: e,
      })
    );
    setRoleR(e);
  };
  const handleRoleL = (e) => {
    dispatch(
      updateFootComberlandFieldScore({
        key: "roleL",
        value: e,
      })
    );
    setRoleL(e);
  };

  const [chk, setChk] = useState(false);
  const FootInsta = useSelector((state) => state.footAnkle.comberland[0]);
  const json = useSelector((state) => state.footAnkle);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setDescripR(FootInsta.descripR);
    setDescripL(FootInsta.descripL);
    setUnstableR(FootInsta.unstableR);
    setUnstableL(FootInsta.unstableL);
    setSharpR(FootInsta.sharpR);
    setSharpL(FootInsta.sharpL);
    setStairsR(FootInsta.stairsR);
    setStairsL(FootInsta.stairsL);
    setStandR(FootInsta.standR);
    setStandL(FootInsta.standL);
    setFeelsR(FootInsta.feelsR);
    setFeelsL(FootInsta.feelsL);
    setFeelsankR(FootInsta.feelsankR);
    setFeelsankL(FootInsta.feelsankL);
    setTwistR(FootInsta.twistR);
    setTwistL(FootInsta.twistL);
    setRoleR(FootInsta.roleR);
    setRoleL(FootInsta.roleL);
  }, [FootInsta]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [FootInsta, updateTotal]);
  const continueRedirect = (e) => {
    dispatch(
      updateFootComberlandData([
        {
          descripR,
          descripL,
          unstableR,
          unstableL,
          sharpR,
          sharpL,
          stairsR,
          stairsL,
          standR,
          standL,
          feelsR,
          feelsL,
          feelsankR,
          feelsankL,
          twistR,
          twistL,
          roleR,
          roleL,
        },
      ])
    );
    navigate("/foot-ankle-other-symptoms/" + rId);
  };
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_foot_ankle_cumberland}
              </h4>
              <strong>
                {translation.patient_foot_ankle_cumberland_instruction}
              </strong>
              {/* <div className="row">
                                <div className="mb_20">
                                    <FullDropdown
                                        data={[
                                            {
                                                value: "1",
                                                label: "Right",
                                            },
                                            {
                                                value: "2",
                                                label: "Left",
                                            },
                                            {
                                                value: "3",
                                                label: "Both",
                                            },
                                        ]}
                                        label={translation.Evaluated_foot_ankle}
                                        styleClass=""
                                        value={anklePain}
                                        onChange={handleAnklePain}
                                    />
                                </div>

                            </div>  */}
              <div className="row mt-2">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <ElbowFeedbackScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q1 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={descripR}
                        placeholder={translation.Runn_un}
                        onChange={handleDescripR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a5,
                          },
                          {
                            score: "5",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a6,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <ElbowFeedbackScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q1 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={descripL}
                        onChange={handleDescripL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a5,
                          },
                          {
                            score: "5",
                            label:
                              translation.patient_foot_ankle_cumberland_q1_a6,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-5 mb-5">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q2 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={unstableR}
                        onChange={handleUnstableR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q2 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={unstableL}
                        onChange={handleUnstableL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="3"
                        label={
                          translation.patient_foot_ankle_cumberland_q3 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={sharpR}
                        onChange={handleSharpR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="3"
                        label={
                          translation.patient_foot_ankle_cumberland_q3 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={sharpL}
                        onChange={handleSharpL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q3_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="3"
                        label={
                          translation.patient_foot_ankle_cumberland_q4 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={stairsR}
                        onChange={handleStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="3"
                        label={
                          translation.patient_foot_ankle_cumberland_q4 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={stairsL}
                        onChange={handleStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q4_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="2"
                        label={
                          translation.patient_foot_ankle_cumberland_q5 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={standR}
                        onChange={handleStandR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a3,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        maxnum="2"
                        label={
                          translation.patient_foot_ankle_cumberland_q5 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={standL}
                        onChange={handleStandL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q5_a3,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q6 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={feelsR}
                        maxnum="3"
                        onChange={handleFeelsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q6 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={feelsL}
                        maxnum="3"
                        onChange={handleFeelsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q6_a4,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2 mb-4">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q7 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={feelsankR}
                        onChange={handleFeelsankR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q7 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={feelsankL}
                        onChange={handleFeelsankL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2 mb-4">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q8 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={twistR}
                        onChange={handleTwistR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q8 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={twistL}
                        onChange={handleTwistL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2 mb-4">
                {anklePain === "1" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q9 +
                          RigthTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={roleR}
                        onChange={handleRoleR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || ankleChk === "0" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_cumberland_q9 +
                          LeftTrans(anklePain, translation, ankleChk)
                        }
                        styleClass=""
                        value={roleL}
                        onChange={handleRoleL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_cumberland_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/foot-ankle-instability/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Cumberland;

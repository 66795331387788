import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

function Satisfaction({ satisfactionData, AllData })  {
  const translation = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translation.patient_shoulder_satisfaction}</h2>
          <div className="treatment-header-right">
          <p>{translation.Assessment_Date} : {AllData.assessment_date}</p>
          </div>
        </div>

        <div className="treatment-content">
          {/* <div className="treatment-row-header bd-none mb-0">section A: PAIN</div> */}

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                   
                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                   {translation.patient_shoulder_satisfaction_q1}:
                    </td>
                    
                    <td>{satisfactionData.satisfaction_right === "0" ? translation.patient_shoulder_satisfaction_q1_a2 : satisfactionData.satisfaction_right === "1" ? translation.patient_shoulder_satisfaction_q1_a1 : ""}</td>
                    <td>{satisfactionData.satisfaction_left === "0" ? translation.patient_shoulder_satisfaction_q1_a2 : satisfactionData.satisfaction_left === "1" ? translation.patient_shoulder_satisfaction_q1_a1 : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Satisfaction;

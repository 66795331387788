import React, { useState, useEffect } from "react";
import ViewPast from "./ViewPast";

import ViewHistory from "./ViewHistory";
import ViewSocial from "./ViewSocial";
import useAxios from "../../../axiosinstance";
import { useParams, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Button from "../../custom/button/Button";
import PersonalInformation from "./PersonalInformation";
import ViewFeedback from "./ViewFeedback";
export default function ViewTreatmentForm() {
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const axiosInstance = useAxios();
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(()=>{
    localStorage.setItem("isdoctorForm",isdoctorForm)
  },[isdoctorForm])
  // const [initialAssesment, setInitialAssesment] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [historyData, setHistorydata] = useState({});
  const [socialData, setSocialdata] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [pastData, setPastdata] = useState({});
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [doctorFeed, setDoctorScore] = useState({});
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);

  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=33&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        console.log(res.data);
        // setInitialAssesment(res.data.data2[0].initial_assessment)
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setHistorydata(res.data.data2[0].History[0]);
        setSocialdata(res.data.data2[0].Social[0]);
        setPastdata(res.data.data2[0].Past[0]);
        seteditShow(res.data.status);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setDoctorScore(res.data.data2[0].doctorFeedback[0]);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };
  useEffect(() => {
    save();
  }, []);
  const handleEdit = () => {
    navigate("/general-history/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");
  };

  // console.log(doctorFeed)
  // console.log(isLoading,
  //   AllData,
  //   profileData,
  //   historyData,
  //   socialData,
  //   editShow,
  //   pastData);

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const profileAllData = React.useMemo(() => profileData, [profileData]);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>General Treatment Form</h4>
            </div>
            {/* {management === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleDelete()}
                  value="Delete Form"
                  buttonStyle="btn_fill"
                />
                {editShow && (
                  <Button
                    onClick={() => handleEdit()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
                {!editShow && (
                  <Button
                    onClick={() => console.log("")}
                    value="Edit Form"
                    buttonStyle=" ml_15 button_disabled "
                  />
                )}
              </div>
            )} */}

            <DeleteConfirmation
              showModal={displayConfirmationModalDelete}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              message={"Form"}
              type="task_id"
              id={rId}
            />
          </div>

          <PersonalInformation
            profileAllData={profileAllData}
            AllData={AllData}
          />
          {viewPatient === "0" ? (
            <div className="no_data">Patient form is not yet filled </div>
          ) : (
            <>
              <ViewHistory historyData={historyData} />
              <ViewPast pastData={pastData} />
              <ViewSocial socialData={socialData} />
            </>
          )}
          {pScore === "true" && action === "2" && (
            <>
              <ViewFeedback AllData={AllData}  doctorFeed={doctorFeed}  />
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
        <>
        <ViewFeedback AllData={AllData} doctorFeed={doctorFeed} />
       
        </>
      )}
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import RadioButton from "../../../custom/radio-button/RadioButton";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import AssessmentPowerKgWidget from "../../../custom/assessment-widgets/AssessmentPowerKgWidget";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateHipFeedback } from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/doctorTranslation";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import { Validators } from "../../../../utilities/Validator";
import {
  updateHipScores,
  updateHipPainFieldScore,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";

// import Button from "../../custom/button/Button";

export default function Doctorefeedback() {
  const translation = useTranslation();
  const axiosinstance = useAxios();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
   useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  // const axiosinstance = useAxios();
  // const json = useSelector((state) => state.hipScoreReducer.doctorFeedback);
  // console.log(json);
  const [ir1Right, setIr1Right] = useState("");
  const [ir1Left, setIr1Left] = useState("");
  const [ir2Right, setIr2Right] = useState("");
  const [ir2Left, setIr2Left] = useState("");
  const [ir3Right, setIr3Right] = useState("");
  const [ir3Left, setIr3Left] = useState("");
  const [ir4Right, setIr4Right] = useState("");
  const [ir4Left, setIr4Left] = useState("");

  const [bearHugRightVal, setBearHugRightVal] = useState("");
  const [bearHugLeftVal, setBearHugLeftVal] = useState("");

  const [rightFixed, setRightFixed] = useState("");
  const [leftFixed, setLeftFixed] = useState("");
  const [RightFlexionR, setRightFlexionR] = useState("");
  const [RightFlexionL, setRightFlexionL] = useState("");
  const [LeftFlexionR, setLeftFlexionR] = useState("");
  const [LeftFlexionL, setLeftFlexionL] = useState("");
  const [RightExtensionR, setRightExtensionR] = useState("");
  const [RightExtensionL, setRightExtensionL] = useState("");
  const [LeftExtensionR, setLeftExtensionR] = useState("");
  const [LeftExtensionL, setLeftExtensionL] = useState("");
  const [RightAbductionR, setRightAbductionR] = useState("");
  const [RightAbductionL, setRightAbductionL] = useState("");
  const [LeftAbductionR, setLeftAbductionR] = useState("");
  const [LeftAbductionL, setLeftAbductionL] = useState("");
  const [RightAdductionR, setRightAdductionR] = useState("");
  const [RightAdductionL, setRightAdductionL] = useState("");
  const [LeftAdductionR, setLeftAdductionR] = useState("");
  const [LeftAdductionL, setLeftAdductionL] = useState("");
  const [RightERR, setRightERR] = useState("");
  const [RightERL, setRightERL] = useState("");
  const [LeftERR, setLeftERR] = useState("");
  const [LeftERL, setLeftERL] = useState("");
  const [RightERExR, setRightERExR] = useState("");
  const [RightERExL, setRightERExL] = useState("");
  const [LeftERExR, setLeftERExR] = useState("");
  const [LeftERExL, setLeftERExL] = useState("");
  const [RightIRFlexR, setRightIRFlexR] = useState("");
  const [RightIRFlexL, setRightIRFlexL] = useState("");
  const [LeftIRFlexR, setLeftIRFlexR] = useState("");
  const [LeftIRFlexL, setLeftIRFlexL] = useState("");
  const [RightIRExR, setRightIRExR] = useState("");
  const [RightIRExL, setRightIRExL] = useState("");
  const [LeftIRExR, setLeftIRExR] = useState("");
  const [LeftIRExL, setLeftIRExL] = useState("");
  const [otherR, setOtherR] = useState("");
  const [otherL, setOtherL] = useState("");
  const [limbAR, setLimbAR] = useState("");
  const [limbTL, setLimbTL] = useState("");
  const [rightpspecify, setrightpspecify] = useState("");
  const [leftpspecify, setleftpspecify] = useState("");
  const [rightaspecify, setrightaspecify] = useState("");
  const [leftaspecify, setleftaspecify] = useState("");
  const [subscapularisPowerR, setSubscapularisPowerR] = useState("");
  const [subscapularisPowerL, setSubscapularisPowerL] = useState("");
  const [limbTRL, setlimbTRL] = useState("");
  const [limbRAR, setlimbRAR] = useState("");
  const [chk, setChk] = useState(false);
  const navigate = useNavigate();

  const docFeedback = useSelector(
    (state) => state.hipScoreReducer.doctorFeedback[0]
  );
  const docFeedback1 = useSelector(
    (state) => state.hipScoreReducer.doctorFeedback
  );
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setIr1Right(docFeedback.ir1Right);
    setIr1Left(docFeedback.ir1Left);
    setIr2Right(docFeedback.ir2Right);
    setIr2Left(docFeedback.ir2Left);
    setIr3Right(docFeedback.ir3Right);
    setIr3Left(docFeedback.ir3Left);
    setIr4Right(docFeedback.ir4Right);
    setIr4Left(docFeedback.ir4Left);
    setBearHugRightVal(docFeedback.bearHugRightVal);
    setBearHugLeftVal(docFeedback.bearHugLeftVal);
    setRightFlexionR(docFeedback.RightFlexionR);
    setRightFlexionL(docFeedback.RightFlexionL);
    setLeftFlexionR(docFeedback.LeftFlexionR);
    setLeftFlexionL(docFeedback.LeftFlexionL);
    setRightExtensionR(docFeedback.RightExtensionR);
    setRightExtensionL(docFeedback.RightExtensionL);
    setLeftExtensionR(docFeedback.LeftExtensionR);
    setLeftExtensionL(docFeedback.LeftExtensionL);
    setRightAbductionR(docFeedback.RightAbductionR);
    setRightAbductionL(docFeedback.RightAbductionL);
    setLeftAbductionR(docFeedback.LeftAbductionR);
    setLeftAbductionL(docFeedback.LeftAbductionL);
    setRightAdductionR(docFeedback.RightAdductionR);
    setRightAdductionL(docFeedback.RightAdductionL);
    setLeftAdductionR(docFeedback.LeftAdductionR);
    setLeftAdductionL(docFeedback.LeftAdductionL);
    setRightERR(docFeedback.RightERR);
    setRightERL(docFeedback.RightERL);
    setLeftERR(docFeedback.LeftERR);
    setLeftERL(docFeedback.LeftERL);
    setRightERExR(docFeedback.RightERExR);
    setRightERExL(docFeedback.RightERExL);
    setLeftERExR(docFeedback.LeftERExR);
    setLeftERExL(docFeedback.LeftERExL);
    setRightIRFlexR(docFeedback.RightIRFlexR);
    setRightIRFlexL(docFeedback.RightIRFlexL);
    setLeftIRFlexR(docFeedback.LeftIRFlexR);
    setLeftIRFlexL(docFeedback.LeftIRFlexL);
    setRightIRExR(docFeedback.RightIRExR);
    setRightIRExL(docFeedback.RightIRExL);
    setLeftIRExR(docFeedback.LeftIRExR);
    setLeftIRExL(docFeedback.LeftIRExL);
    setOtherR(docFeedback.otherR);
    setOtherL(docFeedback.otherL);
    setLimbAR(docFeedback.limbAR);
    setLimbTL(docFeedback.limbTL);
    setrightpspecify(docFeedback.rightpspecify);
    setleftpspecify(docFeedback.leftpspecify);
    setrightaspecify(docFeedback.rightaspecify);
    setleftaspecify(docFeedback.leftaspecify);
    setSubscapularisPowerR(docFeedback.subscapularisPowerR);
    setSubscapularisPowerL(docFeedback.subscapularisPowerL);
    setlimbTRL(docFeedback.limbTRL);
    setlimbRAR(docFeedback.limbRAR);
  }, [docFeedback]);

  const ir1ChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir1Right",
        value: e,
      })
    );
    setIr1Right(e);
  };

  const ir1ChangeL = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir1Left",
        value: e,
      })
    );
    setIr1Left(e);
  };

  const ir2ChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir2Right",
        value: e,
      })
    );
    setIr2Right(e);
  };
  const ir2ChangeL = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir2Left",
        value: e,
      })
    );
    setIr2Left(e);
  };

  const ir3ChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir3Right",
        value: e,
      })
    );
    setIr3Right(e);
  };
  const ir3ChangeL = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir3Left",
        value: e,
      })
    );
    setIr3Left(e);
  };

  const ir4ChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "ir4Right",
        value: e,
      })
    );
    setIr4Right(e);
  };
  const ir4ChangeL = (e) => {
    setIr4Left(e);
    dispatch(
      updateHipFeedback({
        key: "ir4Left",
        value: e,
      })
    );
  };

  const bearHugChangeRight = (e) => {
    dispatch(
      updateHipFeedback({
        key: "bearHugRightVal",
        value: e,
      })
    );
    setBearHugRightVal(e);
  };
  const bearHugChangeLeft = (e) => {
    setBearHugLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "bearHugLeftVal",
        value: e,
      })
    );
  };

  const ir2RightFlexionR = (e) => {
    setRightFlexionR(e);
    dispatch(
      updateHipFeedback({
        key: "RightFlexionR",
        value: e,
      })
    );
  };
  const ir2RightFlexionL = (e) => {
    setRightFlexionL(e);
    dispatch(
      updateHipFeedback({
        key: "RightFlexionL",
        value: e,
      })
    );
  };

  const ir2LeftFlexionR = (e) => {
    setLeftFlexionR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftFlexionR",
        value: e,
      })
    );
  };
  const ir2LeftFlexionL = (e) => {
    setLeftFlexionL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftFlexionL",
        value: e,
      })
    );
  };
  const ir2RightExtensionR = (e) => {
    setRightExtensionR(e);
    dispatch(
      updateHipFeedback({
        key: "RightExtensionR",
        value: e,
      })
    );
  };
  const ir2RightExtensionL = (e) => {
    setRightExtensionL(e);
    dispatch(
      updateHipFeedback({
        key: "RightExtensionL",
        value: e,
      })
    );
  };
  const ir2LeftExtensionR = (e) => {
    setLeftExtensionR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftExtensionR",
        value: e,
      })
    );
  };
  const ir2LeftExtensionL = (e) => {
    setLeftExtensionL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftExtensionL",
        value: e,
      })
    );
  };

  const ir2RightAbductionR = (e) => {
    setRightAbductionR(e);
    dispatch(
      updateHipFeedback({
        key: "RightAbductionR",
        value: e,
      })
    );
  };
  const ir2RightAbductionL = (e) => {
    setRightAbductionL(e);
    dispatch(
      updateHipFeedback({
        key: "RightAbductionL",
        value: e,
      })
    );
  };
  const ir2LeftAbductionR = (e) => {
    setLeftAbductionR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftAbductionR",
        value: e,
      })
    );
  };
  const ir2LeftAbductionL = (e) => {
    setLeftAbductionL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftAbductionL",
        value: e,
      })
    );
  };
  const ir2RightAdductionR = (e) => {
    setRightAdductionR(e);
    dispatch(
      updateHipFeedback({
        key: "RightAdductionR",
        value: e,
      })
    );
  };
  const ir2RightAdductionL = (e) => {
    setRightAdductionL(e);
    dispatch(
      updateHipFeedback({
        key: "RightAdductionL",
        value: e,
      })
    );
  };
  const ir2LeftAdductionR = (e) => {
    setLeftAdductionR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftAdductionR",
        value: e,
      })
    );
  };
  const ir2LeftAdductionL = (e) => {
    setLeftAdductionL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftAdductionL",
        value: e,
      })
    );
  };
  const ir2RightERR = (e) => {
    setRightERR(e);
    dispatch(
      updateHipFeedback({
        key: "RightERR",
        value: e,
      })
    );
  };
  const ir2RightERL = (e) => {
    setRightERL(e);
    dispatch(
      updateHipFeedback({
        key: "RightERL",
        value: e,
      })
    );
  };

  const ir2LeftERR = (e) => {
    setLeftERR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftERR",
        value: e,
      })
    );
  };
  const ir2LeftERL = (e) => {
    setLeftERL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftERL",
        value: e,
      })
    );
  };
  const ir2RightERExR = (e) => {
    setRightERExR(e);
    dispatch(
      updateHipFeedback({
        key: "RightERExR",
        value: e,
      })
    );
  };
  const ir2RightERExL = (e) => {
    setRightERExL(e);
    dispatch(
      updateHipFeedback({
        key: "RightERExL",
        value: e,
      })
    );
  };

  const ir2LeftERExR = (e) => {
    setLeftERExR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftERExR",
        value: e,
      })
    );
  };
  const ir2LeftERExL = (e) => {
    setLeftERExL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftERExL",
        value: e,
      })
    );
  };

  const ir2RightIRFlexR = (e) => {
    setRightIRFlexR(e);
    dispatch(
      updateHipFeedback({
        key: "RightIRFlexR",
        value: e,
      })
    );
  };
  const ir2RightIRFlexL = (e) => {
    setRightIRFlexL(e);
    dispatch(
      updateHipFeedback({
        key: "RightIRFlexL",
        value: e,
      })
    );
  };
  const ir2LeftIRFlexR = (e) => {
    setLeftIRFlexR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftIRFlexR",
        value: e,
      })
    );
  };
  const ir2LeftIRFlexL = (e) => {
    setLeftIRFlexL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftIRFlexL",
        value: e,
      })
    );
  };

  const ir2RightIRExR = (e) => {
    setRightIRExR(e);
    dispatch(
      updateHipFeedback({
        key: "RightIRExR",
        value: e,
      })
    );
  };
  const ir2RightIRExL = (e) => {
    setRightIRExL(e);
    dispatch(
      updateHipFeedback({
        key: "RightIRExL",
        value: e,
      })
    );
  };
  const ir2LeftIRExR = (e) => {
    setLeftIRExR(e);
    dispatch(
      updateHipFeedback({
        key: "LeftIRExR",
        value: e,
      })
    );
  };
  const ir2LeftIRExL = (e) => {
    setLeftIRExL(e);
    dispatch(
      updateHipFeedback({
        key: "LeftIRExL",
        value: e,
      })
    );
  };
  const handleOtherR = (e) => {
    setOtherR(e);
    dispatch(
      updateHipFeedback({
        key: "otherR",
        value: e,
      })
    );
  };
  const handleOtherL = (e) => {
    setOtherL(e);
    dispatch(
      updateHipFeedback({
        key: "otherL",
        value: e,
      })
    );
  };

  const subscapularisPowerChangeR = (e) => {
    setSubscapularisPowerR(e);
    dispatch(
      updateHipFeedback({
        key: "subscapularisPowerR",
        value: e,
      })
    );
  };
  const subscapularisPowerChangeL = (e) => {
    setSubscapularisPowerL(e);
    dispatch(
      updateHipFeedback({
        key: "subscapularisPowerL",
        value: e,
      })
    );
  };

  const handleLimbAR = (e) => {
    setLimbAR(e);
    dispatch(
      updateHipFeedback({
        key: "limbAR",
        value: e,
      })
    );
  };
  const handleLimbTL = (e) => {
    setLimbTL(e);
    dispatch(
      updateHipFeedback({
        key: "limbTL",
        value: e,
      })
    );
  };

  const handleLimbTRL = (e) => {
    setlimbTRL(e);
    dispatch(
      updateHipFeedback({
        key: "limbTRL",
        value: e,
      })
    );
  };

  const handleLimbRAR = (e) => {
    setlimbRAR(e);
    dispatch(
      updateHipFeedback({
        key: "limbRAR",
        value: e,
      })
    );
  };

  const handlerightpspecify = (e) => {
    setrightpspecify(e);
    dispatch(
      updateHipFeedback({
        key: "rightpspecify",
        value: e,
      })
    );
  };

  const handlerightaspecify = (e) => {
    setrightaspecify(e);
    dispatch(
      updateHipFeedback({
        key: "rightaspecify",
        value: e,
      })
    );
  };

  const handleleftpspecify = (e) => {
    setleftpspecify(e);
    dispatch(
      updateHipFeedback({
        key: "leftpspecify",
        value: e,
      })
    );
  };
  const handleleftaspecify = (e) => {
    setleftaspecify(e);
    dispatch(
      updateHipFeedback({
        key: "leftaspecify",
        value: e,
      })
    );
  };

  const continueRedirectPage = (e) => {
    navigate("/hip-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`);
  };

  const continueRedirect = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      continueRedirectPage();
      console.log(response);
    }
  };
  const updateDate = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      console.log(response);
    }
  };

  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    // updateDate1()
    }
    }
  },[docFeedback1])
  // const updateDate = () => {
  //   axiosinstance.current
  //     .put(`extapp/forms/update_doctor_feedback?resource_id=${rId}`, docFeedback, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };
  // useEffect(() => {
  //   // if (chk) {

  // }
  // }, [docFeedback]);

  // const continueRedirect = (e) => {
  //   updateDate();
  //   navigate("/hip-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`);
  // };
  const continueRedirect2 = (e) => {
    updateDate();
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");

  return (
    <>
      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <HipTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
      </div>

      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}
        <HeaderInnerBreadcrumb />
        {/* <div className="row mb_30">
        <div className="col-md-6 col-sm-6"> */}

        <div className="input_tab_box mb_30 ">
          <div className="row">
            <h4 className="range_sl_title">{translation.Gait}</h4>

            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle="Right Hip*"
                label="Normal"
                selected={ir1Right === "1"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="1"
                id="ir1Right1"
                name="ir1Right"
              />
              <RadioButton
                label="Abnormal"
                selected={ir1Right === "2"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="2"
                id="ir1Right2"
                name="ir1Right"
              />
            </div>

            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle="Left Hip*"
                label="Normal"
                selected={ir1Left === "1"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="1"
                id="ir1Left1"
                name="ir1Left"
              />
              <RadioButton
                label="Abnormal"
                selected={ir1Left === "2"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="2"
                id="ir1Left2"
                name="ir1Left"
              />
            </div>
          </div>
        </div>
        {ir1Left === "2" || ir1Right === "2" ? (
          <div className="input_tab_box mb_30 ">
            <div className="row">
              <h4 className="range_sl_title">{translation.Gait_abnormality}</h4>
              {/* {ir1Right === "2" && (
                <div className="col-md-6 col-sm-6">
                  <FullDropdown
                    data={[
                      { value: "1", label: "Antalgic" },
                      { value: "2", label: "Trendelenburg" },
                      { value: "3", label: "Rigid" },
                      { value: "4", label: "Supratentorial" },
                      { value: "5", label: "Limping" },
                      { value: "6", label: "Tiptoe gait" },
                      { value: "7", label: "Trendelenberg gait" },
                      { value: "8", label: "Waddling gait" },
                      { value: "9", label: "In-toeing gait" },
                      { value: "10", label: "Out-toeing gait" },
                      { value: "11", label: "Crouch gait" },
                      { value: "12", label: "Others" },
                    ]}
                    label="Right Gait abnormality"
                    styleClass="mxw_70"
                    value={ir2Right}
                    placeholder="None"
                    onChange={ir2ChangeR}
                  />

                  {ir2Right == "12" && (
                    <>
                      <InputField
                        label=""
                        value={otherR}
                        type="text"
                        placeholder="Other Field"
                        styleClass="mxw_100"
                        onChange={handleOtherR}
                      />
                    </>
                  )}
                </div>
              )} */}

              {ir1Right === "2" && (
                <div className="col-md-6 col-sm-6">
                  <FullDropdown
                    data={[
                      { value: "1", label: "Antalgic" },
                      { value: "2", label: "Trendelenburg" },
                      { value: "3", label: "Rigid" },
                      { value: "4", label: "Supratentorial" },
                      { value: "5", label: "Limping" },
                      { value: "6", label: "Tiptoe gait" },
                      { value: "7", label: "Trendelenberg gait" },
                      { value: "8", label: "Waddling gait" },
                      { value: "9", label: "In-toeing gait" },
                      { value: "10", label: "Out-toeing gait" },
                      { value: "11", label: "Crouch gait" },
                      { value: "12", label: "Others" },
                    ]}
                    label="Right Gait abnormality"
                    styleClass="mxw_70"
                    value={ir2Right}
                    placeholder="None"
                    onChange={ir2ChangeR}
                  />

                  {ir2Right == "12" && (
                    <>
                      <InputField
                        label=""
                        value={otherR}
                        type="text"
                        placeholder="Other Field"
                        styleClass="mxw_100"
                        onChange={handleOtherR}
                      />
                    </>
                  )}
                </div>
              )}

              {ir1Left === "2" && (
                <div className="col-md-6 col-sm-6">
                  <FullDropdown
                    data={[
                      { value: "1", label: "Antalgic" },
                      { value: "2", label: "Trendelenburg" },
                      { value: "3", label: "Rigid" },
                      { value: "4", label: "Supratentorial" },
                      { value: "5", label: "Limping" },
                      { value: "6", label: "Tiptoe gait" },
                      { value: "7", label: "Trendelenberg gait" },
                      { value: "8", label: "Waddling gait" },
                      { value: "9", label: "In-toeing gait" },
                      { value: "10", label: "Out-toeing gait" },
                      { value: "11", label: "Crouch gait" },
                      { value: "12", label: "Others" },
                    ]}
                    label="Left Gait abnormality"
                    styleClass="mxw_70"
                    value={ir2Left}
                    placeholder="None"
                    onChange={ir2ChangeL}
                  />

                  {ir2Left == "12" && (
                    <>
                      <InputField
                        label=""
                        value={otherL}
                        type="text"
                        placeholder="Other Field"
                        styleClass="mxw_100"
                        onChange={handleOtherL}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}

        <div className="row mb_30 input_tab_box ">
          <h4 className="range_sl_title">{translation.Pelvic_tilt}</h4>
          <div className="col-md-6 col-sm-6">
            <RadioSwitch
              labelLeft="Negative"
              labelRight="Positive"
              idLeft="bearHugnveR"
              idRight="bearHugPveR"
              name="bearHugR"
              title="Right Hip"
              styleClass=""
              value={bearHugRightVal}
              onChange={bearHugChangeRight}
              placeholder="select"
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <RadioSwitch
              labelLeft="Negative"
              labelRight="Positive"
              idLeft="bearHugnveL"
              idRight="bearHugPveL"
              name="bearHugL"
              title="Left Hip"
              styleClass=""
              value={bearHugLeftVal}
              onChange={bearHugChangeLeft}
              placeholder="select"
            />
          </div>
        </div>

        <div className="row mb_30 input_tab_box ">
          <h4 className="range_sl_title">{translation.Limb_length_HIP}</h4>

          <div className="col-md-6 col-sm-6">
            {/* <FullDropdown
              data={[
                { value: "none", label: "None" },
                { value: "1", label: "True" },
                { value: "2", label: "Apparent" },
              ]}
              label="Left Limb length"
              styleClass="mxw_70"
              value={ir3Left}
              placeholder="None"
              onChange={ir3ChangeL}
            /> */}

            <>
              <label>True length (Right Hip)</label>
              <InputField
                label=""
                value={limbTL}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbTL}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]}
              />
            </>

            <>
              <label>Apparent length (Right Hip)</label>
              <InputField
                label=""
                value={limbAR}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbAR}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]}
              />
            </>
          </div>

          <div className="col-md-6 col-sm-6">
            {/* <FullDropdown
              data={[
                { value: "none", label: "None" },
                { value: "1", label: "True" },
                { value: "2", label: "Apparent" },
              ]}
              label="Right Limb length"
              styleClass="mxw_70"
              value={ir3Right}
              placeholder="None"
              onChange={ir3ChangeR}
            /> */}

            <>
              <label>True length (Left Hip)</label>
              <InputField
                label=""
                value={limbTRL}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbTRL}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]}
              />
            </>

            <>
              <label>Apparent length (Left Hip)</label>
              <InputField
                label=""
                value={limbRAR}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbRAR}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]}
              />
            </>
          </div>

          <div className="col-md-6 col-sm-6">
            {/* <FullDropdown
              data={[
                { value: "none", label: "None" },
                { value: "1", label: "True" },
                { value: "2", label: "Apparent" },
              ]}
              label="Left Limb length"
              styleClass="mxw_70"
              value={ir3Left}
              placeholder="None"
              onChange={ir3ChangeL}
            /> */}

            <>
              {/* <label>True length (Left Hip)</label>
              <InputField
                label=""
                value={limbTRL}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbTRL}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]} */}
              {/* /> */}
            </>

            {/* <>
              <label>Apparent length (Left Hip)</label>
              <InputField
                label=""
                value={limbRAR}
                type="text"
                placeholder="cm"
                styleClass=""
                onChange={handleLimbRAR}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                ]}
              />
            </> */}
          </div>
        </div>

        <div className="row mb_30 input_tab_box ">
          <h4 className="range_sl_title">{translation.Fixed_contracture}</h4>

          <div className="col-md-6 col-sm-6">
            <RadioSwitch
              labelLeft="Present"
              labelRight="Absent"
              idLeft="FixednveR"
              idRight="FixedPveR"
              name="FixedR"
              title="Right Hip"
              styleClass=""
              value={ir4Right}
              onChange={ir4ChangeR}
              placeholder="select"
            />

            {ir4Right == "0" && (
              <>
                <h4 className="range_sl_title">{translation.Specify}</h4>
                <InputField
                  label=""
                  value={rightpspecify}
                  type="text"
                  placeholder="Specify"
                  styleClass="mxw_100"
                  onChange={handlerightpspecify}
                />{" "}
              </>
            )}
          </div>
          <div className="col-md-6 col-sm-6">
            <RadioSwitch
              labelLeft="Present"
              labelRight="Absent"
              idLeft="FixednveL"
              idRight="FixedPveL"
              name="FixedL"
              title="Left Hip"
              styleClass=""
              value={ir4Left}
              onChange={ir4ChangeL}
              placeholder="select"
            />

            {ir4Left == "0" && (
              <>
                <h4 className="range_sl_title">{translation.Specify}</h4>
                <InputField
                  label=""
                  value={leftpspecify}
                  type="text"
                  placeholder="Specify"
                  styleClass="mxw_100"
                  onChange={handleleftpspecify}
                />
              </>
            )}
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">{translation.Range_of_motion}</h4>
          <div className="row mb_30 ">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightFlexionR}
                valueL={RightFlexionL}
                title="Right Flexion"
                onChangeR={ir2RightFlexionR}
                onChangeL={ir2RightFlexionL}
                labelR="Active"
                labelL="Passive"
                nameR="Flexion Right"
                nameL="Flexion Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftFlexionR}
                valueL={LeftFlexionL}
                title="Left Flexion"
                onChangeR={ir2LeftFlexionR}
                onChangeL={ir2LeftFlexionL}
                labelR="Active"
                labelL="Passive"
                nameR="Flexion Right"
                nameL="Flexion Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightExtensionR}
                valueL={RightExtensionL}
                title="Right Extension"
                onChangeR={ir2RightExtensionR}
                onChangeL={ir2RightExtensionL}
                labelR="Active"
                labelL="Passive"
                nameR="Extension Right"
                nameL="Extension Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftExtensionR}
                valueL={LeftExtensionL}
                title="Left Extension"
                onChangeR={ir2LeftExtensionR}
                onChangeL={ir2LeftExtensionL}
                labelR="Active"
                labelL="Passive"
                nameR="Extension Right"
                nameL="Extension Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightAbductionR}
                valueL={RightAbductionL}
                title="Right Abduction"
                onChangeR={ir2RightAbductionR}
                onChangeL={ir2RightAbductionL}
                labelR="Active"
                labelL="Passive"
                nameR="Abduction Right"
                nameL="Abduction Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftAbductionR}
                valueL={LeftAbductionL}
                title="Left Abduction"
                onChangeR={ir2LeftAbductionR}
                onChangeL={ir2LeftAbductionL}
                labelR="Active"
                labelL="Passive"
                nameR="Abduction Right"
                nameL="Abduction Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightAdductionR}
                valueL={RightAdductionL}
                title="Right Adduction"
                onChangeR={ir2RightAdductionR}
                onChangeL={ir2RightAdductionL}
                labelR="Active"
                labelL="Passive"
                nameR="Adduction Right"
                nameL="Adduction Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftAdductionR}
                valueL={LeftAdductionL}
                title="Left Adduction"
                onChangeR={ir2LeftAdductionR}
                onChangeL={ir2LeftAdductionL}
                labelR="Active"
                labelL="Passive"
                nameR="Adduction Right"
                nameL="Adduction Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightERR}
                valueL={RightERL}
                title="Right ER in flexion"
                onChangeR={ir2RightERR}
                onChangeL={ir2RightERL}
                labelR="Active"
                labelL="Passive"
                nameR="ER in flexion Right"
                nameL="ER in flexion Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftERR}
                valueL={LeftERL}
                title="Left ER in flexion"
                onChangeR={ir2LeftERR}
                onChangeL={ir2LeftERL}
                labelR="Active"
                labelL="Passive"
                nameR="ER in flexion Right"
                nameL="ER in flexion Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightERExR}
                valueL={RightERExL}
                title="Right ER in extension"
                onChangeR={ir2RightERExR}
                onChangeL={ir2RightERExL}
                labelR="Active"
                labelL="Passive"
                nameR="ER in extension Right"
                nameL="ER in extension Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftERExR}
                valueL={LeftERExL}
                title="Left ER in extension"
                onChangeR={ir2LeftERExR}
                onChangeL={ir2LeftERExL}
                labelR="Active"
                labelL="Passive"
                nameR="ER in extension Right"
                nameL="ER in extension Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightIRFlexR}
                valueL={RightIRFlexL}
                title="Right IR in flexion"
                onChangeR={ir2RightIRFlexR}
                onChangeL={ir2RightIRFlexL}
                labelR="Active"
                labelL="Passive"
                nameR="IR in flexion Right"
                nameL="IR in flexion Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftIRFlexR}
                valueL={LeftIRFlexL}
                title="Left IR in flexion"
                onChangeR={ir2LeftIRFlexR}
                onChangeL={ir2LeftIRFlexL}
                labelR="Active"
                labelL="Passive"
                nameR="IR in flexion Right"
                nameL="IR in flexion Left"
              />
            </div>
          </div>

          <div className="row mb_30">
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={RightIRExR}
                valueL={RightIRExL}
                title="Right IR in extension"
                onChangeR={ir2RightIRExR}
                onChangeL={ir2RightIRExL}
                labelR="Active"
                labelL="Passive"
                nameR="IR in extension Right"
                nameL="IR in extension Left"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <AssessmentDegreeWidget
                valueR={LeftIRExR}
                valueL={LeftIRExL}
                title="Left IR in extension"
                onChangeR={ir2LeftIRExR}
                onChangeL={ir2LeftIRExL}
                labelR="Active"
                labelL="Passive"
                nameR="IR in extension Right"
                nameL="IR in extension Left"
              />
            </div>
          </div>
          <div className="row mb_30">
            <div className="col-md-6 col-sm-6 ">
              <div className="col-md-6 col-sm-6 ">
                <AssessmentPowerKgWidget
                  powerAction="true"
                  kgview="true"
                  // kgAction="false"
                  power={subscapularisPowerR}
                  onChangeP={subscapularisPowerChangeR}
                  // onChangeK={subscapularisKgChangeR}
                  label="Right Abductor Power"
                  // nameR="subscapularisPowerR"
                  nameL="subscapularisKgR"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="col-md-6 col-sm-6">
                <AssessmentPowerKgWidget
                  power={subscapularisPowerL}
                  kgview="true"
                  powerAction="true"
                  // kgAction="false"
                  onChangeP={subscapularisPowerChangeL}
                  // onChangeK={subscapularisKgChangeR}
                  label="Left Abductor Power"
                  // nameR="subscapularisPowerR"
                  nameL="subscapularisKgR"
                />
              </div>
            </div>
          </div>
        </div>

        {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6"></div>
            <div className="col-md-6 col-sm-6 text-end">
              <button
                className="next_btn btn_fill"
                onClick={() => continueRedirect2()}
              >
                {translation.submit}
                <span>
                  <img src={nextIcon} alt="" />
                  {/* <img src={nextWhiteIcon} alt="" /> */}
                </span>
              </button>
            </div>
          </div>
        )}

        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              {/* <Link to="/patient-list" className="assm_back_btn">
   <img src={backIcon} alt="" />
   <span>{translation.back_button}</span>
 </Link> */}
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button className="next_btn btn_fill" onClick={continueRedirect}>
                {translation.next_button}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

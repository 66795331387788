import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import InputField from "../../../custom/inputfield/InputField";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import FrontSvg from "./FrontSvg";
import BackSvg from "./BackSvg";
import "./Pain.css";
import Tab from "../../tab-section/ShoulderTab";
import { useSearchParams } from "react-router-dom";
import {
  updateShoulder,
  updateShoulderPainFieldScore,
  updateShoulderPainScores,
  updateShoulderFunctionScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
  updateAllData,
  updateFeedbackFunctionScores,
} from "../../../../redux/actions/shoulderAction";

// import Button from "../../custom/button/Button";
import useAxios from "../../../../axiosinstance";
import useTransition from "../../../customHooks/translations";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import { Validators } from "../../../../utilities/Validator";
import { RightSide, LeftSide, LeftTrans, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function Pain() {
  const translation = useTransition();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  let isdoctorForm = searchParams.get("is_doctor_form");
  let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
  useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  let doctordata=localStorage.getItem("isdoctorForm")
  const [isLoading, setIsLoading] = useState(true);
  const [shoulderPainL, setShoulderPainL] = useState("0");
  const [shoulderPainR, setShoulderPainR] = useState("0");
  const [describePainL, setDescribePainL] = useState("");
  const [describePainR, setDescribePainR] = useState("");
  const [affectsSleepL, setAffectsSleepL] = useState("0");
  const [affectsSleepR, setAffectsSleepR] = useState("0");
  const [painTodayRatingL, setPainTodayRatingL] = useState("0");
  const [painTodayRatingR, setPainTodayRatingR] = useState("0");
  const [smoker, setSmoker] = useState("1");

  const [cigratesperday, setCigratesperday] = useState("0");
  const [cigratesperdayErr, setCigratesperdayErr] = useState(false);

  const [cigratesMonthLeft, setCigratesMonthLeft] = useState("");
  const [smokeYears, setSmokeYears] = useState("");
  const [painMedication, setPainMedication] = useState("");
  const [naroticMedication, setNaroticMedication] = useState("0");

  const [pillsAverage, setPillsAverage] = useState("0");
  const [pillsAverageErr, setPillsAverageErr] = useState(false);

  const [bestStatmentForPainL, setBestStatmentForPainL] = useState("0");
  const [bestStatmentForPainR, setBestStatmentForPainR] = useState("0");
  const [pinOrTinglingL, setPinOrTinglingL] = useState("");
  const [pinOrTinglingR, setPinOrTinglingR] = useState("");
  const [body_selection, setbody_selection] = useState([]);
  const [backSvgData, setbackSvgData] = useState([]);
  const [forntSvgData, setforntSvgData] = useState([]);
  const navigate = useNavigate();
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backD, setbackD] = useState({});
  const [painErr, setPainErr] = useState(false);
  const [longpainErr, setLongPainErr] = useState(false);
  const [backDoc, setBackDoc] = useState('')
  // console.log(backSvgData)
  const pillsAverageRef = useRef()
  const ciggarateRef = useRef()
  const cigquitlong = useRef()
  const ciglongyears = useRef()
  // console.log(forntSvgData)
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form)
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(
          updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
        );

        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt)
        sessionStorage.setItem("doctor_id", res.data.data.ak_dc)
      });
  };
  useEffect(() => {
    save();
    setTimeout(() => {
      
      backData();
    }, 2000);
  }, []);

  const shoulderPain = useSelector((state) => state.shoulderScore.painScore[0]);
  useEffect(() => {
    setShoulderPainL(shoulderPain.painshoulder_left);
    setShoulderPainR(shoulderPain.painshoulder_right);
    setDescribePainL(shoulderPain.describe_pain_left);
    setDescribePainR(shoulderPain.describe_pain_right);
    setPainTodayRatingL(shoulderPain.pain_today_rating_left);
    setPainTodayRatingR(shoulderPain.pain_today_rating_right);
    setAffectsSleepL(shoulderPain.shoulder_pain_night_left);
    setAffectsSleepR(shoulderPain.shoulder_pain_night_right);
    setSmoker(shoulderPain.smoker);
    setCigratesperday(shoulderPain.cigrates_perday);
    setCigratesMonthLeft(shoulderPain.cigrates_month_left);
    setSmokeYears(shoulderPain.smoke_years);
    setPainMedication(shoulderPain.pain_medication);
    setNaroticMedication(shoulderPain.narotic_medication);
    setPillsAverage(shoulderPain.pills_average);
    setBestStatmentForPainL(shoulderPain.best_statment_pain_left);
    setBestStatmentForPainR(shoulderPain.best_statment_pain_right);
    setPinOrTinglingL(shoulderPain.Pin_tingling_left);
    setPinOrTinglingR(shoulderPain.pin_tingling_right);
    setbody_selection(shoulderPain.body_selection);
  }, [shoulderPain]);

  const json = useSelector((state) => state.shoulderScore);
  
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [shoulderPain, updateTotal,doctorEnable]);

  const backSvg = (e) => {
    setbackSvgData(e);
  };
  const forntSvg = (e) => {
    setforntSvgData(e);
  };
  useEffect(() => {
    setbody_selection([...backSvgData, ...forntSvgData]);

    dispatch(
      updateShoulderPainFieldScore({
        key: "body_selection",
        value: [backSvgData, forntSvgData],
      })
    );
  }, [backSvgData, forntSvgData]);
  // console.log(body_selection)

  useEffect(() => {
    if(smoker==="1" || smoker==="2"){
    dispatch(
      updateShoulderPainFieldScore({
        key: "cigrates_perday",
        value: "",
      })
    );
    dispatch(
      updateShoulderPainFieldScore({
        key: "cigrates_month_left",
        value: "",
      })
    );
    dispatch(
      updateShoulderPainFieldScore({
        key: "smoke_years",
        value: "",
      })
    );
    setCigratesperday("")
    setCigratesMonthLeft("")
    setSmokeYears("")
    }
  }, [smoker])

  useEffect(() => {
    if(painMedication!=="1"){
    dispatch(
      updateShoulderPainFieldScore({
        key: "pills_average",
        value: "0",
      })
    );
    setPillsAverage("0")
    }
  }, [painMedication])

  const handleShoulderPainL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "painshoulder_left",
        value: e,
      })
    );
    setShoulderPainL(e);
  };
  const handleShoulderPainR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "painshoulder_right",
        value: e,
      })
    );
    setShoulderPainR(e);
  };
  const handleDescribePainL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "describe_pain_left",
        value: e,
      })
    );
    setDescribePainL(e);
  };
  const handleDescribePainR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "describe_pain_right",
        value: e,
      })
    );
    setDescribePainR(e);
  };
  const handleAffectsSleepL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "shoulder_pain_night_left",
        value: e,
      })
    );
    setAffectsSleepL(e);
  };
  const handleAffectsSleepR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "shoulder_pain_night_right",
        value: e,
      })
    );
    setAffectsSleepR(e);
  };
  const handlePainTodayRatingL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "pain_today_rating_left",
        value: e,
      })
    );
    setPainTodayRatingL(e);
  };
  const handlePainTodayRatingR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "pain_today_rating_right",
        value: e,
      })
    );
    setPainTodayRatingR(e);
  };
  const handleSmoker = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "smoker",
        value: e,
      })
    );
    setSmoker(e);
  };

  const handleCigratesperday = (e, err) => {

    console.log(err)
    dispatch(
      updateShoulderPainFieldScore({
        key: "cigrates_perday",
        value: e,
      })
    );
    setCigratesperday(e);
    setCigratesperdayErr(err)
  };
  const handleCigratesMonthLeft = (e, err) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "cigrates_month_left",
        value: e,
      })
    );
    setCigratesMonthLeft(e, err);
    setPainErr(err)
  };
  const handleSmokeYears = (e, err) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "smoke_years",
        value: e,
      })
    );
    setSmokeYears(e);
    setLongPainErr(err)
  };
  const handlePainMedication = (e) => {

    dispatch(
      updateShoulderPainFieldScore({
        key: "pain_medication",
        value: e,
      })
    );
    setPainMedication(e);
  };
  const handleNaroticMedication = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "narotic_medication",
        value: e,
      })
    );
    setNaroticMedication(e);
  };
  const handlePillsAverage = (e, err) => {
    console.log(err)
    dispatch(
      updateShoulderPainFieldScore({
        key: "pills_average",
        value: e,
      })
    );
    setPillsAverage(e);
    setPillsAverageErr(err)
  };
  const handleBestStatmentForPainL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "best_statment_pain_left",
        value: e,
      })
    );
    setBestStatmentForPainL(e);
  };
  const handleBestStatmentForPainR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "best_statment_pain_right",
        value: e,
      })
    );
    setBestStatmentForPainR(e);
  };
  const handlePinOrTinglingL = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "Pin_tingling_left",
        value: e,
      })
    );
    setPinOrTinglingL(e);
  };
  const handlePinOrTinglingR = (e) => {
    dispatch(
      updateShoulderPainFieldScore({
        key: "pin_tingling_right",
        value: e,
      })
    );
    setPinOrTinglingR(e);
  };





  const continueRedirect = (e) => {

    // if (painMedication === "1") {
    //   if (pillsAverage) {
    //       pillsAverageRef.current.errUpdate()
    //   }
    //   if (pillsAverage) {
    //     return
    //  }
    // } else {
    //   navigate("/function/" + rId);
    // }

    if (painErr == false && longpainErr == false && cigratesperdayErr === false && pillsAverageErr === false) {
      navigate("/function/" + rId+`?is_doctor_form=${isdoctorForm}`);
    } else {
      return
    }

    // if (smoker === "0") {
    //   if (cigratesperday) {
    //     ciggarateRef.current.errUpdate()
    //   }
    //   if (cigratesperday) {
    //     return
    //  }
    // } else {
    //   navigate("/function/" + rId);
    // }





    // dispatch(updateShoulderPainScores(
    //   [{
    //      "shoulderPainL":shoulderPainL,
    //     "shoulderPainR":shoulderPainR,
    //      "describePainL":describePainL,
    //     "describePainR":describePainR,
    //     "affectsSleepL":affectsSleepL,
    //     "affectsSleepR":affectsSleepR,
    //     "painTodayRatingL":painTodayRatingL,
    //     "painTodayRatingR":painTodayRatingR,
    //     "smoker":smoker,
    //     "cigratesperday":cigratesperday,
    //     "cigratesMonthLeft":cigratesMonthLeft,
    //     "smokeYears":smokeYears,
    //     "painMedication":painMedication,
    //     "naroticMedication":naroticMedication,
    //     "pillsAverage":pillsAverage,
    //     "bestStatmentForPainL":bestStatmentForPainL,
    //     "bestStatmentForPainR":bestStatmentForPainR,
    //     "pinOrTinglingL":pinOrTinglingL,
    //     "pinOrTinglingR":pinOrTinglingR,
    //   }]
    //   ))


  };

  const handleAnklePain = (e) => {
    dispatch(updateShoulder(e));
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(true);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
console.log(shoulderPain.backbody,"shoulderPain.backbody",shoulderPain.frontbody);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex flex-row-reverse">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <Tab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />



              <h4 className="ph_title f_40">{translation.patient_shoulder_pain}</h4>
              {/* <div className="row">
                <div className="mb_20">
                  <FullDropdown
                    data={[
                      {
                        value: "1",
                        label: "Right",
                      },
                      {
                        value: "2",
                        label: "Left",
                      },
                      {
                        value: "3",
                        label: "Both",
                      },
                    ]}
                    label={translation.Evaluated_Shoulder}
                    styleClass=""
                    value={shoulserScore}
                    onChange={handleAnklePain}
                  />
                </div>
              </div> */}
              <div className="row pt_20">
                <div className="col-xxl-8 col-lg-12 col-md-12 col-12">
                  <div className="row">
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + RightSide()}>
                        <div className="mb_10">
                          <RadioSwitch
                            labelLeft={translation.patient_shoulder_pain_q1_a1}
                            labelRight={translation.patient_shoulder_pain_q1_a2}
                            idLeft="shoulderPainRNo"
                            idRight="shoulderPainRYes"
                            name="shoulderPainR"
                            title={translation.patient_shoulder_pain_q1 + RigthTrans(shoulserScore, translation)}
                            styleClass=""
                            value={shoulderPainR}
                            onChange={handleShoulderPainR}
                            placeholder={translation.Select}
                          />
                        </div>
                      </div>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + LeftSide()}>
                        <div className="mb_10">
                          <RadioSwitch
                            labelLeft={translation.patient_shoulder_pain_q1_a1}
                            labelRight={translation.patient_shoulder_pain_q1_a2}
                            idLeft="shoulderPainLNo"
                            idRight="shoulderPainLYes"
                            name="shoulderPainL"
                            title={translation.patient_shoulder_pain_q1 + LeftTrans(shoulserScore, translation)}
                            styleClass=""
                            value={shoulderPainL}
                            onChange={handleShoulderPainL}
                            placeholder={translation.Select}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + RightSide()}>
                        <div className="mb_10">
                          <RangeSlider
                            id="painTodayRatingR"
                            min="0"
                            max="15"
                            step="1"
                            type="both"
                            rightTitle={translation.patient_shoulder_pain_q2_a1}
                            leftTitle={translation.patient_shoulder_pain_q2_a2}
                            label={translation.patient_shoulder_pain_q2 + RigthTrans(shoulserScore, translation)}
                            value={describePainR}
                            onChange={handleDescribePainR}
                          />
                          {/* <FullDropdown
                            data={[
                              { value: "0", label: translation.patient_shoulder_pain_q2_a1 },
                              { value: "1", label: translation.patient_shoulder_pain_q2_a2 },
                              { value: "2", label: translation.patient_shoulder_pain_q2_a3 },
                              { value: "3", label: translation.patient_shoulder_pain_q2_a4 },
                            ]}
                            label={translation.patient_shoulder_pain_q2 + RigthTrans(shoulserScore, translation)}
                            styleClass=""
                            value={describePainR}
                            placeholder={translation.none}
                            onChange={handleDescribePainR}
                          /> */}
                        </div>
                      </div>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + LeftSide()}>
                        <div className="mb_10">
                        <RangeSlider
                            id="painTodayRatingR"
                            min="0"
                            max="15"
                            step="1"
                            type="both"
                            rightTitle={translation.patient_shoulder_pain_q2_a1}
                            leftTitle={translation.patient_shoulder_pain_q2_a2}
                            label={translation.patient_shoulder_pain_q2 + LeftTrans(shoulserScore, translation)}
                            value={describePainL}
                            onChange={handleDescribePainL}
                          />
                          {/* <FullDropdown
                            data={[
                              { value: "0", label: translation.patient_shoulder_pain_q2_a1 },
                              { value: "1", label: translation.patient_shoulder_pain_q2_a2 },
                              { value: "2", label: translation.patient_shoulder_pain_q2_a3 },
                              { value: "3", label: translation.patient_shoulder_pain_q2_a4 },
                            ]}
                            label={translation.patient_shoulder_pain_q2 + LeftTrans(shoulserScore, translation)}
                            styleClass=""
                            value={describePainL}
                            placeholder={translation.none}
                            onChange={handleDescribePainL}
                          /> */}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row mt-5">
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + RightSide()}>
                        <div className="mb_10">
                           <FullDropdown
                            data={[
                              { value: "0", label: translation.patient_shoulder_pain_q3_a1 },
                              { value: "1", label: translation.patient_shoulder_pain_q3_a2},
                              { value: "2", label: translation.patient_shoulder_pain_q3_a3 },
                            ]}
                            label={translation.patient_shoulder_pain_q3 + RigthTrans(shoulserScore, translation)}
                            styleClass=""
                            value={affectsSleepR}
                            onChange={handleAffectsSleepR}
                            placeholder={translation.Select}
                          />
                          {/* <RadioSwitch (old)
                            labelLeft={translation.patient_shoulder_pain_q3_a1}
                            labelRight={translation.patient_shoulder_pain_q3_a2}
                            idLeft="affectsSleepRNo"
                            idRight="affectsSleepRYes"
                            name="affectsSleepR"
                            title={translation.patient_shoulder_pain_q3 + RigthTrans(shoulserScore, translation)}
                            styleClass=""
                            value={affectsSleepR}
                            onChange={handleAffectsSleepR}
                            placeholder={translation.Select}
                          /> */}
                        </div>
                      </div>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <div className={"col-md-6 col-sm-6" + LeftSide()}>
                        <div className="mb_10">
                        <FullDropdown
                            data={[
                              { value: "0", label: translation.patient_shoulder_pain_q3_a1 },
                              { value: "1", label: translation.patient_shoulder_pain_q3_a2 },
                              { value: "2", label: translation.patient_shoulder_pain_q3_a3 },
                            ]}
                            label={translation.patient_shoulder_pain_q3 + LeftTrans(shoulserScore, translation)}
                            styleClass=""
                            value={affectsSleepL}
                            onChange={handleAffectsSleepL}
                            placeholder={translation.Select}
                          />
                          {/* <RadioSwitch
                            labelLeft={translation.patient_shoulder_pain_q3_a1}
                            labelRight={translation.patient_shoulder_pain_q3_a2}
                            idLeft="affectsSleepLNo"
                            idRight="affectsSleepLYes"
                            name="affectsSleepL"
                            title={translation.patient_shoulder_pain_q3 + LeftTrans(shoulserScore, translation)}
                            styleClass=""
                            value={affectsSleepL}
                            onChange={handleAffectsSleepL}
                            placeholder={translation.Select}
                          /> */}
                        </div>
                      </div>
                    ) : null}
                  </div>

                </div>
                <div className="col-xxl-4 col-lg-12 col-md-12 col-12 d-flex justify-content-center">
                  <div className="pain-imagebox">
                    <label>{translation.patient_shoulder_pain_q9}</label>
                    <div className="imagebox-top">
                      <FrontSvg
                        forntSvg={forntSvg}
                        front={shoulderPain.frontbody}
                      />
                      <BackSvg backSvg={backSvg} back={shoulderPain.backbody} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                {shoulserScore === "1" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20">
                      <RangeSlider
                        id="painTodayRatingR"
                        min="0"
                        max="10"
                        step="1"
                        type="image"
                        label={translation.patient_shoulder_pain_q4 + RigthTrans(shoulserScore, translation)}
                        value={painTodayRatingR}
                        onChange={handlePainTodayRatingR}
                      />
                    </div>
                  </div>
                ) : null}
                {shoulserScore === "2" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20">
                      <RangeSlider
                        id="painTodayRatingL"
                        min="0"
                        max="10"
                        step="1"
                        type="image"
                        label={translation.patient_shoulder_pain_q4 + LeftTrans(shoulserScore, translation)}
                        value={painTodayRatingL}
                        onChange={handlePainTodayRatingL}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="mb_10">
                  <FullDropdown
                    data={[
                      { value: "1", label: translation.patient_shoulder_pain_q5_a1 },
                      { value: "0", label: translation.patient_shoulder_pain_q5_a2 },
                      { value: "2", label: translation.patient_shoulder_pain_q5_a3 },
                    ]}
                    label={translation.patient_shoulder_pain_q5}
                    styleClass=""
                    value={smoker}
                    placeholder={translation.none}
                    onChange={handleSmoker}
                  />
                </div>
                {smoker === "0" && (
                  <div className="mb_10">
                    <InputField
                      label={translation.patient_shoulder_pain_q5_a2_q1}
                      value={cigratesperday}
                      type="text"
                      placeholder={translation.Get_In}
                      // placeholder={translation.Get_In + " "+translation.Number}
                      onChange={handleCigratesperday}
                      ref={ciggarateRef}
                      maxLength={2}
                      validators={[
                        {
                          check: Validators.number,
                          message: translation.Only_I,
                        },
                        {
                          check: Validators.maxformtext2,
                          message: translation.Only_I,
                        }
                      ]}
                    />
                  </div>
                )}
                {smoker === "2" && (
                  <>
                    <div className="mb_10">
                      <InputField
                        label={translation.patient_shoulder_pain_q5_a3_q1}
                        value={cigratesMonthLeft}
                        type="text"
                        placeholder={translation.Get_In}
                        ref={cigquitlong}
                        onChange={handleCigratesMonthLeft}
                        maxLength={2}
                        validators={[
                          {
                            check: Validators.number,
                            message: translation.Only_I,
                          },
                          {
                            check: Validators.maxformtext2,
                            message: translation.Digits_I,
                          }
                        ]}
                      />
                    </div>
                    <div className="mb_10">
                      <InputField
                        label={translation.patient_shoulder_pain_q5_a3_q2}
                        value={smokeYears}
                        type="text"
                        ref={ciglongyears}
                        placeholder={translation.Get_In}
                        onChange={handleSmokeYears}
                        maxLength={2}
                        validators={[
                          {
                            check: Validators.number,
                            message: translation.Only_I,
                          },
                          {
                            check: Validators.maxformtext2,
                            message: translation.Digits_I,
                          }
                        ]}
                      />
                    </div>
                  </>
                )}

                <div className="mb_10">
                  <RadioSwitch
                    labelLeft={translation.patient_shoulder_pain_q6_a1}
                    labelRight={translation.patient_shoulder_pain_q6_a2}
                    idLeft="painMedicationNo"
                    idRight="painMedicationYes"
                    name="painMedication"
                    title={translation.patient_shoulder_pain_q6}
                    styleClass=""
                    value={painMedication}
                    onChange={handlePainMedication}
                    placeholder={translation.Select}
                  />
                </div>
                {painMedication === "1" && (
                  <>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_shoulder_pain_q6_a2_q1_a1}
                        labelRight={translation.patient_shoulder_pain_q6_a2_q1_a2}
                        idLeft="naroticMedicationNo"
                        idRight="naroticMedicationYes"
                        name="naroticMedication"
                        title={translation.patient_shoulder_pain_q6_a2_q1}
                        styleClass=""
                        value={naroticMedication}
                        onChange={handleNaroticMedication}
                        placeholder={translation.Select}
                      />
                    </div>
                    <div className="mb_10">
                      <InputField
                        label={translation.patient_shoulder_pain_q6_a2_q2}
                        value={pillsAverage}
                        type="text"
                        placeholder={translation.Get_In}
                        onChange={handlePillsAverage}
                        ref={pillsAverageRef}
                        maxLength={2}
                        validators={[
                          {
                            check: Validators.number,
                            message: translation.Only_I,
                          },
                          {
                            check: Validators.maxformtext2,
                            message: translation.Only_II,
                          }


                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                {shoulserScore === "1" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "0", label: translation.patient_shoulder_pain_q7_a1 },
                          {
                            value: "1",
                            label: translation.patient_shoulder_pain_q7_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_shoulder_pain_q7_a3,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_shoulder_pain_q7_a4,
                          },
                          {
                            value: "4",
                            label:
                              translation.patient_shoulder_pain_q7_a5,
                          },
                          {
                            value: "5",
                            label:
                              translation.patient_shoulder_pain_q7_a6,
                          },
                        ]}
                        label={translation.patient_shoulder_pain_q7 + RigthTrans(shoulserScore, translation)}
                        styleClass="mxw_70"
                        value={bestStatmentForPainR}
                        placeholder={translation.none}
                        onChange={handleBestStatmentForPainR}
                      />
                    </div>

                  </div>
                ) : null}
                {shoulserScore === "2" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "0", label: translation.patient_shoulder_pain_q7_a1 },
                          {
                            value: "1",
                            label: translation.patient_shoulder_pain_q7_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_shoulder_pain_q7_a3,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_shoulder_pain_q7_a4,
                          },
                          {
                            value: "4",
                            label:
                              translation.patient_shoulder_pain_q7_a5,
                          },
                          {
                            value: "5",
                            label:
                              translation.patient_shoulder_pain_q7_a6,
                          },
                        ]}
                        label={translation.patient_shoulder_pain_q7 + LeftTrans(shoulserScore, translation)}
                        styleClass="mxw_70"
                        value={bestStatmentForPainL}
                        placeholder={translation.none}
                        onChange={handleBestStatmentForPainL}
                      />
                    </div>

                  </div>
                ) : null}
              </div>


              <div className="row">
                {shoulserScore === "1" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_shoulder_pain_q8_a1}
                        labelRight={translation.patient_shoulder_pain_q8_a2}
                        idLeft="pinOrTinglingRNo"
                        idRight="pinOrTinglingRYes"
                        name="pinOrTinglingR"
                        title={translation.patient_shoulder_pain_q8 + RigthTrans(shoulserScore, translation)}
                        styleClass=""
                        value={pinOrTinglingR}
                        onChange={handlePinOrTinglingR}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : null}
                {shoulserScore === "2" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_shoulder_pain_q8_a1}
                        labelRight={translation.patient_shoulder_pain_q8_a2}
                        idLeft="pinOrTinglingLNo"
                        idRight="pinOrTinglingLYes"
                        name="pinOrTinglingL"
                        title={translation.patient_shoulder_pain_q8 + LeftTrans(shoulserScore, translation)}
                        styleClass=""
                        value={pinOrTinglingL}
                        onChange={handlePinOrTinglingL}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && viewicon!=="true"&& (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc !== 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${doctordata}&doctorvalue=0`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      )
      }
    </>
  );
}

export default Pain;

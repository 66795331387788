import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import ElbowScoreRangeSlider from "../../../custom/elbow-score-range-slider/ElbowScoreRangeSlider";
import backIcon from "../../../../images/back.png";

import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import Checkbox from "../../../custom/checkbox/Checkbox";
import InputField from "../../../custom/inputfield/InputField";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";

import {
  updateElbowFunctionFieldScore,
  updateElbowFunctionData,
  updateElbowSymptomsData,
  updateElbowSatisficationData,
  updateElbowScores,
  updateElbowPainData,
  updateElbowQOLData,
  updateElbowFeedbackScores,
  updateAllData,
} from "../../../../redux/actions/elbowAction";
import ElbowTab from "../../tab-section/ElbowTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

const ElbowFunction = () => {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const navigator = useNavigate();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
  const functionScore = useSelector(
    (state) => state.elbowScore.functionScore[0]
  );
  const json = useSelector((state) => state.elbowScore);
  let doctordata =localStorage.getItem("isdoctorForm");
  let viewicon=localStorage.getItem("viewicon")
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [funDescR, setFunDescR] = useState("0");
  const [funDescL, setFunDescL] = useState("0");
  const [funActivityR, setFunActivityR] = useState(0);
  const [funActivityL, setFunActivityL] = useState(0);
  const [funUselongR, setFunUselongR] = useState(0);
  const [funUselongL, setFunUselongL] = useState(0);
  const [tightR, setTightR] = useState("0");
  const [tightL, setTightL] = useState("0");
  const [funHouseholdR, setFunHouseholdR] = useState("0");
  const [funHouseholdL, setFunHouseholdL] = useState("0");
  const [funshoppingL, setFunshoppingL] = useState("0");
  const [funshoppingR, setFunshoppingR] = useState("0");

  const [funrubbishR, setFunrubbishR] = useState("0");
  const [funrubbishL, setFunrubbishL] = useState("0");
  const [funWashR, setFunWashR] = useState("0");
  const [funWashL, setFunWashL] = useState("0");
  const [funToiletinghR, setFunToiletinghR] = useState("0");
  const [funToiletinghL, setFunToiletinghL] = useState("0");
  const [funWashingR, setFunWashingR] = useState("0");
  const [funWashingL, setFunWashingL] = useState("0");

  const [funHygienegR, setFunHygienegR] = useState("0");
  const [funHygienegL, setFunHygienegL] = useState("0");
  const [funKnifeR, setFunKnifeR] = useState("0");
  const [funKnifeL, setFunKnifeL] = useState("0");
  const [funUtensilR, setFunUtensilR] = useState("0");
  const [funUtensilL, setFunUtensilL] = useState("0");
  const [funRecreationalR, setFunRecreationalR] = useState("0");
  const [funRecreationalL, setFunRecreationalL] = useState("0");
  const [funSleepingL, setFunSleepingL] = useState("0");
  const [funShirtR, setFunShirtR] = useState("0");
  const [funShirtL, setFunShirtL] = useState("0");
  const [funTopShirtR, setFunTopShirtR] = useState("0");
  const [funTopShirtL, setFunTopShirtL] = useState("0");
  const [funSleepingR, setFunSleepingR] = useState("0");
  const [funDressingR, setFunDressingR] = useState("0");
  const [funDressingL, setFunDressingL] = useState("0");
  const [funPutShoesR, setFunPutShoesR] = useState("0");
  const [funPutShoesL, setFunPutShoesL] = useState("0");
  const [funTieShoesR, setFunTieShoesR] = useState("0");
  const [funTieShoesL, setFunTieShoesL] = useState("0");
  const [funHeavyR, setFunHeavyR] = useState("0");
  const [funHeavyL, setFunHeavyL] = useState("0");
  const [funChairR, setFunChairR] = useState("0");
  const [funChairL, setFunChairL] = useState("0");
  const [householdR, setHouseholdR] = useState("0");
  const [householdL, setHouseholdL] = useState("0");
  const [funTurnR, setFunTurnR] = useState("0");
  const [funTurnL, setFunTurnL] = useState("0");
  const [funBallR, setFunBallR] = useState("0");
  const [funBallL, setFunBallL] = useState("0");
  const [funWorkR, setFunWorkR] = useState("0");
  const [funWorkL, setFunWorkL] = useState("0");
  const [funTechniqueR, setFunTechniqueR] = useState("0");
  const [funTechniqueL, setFunTechniqueL] = useState("0");
  const [funSpendingR, setFunSpendingR] = useState("0");
  const [funSpendingL, setFunSpendingL] = useState("0");
  const [funMusicalR, setFunMusicalR] = useState("0");
  const [funMusicalL, setFunMusicalL] = useState("0");
  const [funInstrumentR, setFunInstrumentR] = useState("0");
  const [funInstrumentL, setFunInstrumentL] = useState("0");

  const [funPlayingR, setFunPlayingR] = useState("0");
  const [funPlayingL, setFunPlayingL] = useState("0");

  const [funPractisingR, setFunPractisingR] = useState("0");
  const [funPractisingL, setFunPractisingL] = useState("0");

  const [funWorkAsWallR, setFunWorkAsWallR] = useState("0");
  const [funWorkAsWallL, setFunWorkAsWallL] = useState("0");
  const [funHairR, setFunHairR] = useState("0");
  const [funHairL, setFunHairL] = useState("0");
  const [funLeisureR, setFunLeisureR] = useState("0");
  const [funLeisureL, setFunLeisureL] = useState("0");
  const [funRegularR, setFunRegularR] = useState("0");
  const [funRegularL, setFunRegularL] = useState("0");
  const [funTextR, setfunTextR] = useState("");
  const [checkR, setcheckR] = useState("");
  const [checkL, setcheckL] = useState("");
  const [funTextL, setfunTextL] = useState("");
  const [funMusicaTextR, setfunMusicaTextR] = useState("");
  const [checkMusicaR, setcheckMusicaR] = useState("");
  const [checkMusicaL, setcheckMusicaL] = useState("");
  const [funMusicaTextL, setfunMusicaTextL] = useState("");
  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigator("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setFunDescR(functionScore.funDescR);
    setFunDescL(functionScore.funDescL);
    setFunActivityR(functionScore.funActivityR);
    setFunActivityL(functionScore.funActivityL);
    setFunUselongR(functionScore.funUselongR);
    setFunUselongL(functionScore.funUselongL);
    setTightR(functionScore.tightR);
    setTightL(functionScore.tightL);
    setFunHouseholdR(functionScore.funHouseholdR);
    setFunHouseholdL(functionScore.funHouseholdL);
    setFunshoppingL(functionScore.funshoppingL);
    setFunshoppingR(functionScore.funshoppingR);
    setFunrubbishR(functionScore.funrubbishR);
    setFunrubbishL(functionScore.funrubbishL);
    setFunWashR(functionScore.funWashR);
    setFunWashL(functionScore.funWashL);
    setFunToiletinghR(functionScore.funToiletinghR);
    setFunToiletinghL(functionScore.funToiletinghL);
    setFunWashingR(functionScore.funWashingR);
    setFunWashingL(functionScore.funWashingL);
    setFunHygienegR(functionScore.funHygienegR);
    setFunHygienegL(functionScore.funHygienegL);
    setFunKnifeR(functionScore.funKnifeR);
    setFunKnifeL(functionScore.funKnifeL);
    setFunUtensilR(functionScore.funUtensilR);
    setFunUtensilL(functionScore.funUtensilL);
    setFunRecreationalR(functionScore.funRecreationalR);
    setFunRecreationalL(functionScore.funRecreationalL);
    setFunSleepingL(functionScore.funSleepingL);
    setFunShirtR(functionScore.funShirtR);
    setFunShirtL(functionScore.funShirtL);
    setFunTopShirtR(functionScore.funTopShirtR);
    setFunTopShirtL(functionScore.funTopShirtL);
    setFunSleepingR(functionScore.funSleepingR);
    setFunDressingR(functionScore.funDressingR);
    setFunDressingL(functionScore.funDressingL);
    setFunPutShoesR(functionScore.funPutShoesR);
    setFunPutShoesL(functionScore.funPutShoesL);
    setFunTieShoesR(functionScore.funTieShoesR);
    setFunTieShoesL(functionScore.funTieShoesL);
    setFunHeavyR(functionScore.funHeavyR);
    setFunHeavyL(functionScore.funHeavyL);
    setFunChairR(functionScore.funChairR);
    setFunChairL(functionScore.funChairL);
    setHouseholdR(functionScore.householdR);
    setHouseholdL(functionScore.householdL);
    setFunTurnR(functionScore.funTurnR);
    setFunTurnL(functionScore.funTurnL);
    setFunBallR(functionScore.funBallR);
    setFunBallL(functionScore.funBallL);
    setFunWorkR(functionScore.funWorkR);
    setFunWorkL(functionScore.funWorkL);
    setFunTechniqueR(functionScore.funTechniqueR);
    setFunTechniqueL(functionScore.funTechniqueL);
    setFunSpendingR(functionScore.funSpendingR);
    setFunSpendingL(functionScore.funSpendingL);
    setFunMusicalR(functionScore.funMusicalR);
    setFunMusicalL(functionScore.funMusicalL);
    setFunInstrumentR(functionScore.funInstrumentR);
    setFunInstrumentL(functionScore.funInstrumentL);
    setFunPlayingR(functionScore.funPlayingR);
    setFunPlayingL(functionScore.funPlayingL);
    setFunPractisingR(functionScore.funPractisingR);
    setFunPractisingL(functionScore.funPractisingL);
    setFunWorkAsWallR(functionScore.funWorkAsWallR);
    setFunWorkAsWallL(functionScore.funWorkAsWallL);
    setFunHairR(functionScore.funHairR);
    setFunHairL(functionScore.funHairL);
    setFunLeisureR(functionScore.funLeisureR);
    setFunLeisureL(functionScore.funLeisureL);
    setFunRegularR(functionScore.funRegularR);
    setFunRegularL(functionScore.funRegularL);
    setfunTextR(functionScore.funTextR);
    setcheckR(functionScore.checkR);
    setcheckL(functionScore.checkL);
    setfunTextL(functionScore.funTextL);
    setfunMusicaTextR(functionScore.funMusicaTextR);
    setcheckMusicaR(functionScore.checkMusicaR);
    setcheckMusicaL(functionScore.checkMusicaL);
    setfunMusicaTextL(functionScore.funMusicaTextL);
  }, [functionScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [functionScore, updateTotal]);

  const handleFunDescR = (e) => {
    setFunDescR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funDescR",
        value: e,
      })
    );
  };
  const handleFunDescL = (e) => {
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funDescL",
        value: e,
      })
    );
    setFunDescL(e);
  };
  const handleFunActivityR = (e) => {
    setFunActivityR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funActivityR",
        value: e,
      })
    );
  };
  const handleFunActivityL = (e) => {
    setFunActivityL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funActivityL",
        value: e,
      })
    );
  };
  const handleFunUselongR = (e) => {
    setFunUselongR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funUselongR",
        value: e,
      })
    );
  };
  const handleFunUselongL = (e) => {
    setFunUselongL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funUselongL",
        value: e,
      })
    );
  };
  const handleTightR = (e) => {
    setTightR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "tightR",
        value: e,
      })
    );
  };
  const handleTightL = (e) => {
    setTightL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "tightL",
        value: e,
      })
    );
  };
  const handlefunHouseholdR = (e) => {
    setFunHouseholdR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHouseholdR",
        value: e,
      })
    );
  };
  const handlefunHouseholdL = (e) => {
    setFunHouseholdL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHouseholdL",
        value: e,
      })
    );
  };
  const handleFunshoppingR = (e) => {
    setFunshoppingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funshoppingR",
        value: e,
      })
    );
  };
  const handleFunshoppingL = (e) => {
    setFunshoppingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funshoppingL",
        value: e,
      })
    );
  };
  const handleFunrubbishL = (e) => {
    setFunrubbishL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funrubbishL",
        value: e,
      })
    );
  };
  const handleFunrubbishR = (e) => {
    setFunrubbishR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funrubbishR",
        value: e,
      })
    );
  };
  const handleFunWashR = (e) => {
    setFunWashR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWashR",
        value: e,
      })
    );
  };
  const handleFunWashL = (e) => {
    setFunWashL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWashL",
        value: e,
      })
    );
  };
  const handleFunToiletinghL = (e) => {
    setFunToiletinghL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funToiletinghL",
        value: e,
      })
    );
  };
  const handleFunToiletinghR = (e) => {
    setFunToiletinghR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funToiletinghR",
        value: e,
      })
    );
  };
  const handleFunWashingR = (e) => {
    setFunWashingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWashingR",
        value: e,
      })
    );
  };
  const handleFunWashingL = (e) => {
    setFunWashingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWashingL",
        value: e,
      })
    );
  };

  const handleFunHygienegR = (e) => {
    setFunHygienegR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHygienegR",
        value: e,
      })
    );
  };
  const handleFunHygienegL = (e) => {
    setFunHygienegL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHygienegL",
        value: e,
      })
    );
  };
  const handleFunKnifeR = (e) => {
    setFunKnifeR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funKnifeR",
        value: e,
      })
    );
  };
  const handleFunKnifeL = (e) => {
    setFunKnifeL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funKnifeL",
        value: e,
      })
    );
  };

  const handleFunUtensilR = (e) => {
    setFunUtensilR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funUtensilR",
        value: e,
      })
    );
  };
  const handleFunUtensilL = (e) => {
    setFunUtensilL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funUtensilL",
        value: e,
      })
    );
  };

  const handleFunRecreationalR = (e) => {
    setFunRecreationalR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funRecreationalR",
        value: e,
      })
    );
  };
  const handleFunRecreationalL = (e) => {
    setFunRecreationalL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funRecreationalL",
        value: e,
      })
    );
  };

  const handleFunSleepingR = (e) => {
    setFunSleepingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSleepingR",
        value: e,
      })
    );
  };
  const handleFunSleepingL = (e) => {
    setFunSleepingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSleepingL",
        value: e,
      })
    );
  };

  const handleFunShirtR = (e) => {
    setFunShirtR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funShirtR",
        value: e,
      })
    );
  };
  const handleFunShirtL = (e) => {
    setFunShirtL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funShirtL",
        value: e,
      })
    );
  };

  const handleFunTopShirtR = (e) => {
    setFunTopShirtR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTopShirtR",
        value: e,
      })
    );
  };
  const handleFunTopShirtL = (e) => {
    setFunTopShirtL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTopShirtL",
        value: e,
      })
    );
  };
  const handleFunDressingR = (e) => {
    setFunDressingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funDressingR",
        value: e,
      })
    );
  };
  const handleFunDressingL = (e) => {
    setFunDressingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funDressingL",
        value: e,
      })
    );
  };

  const handleFunPutShoesR = (e) => {
    setFunPutShoesR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPutShoesR",
        value: e,
      })
    );
  };
  const handleFunPutShoesL = (e) => {
    setFunPutShoesL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPutShoesL",
        value: e,
      })
    );
  };
  const handleFunTieShoesR = (e) => {
    setFunTieShoesR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTieShoesR",
        value: e,
      })
    );
  };
  const handleFunTieShoesL = (e) => {
    setFunTieShoesL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTieShoesL",
        value: e,
      })
    );
  };
  const handleFunHeavyR = (e) => {
    setFunHeavyR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHeavyR",
        value: e,
      })
    );
  };
  const handleFunHeavyL = (e) => {
    setFunHeavyL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHeavyL",
        value: e,
      })
    );
  };
  const handleFunChairR = (e) => {
    setFunChairR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funChairR",
        value: e,
      })
    );
  };
  const handleFunChairL = (e) => {
    setFunChairL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funChairL",
        value: e,
      })
    );
  };
  const handleHouseholdR = (e) => {
    setHouseholdR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "householdR",
        value: e,
      })
    );
  };
  const handleHouseholdL = (e) => {
    setHouseholdL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "householdL",
        value: e,
      })
    );
  };
  const handleFunTurnR = (e) => {
    setFunTurnR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTurnR",
        value: e,
      })
    );
  };
  const handleFunTurnL = (e) => {
    setFunTurnL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTurnL",
        value: e,
      })
    );
  };
  const handleFunBallR = (e) => {
    setFunBallR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funBallR",
        value: e,
      })
    );
  };
  const handleFunBallL = (e) => {
    setFunBallL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funBallL",
        value: e,
      })
    );
  };
  const handleFunWorkR = (e) => {
    setFunWorkR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkR",
        value: e,
      })
    );
  };
  const handleFunWorkL = (e) => {
    setFunWorkL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkL",
        value: e,
      })
    );
  };
  const handleFunTechniqueR = (e) => {
    setFunTechniqueR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTechniqueR",
        value: e,
      })
    );
  };
  const handleFunTechniqueL = (e) => {
    setFunTechniqueL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTechniqueL",
        value: e,
      })
    );
  };
  const handleFunWorkAsWallR = (e) => {
    setFunWorkAsWallR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkAsWallR",
        value: e,
      })
    );
  };
  const handleFunWorkAsWallL = (e) => {
    setFunWorkAsWallL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkAsWallL",
        value: e,
      })
    );
  };
  const handleFunSpendingR = (e) => {
    setFunSpendingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSpendingR",
        value: e,
      })
    );
  };
  const handleFunSpendingL = (e) => {
    setFunSpendingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSpendingL",
        value: e,
      })
    );
  };
  const handleFunMusicalR = (e) => {
    setFunMusicalR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funMusicalR",
        value: e,
      })
    );
  };
  const handleFunMusicalL = (e) => {
    setFunMusicalL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funMusicalL",
        value: e,
      })
    );
  };
  const handleFunInstrumentR = (e) => {
    setFunInstrumentR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funInstrumentR",
        value: e,
      })
    );
  };
  const handleFunInstrumentL = (e) => {
    setFunInstrumentL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funInstrumentL",
        value: e,
      })
    );
  };

  const handleFunPlayingR = (e) => {
    setFunPlayingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPlayingR",
        value: e,
      })
    );
  };
  const handleFunPlayingL = (e) => {
    setFunPlayingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPlayingL",
        value: e,
      })
    );
  };

  const handleFunPractisingR = (e) => {
    setFunPractisingR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPractisingR",
        value: e,
      })
    );
  };
  const handleFunPractisingL = (e) => {
    setFunPractisingL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPractisingL",
        value: e,
      })
    );
  };

  const handleFunHairR = (e) => {
    setFunHairR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHairR",
        value: e,
      })
    );
  };
  const handleFunHairL = (e) => {
    setFunHairL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funHairL",
        value: e,
      })
    );
  };
  const handleFunLeisureR = (e) => {
    setFunLeisureR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funLeisureR",
        value: e,
      })
    );
  };
  const handleFunLeisureL = (e) => {
    setFunLeisureL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funLeisureL",
        value: e,
      })
    );
  };

  const handleFunRegularR = (e) => {
    setFunRegularR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funRegularR",
        value: e,
      })
    );
  };
  const handleFunRegularL = (e) => {
    setFunRegularL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funRegularL",
        value: e,
      })
    );
  };

  const haldlefunTextR = (e) => {
    setfunTextR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTextR",
        value: e,
      })
    );
  };
  const haldlefunTextL = (e) => {
    setfunTextL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTextL",
        value: e,
      })
    );
  };

  const handleCheckR = (e) => {
    setcheckR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "checkR",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTechniqueR",
        value: "",
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkAsWallR",
        value: "",
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSpendingR",
        value: "",
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funTechniqueL",
        value: "",
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funWorkAsWallL",
        value: "",
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funSpendingL",
        value: "",
      })
    );
  };
  const handleCheckL = (e) => {
    setcheckL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "checkL",
        value: e,
      })
    );
  };

  const haldlefunMusicaTextR = (e) => {
    setfunMusicaTextR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funMusicaTextR",
        value: e,
      })
    );
  };
  const haldlefunMusicaTextL = (e) => {
    setfunMusicaTextL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funMusicaTextL",
        value: e,
      })
    );
  };

  const handleMusicaCheckL = (e) => {
    setcheckMusicaL(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "checkMusicaL",
        value: e,
      })
    );
  };
  const handleMusicaCheckR = (e) => {
    setcheckMusicaR(e);
    dispatch(
      updateElbowFunctionFieldScore({
        key: "checkMusicaR",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funInstrumentR",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPlayingR",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPractisingR",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funInstrumentL",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPlayingL",
        value: e,
      })
    );
    dispatch(
      updateElbowFunctionFieldScore({
        key: "funPractisingL",
        value: e,
      })
    );
  };

  // useEffect(() => {
  //   if (checkR === true) {
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funTechniqueR",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funWorkAsWallR",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funSpendingR",
  //         value: "0",
  //       })
  //     );

  //   }
  // }, [checkR])

  // useEffect(() => {
  //   if (checkL === true) {
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funTechniqueL",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funWorkAsWallL",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateElbowFunctionFieldScore({
  //         key: "funSpendingL",
  //         value: "0",
  //       })
  //     );

  //   }
  // }, [checkL])

  useEffect(() => {
    if (checkMusicaR === true) {
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funInstrumentR",
          value: "0",
        })
      );
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funPlayingR",
          value: "0",
        })
      );
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funPractisingR",
          value: "0",
        })
      );
    }
  }, [checkMusicaR]);
  useEffect(() => {
    if (checkMusicaL === true) {
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funInstrumentL",
          value: "0",
        })
      );
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funPlayingL",
          value: "0",
        })
      );
      dispatch(
        updateElbowFunctionFieldScore({
          key: "funPractisingL",
          value: "0",
        })
      );
    }
  }, [checkMusicaL]);

  const continueRedirect = () => {
    dispatch(
      updateElbowFunctionData([
        {
          funDescR: funDescR,
          funDescL: funDescL,
          funActivityR: funActivityR,
          funActivityL: funActivityL,
          funUselongR: funUselongR,
          funUselongL: funUselongL,
          tightR: tightR,
          tightL: tightL,
          funHouseholdR: funHouseholdR,
          funHouseholdL: funHouseholdL,
          funshoppingL: funshoppingL,
          funshoppingR: funshoppingR,
          funrubbishR: funrubbishR,
          funrubbishL: funrubbishL,
          funWashR: funWashR,
          funWashL: funWashL,
          funToiletinghR: funToiletinghR,
          funToiletinghL: funToiletinghL,
          funWashingR: funWashingR,
          funWashingL: funWashingL,
          funHygienegR: funHygienegR,
          funHygienegL: funHygienegL,
          funKnifeR: funKnifeR,
          funKnifeL: funKnifeL,
          funUtensilR: funUtensilR,
          funUtensilL: funUtensilL,
          funRecreationalR: funRecreationalR,
          funRecreationalL: funRecreationalL,
          funSleepingL: funSleepingL,
          funShirtR: funShirtR,
          funShirtL: funShirtL,
          funTopShirtR: funTopShirtR,
          funTopShirtL: funTopShirtL,
          funSleepingR: funSleepingR,
          funDressingR: funDressingR,
          funDressingL: funDressingL,
          funPutShoesR: funPutShoesR,
          funPutShoesL: funPutShoesL,
          funTieShoesR: funTieShoesR,
          funTieShoesL: funTieShoesL,
          funHeavyR: funHeavyR,
          funHeavyL: funHeavyL,
          funChairR: funChairR,
          funChairL: funChairL,
          householdR: householdR,
          householdL: householdL,
          funTurnR: funTurnR,
          funTurnL: funTurnL,
          funBallR: funBallR,
          funBallL: funBallL,
          funWorkR: funWorkR,
          funWorkL: funWorkL,
          funTechniqueR: funTechniqueR,
          funTechniqueL: funTechniqueL,
          funSpendingR: funSpendingR,
          funSpendingL: funSpendingL,
          funMusicalR: funMusicalR,
          funMusicalL: funMusicalL,
          funInstrumentR: funInstrumentR,
          funInstrumentL: funInstrumentL,
          funPlayingR: funPlayingR,
          funPlayingL: funPlayingL,
          funPractisingR: funPractisingR,
          funPractisingL: funPractisingL,
          funWorkAsWallR: funWorkAsWallR,
          funWorkAsWallL: funWorkAsWallL,
          funHairR: funHairR,
          funHairL: funHairL,
          funLeisureR: funLeisureR,
          funLeisureL: funLeisureL,
          funRegularR: funRegularR,
          funRegularL: funRegularL,
          funTextR: funTextR,
          funTextL: funTextL,
          checkR: checkR,
          checkL: checkL,
          funMusicaTextR: funMusicaTextR,
          funMusicaTextL: funMusicaTextL,
          checkMusicaL: checkMusicaL,
          checkMusicaR: checkMusicaR,
        },
      ])
    );
    navigator("/elbow-symptoms/" + rId);
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <ElbowTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_elbow_function}
              </h4>

              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "5",
                              label: translation.patient_elbow_function_q1_a1,
                            },
                            {
                              value: "4",
                              label: translation.patient_elbow_function_q1_a2,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q1_a3,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q1_a4,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q1_a5,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q1_a6,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q1 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funDescR}
                          onChange={handleFunDescR}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "5",
                              label: translation.patient_elbow_function_q1_a1,
                            },
                            {
                              value: "4",
                              label: translation.patient_elbow_function_q1_a2,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q1_a3,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q1_a4,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q1_a5,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q1_a6,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q1 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funDescL}
                          onChange={handleFunDescL}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_elbow_function_q2_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q2_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q2_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q2_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q2_a5,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q2 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funActivityR}
                          onChange={handleFunActivityR}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_elbow_function_q2_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q2_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q2_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q2_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q2_a5,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q2 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funActivityL}
                          onChange={handleFunActivityL}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q3_a1,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q3_a2,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q3_a3,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q3_a4,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q3 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funUselongR}
                          onChange={handleFunUselongR}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "3",
                              label: translation.patient_elbow_function_q3_a1,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_function_q3_a2,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_function_q3_a3,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_function_q3_a4,
                            },
                          ]}
                          label={
                            translation.patient_elbow_function_q3 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funUselongL}
                          onChange={handleFunUselongL}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="function_formtop">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 function_head">
                        <label>
                          {translation.patient_elbow_function_instruction}
                        </label>{" "}
                        <br />
                        <span dir={lg === "ara" ? "rtl" : " "}>
                          (<strong>0</strong> ={" "}
                          {translation.patient_elbow_function_instruction_0},{" "}
                          <strong>1</strong> ={" "}
                          {translation.patient_elbow_function_instruction_1},{" "}
                          <strong>2</strong> ={" "}
                          {translation.patient_elbow_function_instruction_2},{" "}
                          <strong>3</strong> ={" "}
                          {translation.patient_elbow_function_instruction_3},{" "}
                          <strong>4</strong> ={" "}
                          {translation.patient_elbow_function_instruction_4})
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q4 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={tightR}
                            onChange={handleTightR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q4_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q4_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q4_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q4_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q4 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={tightL}
                            onChange={handleTightL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q4_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q4_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q4_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q4_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* 
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div className="col-md-6 col-sm-6 mb_10">
                          <ElbowScoreRangeSlider
                            label={evlElbowPain === "3" ? translation.Do_heavy_household_chores_R : translation.Do_heavy_household_chores}
                            classes=""
                            value={funHouseholdR}
                            onChange={handlefunHouseholdR}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className="col-md-6 col-sm-6 mb_10">
                          <ElbowScoreRangeSlider
                            label={evlElbowPain === "3" ? translation.Do_heavy_household_chores_L : translation.Do_heavy_household_chores}
                            classes=""
                            value={funHouseholdL}
                            onChange={handlefunHouseholdL}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q5 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funshoppingR}
                            onChange={handleFunshoppingR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q5_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q5_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q5_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q5_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q5 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funshoppingL}
                            onChange={handleFunshoppingL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q5_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q5_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q5_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q5_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q6 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funrubbishR}
                            onChange={handleFunrubbishR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q6_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q6_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q6_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q6_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q6 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funrubbishL}
                            onChange={handleFunrubbishL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q6_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q6_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q6_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q6_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q7 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWashR}
                            onChange={handleFunWashR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q7_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q7_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q7_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q7_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q7 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWashL}
                            onChange={handleFunWashL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q7_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q7_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q7_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q7_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q8 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funToiletinghR}
                            onChange={handleFunToiletinghR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q8_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q8_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q8_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q8_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q8 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funToiletinghL}
                            onChange={handleFunToiletinghL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q8_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q8_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q8_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q8_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q9 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWashingR}
                            onChange={handleFunWashingR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q9_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q9_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q9_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q9_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q9 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWashingL}
                            onChange={handleFunWashingL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_elbow_function_q9_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_elbow_function_q9_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_elbow_function_q9_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_elbow_function_q9_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_elbow_function_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q10 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHygienegR}
                            onChange={handleFunHygienegR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q10 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHygienegL}
                            onChange={handleFunHygienegL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q11 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funKnifeR}
                            onChange={handleFunKnifeR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q11 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funKnifeL}
                            onChange={handleFunKnifeL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q12 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funUtensilR}
                            onChange={handleFunUtensilR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q12_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q12_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q12_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q12_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q12 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funUtensilL}
                            onChange={handleFunUtensilL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q12_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q12_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q12_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q12_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q13 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funRecreationalR}
                            onChange={handleFunRecreationalR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q13 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funRecreationalL}
                            onChange={handleFunRecreationalL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q14 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funSleepingR}
                            onChange={handleFunSleepingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q14 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funSleepingL}
                            onChange={handleFunSleepingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q15 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funShirtR}
                            onChange={handleFunShirtR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q15 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funShirtL}
                            onChange={handleFunShirtL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q16 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTopShirtR}
                            onChange={handleFunTopShirtR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q16_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q16_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q16_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q16_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q16 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTopShirtL}
                            onChange={handleFunTopShirtL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q16_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q16_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q16_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q16_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q17 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funDressingR}
                            onChange={handleFunDressingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q17_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q17_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q17_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q17_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q17 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funDressingL}
                            onChange={handleFunDressingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q17_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q17_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q17_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q17_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q18 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHairR}
                            onChange={handleFunHairR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q18_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q18_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q18_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q18_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q18 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHairL}
                            onChange={handleFunHairL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q18_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q18_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q18_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q18_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q19 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funPutShoesR}
                            onChange={handleFunPutShoesR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q19_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q19_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q19_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q19_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q19_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q19 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funPutShoesL}
                            onChange={handleFunPutShoesL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q19_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q19_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q19_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q19_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q19_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q20 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTieShoesR}
                            onChange={handleFunTieShoesR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q20_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q20_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q20_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q20_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q20_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q20 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTieShoesL}
                            onChange={handleFunTieShoesL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q20_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q20_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q20_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q20_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q20_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q21 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHeavyR}
                            onChange={handleFunHeavyR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q21_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q21_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q21_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q21_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q21_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q21 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funHeavyL}
                            onChange={handleFunHeavyL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q21_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q21_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q21_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q21_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q21_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q22 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funChairR}
                            onChange={handleFunChairR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q22_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q22_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q22_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q22_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q22_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q22 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funChairL}
                            onChange={handleFunChairL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q22_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q22_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q22_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q22_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q22_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q23 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={householdR}
                            onChange={handleHouseholdR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q23_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q23_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q23_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q23_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q23_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q23 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={householdL}
                            onChange={handleHouseholdL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q23_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q23_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q23_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q23_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q23_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q24 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTurnR}
                            onChange={handleFunTurnR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q24_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q24_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q24_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q24_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q24_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q24 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funTurnL}
                            onChange={handleFunTurnL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q24_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q24_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q24_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q24_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q24_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q25 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funBallR}
                            onChange={handleFunBallR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q25_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q25_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q25_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q25_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q25_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q25 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funBallL}
                            onChange={handleFunBallL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q25_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q25_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q25_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q25_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q25_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q26 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWorkR}
                            onChange={handleFunWorkR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q26_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q26_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q26_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q26_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q26_a5,
                              },
                            ]}
                          />

                          {/* <InputField
                              label={translation.patient_elbow_function_q27}
                              placeholder={translation.patient_elbow_function_q27_placeholder}
                              onChange={haldlefunTextR}
                              value={funTextR}
                            /> */}

                          {/* <Checkbox
                            label={evlElbowPain === "3" ? translation.I_do_not_play_a_sport_or_instrument_R_elbow : translation.I_do_not_play_a_sport_or_instrument_Elbowww}
                            value={checkR}
                            selected={checkR}
                            onChange={handleCheckR}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
            
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q26 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funWorkL}
                            onChange={handleFunWorkL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q26_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q26_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q26_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q26_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q26_a5,
                              },
                            ]}
                          />

                          {/* <Checkbox
                            label={evlElbowPain === "3" ? translation.I_do_not_play_a_sport_or_instrument_L_elbow : translation.I_do_not_play_a_sport_or_instrument_Elbowww}
                            value={checkL}
                            selected={checkL}
                            onChange={handleCheckL}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <InputField
                        label={translation.patient_elbow_function_q27}
                        placeholder={
                          translation.patient_elbow_function_q27_placeholder
                        }
                        onChange={haldlefunTextL}
                        value={funTextL}
                        styleClass="w-50"
                      />
                    <div className="row">
                      <Checkbox
                        label={translation.patient_elbow_function_i_donot_work}
                        value={checkR}
                        selected={checkR}
                        onChange={handleCheckR}
                      />
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          {checkL || checkR ? null : (
                            <>
                              <div className="">
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q28 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funTechniqueR}
                                  onChange={handleFunTechniqueR}
                                  onClick={handleFunTechniqueR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q28_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q28_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q28_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q28_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q28_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q29 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funWorkAsWallR}
                                  onChange={handleFunWorkAsWallR}
                                  onClick={handleFunWorkAsWallR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q29_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q29_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q29_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q29_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q29_a5,
                                    },
                                  ]}
                                />

                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q30 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funSpendingR}
                                  onChange={handleFunSpendingR}
                                  onClick={handleFunSpendingR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q30_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q30_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q30_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q30_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q30_a5,
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          {checkL || checkR ? null : (
                            <>
                              <div className="">
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q28 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funTechniqueL}
                                  onChange={handleFunTechniqueL}
                                  onClick={handleFunTechniqueL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q28_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q28_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q28_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q28_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q28_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q29 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funWorkAsWallL}
                                  onChange={handleFunWorkAsWallL}
                                  onClick={handleFunWorkAsWallL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q29_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q29_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q29_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q29_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q29_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q30 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funSpendingL}
                                  onChange={handleFunSpendingL}
                                  onClick={handleFunSpendingL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q30_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q30_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q30_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q30_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q30_a5,
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q31 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funMusicalR}
                            onChange={handleFunMusicalR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q31_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q31_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q31_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q31_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q31_a5,
                              },
                            ]}
                          />

                          {/* <InputField
                            label={translation.patient_elbow_function_q32}
                            placeholder={translation.patient_elbow_function_q32_placeholder}
                            onChange={haldlefunMusicaTextR}
                            value={funMusicaTextR}
                          /> */}

                          {/* <Checkbox
                            label={evlElbowPain === "3" ? translation.I_do_not_play_a_sport_or_instrument_R : translation.I_do_not_play_a_sport_or_instrument_Elbow}
                            value={checkMusicaR}
                            selected={checkMusicaR}
                            onChange={handleMusicaCheckR}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                     
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"}>
                          <ElbowScoreRangeSlider
                            label={
                              translation.patient_elbow_function_q31 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            classes=""
                            value={funMusicalL}
                            onChange={handleFunMusicalL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_elbow_function_q31_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_elbow_function_q31_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_elbow_function_q31_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_elbow_function_q31_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_elbow_function_q31_a5,
                              },
                            ]}
                          />

                          {/* <Checkbox
                            label={evlElbowPain === "3" ? translation.I_do_not_play_a_sport_or_instrument_L : translation.I_do_not_play_a_sport_or_instrument_Elbow}
                            value={checkMusicaL}
                            selected={checkMusicaL}
                            onChange={handleMusicaCheckL}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <InputField
                        label={translation.patient_elbow_function_q32}
                        placeholder={
                          translation.patient_elbow_function_q32_placeholder
                        }
                        onChange={haldlefunMusicaTextL}
                        value={funMusicaTextL}
                        styleClass="w-50"
                      />
                    <div className="row">
                      <Checkbox
                        label={translation.patient_elbow_function_i_donot_play}
                        value={checkMusicaR}
                        selected={checkMusicaR}
                        onChange={handleMusicaCheckR}
                      />
                    </div>

                    <div className="row">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          {checkMusicaR || checkMusicaL ? null : (
                            <>
                              <div className="">
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q33 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funInstrumentR}
                                  onChange={handleFunInstrumentR}
                                  onClick={handleFunInstrumentR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q33_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q33_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q33_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q33_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q33_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q34 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funPlayingR}
                                  onChange={handleFunPlayingR}
                                  onClick={handleFunPlayingR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q34_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q34_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q34_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q34_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q34_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q35 +
                                    RigthTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funPractisingR}
                                  onChange={handleFunPractisingR}
                                  onClick={handleFunPractisingR}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q35_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q35_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q35_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q35_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q35_a5,
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          {checkMusicaR || checkMusicaL ? null : (
                            <>
                              <div className="">
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q33 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funInstrumentL}
                                  onChange={handleFunInstrumentL}
                                  onClick={handleFunInstrumentL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q33_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q33_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q33_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q33_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q33_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q34 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funPlayingL}
                                  onChange={handleFunPlayingL}
                                  onClick={handleFunPlayingL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q34_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q34_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q34_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q34_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q34_a5,
                                    },
                                  ]}
                                />
                                <ElbowScoreRangeSlider
                                  label={
                                    translation.patient_elbow_function_q35 +
                                    LeftTrans(evlElbowPain, translation)
                                  }
                                  classes=""
                                  value={funPractisingL}
                                  onChange={handleFunPractisingL}
                                  onClick={handleFunPractisingL}
                                  sliderData={[
                                    {
                                      score: "0",
                                      label:
                                        translation.patient_elbow_function_q35_a1,
                                    },
                                    {
                                      score: "1",
                                      label:
                                        translation.patient_elbow_function_q35_a2,
                                    },
                                    {
                                      score: "2",
                                      label:
                                        translation.patient_elbow_function_q35_a3,
                                    },
                                    {
                                      score: "3",
                                      label:
                                        translation.patient_elbow_function_q35_a4,
                                    },
                                    {
                                      score: "4",
                                      label:
                                        translation.patient_elbow_function_q35_a5,
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <br />
                  </div>

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_function_q36 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funLeisureR}
                          onChange={handleFunLeisureR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_function_q36_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_function_q36_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_function_q36_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_function_q36_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_function_q36_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_function_q36 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funLeisureL}
                          onChange={handleFunLeisureL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_function_q36_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_function_q36_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_function_q36_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_function_q36_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_function_q36_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_function_q37 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funRegularR}
                          onChange={handleFunRegularR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_function_q37_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_function_q37_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_function_q37_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_function_q37_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_function_q37_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_function_q37 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={funRegularL}
                          onChange={handleFunRegularL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_function_q37_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_function_q37_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_function_q37_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_function_q37_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_function_q37_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={"/elbow-pain/" + rId+`?is_doctor_form=${doctordata}&edit=${viewicon}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ElbowFunction;

import React from "react";
import useTranslation from "../../customHooks/translations";

export default function HandWristbctq({ handbctq, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            6. {translations.patient_Hand_BCTQ}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Hand_nd_Wrist}</th>
                    <th>{translations.Left_Hand_nd_Wrist}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q1}
                    </td>
                    <td>{handbctq.severeR}</td>
                    <td>{handbctq.severeL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q2}
                    </td>
                    <td>{handbctq.oftenR}</td>
                    <td>{handbctq.oftenL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q3}
                    </td>
                    <td>{handbctq.typicallyR}</td>
                    <td>{handbctq.typicallyL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q4}
                    </td>
                    <td>{handbctq.daytimeR}</td>
                    <td>{handbctq.daytimeL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q5}
                    </td>
                    <td>{handbctq.averageR}</td>
                    <td>{handbctq.averageL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q6}
                    </td>
                    <td>{handbctq.numbnessR}</td>
                    <td>{handbctq.numbnessL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q7}
                    </td>
                    <td>{handbctq.weaknessR}</td>
                    <td>{handbctq.weaknessL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q8}
                    </td>
                    <td>{handbctq.tinglingR}</td>
                    <td>{handbctq.tinglingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q9}
                    </td>
                    <td>{handbctq.nightR}</td>
                    <td>{handbctq.nightL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_symptom_q10}
                    </td>
                    <td>{handbctq.duringR}</td>
                    <td>{handbctq.duringL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q1}
                    </td>
                    <td>{handbctq.DifficultyR}</td>
                    <td>{handbctq.DifficultyL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q2}
                    </td>
                    <td>{handbctq.WritingR}</td>
                    <td>{handbctq.WritingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q3}
                    </td>
                    <td>{handbctq.ButtoningR}</td>
                    <td>{handbctq.ButtoningL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q4}
                    </td>
                    <td>{handbctq.HoldingR}</td>
                    <td>{handbctq.HoldingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q5}
                    </td>
                    <td>{handbctq.GrippingR}</td>
                    <td>{handbctq.GrippingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q6}
                    </td>
                    <td>{handbctq.OpeningR}</td>
                    <td>{handbctq.OpeningL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q7}
                    </td>
                    <td>{handbctq.HouseholdR}</td>
                    <td>{handbctq.HouseholdL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q8}
                    </td>
                    <td>{handbctq.CarryingR}</td>
                    <td>{handbctq.CarryingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_hand_questionnaire_function_q9}
                    </td>
                    <td>{handbctq.BathingR}</td>
                    <td>{handbctq.BathingL}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import {
  updateFootFunctionScore,
  updateFootFunctionData,
  updateFootAnkle,
  updateFootAnklePainData,
  updateFootQolData,
  updateFootSatisfactionData,
  updateFootInstabilityData,
  updateFootSymptomsData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
  updateAllData,
} from "../../../../redux/actions/footAnkle";

import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import ElbowFeedbackScoreRangeSlider from "../../../custom/elbow-doctorfeedback-score-range-slider/ElbowFeedbackScoreRangeSlider";
import HipScoreRangeSlider from "../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function FootAnkleFunction() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [footActivityL, setFootActivityL] = useState("0");
  const [footActivityR, setFootActivityR] = useState("0");
  const [footwearL, setFootwearL] = useState("0");
  const [footwearR, setFootwearR] = useState("0");
  const [walkingL, setWalkingL] = useState("0");
  const [walkingR, setWalkingR] = useState("0");
  const [footStraightL, setFootStraightL] = useState("0");
  const [footStraightR, setFootStraightR] = useState("0");
  const [footBendingL, setFootBendingL] = useState("0");
  const [footBendingR, setFootBendingR] = useState("0");
  const [desStairL, setDesStairL] = useState("0");
  const [desStairR, setDesStairR] = useState("0");
  const [ascStairL, setAscStairL] = useState("0");
  const [ascStairR, setAscStairR] = useState("0");
  const [risingL, setRisingL] = useState("0");
  const [risingR, setRisingR] = useState("0");
  const [standingL, setStandingL] = useState("0");
  const [standingR, setStandingR] = useState("0");
  const [bendingFloorL, setBendingFloorL] = useState("0");
  const [bendingFloorR, setBendingFloorR] = useState("0");
  const [walkingFlatL, setWalkingFlatL] = useState("0");
  const [walkingFlatR, setWalkingFlatR] = useState("0");
  const [maxWalkingL, setMaxWalkingL] = useState("0");
  const [maxWalkingR, setMaxWalkingR] = useState("0");
  const [walkingWithoutShoesL, setWalkingWithoutShoesL] = useState("0");
  const [walkingWithoutShoesR, setWalkingWithoutShoesR] = useState("0");
  const [walkingHillsL, setWalkingHillsL] = useState("0");
  const [walkingHillsR, setWalkingHillsR] = useState("0");
  const [walkingDownHillsL, setWalkingDownHillsL] = useState("0");
  const [walkingDownHillsR, setWalkingDownHillsR] = useState("0");
  const [walkingUnevenL, setWalkingUnevenL] = useState("0");
  const [walkingUnevenR, setWalkingUnevenR] = useState("0");
  const [steppingUpL, setSteppingUpL] = useState("0");
  const [steppingUpR, setSteppingUpR] = useState("0");
  const [comingUpL, setComingUpL] = useState("0");
  const [comingUpR, setComingUpR] = useState("0");
  const [walkIntiallyL, setWalkIntiallyL] = useState("0");
  const [walkIntiallyR, setWalkIntiallyR] = useState("0");
  const [walkFiveL, setWalkFiveL] = useState("0");
  const [walkFiveR, setWalkFiveR] = useState("0");
  const [walkTenL, setWalkTenL] = useState("0");
  const [walkTenR, setWalkTenR] = useState("0");
  const [walkFifteenL, setWalkFifteenL] = useState("0");
  const [walkFifteenR, setWalkFifteenR] = useState("0");
  const [gettingCarL, setGettingCarL] = useState("0");
  const [gettingCarR, setGettingCarR] = useState("0");
  const [goingShoppingL, setGoingShoppingL] = useState("0");
  const [goingShoppingR, setGoingShoppingR] = useState("0");
  const [puttingSocksL, setPuttingSocksL] = useState("0");
  const [puttingSocksR, setPuttingSocksR] = useState("0");
  const [takingOffL, setTakingOffL] = useState("0");
  const [takingOffR, setTakingOffR] = useState("0");
  const [risingBedL, setRisingBedL] = useState("0");
  const [risingBedR, setRisingBedR] = useState("0");
  const [lyingL, setLyingL] = useState("0");
  const [lyingR, setLyingR] = useState("0");
  const [getBathL, setGetBathL] = useState("0");
  const [getBathR, setGetBathR] = useState("0");
  const [sittingL, setSittingL] = useState("0");
  const [sittingR, setSittingR] = useState("0");
  const [getToiletL, setGetToiletL] = useState("0");
  const [getToiletR, setGetToiletR] = useState("0");
  const [homeL, setHomeL] = useState("0");
  const [homeR, setHomeR] = useState("0");
  const [activitiesL, setActivitiesL] = useState("0");
  const [activitiesR, setActivitiesR] = useState("0");
  const [personalCareL, setPersonalCareL] = useState("0");
  const [personalCareR, setPersonalCareR] = useState("0");
  const [heavyWorkL, setHeavyWorkL] = useState("0");
  const [heavyWorkR, setHeavyWorkR] = useState("0");
  const [lightWorkL, setLightWorkL] = useState("0");
  const [lightWorkR, setLightWorkR] = useState("0");
  const [moderateWorkL, setModerateWorkL] = useState("0");
  const [moderateWorkR, setModerateWorkR] = useState("0");
  const [recreationalL, setRecreationalL] = useState("0");
  const [recreationalR, setRecreationalR] = useState("0");
  const [squattingL, setSquattingL] = useState("0");
  const [squattingR, setSquattingR] = useState("0");
  const [twistingL, setTwistingL] = useState("0");
  const [twistingR, setTwistingR] = useState("0");
  const [kneelingL, setKneelingL] = useState("0");
  const [kneelingR, setKneelingR] = useState("0");
  const [runningL, setRunnigL] = useState("0");
  const [runningR, setRunnigR] = useState("0");
  const [jumpingL, setJumpingL] = useState("0");
  const [jumpingR, setJumpingR] = useState("0");
  const [landingL, setLandnigL] = useState("0");
  const [landingR, setLandnigR] = useState("0");
  const [stoppingL, setStoppingL] = useState("0");
  const [stoppingR, setStoppingR] = useState("0");
  const [cuttingL, setCuttingL] = useState("0");
  const [cuttingR, setCuttingR] = useState("0");
  const [lowActL, setLowActL] = useState("0");
  const [lowActR, setLowActR] = useState("0");
  const [abilityPerformL, setAbilityPerformL] = useState("0");
  const [abilityPerformR, setAbilityPerformR] = useState("0");
  const [abilityParticipateL, setAbilityParticipateL] = useState("0");
  const [abilityParticipateR, setAbilityParticipateR] = useState("0");
  const [footPercent, setFootPercent] = useState("0");
  const [footPercentL, setFootPercentL] = useState("0");
  const [Hindfoot, setHindfoot] = useState("0");
  const [Midfoot, setMidfoot] = useState("0");
  const [Hallux, setHallux] = useState("0");
  const [LesserToes, setLesserToes] = useState("0");
  const [Midfootb, setMidfootb] = useState("0");
  const [Halluxb, setHalluxb] = useState("0");
  const [LesserToesb, setLesserToesb] = useState("0");
  const [Hindfootc, setHindfootc] = useState("0");
  const [Midfootc, setMidfootc] = useState("0");
  const [sleepingR, setSleepingR] = useState("0");
  const [sleepingL, setSleepingL] = useState("0");

  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const [runSelectR, setRunSelectR] = useState(false);
  const [runSelectL, setRunSelectL] = useState(false);

  const [jumpSelectR, setjumpSelectR] = useState(false);
  const [jumpSelectL, setjumpSelectL] = useState(false);

  const [landingSelectR, setlandingSelectR] = useState(false);
  const [landingSelectL, setlandingSelectL] = useState(false);

  const [startingSelectR, setstartingSelectR] = useState(false);
  const [startingSelectL, setstartingSelectL] = useState(false);

  const [cuttingSelectR, setcuttingSelectR] = useState(false);
  const [cuttingSelectL, setcuttingSelectL] = useState(false);

  const [impactSelectR, setimapactSelectR] = useState(false);
  const [impactSelectL, setimapactSelectL] = useState(false);

  const [performSelectR, setperformSelectR] = useState(false);
  const [performSelectL, setperformSelectL] = useState(false);

  const [participateSelectR, setparticipateSelectR] = useState(false);
  const [participateSelectL, setparticipateSelectL] = useState(false);

  const [highestActR, setHighestActR] = useState("");
  const [highestActL, setHighestActL] = useState("");

  const [topLevelR, setTopLevelR] = useState([]);
  const [topLevelL, setTopLevelL] = useState([]);
  const [lowLevelR, setLowLevelR] = useState([]);
  const [lowLevelL, setLowLevelL] = useState([]);
  const [recreatR, setRecreatR] = useState([]);
  const [recreatL, setRecreatL] = useState([]);
  const [idoSpotR, setIdoSpotR] = useState("");
  const [idoSpotL, setIdoSpotL] = useState("");
  const [noSpotR, setNoSpotR] = useState("");
  const [noSpotL, setNoSpotL] = useState("");

  const [initialAssesment, setInitialAssesment] = useState(0);

  const [value, setValue] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);




  const [options, setOptions] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    },
  ])
  const [options1, setOptions1] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    },
  ])

  const [options2, setOptions2] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    }
  ])

  const [options3, setOptions3] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    }
  ])

  const [options4, setOptions4] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    }
  ])

  const [options5, setOptions5] = useState([
    {
      value: "0",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
    },
    {
      value: "1",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
    },
    {
      value: "2",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
    },
    {
      value: "3",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
    },
    {
      value: "4",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
    },
    {
      value: "5",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
    },
    {
      value: "6",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
    },
    {
      value: "7",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
    },
    {
      value: "8",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
    },
    {
      value: "9",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
    },
    {
      value: "10",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
    },
    {
      value: "11",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
    },
    {
      value: "12",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
    },
    {
      value: "13",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
    },
    {
      value: "14",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
    },
    {
      value: "15",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
    },
    {
      value: "16",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
    },
    {
      value: "17",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
    },
    {
      value: "18",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
    },
    {
      value: "19",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
    },
    {
      value: "20",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
    },
    {
      value: "21",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
    },
    {
      value: "22",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
    },
    {
      value: "23",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
    },
    {
      value: "24",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
    },
    {
      value: "25",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
    },
    {
      value: "26",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
    },
    {
      value: "27",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
    },
    {
      value: "28",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
    },
    {
      value: "29",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
    },
    {
      value: "30",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
    },
    {
      value: "31",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
    },
    {
      value: "32",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
    },
    {
      value: "33",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
    },
    {
      value: "34",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
    },
    {
      value: "35",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
    },
    {
      value: "36",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
    },
    {
      value: "37",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
    },
    {
      value: "38",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
    },
    {
      value: "39",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
    },
    {
      value: "40",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
    },
    {
      value: "41",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
    },
    {
      value: "42",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
    },
    {
      value: "43",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
    },
    {
      value: "44",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
    },
    {
      value: "45",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
    },
    {
      value: "46",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
    },
    {
      value: "47",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
    },
    {
      value: "48",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
    },
    {
      value: "49",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
    },
    {
      value: "50",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
    },
    {
      value: "51",
      label:
        translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
    }
  ])


  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const Footfunction = useSelector((state) => state.footAnkle.functionScore[0]);
  console.log(Footfunction, "FootfunctionFootfunction");
  const json = useSelector((state) => state.footAnkle);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setFootActivityL(Footfunction.footActivityL);
    setFootActivityR(Footfunction.footActivityR);
    setFootwearL(Footfunction.footwearL);
    setFootwearR(Footfunction.footwearR);
    setWalkingL(Footfunction.walkingL);
    setWalkingR(Footfunction.walkingR);
    setFootStraightL(Footfunction.footStraightL);
    setFootStraightR(Footfunction.footStraightR);
    setFootBendingL(Footfunction.footBendingL);
    setFootBendingR(Footfunction.footBendingR);
    setDesStairL(Footfunction.desStairL);
    setDesStairR(Footfunction.desStairR);
    setAscStairL(Footfunction.ascStairL);
    setAscStairR(Footfunction.ascStairR);
    setRisingL(Footfunction.risingL);
    setRisingR(Footfunction.risingR);
    setStandingL(Footfunction.standingL);
    setStandingR(Footfunction.standingR);
    setBendingFloorL(Footfunction.bendingFloorL);
    setBendingFloorR(Footfunction.bendingFloorR);
    setWalkingFlatL(Footfunction.walkingFlatL);
    setWalkingFlatR(Footfunction.walkingFlatR);
    setMaxWalkingL(Footfunction.maxWalkingL);
    setMaxWalkingR(Footfunction.maxWalkingR);
    setWalkingWithoutShoesL(Footfunction.walkingWithoutShoesL);
    setWalkingWithoutShoesR(Footfunction.walkingWithoutShoesR);
    setWalkingHillsL(Footfunction.walkingHillsL);
    setWalkingHillsR(Footfunction.walkingHillsR);
    setWalkingDownHillsL(Footfunction.walkingDownHillsL);
    setWalkingDownHillsR(Footfunction.walkingDownHillsR);
    setWalkingUnevenL(Footfunction.walkingUnevenL);
    setWalkingUnevenR(Footfunction.walkingUnevenR);
    setSteppingUpL(Footfunction.steppingUpL);
    setSteppingUpR(Footfunction.steppingUpR);
    setComingUpL(Footfunction.comingUpL);
    setComingUpR(Footfunction.comingUpR);
    setWalkIntiallyL(Footfunction.walkIntiallyL);
    setWalkIntiallyR(Footfunction.walkIntiallyR);
    setWalkFiveL(Footfunction.walkFiveL);
    setWalkFiveR(Footfunction.walkFiveR);
    setWalkTenL(Footfunction.walkTenL);
    setWalkTenR(Footfunction.walkTenR);
    setWalkFifteenL(Footfunction.walkFifteenL);
    setWalkFifteenR(Footfunction.walkFifteenR);
    setGettingCarL(Footfunction.gettingCarL);
    setGettingCarR(Footfunction.gettingCarR);
    setGoingShoppingL(Footfunction.goingShoppingL);
    setGoingShoppingR(Footfunction.goingShoppingR);
    setPuttingSocksL(Footfunction.puttingSocksL);
    setPuttingSocksR(Footfunction.puttingSocksR);
    setTakingOffL(Footfunction.takingOffL);
    setTakingOffR(Footfunction.takingOffR);
    setRisingBedL(Footfunction.risingBedL);
    setRisingBedR(Footfunction.risingBedR);
    setLyingL(Footfunction.lyingL);
    setLyingR(Footfunction.lyingR);
    setGetBathL(Footfunction.getBathL);
    setGetBathR(Footfunction.getBathR);
    setSittingL(Footfunction.sittingL);
    setSittingR(Footfunction.sittingR);
    setGetToiletL(Footfunction.getToiletL);
    setGetToiletR(Footfunction.getToiletR);
    setHomeL(Footfunction.homeL);
    setHomeR(Footfunction.homeR);
    setActivitiesL(Footfunction.activitiesL);
    setActivitiesR(Footfunction.activitiesR);
    setPersonalCareL(Footfunction.personalCareL);
    setPersonalCareR(Footfunction.personalCareR);
    setHeavyWorkL(Footfunction.heavyWorkL);
    setHeavyWorkR(Footfunction.heavyWorkR);
    setLightWorkL(Footfunction.lightWorkL);
    setLightWorkR(Footfunction.lightWorkR);
    setModerateWorkL(Footfunction.moderateWorkL);
    setModerateWorkR(Footfunction.moderateWorkR);
    setRecreationalL(Footfunction.recreationalL);
    setRecreationalR(Footfunction.recreationalR);
    setSquattingL(Footfunction.squattingL);
    setSquattingR(Footfunction.squattingR);
    setTwistingL(Footfunction.twistingL);
    setTwistingR(Footfunction.twistingR);
    setKneelingL(Footfunction.kneelingL);
    setKneelingR(Footfunction.kneelingR);
    setRunnigL(Footfunction.runningL);
    setRunnigR(Footfunction.runningR);
    setJumpingL(Footfunction.jumpingL);
    setJumpingR(Footfunction.jumpingR);
    setLandnigL(Footfunction.landingL);
    setLandnigR(Footfunction.landingR);
    setStoppingL(Footfunction.stoppingL);
    setStoppingR(Footfunction.stoppingR);
    setCuttingL(Footfunction.cuttingL);
    setCuttingR(Footfunction.cuttingR);
    setLowActL(Footfunction.lowActL);
    setLowActR(Footfunction.lowActR);
    setAbilityPerformL(Footfunction.abilityPerformL);
    setAbilityPerformR(Footfunction.abilityPerformR);
    setAbilityParticipateL(Footfunction.abilityParticipateL);
    setAbilityParticipateR(Footfunction.abilityParticipateR);
    setFootPercent(Footfunction.footPercent);
    setFootPercentL(Footfunction.footPercentL);
    setHindfoot(Footfunction.Hindfoot);
    setMidfoot(Footfunction.Midfoot);
    setHallux(Footfunction.Hallux);
    setLesserToes(Footfunction.LesserToes);
    setMidfootb(Footfunction.Midfootb);
    setHalluxb(Footfunction.Halluxb);
    setLesserToesb(Footfunction.LesserToesb);
    setHindfootc(Footfunction.Hindfootc);
    setMidfootc(Footfunction.Midfootc);
    setSleepingR(Footfunction.sleepingR);
    setSleepingL(Footfunction.sleepingL);
    setRunSelectR(Footfunction.runSelectR);
    setRunSelectL(Footfunction.runSelectL);
    setjumpSelectR(Footfunction.jumpSelectR);
    setjumpSelectL(Footfunction.jumpSelectL);
    setlandingSelectR(Footfunction.landingSelectR);
    setlandingSelectL(Footfunction.landingSelectL);
    setstartingSelectR(Footfunction.startingSelectR);
    setstartingSelectL(Footfunction.startingSelectL);
    setcuttingSelectR(Footfunction.cuttingSelectR);
    setcuttingSelectL(Footfunction.cuttingSelectL);
    setimapactSelectR(Footfunction.impactSelectR);
    setimapactSelectL(Footfunction.impactSelectL);
    setperformSelectR(Footfunction.performSelectR);
    setperformSelectL(Footfunction.performSelectL);
    setparticipateSelectR(Footfunction.participateSelectR);
    setparticipateSelectL(Footfunction.participateSelectL);
    setHighestActR(Footfunction.highestActR);
    setHighestActL(Footfunction.highestActL);
    setTopLevelR(Footfunction.topLevelR === "0" ? [] : Footfunction.topLevelR);
    setTopLevelL(Footfunction.topLevelL === "0" ? [] : Footfunction.topLevelL);
    setLowLevelR(Footfunction.lowLevelR === "0" ? [] : Footfunction.lowLevelR);
    setLowLevelL(Footfunction.lowLevelL === "0" ? [] : Footfunction.lowLevelL);
    setRecreatR(Footfunction.recreatR === "0" ? [] : Footfunction.recreatR);
    setRecreatL(Footfunction.recreatL==="0" ? [] : Footfunction.recreatL);
    setIdoSpotR(Footfunction.idoSpotR);
    setIdoSpotL(Footfunction.idoSpotL);
    setNoSpotR(Footfunction.noSpotR);
    setNoSpotL(Footfunction.noSpotL);
  }, [Footfunction]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [Footfunction, updateTotal]);

  const navigate = useNavigate();
  const continueRedirect = (e) => {
    dispatch(
      updateFootFunctionData([
        {
          footActivityL: footActivityL,
          footActivityR: footActivityR,
          footwearL: footwearL,
          footwearR: footwearR,
          walkingL: walkingL,
          walkingR: walkingR,
          footStraightL: footStraightL,
          footStraightR: footStraightR,
          footBendingL: footBendingL,
          footBendingR: footBendingR,
          desStairL: desStairL,
          desStairR: desStairR,
          ascStairL: ascStairL,
          ascStairR: ascStairR,
          risingL: risingL,
          risingR: risingR,
          standingL: standingL,
          standingR: standingR,
          bendingFloorL: bendingFloorL,
          bendingFloorR: bendingFloorR,
          walkingFlatL: walkingFlatL,
          walkingFlatR: walkingFlatR,
          maxWalkingL: maxWalkingL,
          maxWalkingR: maxWalkingR,
          walkingWithoutShoesL: walkingWithoutShoesL,
          walkingWithoutShoesR: walkingWithoutShoesR,
          walkingHillsL: walkingHillsL,
          walkingHillsR: walkingHillsR,
          walkingDownHillsL: walkingDownHillsL,
          walkingDownHillsR: walkingDownHillsR,
          walkingUnevenL: walkingUnevenL,
          walkingUnevenR: walkingUnevenR,
          steppingUpL: steppingUpL,
          steppingUpR: steppingUpR,
          comingUpL: comingUpL,
          comingUpR: comingUpR,
          walkIntiallyL: walkIntiallyL,
          walkIntiallyR: walkIntiallyR,
          walkFiveL: walkFiveL,
          walkFiveR: walkFiveR,
          walkTenL: walkTenL,
          walkTenR: walkTenR,
          walkFifteenL: walkFifteenL,
          walkFifteenR: walkFifteenR,
          gettingCarL: gettingCarL,
          gettingCarR: gettingCarR,
          goingShoppingL: goingShoppingL,
          goingShoppingR: goingShoppingR,
          puttingSocksL: puttingSocksL,
          puttingSocksR: puttingSocksR,
          takingOffL: takingOffL,
          takingOffR: takingOffR,
          risingBedL: risingBedL,
          risingBedR: risingBedR,
          lyingL: lyingL,
          lyingR: lyingR,
          getBathL: getBathL,
          getBathR: getBathR,
          sittingL: sittingL,
          sittingR: sittingR,
          getToiletL: getToiletL,
          getToiletR: getToiletR,
          homeL: homeL,
          homeR: homeR,
          activitiesL: activitiesL,
          activitiesR: activitiesR,
          personalCareL: personalCareL,
          personalCareR: personalCareR,
          heavyWorkL: heavyWorkL,
          heavyWorkR: heavyWorkR,
          lightWorkL: lightWorkL,
          lightWorkR: lightWorkR,
          moderateWorkL: moderateWorkL,
          moderateWorkR: moderateWorkR,
          recreationalL: recreationalL,
          recreationalR: recreationalR,
          squattingL: squattingL,
          squattingR: squattingR,
          twistingR: twistingR,
          kneelingL: kneelingL,
          kneelingR: kneelingR,
          runningL: runningL,
          runningR: runningR,
          jumpingL: jumpingL,
          jumpingR: jumpingR,
          landingL: landingL,
          landingR: landingR,
          stoppingL: stoppingL,
          stoppingR: stoppingR,
          cuttingL: cuttingL,
          cuttingR: cuttingR,
          lowActL: lowActL,
          lowActR: lowActR,
          abilityPerformL: abilityPerformL,
          abilityPerformR: abilityPerformR,
          abilityParticipateL: abilityParticipateL,
          abilityParticipateR: abilityParticipateR,
          footPercent: footPercent,
          Hindfoot: Hindfoot,
          Midfoot: Midfoot,
          Hallux: Hallux,
          LesserToes: LesserToes,
          Midfootb: Midfootb,
          Halluxb: Halluxb,
          LesserToesb: LesserToesb,
          Hindfootc: Hindfootc,
          Midfootc: Midfootc,
          sleepingR: sleepingR,
          sleepingL: sleepingL,
          runSelectR: runSelectR,
          runSelectL: runSelectL,
          jumpSelectR: jumpSelectR,
          jumpSelectL: jumpSelectL,
          landingSelectR: landingSelectR,
          landingSelectL: landingSelectL,
          startingSelectR: startingSelectR,
          startingSelectL: startingSelectL,
          cuttingSelectR: cuttingSelectR,
          cuttingSelectL: cuttingSelectL,
          impactSelectR: impactSelectR,
          impactSelectL: impactSelectL,
          performSelectR: performSelectR,
          performSelectL: performSelectL,
          participateSelectR: participateSelectR,
          participateSelectL: participateSelectL,
          highestActR: highestActR,
          highestActL: highestActL,
          topLevelR: topLevelR,
          topLevelL: topLevelL,
          lowLevelR: lowLevelR,
          lowLevelL: lowLevelL,
          recreatR: recreatR,
          recreatL: recreatL,
          idoSpotR: idoSpotR,
          idoSpotL: idoSpotL,
          noSpotR: noSpotR,
          noSpotL: noSpotL,
        },
      ])
    );
    navigate("/foot-ankle-quality-of-life/" + rId);
  };
  const handleFootActivityL = (e) => {
    setFootActivityL(e);
    dispatch(
      updateFootFunctionScore({
        key: "footActivityL",
        value: e,
      })
    );
  };
  const handleFootActivityR = (e) => {
    setFootActivityR(e);
    dispatch(
      updateFootFunctionScore({
        key: "footActivityR",
        value: e,
      })
    );
  };
  const handleFootwearL = (e) => {
    setFootwearL(e);
    dispatch(
      updateFootFunctionScore({
        key: "footwearL",
        value: e,
      })
    );
  };
  const handleFootwearR = (e) => {
    setFootwearR(e);
    dispatch(
      updateFootFunctionScore({
        key: "footwearR",
        value: e,
      })
    );
  };
  const handleWalkingL = (e) => {
    setWalkingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingL",
        value: e,
      })
    );
  };
  const handleWalkingR = (e) => {
    setWalkingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingR",
        value: e,
      })
    );
  };
  const handleFootStraightL = (e) => {
    setFootStraightL(e);
    dispatch(
      updateFootFunctionScore({
        key: "footStraightL",
        value: e,
      })
    );
  };
  const handleFootStraightR = (e) => {
    setFootStraightR(e);
    dispatch(
      updateFootFunctionScore({
        key: "footStraightR",
        value: e,
      })
    );
  };
  const handleFootBendingL = (e) => {
    setFootBendingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "footBendingL",
        value: e,
      })
    );
  };
  const handleFootBendingR = (e) => {
    setFootBendingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "footBendingR",
        value: e,
      })
    );
  };
  const handleDesStairL = (e) => {
    setDesStairL(e);
    dispatch(
      updateFootFunctionScore({
        key: "desStairL",
        value: e,
      })
    );
  };
  const handleDesStairR = (e) => {
    setDesStairR(e);
    dispatch(
      updateFootFunctionScore({
        key: "desStairR",
        value: e,
      })
    );
  };
  const handleAscStairL = (e) => {
    setAscStairL(e);
    dispatch(
      updateFootFunctionScore({
        key: "ascStairL",
        value: e,
      })
    );
  };
  const handleAscStairR = (e) => {
    setAscStairR(e);
    dispatch(
      updateFootFunctionScore({
        key: "ascStairR",
        value: e,
      })
    );
  };
  const handleRisingL = (e) => {
    setRisingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "risingL",
        value: e,
      })
    );
  };
  const handleRisingR = (e) => {
    setRisingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "risingR",
        value: e,
      })
    );
  };
  const handleStandingL = (e) => {
    setStandingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "standingL",
        value: e,
      })
    );
  };
  const handleStandingR = (e) => {
    setStandingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "standingR",
        value: e,
      })
    );
  };
  const handleBendingFloorL = (e) => {
    setBendingFloorL(e);
    dispatch(
      updateFootFunctionScore({
        key: "bendingFloorL",
        value: e,
      })
    );
  };
  const handleBendingFloorR = (e) => {
    setBendingFloorR(e);
    dispatch(
      updateFootFunctionScore({
        key: "bendingFloorR",
        value: e,
      })
    );
  };
  const handleWalkingFlatL = (e) => {
    setWalkingFlatL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingFlatL",
        value: e,
      })
    );
  };
  const handleWalkingFlatR = (e) => {
    setWalkingFlatR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingFlatR",
        value: e,
      })
    );
  };
  const handleMaxWalkingL = (e) => {
    setMaxWalkingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "maxWalkingL",
        value: e,
      })
    );
  };
  const handleMaxWalkingR = (e) => {
    setMaxWalkingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "maxWalkingR",
        value: e,
      })
    );
  };
  const handleWalkingWithoutShoesL = (e) => {
    setWalkingWithoutShoesL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingWithoutShoesL",
        value: e,
      })
    );
  };
  const handleWalkingWithoutShoesR = (e) => {
    setWalkingWithoutShoesR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingWithoutShoesR",
        value: e,
      })
    );
  };
  const handleWalkingHillsL = (e) => {
    setWalkingHillsL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingHillsL",
        value: e,
      })
    );
  };
  const handleWalkingHillsR = (e) => {
    setWalkingHillsR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingHillsR",
        value: e,
      })
    );
  };
  const handleWalkingDownHillsL = (e) => {
    setWalkingDownHillsL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingDownHillsL",
        value: e,
      })
    );
  };
  const handleWalkingDownHillsR = (e) => {
    setWalkingDownHillsR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingDownHillsR",
        value: e,
      })
    );
  };
  const handleWalkingUnevenL = (e) => {
    setWalkingUnevenL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingUnevenL",
        value: e,
      })
    );
  };
  const handleWalkingUnevenR = (e) => {
    setWalkingUnevenR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkingUnevenR",
        value: e,
      })
    );
  };
  const handleSteppingUpL = (e) => {
    setSteppingUpL(e);
    dispatch(
      updateFootFunctionScore({
        key: "steppingUpL",
        value: e,
      })
    );
  };
  const handleSteppingUpR = (e) => {
    setSteppingUpR(e);
    dispatch(
      updateFootFunctionScore({
        key: "steppingUpR",
        value: e,
      })
    );
  };
  const handleComingUpL = (e) => {
    setComingUpL(e);
    dispatch(
      updateFootFunctionScore({
        key: "comingUpL",
        value: e,
      })
    );
  };
  const handleComingUpR = (e) => {
    setComingUpR(e);
    dispatch(
      updateFootFunctionScore({
        key: "comingUpR",
        value: e,
      })
    );
  };
  const handleWalkIntiallyL = (e) => {
    setWalkIntiallyL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkIntiallyL",
        value: e,
      })
    );
  };
  const handleWalkIntiallyR = (e) => {
    setWalkIntiallyR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkIntiallyR",
        value: e,
      })
    );
  };
  const handleWalkFiveL = (e) => {
    setWalkFiveL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkFiveL",
        value: e,
      })
    );
  };
  const handleWalkFiveR = (e) => {
    setWalkFiveR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkFiveR",
        value: e,
      })
    );
  };
  const handleWalkTenL = (e) => {
    setWalkTenL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkTenL",
        value: e,
      })
    );
  };
  const handleWalkTenR = (e) => {
    setWalkTenR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkTenR",
        value: e,
      })
    );
  };
  const handleWalkFifteenL = (e) => {
    setWalkFifteenL(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkFifteenL",
        value: e,
      })
    );
  };
  const handleWalkFifteenR = (e) => {
    setWalkFifteenR(e);
    dispatch(
      updateFootFunctionScore({
        key: "walkFifteenR",
        value: e,
      })
    );
  };
  const handleGettingCarL = (e) => {
    setGettingCarL(e);
    dispatch(
      updateFootFunctionScore({
        key: "gettingCarL",
        value: e,
      })
    );
  };
  const handleGettingCarR = (e) => {
    setGettingCarR(e);
    dispatch(
      updateFootFunctionScore({
        key: "gettingCarR",
        value: e,
      })
    );
  };
  const handleGoingShoppingL = (e) => {
    setGoingShoppingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "goingShoppingL",
        value: e,
      })
    );
  };
  const handleGoingShoppingR = (e) => {
    setGoingShoppingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "goingShoppingR",
        value: e,
      })
    );
  };
  const handlePuttingSocksL = (e) => {
    setPuttingSocksL(e);
    dispatch(
      updateFootFunctionScore({
        key: "puttingSocksL",
        value: e,
      })
    );
  };
  const handlePuttingSocksR = (e) => {
    setPuttingSocksR(e);
    dispatch(
      updateFootFunctionScore({
        key: "puttingSocksR",
        value: e,
      })
    );
  };
  const handletakingOffL = (e) => {
    setTakingOffL(e);
    dispatch(
      updateFootFunctionScore({
        key: "takingOffL",
        value: e,
      })
    );
  };
  const handletakingOffR = (e) => {
    setTakingOffR(e);
    dispatch(
      updateFootFunctionScore({
        key: "takingOffR",
        value: e,
      })
    );
  };
  const handleRisingBedL = (e) => {
    setRisingBedL(e);
    dispatch(
      updateFootFunctionScore({
        key: "risingBedL",
        value: e,
      })
    );
  };
  const handleRisingBedR = (e) => {
    setRisingBedR(e);
    dispatch(
      updateFootFunctionScore({
        key: "risingBedR",
        value: e,
      })
    );
  };
  const handleLyingL = (e) => {
    setLyingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "lyingL",
        value: e,
      })
    );
  };
  const handleLyingR = (e) => {
    setLyingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "lyingR",
        value: e,
      })
    );
  };

  const handleSleepingR = (e) => {
    setSleepingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "sleepingR",
        value: e,
      })
    );
  };

  const handleSleepingL = (e) => {
    setSleepingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "sleepingL",
        value: e,
      })
    );
  };
  const handleGetBathL = (e) => {
    setGetBathL(e);
    dispatch(
      updateFootFunctionScore({
        key: "getBathL",
        value: e,
      })
    );
  };
  const handleGetBathR = (e) => {
    setGetBathR(e);
    dispatch(
      updateFootFunctionScore({
        key: "getBathR",
        value: e,
      })
    );
  };
  const handleSittingL = (e) => {
    setSittingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "sittingL",
        value: e,
      })
    );
  };
  const handleSittingR = (e) => {
    setSittingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "sittingR",
        value: e,
      })
    );
  };
  const handleGetToiletL = (e) => {
    setGetToiletL(e);
    dispatch(
      updateFootFunctionScore({
        key: "getToiletL",
        value: e,
      })
    );
  };
  const handleGetToiletR = (e) => {
    setGetToiletR(e);
    dispatch(
      updateFootFunctionScore({
        key: "getToiletR",
        value: e,
      })
    );
  };
  const handleHomeL = (e) => {
    setHomeL(e);
    dispatch(
      updateFootFunctionScore({
        key: "homeL",
        value: e,
      })
    );
  };
  const handleHomeR = (e) => {
    setHomeR(e);
    dispatch(
      updateFootFunctionScore({
        key: "homeR",
        value: e,
      })
    );
  };
  const handleactivitiesL = (e) => {
    setActivitiesL(e);
    dispatch(
      updateFootFunctionScore({
        key: "activitiesL",
        value: e,
      })
    );
  };
  const handleactivitiesR = (e) => {
    setActivitiesR(e);
    dispatch(
      updateFootFunctionScore({
        key: "activitiesR",
        value: e,
      })
    );
  };
  const handlePersonalCareL = (e) => {
    setPersonalCareL(e);
    dispatch(
      updateFootFunctionScore({
        key: "personalCareL",
        value: e,
      })
    );
  };
  const handlePersonalCareR = (e) => {
    setPersonalCareR(e);
    dispatch(
      updateFootFunctionScore({
        key: "personalCareR",
        value: e,
      })
    );
  };
  const handleHeavyWorkL = (e) => {
    setHeavyWorkL(e);
    dispatch(
      updateFootFunctionScore({
        key: "heavyWorkL",
        value: e,
      })
    );
  };
  const handleHeavyWorkR = (e) => {
    setHeavyWorkR(e);
    dispatch(
      updateFootFunctionScore({
        key: "heavyWorkR",
        value: e,
      })
    );
  };
  const handlelightWorkL = (e) => {
    setLightWorkL(e);
    dispatch(
      updateFootFunctionScore({
        key: "lightWorkL",
        value: e,
      })
    );
  };
  const handlelightWorkR = (e) => {
    setLightWorkR(e);
    dispatch(
      updateFootFunctionScore({
        key: "lightWorkR",
        value: e,
      })
    );
  };
  const handleModerateWorkL = (e) => {
    setModerateWorkL(e);
    dispatch(
      updateFootFunctionScore({
        key: "moderateWorkL",
        value: e,
      })
    );
  };
  const handleModerateWorkR = (e) => {
    setModerateWorkR(e);
    dispatch(
      updateFootFunctionScore({
        key: "moderateWorkR",
        value: e,
      })
    );
  };
  const handleRecreationalL = (e) => {
    setRecreationalL(e);
    dispatch(
      updateFootFunctionScore({
        key: "recreationalL",
        value: e,
      })
    );
  };
  const handleRecreationalR = (e) => {
    setRecreationalR(e);
    dispatch(
      updateFootFunctionScore({
        key: "recreationalR",
        value: e,
      })
    );
  };
  const handleSquattingL = (e) => {
    setSquattingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "squattingL",
        value: e,
      })
    );
  };
  const handleSquattingR = (e) => {
    setSquattingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "squattingR",
        value: e,
      })
    );
  };
  const handleTwistingL = (e) => {
    setTwistingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "twistingL",
        value: e,
      })
    );
  };
  const handleTwistingR = (e) => {
    setTwistingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "twistingR",
        value: e,
      })
    );
  };
  const handleKneelingL = (e) => {
    setKneelingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "kneelingL",
        value: e,
      })
    );
  };
  const handleKneelingR = (e) => {
    setKneelingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "kneelingR",
        value: e,
      })
    );
  };
  const handleRunningL = (e) => {
    setRunnigL(e);
    dispatch(
      updateFootFunctionScore({
        key: "runningL",
        value: e,
      })
    );
  };
  const handleRunningR = (e) => {
    setRunnigR(e);
    dispatch(
      updateFootFunctionScore({
        key: "runningR",
        value: e,
      })
    );
  };
  const handleJumpingL = (e) => {
    setJumpingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "jumpingL",
        value: e,
      })
    );
  };
  const handleJumpingR = (e) => {
    setJumpingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "jumpingR",
        value: e,
      })
    );
  };
  const handleLandingL = (e) => {
    setLandnigL(e);
    dispatch(
      updateFootFunctionScore({
        key: "landingL",
        value: e,
      })
    );
  };
  const handleLandingR = (e) => {
    setLandnigR(e);
    dispatch(
      updateFootFunctionScore({
        key: "landingR",
        value: e,
      })
    );
  };
  const handleStoppingL = (e) => {
    setStoppingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "stoppingL",
        value: e,
      })
    );
  };
  const handleStoppingR = (e) => {
    setStoppingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "stoppingR",
        value: e,
      })
    );
  };
  const handleCuttingL = (e) => {
    setCuttingL(e);
    dispatch(
      updateFootFunctionScore({
        key: "cuttingL",
        value: e,
      })
    );
  };
  const handleCuttingR = (e) => {
    setCuttingR(e);
    dispatch(
      updateFootFunctionScore({
        key: "cuttingR",
        value: e,
      })
    );
  };
  const handleLowActL = (e) => {
    setLowActL(e);
    dispatch(
      updateFootFunctionScore({
        key: "lowActL",
        value: e,
      })
    );
  };
  const handleLowActR = (e) => {
    setLowActR(e);
    dispatch(
      updateFootFunctionScore({
        key: "lowActR",
        value: e,
      })
    );
  };
  const handleAbilityPerformL = (e) => {
    setAbilityPerformL(e);
    dispatch(
      updateFootFunctionScore({
        key: "abilityPerformL",
        value: e,
      })
    );
  };
  const handleAbilityPerformR = (e) => {
    setAbilityPerformR(e);
    dispatch(
      updateFootFunctionScore({
        key: "abilityPerformR",
        value: e,
      })
    );
  };
  const handleAbilityParticipateL = (e) => {
    setAbilityParticipateL(e);
    dispatch(
      updateFootFunctionScore({
        key: "abilityParticipateL",
        value: e,
      })
    );
  };
  const handleAbilityParticipateR = (e) => {
    setAbilityParticipateR(e);
    dispatch(
      updateFootFunctionScore({
        key: "abilityParticipateR",
        value: e,
      })
    );
  };
  const handleFootPercent = (e) => {
    setFootPercent(e);
    dispatch(
      updateFootFunctionScore({
        key: "footPercent",
        value: e,
      })
    );
  };
  const handleFootPercentL = (e) => {
    setFootPercentL(e);
    dispatch(
      updateFootFunctionScore({
        key: "footPercentL",
        value: e,
      })
    );
  };

  const handleSelectRunR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "runSelectR",
        value: !runSelectR,
      })
    );
    setRunSelectR(!runSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "runningR",
        value: "0",
      })
    );
  };

  const handleSelectRunL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "runSelectL",
        value: !runSelectL,
      })
    );
    setRunSelectL(!runSelectL);
  };

  useEffect(() => {
    if (runSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "runningL",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "runningR",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "runningL",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "runningR",
          value: "0",
        })
      );
    }
  }, [runSelectL]);

  const handleSelectJumpR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "jumpSelectR",
        value: !jumpSelectR,
      })
    );
    setjumpSelectR(!jumpSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "jumpingR",
        value: "0",
      })
    );
  };

  const handleSelectJumpL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "jumpSelectL",
        value: !jumpSelectL,
      })
    );
    setjumpSelectL(!jumpSelectL);
  };
  useEffect(() => {
    if (jumpSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "jumpingL",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "jumpingR",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "jumpingL",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "jumpingR",
          value: "0",
        })
      );
    }
  }, [jumpSelectL]);

  const handleSelectLandingR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "landingSelectR",
        value: !landingSelectR,
      })
    );
    setlandingSelectR(!landingSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "landingR",
        value: "0",
      })
    );
  };

  const handleSelectLandingL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "landingSelectL",
        value: !landingSelectL,
      })
    );
    setlandingSelectL(!landingSelectL);
  };

  useEffect(() => {
    if (landingSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "landingL",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "landingR",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "landingL",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "landingR",
          value: "0",
        })
      );
    }
  }, [landingSelectL]);

  const handleSelectStartingR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "startingSelectR",
        value: !startingSelectR,
      })
    );
    setstartingSelectR(!startingSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "stoppingR",
        value: "0",
      })
    );
  };

  const handleSelectStartingL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "startingSelectL",
        value: !startingSelectL,
      })
    );
    setstartingSelectL(!startingSelectL);
  };
  useEffect(() => {
    if (startingSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "stoppingL",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "stoppingR",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "stoppingL",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "stoppingR",
          value: "0",
        })
      );
    }
  }, [startingSelectL]);
  const handleSelectCuttingR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "cuttingSelectR",
        value: !cuttingSelectR,
      })
    );
    setcuttingSelectR(!cuttingSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "cuttingR",
        value: "0",
      })
    );
  };

  const handleSelectCuttingL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "cuttingSelectL",
        value: !cuttingSelectL,
      })
    );
    setcuttingSelectL(!cuttingSelectL);
  };
  useEffect(() => {
    if (cuttingSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "cuttingL",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "cuttingR",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "cuttingL",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "cuttingR",
          value: "0",
        })
      );
    }
  }, [cuttingSelectL]);

  const handleSelectImpactR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "impactSelectR",
        value: !impactSelectR,
      })
    );
    setimapactSelectR(!impactSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "lowActR",
        value: "0",
      })
    );
  };

  const handleSelectImpactL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "impactSelectL",
        value: !impactSelectL,
      })
    );
    setimapactSelectL(!impactSelectL);
  };
  useEffect(() => {
    if (impactSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "lowActR",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "lowActL",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "lowActR",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "lowActL",
          value: "0",
        })
      );
    }
  }, [impactSelectL]);
  const handleSelectPerformR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "performSelectR",
        value: !performSelectR,
      })
    );
    setperformSelectR(!performSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "abilityPerformR",
        value: "0",
      })
    );
  };

  const handleSelectPerformL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "performSelectL",
        value: !performSelectL,
      })
    );
    setperformSelectL(!performSelectL);
  };

  useEffect(() => {
    if (performSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "abilityPerformR",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "abilityPerformL",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "abilityPerformR",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "abilityPerformL",
          value: "0",
        })
      );
    }
  }, [performSelectL]);

  const handleSelectParticipateR = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "participateSelectR",
        value: !participateSelectR,
      })
    );
    setparticipateSelectR(!participateSelectR);
    dispatch(
      updateFootFunctionScore({
        key: "abilityParticipateR",
        value: "0",
      })
    );
  };

  const handleSelectParticipateL = (e) => {
    dispatch(
      updateFootFunctionScore({
        key: "participateSelectL",
        value: !participateSelectL,
      })
    );
    setparticipateSelectL(!participateSelectL);
  };
  useEffect(() => {
    if (participateSelectL) {
      dispatch(
        updateFootFunctionScore({
          key: "abilityParticipateR",
          value: "",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "abilityParticipateL",
          value: "",
        })
      );
    } else {
      dispatch(
        updateFootFunctionScore({
          key: "abilityParticipateR",
          value: "0",
        })
      );
      dispatch(
        updateFootFunctionScore({
          key: "abilityParticipateL",
          value: "0",
        })
      );
    }
  }, [participateSelectL]);
  const handleHighestActR = (e) => {
  
    setHighestActR(e);
    dispatch(
      updateFootFunctionScore({
        key: "highestActR",
        value: e,
      })
    );
    dispatch(
      updateFootFunctionScore({
        key: "topLevelR",
        value: "0",
      })
    );
    dispatch(
      updateFootFunctionScore({
        key: "lowLevelR",
        value: "0",
      })
    );
    dispatch(
      updateFootFunctionScore({
        key: "recreatR",
        value: "0",
      })
    );

  };

  const handleHighestActL = (e) => {
    setHighestActL(e);
    dispatch(
      updateFootFunctionScore({
        key: "highestActL",
        value: e,
      })
    );

    dispatch(
      updateFootFunctionScore({
        key: "topLevelL",
        value: "0",
      })
    );
    dispatch(
      updateFootFunctionScore({
        key: "lowLevelL",
        value: "0",
      })
    );
    dispatch(
      updateFootFunctionScore({
        key: "recreatL",
        value: "0",
      })
    );
  };

  const handletopLevelR = (e) => {
    setTopLevelR(e);
    dispatch(
      updateFootFunctionScore({
        key: "topLevelR",
        value: e,
      })
    );
  };

  const handletopLevelL = (e) => {
    setTopLevelL(e);
    dispatch(
      updateFootFunctionScore({
        key: "topLevelL",
        value: e,
      })
    );
  };

  const handlelowLevelR = (e) => {
    setLowLevelR(e);
    dispatch(
      updateFootFunctionScore({
        key: "lowLevelR",
        value: e,
      })
    );
  };

  const handlelowLevelL = (e) => {
    setLowLevelL(e);
    dispatch(
      updateFootFunctionScore({
        key: "lowLevelL",
        value: e,
      })
    );
  };

  const handlerecreatR = (e) => {
    setRecreatR(e);
    dispatch(
      updateFootFunctionScore({
        key: "recreatR",
        value: e,
      })
    );
  };

  const handlerecreatL = (e) => {
    setRecreatL(e);
    dispatch(
      updateFootFunctionScore({
        key: "recreatL",
        value: e,
      })
    );
  };

  const handleidoSpotR = (e) => {
    setIdoSpotR(e);
    dispatch(
      updateFootFunctionScore({
        key: "idoSpotR",
        value: e,
      })
    );
  };

  const handleidoSpotL = (e) => {
    setIdoSpotL(e);
    dispatch(
      updateFootFunctionScore({
        key: "idoSpotL",
        value: e,
      })
    );
  };

  const handlenoSpotR = (e) => {
    setNoSpotR(e);
    dispatch(
      updateFootFunctionScore({
        key: "noSpotR",
        value: e,
      })
    );
  };

  const handlenoSpotL = (e) => {
    setNoSpotL(e);
    dispatch(
      updateFootFunctionScore({
        key: "noSpotL",
        value: e,
      })
    );
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);




  const handleChange = useCallback(
    (inputValue) => {
      setValue(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setTopLevelR(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "topLevelR",
          value: newValue,
        })
      );
    },
    [setTopLevelR]
  );

  useEffect(() => {
    const selectedOptions = options.filter((option) =>
      topLevelR.includes(option.value)
    );
    setValue(selectedOptions);
  }, [options, topLevelR]);
  console.log("topLevelR", topLevelR)





  const handleChange1 = useCallback(
    (inputValue) => {
      setValue1(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setLowLevelR(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "lowLevelR",
          value: newValue,
        })
      );
    },
    [setLowLevelR]
  );

  console.log("lowLevelR", lowLevelR)

  useEffect(() => {
    const selectedOptions1 = options1.filter((option) =>
      lowLevelR.includes(option.value)
    );
    setValue1(selectedOptions1);
  }, [options1, lowLevelR]);


  const handleChange2 = useCallback(
    (inputValue) => {
      setValue2(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setRecreatR(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "recreatR",
          value: newValue,
        })
      );
    },
    [setRecreatR]
  );

  useEffect(() => {
    const selectedOptions2 = options2.filter((option) =>
      recreatR.includes(option.value)
    );
    setValue2(selectedOptions2);
  }, [options2, recreatR]);


  console.log("recreatR", recreatR)





  const handleChange3 = useCallback(
    (inputValue) => {
      setValue3(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setTopLevelL(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "topLevelL",
          value: newValue,
        })
      );
    },
    [setTopLevelL]
  );

  useEffect(() => {
    const selectedOptions3 = options3.filter((option) =>
      topLevelL.includes(option.value)
    );
    setValue3(selectedOptions3);
  }, [options3, topLevelL]);


  console.log("topLevelL", topLevelL)













  const handleChange4 = useCallback(
    (inputValue) => {
      setValue4(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setLowLevelL(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "lowLevelL",
          value: newValue,
        })
      );
    },
    [setLowLevelL]
  );

  useEffect(() => {
    const selectedOptions4 = options4.filter((option) =>
      lowLevelL.includes(option.value)
    );
    setValue4(selectedOptions4);
  }, [options4, lowLevelL]);


  console.log("lowLevelL", lowLevelL)












  const handleChange5 = useCallback(
    (inputValue) => {
      setValue5(inputValue);
      const newValue = inputValue.map((option) => option.value);
      setRecreatL(newValue);
      dispatch(
        updateFootFunctionScore({
          key: "recreatL",
          value: newValue,
        })
      );
    },
    [setRecreatL]
  );

  useEffect(() => {
    const selectedOptions5 = options5.filter((option) =>
    recreatL.includes(option.value)
    );
    setValue5(selectedOptions5);
  }, [options5, recreatL]);


  console.log("recreatL", recreatL)






  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_foot_ankle_function}
              </h4>

              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q1_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q1_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q1_a3,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q1_a4,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q1 +
                              RigthTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={footActivityR}
                            onChange={handleFootActivityR}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q1_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q1_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q1_a3,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q1_a4,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q1 +
                              LeftTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={footActivityL}
                            onChange={handleFootActivityL}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q2_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q2_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q2_a3,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q2 +
                              RigthTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={footwearR}
                            onChange={handleFootwearR}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q2_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q2_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q2_a3,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q2 +
                              LeftTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={footwearL}
                            onChange={handleFootwearL}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q3_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q3_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q3_a3,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q3 +
                              RigthTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={walkingR}
                            onChange={handleWalkingR}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q3_a1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q3_a2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q3_a3,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q3 +
                              LeftTrans(anklePain, translation)
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={walkingL}
                            onChange={handleWalkingL}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="function_formtop">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 function_head">
                        <label>
                          {translation.patient_foot_ankle_function_instruction}
                        </label>{" "}
                        <br />
                        <span dir={lg === "ara" ? "rtl" : " "}>
                          (<strong>0</strong> ={" "}
                          {
                            translation.patient_foot_ankle_function_instruction_0
                          }
                          , <strong>1</strong> ={" "}
                          {
                            translation.patient_foot_ankle_function_instruction_1
                          }
                          , <strong>2</strong> ={" "}
                          {
                            translation.patient_foot_ankle_function_instruction_2
                          }
                          , <strong>3</strong> ={" "}
                          {
                            translation.patient_foot_ankle_function_instruction_3
                          }
                          , <strong>4</strong> ={" "}
                          {
                            translation.patient_foot_ankle_function_instruction_4
                          }
                          )
                        </span>
                      </div>
                      <div className="col-md-6 col-sm-6 function_note">
                        <p>{translation.patient_foot_ankle_function_note}</p>
                      </div>
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q4 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={footStraightR}
                            onChange={handleFootStraightR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q4 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={footStraightL}
                            onChange={handleFootStraightL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q5 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={footBendingR}
                            onChange={handleFootBendingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q5_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q5_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q5_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q5_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q5 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={footBendingL}
                            onChange={handleFootBendingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q5_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q5_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q5_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q5_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q6 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={desStairR}
                            onChange={handleDesStairR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q6_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q6_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q6_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q6_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q6 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={desStairL}
                            onChange={handleDesStairL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q6_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q6_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q6_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q6_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q7 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={ascStairR}
                            onChange={handleAscStairR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q7_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q7_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q7_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q7_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q7 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={ascStairL}
                            onChange={handleAscStairL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q7_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q7_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q7_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q7_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q8 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={risingR}
                            onChange={handleRisingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q8_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q8_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q8_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q8_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q8 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={risingL}
                            onChange={handleRisingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q8_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q8_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q8_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q8_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q9 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={standingR}
                            onChange={handleStandingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q9_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q9_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q9_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q9_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q9 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={standingL}
                            onChange={handleStandingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q9_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q9_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q9_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q9_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q10 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={bendingFloorR}
                            onChange={handleBendingFloorR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q10 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={bendingFloorL}
                            onChange={handleBendingFloorL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q11 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingFlatR}
                            onChange={handleWalkingFlatR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q11 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingFlatL}
                            onChange={handleWalkingFlatL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <div className="mb_20">
                            <KneeScoreRangeSlider
                              label={
                                translation.patient_foot_ankle_function_q12 +
                                RigthTrans(anklePain, translation)
                              }
                              styleClass=""
                              maxnum="3"
                              placeholder={translation.none}
                              value={maxWalkingR}
                              onChange={handleMaxWalkingR}
                              sliderData={[
                                {
                                  score: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a1,
                                },
                                {
                                  score: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a2,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a3,
                                },
                                {
                                  score: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a4,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <div className="mb_20">
                            <KneeScoreRangeSlider
                              label={
                                translation.patient_foot_ankle_function_q12 +
                                LeftTrans(anklePain, translation)
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={maxWalkingL}
                              maxnum="3"
                              onChange={handleMaxWalkingL}
                              sliderData={[
                                {
                                  score: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a1,
                                },
                                {
                                  score: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a2,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a3,
                                },
                                {
                                  score: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q12_a4,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q13 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingWithoutShoesR}
                            onChange={handleWalkingWithoutShoesR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q13 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingWithoutShoesL}
                            onChange={handleWalkingWithoutShoesL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q14 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingHillsR}
                            onChange={handleWalkingHillsR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q14 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingHillsL}
                            onChange={handleWalkingHillsL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q15 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingDownHillsR}
                            onChange={handleWalkingDownHillsR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q15 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingDownHillsL}
                            onChange={handleWalkingDownHillsL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q16 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingUnevenR}
                            onChange={handleWalkingUnevenR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q16_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q16_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q16_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q16_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q16 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingUnevenL}
                            onChange={handleWalkingUnevenL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q16_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q16_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q16_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q16_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q17 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={steppingUpR}
                            onChange={handleSteppingUpR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q17_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q17_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q17_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q17_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q17 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={steppingUpL}
                            onChange={handleSteppingUpL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q17_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q17_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q17_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q17_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q18 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={comingUpR}
                            onChange={handleComingUpR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q18_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q18_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q18_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q18_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q18 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={comingUpL}
                            onChange={handleComingUpL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q18_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q18_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q18_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q18_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q19 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkIntiallyR}
                            onChange={handleWalkIntiallyR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q19_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q19_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q19_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q19_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q19_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q19 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkIntiallyL}
                            onChange={handleWalkIntiallyL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q19_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q19_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q19_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q19_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q19_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q20 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkFiveR}
                            onChange={handleWalkFiveR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q20_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q20_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q20_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q20_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q20_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q20 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkFiveL}
                            onChange={handleWalkFiveL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q20_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q20_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q20_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q20_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q20_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q21 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkTenR}
                            onChange={handleWalkTenR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q21_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q21_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q21_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q21_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q21_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q21 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkTenL}
                            onChange={handleWalkTenL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q21_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q21_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q21_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q21_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q21_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q22 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkFifteenR}
                            onChange={handleWalkFifteenR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q22_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q22_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q22_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q22_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q22_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q22 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkFifteenL}
                            onChange={handleWalkFifteenL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q22_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q22_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q22_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q22_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q22_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q23 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={gettingCarR}
                            onChange={handleGettingCarR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q23_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q23_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q23_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q23_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q23_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q23 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={gettingCarL}
                            onChange={handleGettingCarL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q23_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q23_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q23_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q23_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q23_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q24 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={goingShoppingR}
                            onChange={handleGoingShoppingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q24_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q24_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q24_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q24_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q24_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q24 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={goingShoppingL}
                            onChange={handleGoingShoppingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q24_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q24_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q24_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q24_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q24_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q25 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={puttingSocksR}
                            onChange={handlePuttingSocksR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q25_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q25_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q25_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q25_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q25_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q25 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={puttingSocksL}
                            onChange={handlePuttingSocksL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q25_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q25_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q25_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q25_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q25_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q26 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={takingOffR}
                            onChange={handletakingOffR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q26_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q26_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q26_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q26_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q26_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q26 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={takingOffL}
                            onChange={handletakingOffL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q26_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q26_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q26_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q26_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q26_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q27 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={risingBedR}
                            onChange={handleRisingBedR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q27_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q27_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q27_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q27_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q27_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q27 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={risingBedL}
                            onChange={handleRisingBedL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q27_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q27_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q27_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q27_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q27_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q28 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={lyingR}
                            onChange={handleLyingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q28_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q28_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q28_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q28_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q28_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q28 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={lyingL}
                            onChange={handleLyingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q28_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q28_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q28_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q28_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q28_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q29 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={sleepingR}
                            onChange={handleSleepingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q29_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q29_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q29_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q29_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q29_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q29 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={sleepingL}
                            onChange={handleSleepingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q29_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q29_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q29_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q29_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q29_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q30 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={getBathR}
                            onChange={handleGetBathR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q30_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q30_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q30_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q30_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q30_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q30 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={getBathL}
                            onChange={handleGetBathL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q30_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q30_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q30_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q30_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q30_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q31 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={sittingR}
                            onChange={handleSittingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q31_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q31_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q31_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q31_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q31_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q31 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={sittingL}
                            onChange={handleSittingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q31_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q31_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q31_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q31_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q31_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q32 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={getToiletR}
                            onChange={handleGetToiletR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q32_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q32_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q32_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q32_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q32_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q32 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={getToiletL}
                            onChange={handleGetToiletL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q32_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q32_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q32_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q32_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q32_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q33 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={homeR}
                            onChange={handleHomeR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q33_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q33_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q33_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q33_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q33_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q33 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={homeL}
                            onChange={handleHomeL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q33_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q33_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q33_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q33_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q33_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q34 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={activitiesR}
                            onChange={handleactivitiesR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q34_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q34_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q34_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q34_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q34_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q34 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={activitiesL}
                            onChange={handleactivitiesL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q34_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q34_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q34_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q34_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q34_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q35 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={personalCareR}
                            onChange={handlePersonalCareR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q35_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q35_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q35_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q35_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q35_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q35 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={personalCareL}
                            onChange={handlePersonalCareL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q35_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q35_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q35_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q35_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q35_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q36 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={heavyWorkR}
                            onChange={handleHeavyWorkR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q36_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q36_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q36_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q36_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q36_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q36 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={heavyWorkL}
                            onChange={handleHeavyWorkL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q36_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q36_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q36_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q36_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q36_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q37 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={lightWorkR}
                            onChange={handlelightWorkR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q37_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q37_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q37_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q37_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q37_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q37 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={lightWorkL}
                            onChange={handlelightWorkL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q37_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q37_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q37_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q37_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q37_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q38 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={moderateWorkR}
                            onChange={handleModerateWorkR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q38_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q38_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q38_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q38_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q38_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q38 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={moderateWorkL}
                            onChange={handleModerateWorkL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q38_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q38_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q38_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q38_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q38_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q39 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={recreationalR}
                            onChange={handleRecreationalR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q39_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q39_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q39_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q39_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q39_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q39 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={recreationalL}
                            onChange={handleRecreationalL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q39_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q39_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q39_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q39_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q39_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q40 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={squattingR}
                            onChange={handleSquattingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q40_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q40_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q40_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q40_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q40_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q40 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={squattingL}
                            onChange={handleSquattingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q40_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q40_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q40_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q40_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q40_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q41 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={twistingR}
                            onChange={handleTwistingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q41_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q41_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q41_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q41_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q41_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q41 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={twistingL}
                            onChange={handleTwistingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q41_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q41_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q41_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q41_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q41_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q42 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={kneelingR}
                            onChange={handleKneelingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q42_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q42_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q42_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q42_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q42_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q42 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={kneelingL}
                            onChange={handleKneelingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q42_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q42_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q42_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q42_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q42_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row position-relative">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="running_L"
                                  value={runSelectL}
                                  checked={runSelectL}
                                  onChange={handleSelectRunL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q43_not_applicable
                                }
                              </label>
                            </div>
                          )}
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q43 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={runningR}
                            onChange={handleRunningR}
                            view={runSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q43_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q43_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q43_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q43_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q43_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="running_L"
                                value={runSelectL}
                                checked={runSelectL}
                                onChange={handleSelectRunL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q43_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q43 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={runningL}
                            onChange={handleRunningL}
                            view={runSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q43_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q43_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q43_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q43_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q43_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row position-relative">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="jumping_L"
                                  value={jumpSelectL}
                                  checked={jumpSelectL}
                                  onChange={handleSelectJumpL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q44_not_applicable
                                }
                              </label>
                            </div>
                          )}

                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q44 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={jumpingR}
                            onChange={handleJumpingR}
                            view={jumpSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q44_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q44_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q44_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q44_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q44_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="jumping_L"
                                value={jumpSelectL}
                                checked={jumpSelectL}
                                onChange={handleSelectJumpL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q44_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q44 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={jumpingL}
                            onChange={handleJumpingL}
                            view={jumpSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q44_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q44_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q44_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q44_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q44_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="landing_R"
                                  value={landingSelectL}
                                  checked={landingSelectL}
                                  onChange={handleSelectLandingL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q45_not_applicable
                                }
                              </label>
                            </div>
                          )}

                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q45 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={landingR}
                            onChange={handleLandingR}
                            view={landingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q45_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q45_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q45_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q45_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q45_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="landing_R"
                                value={landingSelectL}
                                checked={landingSelectL}
                                onChange={handleSelectLandingL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q45_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q45 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={landingL}
                            onChange={handleLandingL}
                            view={landingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q45_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q45_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q45_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q45_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q45_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="starting_r"
                                  value={startingSelectL}
                                  checked={startingSelectL}
                                  onChange={handleSelectStartingL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q46_not_applicable
                                }
                              </label>
                            </div>
                          )}
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q46 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={stoppingR}
                            onChange={handleStoppingR}
                            view={startingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q46_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q46_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q46_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q46_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q46_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="starting_l"
                                value={startingSelectL}
                                checked={startingSelectL}
                                onChange={handleSelectStartingL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q46_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q46 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={stoppingL}
                            onChange={handleStoppingL}
                            view={startingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q46_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q46_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q46_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q46_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q46_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="starting_l"
                                  value={cuttingSelectL}
                                  checked={cuttingSelectL}
                                  onChange={handleSelectCuttingL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q47_not_applicable
                                }
                              </label>
                            </div>
                          )}
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q47 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={cuttingR}
                            onChange={handleCuttingR}
                            view={cuttingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q47_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q47_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q47_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q47_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q47_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="starting_l"
                                value={cuttingSelectL}
                                checked={cuttingSelectL}
                                onChange={handleSelectCuttingL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q47_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q47 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={cuttingL}
                            onChange={handleCuttingL}
                            view={cuttingSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q47_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q47_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q47_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q47_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q47_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="impact_r"
                                  value={impactSelectL}
                                  checked={impactSelectL}
                                  onChange={handleSelectImpactL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q48_not_applicable
                                }
                              </label>
                            </div>
                          )}

                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q48 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={lowActR}
                            onChange={handleLowActR}
                            view={impactSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q48_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q48_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q48_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q48_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q48_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="impact_l"
                                value={impactSelectL}
                                checked={impactSelectL}
                                onChange={handleSelectImpactL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q48_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q48 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={lowActL}
                            onChange={handleLowActL}
                            view={impactSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q48_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q48_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q48_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q48_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q48_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="perform_r"
                                  value={performSelectL}
                                  checked={performSelectL}
                                  onChange={handleSelectPerformL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q49_not_applicable
                                }
                              </label>
                            </div>
                          )}

                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q49 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={abilityPerformR}
                            onChange={handleAbilityPerformR}
                            view={performSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q49_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q49_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q49_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q49_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q49_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="perform_r"
                                value={performSelectL}
                                checked={performSelectL}
                                onChange={handleSelectPerformL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q49_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q49 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={abilityPerformL}
                            onChange={handleAbilityPerformL}
                            view={performSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q49_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q49_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q49_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q49_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q49_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          {anklePain === "1" && (
                            <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                              <label>
                                <input
                                  type="checkbox"
                                  name="participate_r"
                                  value={participateSelectL}
                                  checked={participateSelectL}
                                  onChange={handleSelectParticipateL}
                                />{" "}
                                {
                                  translation.patient_foot_ankle_function_q50_not_applicable
                                }
                              </label>
                            </div>
                          )}

                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q50 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={abilityParticipateR}
                            onChange={handleAbilityParticipateR}
                            view={participateSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q50_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q50_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q50_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q50_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q50_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <div className="col-md-6 col-sm-6 mb_10 not-applicable ">
                            <label>
                              <input
                                type="checkbox"
                                name="participate_l"
                                value={participateSelectL}
                                checked={participateSelectL}
                                onChange={handleSelectParticipateL}
                              />{" "}
                              {
                                translation.patient_foot_ankle_function_q50_not_applicable
                              }
                            </label>
                          </div>
                          <HipScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_function_q50 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={abilityParticipateL}
                            onChange={handleAbilityParticipateL}
                            view={participateSelectL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_function_q50_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_function_q50_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_function_q50_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_function_q50_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_function_q50_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                  </div>

                  <div className="row">
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="row">
                        <div className="mb_20">
                          <RangeSlider
                            id="normal_percent"
                            min="0"
                            max="100"
                            step="1"
                            type="text"
                            colorcode="1"
                            label={
                              translation.patient_foot_ankle_function_q51 +
                              LeftTrans(anklePain, translation)
                            }
                            rightTitle={
                              translation.patient_foot_ankle_function_q51_a1
                            }
                            value={footPercentL}
                            onChange={handleFootPercentL}
                            formGroupClass="label_style mb_40"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="row">
                        <div className="mb_20">
                          <RangeSlider
                            id="normal_percent"
                            min="0"
                            max="100"
                            step="1"
                            type="text"
                            colorcode="1"
                            label={
                              translation.patient_foot_ankle_function_q51 +
                              RigthTrans(anklePain, translation)
                            }
                            rightTitle={
                              translation.patient_foot_ankle_function_q51_a1
                            }
                            value={footPercent}
                            onChange={handleFootPercent}
                            formGroupClass="label_style mb_40"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    <h4>{translation.patient_foot_ankle_function_q52}</h4>
                    {initialAssesment === 1 || String(initialAssesment )=== "undefined" ? (
                      <div className="col-md-6 col-sm-6 mb_10">
                        <ElbowFeedbackScoreRangeSlider
                          label={translation.patient_foot_ankle_function_q52_q1}
                          styleClass=""
                          value={highestActR}
                          placeholder={
                            translation.patient_foot_ankle_function_q52_q1
                          }
                          onChange={handleHighestActR}
                          minnum="1"
                          maxnum="4"
                          sliderData={[
                            {
                              score: "1",
                              label:
                                translation.patient_foot_ankle_function_q52_q1_a1,
                            },
                            {
                              score: "2",
                              label:
                                translation.patient_foot_ankle_function_q52_q1_a2,
                            },
                            {
                              score: "3",
                              label:
                                translation.patient_foot_ankle_function_q52_q1_a3,
                            },
                            {
                              score: "4",
                              label:
                                translation.patient_foot_ankle_function_q52_q1_a4,
                            },
                            // {
                            //   score: "5",
                            //   label: translation.patient_foot_ankle_function_q52_q1_a5,
                            // },
                            // {
                            //   score: "6",
                            //   label:
                            //     translation.patient_foot_ankle_function_q52_q1_a6,
                            // },
                          ]}
                        />
                      </div>
                    ):""}
                    <div className="col-md-6 col-sm-6 mb_10">
                      <ElbowFeedbackScoreRangeSlider
                        label={translation.patient_foot_ankle_function_q52_q2}
                        styleClass=""
                        value={highestActL}
                        placeholder={
                          translation.patient_foot_ankle_function_q52_q2
                        }
                        onChange={handleHighestActL}
                        minnum="1"
                        maxnum="4"
                        sliderData={[
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_function_q52_q2_a1,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_function_q52_q2_a2,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_function_q52_q2_a3,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_function_q52_q2_a4,
                          },
                          // {
                          //   score: "5",
                          //   label: translation.patient_foot_ankle_function_q52_q2_a5,
                          // },
                          // {
                          //   score: "6",
                          //   label:
                          //     translation.patient_foot_ankle_function_q52_q2_a6,
                          // },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    {initialAssesment === 1 && (
                      <div className="col-md-6 col-sm-6 mb_10">
                        {highestActR === "1" ? (
                          <div className="mb_20">

                            {/* <FullDropdown
                              data={[
                                {
                                  value: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                                },
                                {
                                  value: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                                },
                                {
                                  value: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                                },
                                {
                                  value: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                                },
                                {
                                  value: "4",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                                },
                                {
                                  value: "5",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                                },
                                {
                                  value: "6",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                                },
                                {
                                  value: "7",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                                },
                                {
                                  value: "8",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                                },
                                {
                                  value: "9",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                                },
                                {
                                  value: "10",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                                },
                                {
                                  value: "11",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                                },
                                {
                                  value: "12",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                                },
                                {
                                  value: "13",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                                },
                                {
                                  value: "14",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                                },
                                {
                                  value: "15",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                                },
                                {
                                  value: "16",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                                },
                                {
                                  value: "17",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                                },
                                {
                                  value: "18",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                                },
                                {
                                  value: "19",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                                },
                                {
                                  value: "20",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                                },
                                {
                                  value: "21",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                                },
                                {
                                  value: "22",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                                },
                                {
                                  value: "23",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                                },
                                {
                                  value: "24",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                                },
                                {
                                  value: "25",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                                },
                                {
                                  value: "26",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                                },
                                {
                                  value: "27",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                                },
                                {
                                  value: "28",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                                },
                                {
                                  value: "29",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                                },
                                {
                                  value: "30",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                                },
                                {
                                  value: "31",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                                },
                                {
                                  value: "32",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                                },
                                {
                                  value: "33",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                                },
                                {
                                  value: "34",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                                },
                                {
                                  value: "35",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                                },
                                {
                                  value: "36",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                                },
                                {
                                  value: "37",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                                },
                                {
                                  value: "38",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                                },
                                {
                                  value: "39",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                                },
                                {
                                  value: "40",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                                },
                                {
                                  value: "41",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                                },
                                {
                                  value: "42",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                                },
                                {
                                  value: "43",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                                },
                                {
                                  value: "44",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                                },
                                {
                                  value: "45",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                                },
                                {
                                  value: "46",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                                },
                                {
                                  value: "47",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                                },
                                {
                                  value: "48",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                                },
                                {
                                  value: "49",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                                },
                                {
                                  value: "50",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                                },
                                {
                                  value: "51",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                                },
                              ]}
                              label={
                                translation.patient_foot_ankle_function_q52_q1_a1_title
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={topLevelR}
                              onChange={handletopLevelR}
                            /> */}
                            <label>{translation.patient_foot_ankle_function_q52_q1_a1_title}</label>
                            <CreatableSelect
                              isClearable
                              isMulti
                              value={value}
                              options={options}
                              onChange={handleChange}
                              className="multi_select_drp_footFun"
                              placeholder={<div>{translation.Select}</div>}
                            // classNamePrefix="form-select"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {highestActR === "2" ? (
                          <div className="mb_20">
                            {/* <FullDropdown
                              data={[
                                {
                                  value: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                                },
                                {
                                  value: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                                },
                                {
                                  value: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                                },
                                {
                                  value: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                                },
                                {
                                  value: "4",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                                },
                                {
                                  value: "5",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                                },
                                {
                                  value: "6",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                                },
                                {
                                  value: "7",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                                },
                                {
                                  value: "8",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                                },
                                {
                                  value: "9",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                                },
                                {
                                  value: "10",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                                },
                                {
                                  value: "11",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                                },
                                {
                                  value: "12",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                                },
                                {
                                  value: "13",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                                },
                                {
                                  value: "14",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                                },
                                {
                                  value: "15",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                                },
                                {
                                  value: "16",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                                },
                                {
                                  value: "17",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                                },
                                {
                                  value: "18",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                                },
                                {
                                  value: "19",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                                },
                                {
                                  value: "20",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                                },
                                {
                                  value: "21",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                                },
                                {
                                  value: "22",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                                },
                                {
                                  value: "23",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                                },
                                {
                                  value: "24",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                                },
                                {
                                  value: "25",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                                },
                                {
                                  value: "26",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                                },
                                {
                                  value: "27",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                                },
                                {
                                  value: "28",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                                },
                                {
                                  value: "29",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                                },
                                {
                                  value: "30",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                                },
                                {
                                  value: "31",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                                },
                                {
                                  value: "32",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                                },
                                {
                                  value: "33",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                                },
                                {
                                  value: "34",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                                },
                                {
                                  value: "35",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                                },
                                {
                                  value: "36",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                                },
                                {
                                  value: "37",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                                },
                                {
                                  value: "38",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                                },
                                {
                                  value: "39",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                                },
                                {
                                  value: "40",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                                },
                                {
                                  value: "41",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                                },
                                {
                                  value: "42",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                                },
                                {
                                  value: "43",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                                },
                                {
                                  value: "44",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                                },
                                {
                                  value: "45",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                                },
                                {
                                  value: "46",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                                },
                                {
                                  value: "47",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                                },
                                {
                                  value: "48",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                                },
                                {
                                  value: "49",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                                },
                                {
                                  value: "50",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                                },
                                {
                                  value: "51",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                                },
                              ]}
                              label={
                                translation.patient_foot_ankle_function_q52_q1_a2_title
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={lowLevelR}
                              onChange={handlelowLevelR}
                            /> */}
                            <label>{translation.patient_foot_ankle_function_q52_q1_a2_title}</label>
                            <CreatableSelect
                              isClearable
                              isMulti
                              value={value1}
                              options={options1}
                              onChange={handleChange1}
                              className="multi_select_drp_footFun"
                              placeholder={<div>{translation.Select}</div>}
                            // classNamePrefix="form-select"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {highestActR === "3" ? (

                          <div className="mb_20">
                            {/* <FullDropdown
                              data={[
                                {
                                  value: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                                },
                                {
                                  value: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                                },
                                {
                                  value: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                                },
                                {
                                  value: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                                },
                                {
                                  value: "4",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                                },
                                {
                                  value: "5",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                                },
                                {
                                  value: "6",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                                },
                                {
                                  value: "7",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                                },
                                {
                                  value: "8",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                                },
                                {
                                  value: "9",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                                },
                                {
                                  value: "10",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                                },
                                {
                                  value: "11",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                                },
                                {
                                  value: "12",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                                },
                                {
                                  value: "13",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                                },
                                {
                                  value: "14",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                                },
                                {
                                  value: "15",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                                },
                                {
                                  value: "16",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                                },
                                {
                                  value: "17",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                                },
                                {
                                  value: "18",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                                },
                                {
                                  value: "19",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                                },
                                {
                                  value: "20",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                                },
                                {
                                  value: "21",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                                },
                                {
                                  value: "22",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                                },
                                {
                                  value: "23",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                                },
                                {
                                  value: "24",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                                },
                                {
                                  value: "25",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                                },
                                {
                                  value: "26",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                                },
                                {
                                  value: "27",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                                },
                                {
                                  value: "28",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                                },
                                {
                                  value: "29",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                                },
                                {
                                  value: "30",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                                },
                                {
                                  value: "31",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                                },
                                {
                                  value: "32",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                                },
                                {
                                  value: "33",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                                },
                                {
                                  value: "34",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                                },
                                {
                                  value: "35",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                                },
                                {
                                  value: "36",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                                },
                                {
                                  value: "37",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                                },
                                {
                                  value: "38",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                                },
                                {
                                  value: "39",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                                },
                                {
                                  value: "40",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                                },
                                {
                                  value: "41",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                                },
                                {
                                  value: "42",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                                },
                                {
                                  value: "43",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                                },
                                {
                                  value: "44",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                                },
                                {
                                  value: "45",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                                },
                                {
                                  value: "46",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                                },
                                {
                                  value: "47",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                                },
                                {
                                  value: "48",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                                },
                                {
                                  value: "49",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                                },
                                {
                                  value: "50",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                                },
                                {
                                  value: "51",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                                },
                              ]}
                              label={
                                translation.patient_foot_ankle_function_q52_q1_a3_title
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={recreatR}
                              onChange={handlerecreatR}
                            /> */}
                            <label>{translation.patient_foot_ankle_function_q52_q1_a3_title}</label>
                            <CreatableSelect
                              isClearable
                              isMulti
                              value={value2}
                              options={options2}
                              onChange={handleChange2}
                              className="multi_select_drp_footFun"
                              placeholder={<div>{translation.Select}</div>}
                            // classNamePrefix="form-select"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {highestActR === "4" ? (
                          <div className="mb_20">
                            <FullDropdown
                              data={[
                                {
                                  value: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o1,
                                },
                                {
                                  value: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o2,
                                },
                                {
                                  value: "3",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o3,
                                },
                                {
                                  value: "4",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o4,
                                },
                                {
                                  value: "5",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o5,
                                },
                                {
                                  value: "6",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q2_a4_o6,
                                },
                                {
                                  value: "7",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a4_o7,
                                },
                              ]}
                              label={
                                translation.patient_foot_ankle_function_q52_q1_a4
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={idoSpotR}
                              onChange={handleidoSpotR}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {highestActR === "5" ? (
                          <div className="mb_20">
                            <FullDropdown
                              data={[
                                {
                                  value: "2",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a5_o1,
                                },
                                {
                                  value: "1",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a5_o2,
                                },
                                {
                                  value: "0",
                                  label:
                                    translation.patient_foot_ankle_function_q52_q1_a5_o3,
                                },
                              ]}
                              label={
                                translation.patient_foot_ankle_function_q52_q1_a5
                              }
                              styleClass=""
                              placeholder={translation.none}
                              value={noSpotR}
                              onChange={handlenoSpotR}
                            />
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    )}

                    <div className="col-md-6 col-sm-6 mb_10">
                      {highestActL === "1" ? (
                        <div className="mb_20">
                          {/* <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                              },
                              {
                                value: "4",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                              },
                              {
                                value: "5",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                              },
                              {
                                value: "6",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                              },
                              {
                                value: "7",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                              },
                              {
                                value: "8",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                              },
                              {
                                value: "9",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                              },
                              {
                                value: "10",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                              },
                              {
                                value: "11",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                              },
                              {
                                value: "12",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                              },
                              {
                                value: "13",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                              },
                              {
                                value: "14",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                              },
                              {
                                value: "15",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                              },
                              {
                                value: "16",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                              },
                              {
                                value: "17",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                              },
                              {
                                value: "18",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                              },
                              {
                                value: "19",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                              },
                              {
                                value: "20",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                              },
                              {
                                value: "21",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                              },
                              {
                                value: "22",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                              },
                              {
                                value: "23",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                              },
                              {
                                value: "24",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                              },
                              {
                                value: "25",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                              },
                              {
                                value: "26",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                              },
                              {
                                value: "27",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                              },
                              {
                                value: "28",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                              },
                              {
                                value: "29",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                              },
                              {
                                value: "30",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                              },
                              {
                                value: "31",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                              },
                              {
                                value: "32",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                              },
                              {
                                value: "33",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                              },
                              {
                                value: "34",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                              },
                              {
                                value: "35",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                              },
                              {
                                value: "36",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                              },
                              {
                                value: "37",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                              },
                              {
                                value: "38",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                              },
                              {
                                value: "39",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                              },
                              {
                                value: "40",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                              },
                              {
                                value: "41",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                              },
                              {
                                value: "42",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                              },
                              {
                                value: "43",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                              },
                              {
                                value: "44",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                              },
                              {
                                value: "45",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                              },
                              {
                                value: "46",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                              },
                              {
                                value: "47",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                              },
                              {
                                value: "48",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                              },
                              {
                                value: "49",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                              },
                              {
                                value: "50",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                              },
                              {
                                value: "51",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q52_q2_a1_title
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={topLevelL}
                            onChange={handletopLevelL}
                          /> */}
                          <label>{translation.patient_foot_ankle_function_q52_q2_a1_title}</label>
                          <CreatableSelect
                            isClearable
                            isMulti
                            value={value3}
                            options={options3}
                            onChange={handleChange3}
                            className="multi_select_drp_footFun"
                            placeholder={<div>{translation.Select}</div>}
                          // classNamePrefix="form-select"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {highestActL === "2" ? (
                        <div className="mb_20">
                          {/* <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                              },
                              {
                                value: "4",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                              },
                              {
                                value: "5",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                              },
                              {
                                value: "6",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                              },
                              {
                                value: "7",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                              },
                              {
                                value: "8",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                              },
                              {
                                value: "9",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                              },
                              {
                                value: "10",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                              },
                              {
                                value: "11",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                              },
                              {
                                value: "12",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                              },
                              {
                                value: "13",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                              },
                              {
                                value: "14",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                              },
                              {
                                value: "15",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                              },
                              {
                                value: "16",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                              },
                              {
                                value: "17",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                              },
                              {
                                value: "18",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                              },
                              {
                                value: "19",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                              },
                              {
                                value: "20",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                              },
                              {
                                value: "21",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                              },
                              {
                                value: "22",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                              },
                              {
                                value: "23",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                              },
                              {
                                value: "24",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                              },
                              {
                                value: "25",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                              },
                              {
                                value: "26",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                              },
                              {
                                value: "27",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                              },
                              {
                                value: "28",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                              },
                              {
                                value: "29",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                              },
                              {
                                value: "30",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                              },
                              {
                                value: "31",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                              },
                              {
                                value: "32",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                              },
                              {
                                value: "33",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                              },
                              {
                                value: "34",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                              },
                              {
                                value: "35",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                              },
                              {
                                value: "36",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                              },
                              {
                                value: "37",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                              },
                              {
                                value: "38",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                              },
                              {
                                value: "39",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                              },
                              {
                                value: "40",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                              },
                              {
                                value: "41",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                              },
                              {
                                value: "42",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                              },
                              {
                                value: "43",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                              },
                              {
                                value: "44",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                              },
                              {
                                value: "45",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                              },
                              {
                                value: "46",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                              },
                              {
                                value: "47",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                              },
                              {
                                value: "48",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                              },
                              {
                                value: "49",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                              },
                              {
                                value: "50",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                              },
                              {
                                value: "51",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q52_q1_a2_title
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={lowLevelL}
                            onChange={handlelowLevelL}
                          /> */}
                          <label>{translation.patient_foot_ankle_function_q52_q1_a2_title}</label>
                          <CreatableSelect
                            isClearable
                            isMulti
                            value={value4}
                            options={options4}
                            onChange={handleChange4}
                            className="multi_select_drp_footFun"
                            placeholder={<div>{translation.Select}</div>}
                          // classNamePrefix="form-select"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {highestActL === "3" ? (
                        <div className="mb_20">
                          {/* <FullDropdown
                            data={[
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o3,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o4,
                              },
                              {
                                value: "4",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o5,
                              },
                              {
                                value: "5",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o6,
                              },
                              {
                                value: "6",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o7,
                              },
                              {
                                value: "7",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_08,
                              },
                              {
                                value: "8",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o9,
                              },
                              {
                                value: "9",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o10,
                              },
                              {
                                value: "10",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o11,
                              },
                              {
                                value: "11",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o12,
                              },
                              {
                                value: "12",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o13,
                              },
                              {
                                value: "13",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o14,
                              },
                              {
                                value: "14",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o15,
                              },
                              {
                                value: "15",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o16,
                              },
                              {
                                value: "16",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o17,
                              },
                              {
                                value: "17",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o18,
                              },
                              {
                                value: "18",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o19,
                              },
                              {
                                value: "19",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o20,
                              },
                              {
                                value: "20",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o21,
                              },
                              {
                                value: "21",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o22,
                              },
                              {
                                value: "22",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o23,
                              },
                              {
                                value: "23",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o24,
                              },
                              {
                                value: "24",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o25,
                              },
                              {
                                value: "25",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o26,
                              },
                              {
                                value: "26",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o27,
                              },
                              {
                                value: "27",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o28,
                              },
                              {
                                value: "28",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o29,
                              },
                              {
                                value: "29",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o30,
                              },
                              {
                                value: "30",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o31,
                              },
                              {
                                value: "31",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o32,
                              },
                              {
                                value: "32",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o33,
                              },
                              {
                                value: "33",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o34,
                              },
                              {
                                value: "34",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o35,
                              },
                              {
                                value: "35",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o36,
                              },
                              {
                                value: "36",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o37,
                              },
                              {
                                value: "37",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o38,
                              },
                              {
                                value: "38",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o39,
                              },
                              {
                                value: "39",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o40,
                              },
                              {
                                value: "40",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o41,
                              },
                              {
                                value: "41",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o42,
                              },
                              {
                                value: "42",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o43,
                              },
                              {
                                value: "43",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o44,
                              },
                              {
                                value: "44",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o45,
                              },
                              {
                                value: "45",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o46,
                              },
                              {
                                value: "46",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o47,
                              },
                              {
                                value: "47",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o48,
                              },
                              {
                                value: "48",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o49,
                              },
                              {
                                value: "49",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o50,
                              },
                              {
                                value: "50",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o51,
                              },
                              {
                                value: "51",
                                label:
                                  translation.patient_foot_ankle_function_q52_q1_a1_a2_a3_o52,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q52_q1_a3_title
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={recreatL}
                            onChange={handlerecreatL}
                          /> */}
                          <label>{translation.patient_foot_ankle_function_q52_q1_a3_title}</label>
                          <CreatableSelect
                            isClearable
                            isMulti
                            value={value5}
                            options={options5}
                            onChange={handleChange5}
                            className="multi_select_drp_footFun"
                            placeholder={<div>{translation.Select}</div>}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {highestActL === "4" ? (
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o1,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o2,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o3,
                              },
                              {
                                value: "4",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o4,
                              },
                              {
                                value: "5",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o5,
                              },
                              {
                                value: "6",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o6,
                              },
                              {
                                value: "7",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o7,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q52_q2_a4
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={idoSpotL}
                            onChange={handleidoSpotL}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {highestActL === "5" ? (
                        <div className="mb_20">
                          <FullDropdown
                            data={[
                              {
                                value: "2",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o4,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o5,
                              },
                              {
                                value: "0",
                                label:
                                  translation.patient_foot_ankle_function_q52_q2_a4_o6,
                              },
                            ]}
                            label={
                              translation.patient_foot_ankle_function_q52_q2_a5
                            }
                            styleClass=""
                            placeholder={translation.none}
                            value={noSpotL}
                            onChange={handlenoSpotL}
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={`/foot-ankle-other-symptoms/${rId}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FootAnkleFunction;

import React, { useState, useEffect } from "react";
import "../../../css/ViewTreatmentForm.css";
import Button from "../../custom/button/Button";
import PersonalInformation from "./PersonalInformation";
import ShoulderScoring from "./ShoulderScoring";
import WesternOntarioShoulder from "./WesternOntarioShoulder";
import SacsScore from "./SacsScore";
import Satisfaction from "./Satisfaction";
import ClinicalExamination from "./ClinicalExamination";
import ClinicalExaminationBottom from "./ClinicalExaminationBottom";
import { useParams, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import useAxios from "../../../axiosinstance";
import useTranslation from "../../customHooks/translations";
function ViewTreatmentForm() {
  const translation = useTranslation();
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(()=>{
    localStorage.setItem("isdoctorForm",isdoctorForm)
  },[isdoctorForm])

  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [shoulderPain, setShoulderPain] = useState({});
  const [functionData, setShoulderFunction] = useState({});
  const [satisfactionData, setShoulderSatisfaction] = useState({});
  const [sacsScore, setsacsScore] = useState({});
  const [wosiScore, setwosiScore] = useState({});
  const [doctorefeedback, setShoulderDoctorFeedback] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const axiosInstance = useAxios();
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=4&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setShoulderPain(res.data.data2[0].painScore[0]);
        setShoulderFunction(res.data.data2[0].functionScore[0]);
        setsacsScore(res.data.data2[0].sacsScore[0]);
        setShoulderSatisfaction(res.data.data2[0].safisScore[0]);
        setwosiScore(res.data.data2[0].wosiScore[0]);
        setShoulderDoctorFeedback(res.data.data2[0].doctorFeedback[0]);
        seteditShow(res.data.status);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };
  useEffect(() => {
    save();
  }, []);
  const handleEdit = () => {
    navigate("/pain/" + rId + "?d=true&akpt=" + pId +"&is_doctor_form=true");
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };

  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "patient-list",
          "_blank"
        );
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };

  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };

  const profileAllData = React.useMemo(() => profileData, [profileData]);

  return (
    <div className="view_treatment_form">
      <div className="row page_header">
        <div className="col-sm-6 col-md-6">
          <h4>Shoulder Treatment Form</h4>
        </div>
        {/* {management === "true" && (
          <div className={`col-sm-6 col-md-6 text-end`}>
            <Button
              onClick={() => handleDelete()}
              value="Delete Form"
              buttonStyle="btn_fill"
            />
            {editShow && (
              <Button
                onClick={() => handleEdit()}
                value="Edit Form"
                buttonStyle="btn_fill ml_15 "
              />
            )}
            {!editShow && (
              <Button
                onClick={() => console.log("")}
                value="Edit Form"
                buttonStyle=" ml_15 button_disabled "
              />
            )}
          </div>
        )} */}
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={"Form"}
        type="task_id"
        id={rId}
      />
      {pScore === "true" && action === "1" && (
        <>
          <PersonalInformation
            profileAllData={profileAllData}
            AllData={AllData}
          />
          {viewPatient === "0" ? (
            <div className="no_data">Patient form is not yet filled </div> 
          ) : (
            <>
              <ShoulderScoring
                shoulderPain={shoulderPain}
                AllData={AllData}
                functionData={functionData}
              />
              <WesternOntarioShoulder wosiScore={wosiScore} AllData={AllData} functionData={functionData}/>
              <SacsScore sacsScore={sacsScore} AllData={AllData} functionData={functionData}/>
              <Satisfaction
                satisfactionData={satisfactionData}
                AllData={AllData}
              />
            </>
          )}
        </>
      )}
      {pScore === "true" && action === "2" && (
        <>
          <ClinicalExamination
            doctorefeedback={doctorefeedback}
            AllData={AllData}
          />
          <ClinicalExaminationBottom
            doctorefeedback={doctorefeedback}
            AllData={AllData}
          />
        </>
      )}

      {management === "true" && (
        <>
          <PersonalInformation
            profileAllData={profileAllData}
            AllData={AllData}
          />

          {viewPatient === "0" ? (
            <div className="no_data">Patient form is not filled yet </div>
          ) : (
            <>
              <ShoulderScoring
                shoulderPain={shoulderPain} 
                AllData={AllData}
                functionData={functionData}
              />

              <WesternOntarioShoulder wosiScore={wosiScore} AllData={AllData} 
                functionData={functionData}
              
              />

            
                <SacsScore sacsScore={sacsScore} AllData={AllData} 
                functionData={functionData}
                
                />
            

              <Satisfaction
                satisfactionData={satisfactionData}
                AllData={AllData}
              />
            </>
          )}
        </>
      )}

      {viewDoctor !== "0" && management === "true" && (
        <>
          <ClinicalExamination
            doctorefeedback={doctorefeedback}
            AllData={AllData}
          />
          <ClinicalExaminationBottom
            doctorefeedback={doctorefeedback}
            AllData={AllData}
          />
        </>
      )}
    </div>
  );
}

export default ViewTreatmentForm;

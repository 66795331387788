import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import Button from "../../../../custom/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePediaTotalScores,
  updatePediaScores,
  updatePediaSatisfactionScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaFunctionScore,
  updatePediaQolScore,
  updatePediaKneeNorwichInstaScore,
  updatePediaKneeFeedbackFieldScores,
  updatePediaKneeFeedbackScores,
} from "../../../../../redux/actions/pediaKnee";
import { updateAdultKneeFeedback } from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import NameCustom from "../../../../custom/name/NameCustom";
import SubmitModel from "../../../SubmissionModel/SubmitModel";
function PediaKneeComplete() {
  const translation = useTransition();
  const [isLoading, setIsLoading] = useState(true);
  const { rId } = useParams();
  const axiosInstance = useAxios();
  const [painSco] = useSelector((state) => state.pediaKnee.painScore);
  const [symptomsSco] = useSelector((state) => state.pediaKnee.symtomsScore);
  const [functionSco] = useSelector((state) => state.pediaKnee.functionScore);
  const [qolSco] = useSelector((state) => state.pediaKnee.qolScore);
  const [satisScore] = useSelector(
    (state) => state.pediaKnee.satisfactionScore
  );
  const [initialAssesment, setIitialAssesment] = useState(0);
  const [satisfactionSco] = useSelector(
    (state) => state.pediaKnee.functionScore
  );
  const [Norwich] = useSelector((state) => state.pediaKnee.norwichInstability);
  const dispatch = useDispatch();
  const [isdoctorform, setisdoctorform] = useState(Number());
  const [patientID, setpatientID] = useState("");
  const navigate = useNavigate();
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        setIitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setpatientID(res.data.data2[0].patient_id);
        setisdoctorform(res.data.data2[0].is_doctor_form);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  const mapValueToPercentage = (value = 0) => {
    return value * 10;
  };

  useEffect(() => {
    save();
  }, []);
  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  function selectedQuestion(...numbers) {
    let count = 0;
    for (const number of numbers) {
      if (number !== "") {
        count++;
      }
    }
    return count;
  }

  //PEDI-IKDC

  const pediapainPastFourWeeksR =
    painSco.painPastFourWeeksR === "0"
      ? 10
      : painSco.painPastFourWeeksR === "1"
      ? 9
      : painSco.painPastFourWeeksR === "2"
      ? 8
      : painSco.painPastFourWeeksR === "3"
      ? 7
      : painSco.painPastFourWeeksR === "4"
      ? 6
      : painSco.painPastFourWeeksR === "5"
      ? 5
      : painSco.painPastFourWeeksR === "6"
      ? 4
      : painSco.painPastFourWeeksR === "7"
      ? 3
      : painSco.painPastFourWeeksR === "8"
      ? 2
      : painSco.painPastFourWeeksR === "9"
      ? 1
      : painSco.painPastFourWeeksR === "10"
      ? 0
      : 0;
  const pediapainPastFourWeeksL =
    painSco.painPastFourWeeksL === "0"
      ? 10
      : painSco.painPastFourWeeksL === "1"
      ? 9
      : painSco.painPastFourWeeksL === "2"
      ? 8
      : painSco.painPastFourWeeksL === "3"
      ? 7
      : painSco.painPastFourWeeksL === "4"
      ? 6
      : painSco.painPastFourWeeksL === "5"
      ? 5
      : painSco.painPastFourWeeksL === "6"
      ? 4
      : painSco.painPastFourWeeksL === "7"
      ? 3
      : painSco.painPastFourWeeksL === "8"
      ? 2
      : painSco.painPastFourWeeksL === "9"
      ? 1
      : painSco.painPastFourWeeksL === "10"
      ? 0
      : 0;
  const pediapainHurtTodayR =
    painSco.painHurtTodayR === "0"
      ? 10
      : painSco.painHurtTodayR === "1"
      ? 9
      : painSco.painHurtTodayR === "2"
      ? 8
      : painSco.painHurtTodayR === "3"
      ? 7
      : painSco.painHurtTodayR === "4"
      ? 6
      : painSco.painHurtTodayR === "5"
      ? 5
      : painSco.painHurtTodayR === "6"
      ? 4
      : painSco.painHurtTodayR === "7"
      ? 3
      : painSco.painHurtTodayR === "8"
      ? 2
      : painSco.painHurtTodayR === "9"
      ? 1
      : painSco.painHurtTodayR === "10"
      ? 0
      : 0;
  const pediapainHurtTodayL =
    painSco.painHurtTodayL === "0"
      ? 10
      : painSco.painHurtTodayL === "1"
      ? 9
      : painSco.painHurtTodayL === "2"
      ? 8
      : painSco.painHurtTodayL === "3"
      ? 7
      : painSco.painHurtTodayL === "4"
      ? 6
      : painSco.painHurtTodayL === "5"
      ? 5
      : painSco.painHurtTodayL === "6"
      ? 4
      : painSco.painHurtTodayL === "7"
      ? 3
      : painSco.painHurtTodayL === "8"
      ? 2
      : painSco.painHurtTodayL === "9"
      ? 1
      : painSco.painHurtTodayL === "10"
      ? 0
      : 0;
  const pediakneeGettingStuckR =
    symptomsSco.kneeGettingStuckR === "0"
      ? 1
      : symptomsSco.kneeGettingStuckR === "1"
      ? 0
      : symptomsSco.kneeGettingStuckR === "2"
      ? 0
      : symptomsSco.kneeGettingStuckR === "3"
      ? 0
      : symptomsSco.kneeGettingStuckR === "4"
      ? 0
      : 0;
  const pediakneeGettingStuckL =
    symptomsSco.kneeGettingStuckL === "0"
      ? 1
      : symptomsSco.kneeGettingStuckL === "1"
      ? 0
      : symptomsSco.kneeGettingStuckL === "2"
      ? 0
      : symptomsSco.kneeGettingStuckL === "3"
      ? 0
      : symptomsSco.kneeGettingStuckL === "4"
      ? 0
      : 0;
  const pediahandleStillMoveR =
    symptomsSco.stillMoveR === "0"
      ? 1
      : symptomsSco.stillMoveR === "1"
      ? 0
      : symptomsSco.stillMoveR === "2"
      ? 0
      : symptomsSco.stillMoveR === "3"
      ? 0
      : symptomsSco.stillMoveR === "4"
      ? 0
      : 0;
  const pediahandleStillMoveL =
    symptomsSco.stillMoveL === "0"
      ? 1
      : symptomsSco.stillMoveL === "1"
      ? 0
      : symptomsSco.stillMoveL === "2"
      ? 0
      : symptomsSco.stillMoveL === "3"
      ? 0
      : symptomsSco.stillMoveL === "4"
      ? 0
      : 0;
  const pediaswollenKneeR =
    symptomsSco.swollenKneeR === "0"
      ? 4
      : symptomsSco.swollenKneeR === "1"
      ? 3
      : symptomsSco.swollenKneeR === "2"
      ? 2
      : symptomsSco.swollenKneeR === "3"
      ? 1
      : symptomsSco.swollenKneeR === "4"
      ? 0
      : 0;
  const pediaswollenKneeL =
    symptomsSco.swollenKneeL === "0"
      ? 4
      : symptomsSco.swollenKneeL === "1"
      ? 3
      : symptomsSco.swollenKneeL === "2"
      ? 2
      : symptomsSco.swollenKneeL === "3"
      ? 1
      : symptomsSco.swollenKneeL === "4"
      ? 0
      : 0;
  const pediawithoutHurtLotR =
    symptomsSco.withoutHurtLotR === "0"
      ? 4
      : symptomsSco.withoutHurtLotR === "1"
      ? 3
      : symptomsSco.withoutHurtLotR === "2"
      ? 2
      : symptomsSco.withoutHurtLotR === "3"
      ? 1
      : symptomsSco.withoutHurtLotR === "4"
      ? 0
      : 0;
  const pediawithoutHurtLotL =
    symptomsSco.withoutHurtLotL === "0"
      ? 4
      : symptomsSco.withoutHurtLotL === "1"
      ? 3
      : symptomsSco.withoutHurtLotL === "2"
      ? 2
      : symptomsSco.withoutHurtLotL === "3"
      ? 1
      : symptomsSco.withoutHurtLotL === "4"
      ? 0
      : 0;
  const pediawithoutPuffyR =
    symptomsSco.withoutPuffyR === "0"
      ? 4
      : symptomsSco.withoutPuffyR === "1"
      ? 3
      : symptomsSco.withoutPuffyR === "2"
      ? 2
      : symptomsSco.withoutPuffyR === "3"
      ? 1
      : symptomsSco.withoutPuffyR === "4"
      ? 0
      : 0;
  const pediawithoutPuffyL =
    symptomsSco.withoutPuffyL === "0"
      ? 4
      : symptomsSco.withoutPuffyL === "1"
      ? 3
      : symptomsSco.withoutPuffyL === "2"
      ? 2
      : symptomsSco.withoutPuffyL === "3"
      ? 1
      : symptomsSco.withoutPuffyL === "4"
      ? 0
      : 0;
  const pediacantHoldR =
    symptomsSco.cantHoldR === "0"
      ? 4
      : symptomsSco.cantHoldR === "1"
      ? 3
      : symptomsSco.cantHoldR === "2"
      ? 2
      : symptomsSco.cantHoldR === "3"
      ? 1
      : symptomsSco.cantHoldR === "4"
      ? 0
      : 0;
  const pediacantHoldL =
    symptomsSco.cantHoldL === "0"
      ? 4
      : symptomsSco.cantHoldL === "1"
      ? 3
      : symptomsSco.cantHoldL === "2"
      ? 2
      : symptomsSco.cantHoldL === "3"
      ? 1
      : symptomsSco.cantHoldL === "4"
      ? 0
      : 0;
  const pediamostOfTimeR =
    symptomsSco.mostOfTimeR === "0"
      ? 4
      : symptomsSco.mostOfTimeR === "1"
      ? 3
      : symptomsSco.mostOfTimeR === "2"
      ? 2
      : symptomsSco.mostOfTimeR === "3"
      ? 1
      : symptomsSco.mostOfTimeR === "4"
      ? 0
      : 0;
  const pediamostOfTimeL =
    symptomsSco.mostOfTimeL === "0"
      ? 4
      : symptomsSco.mostOfTimeL === "1"
      ? 3
      : symptomsSco.mostOfTimeL === "2"
      ? 2
      : symptomsSco.mostOfTimeL === "3"
      ? 1
      : symptomsSco.mostOfTimeL === "4"
      ? 0
      : 0;
  const pediagoUpStairsR =
    functionSco.goUpStairsR === "0"
      ? 4
      : functionSco.goUpStairsR === "1"
      ? 3
      : functionSco.goUpStairsR === "2"
      ? 2
      : functionSco.goUpStairsR === "3"
      ? 1
      : functionSco.goUpStairsR === "4"
      ? 0
      : 0;
  const pediagoUpStairsL =
    functionSco.goUpStairsL === "0"
      ? 4
      : functionSco.goUpStairsL === "1"
      ? 3
      : functionSco.goUpStairsL === "2"
      ? 2
      : functionSco.goUpStairsL === "3"
      ? 1
      : functionSco.goUpStairsL === "4"
      ? 0
      : 0;
  const pediagoDownStairsR =
    functionSco.goDownStairsR === "0"
      ? 4
      : functionSco.goDownStairsR === "1"
      ? 3
      : functionSco.goDownStairsR === "2"
      ? 2
      : functionSco.goDownStairsR === "3"
      ? 1
      : functionSco.goDownStairsR === "4"
      ? 0
      : 0;
  const pediagoDownStairsL =
    functionSco.goDownStairsL === "0"
      ? 4
      : functionSco.goDownStairsL === "1"
      ? 3
      : functionSco.goDownStairsL === "2"
      ? 2
      : functionSco.goDownStairsL === "3"
      ? 1
      : functionSco.goDownStairsL === "4"
      ? 0
      : 0;
  const pediakneelFrontR =
    functionSco.kneelFrontR === "0"
      ? 4
      : functionSco.kneelFrontR === "1"
      ? 3
      : functionSco.kneelFrontR === "2"
      ? 2
      : functionSco.kneelFrontR === "3"
      ? 1
      : functionSco.kneelFrontR === "4"
      ? 0
      : 0;
  const pediakneelFrontL =
    functionSco.kneelFrontL === "0"
      ? 4
      : functionSco.kneelFrontL === "1"
      ? 3
      : functionSco.kneelFrontL === "2"
      ? 2
      : functionSco.kneelFrontL === "3"
      ? 1
      : functionSco.kneelFrontL === "4"
      ? 0
      : 0;
  const pediastraighteningL =
    functionSco.straighteningL === "0"
      ? 4
      : functionSco.straighteningL === "1"
      ? 3
      : functionSco.straighteningL === "2"
      ? 2
      : functionSco.straighteningL === "3"
      ? 1
      : functionSco.straighteningL === "4"
      ? 0
      : 0;
  const pediastraighteningR =
    functionSco.straighteningR === "0"
      ? 4
      : functionSco.straighteningR === "1"
      ? 3
      : functionSco.straighteningR === "2"
      ? 2
      : functionSco.straighteningR === "3"
      ? 1
      : functionSco.straighteningR === "4"
      ? 0
      : 0;
  const pediabendingKneeR =
    functionSco.bendingKneeR === "0"
      ? 4
      : functionSco.bendingKneeR === "1"
      ? 3
      : functionSco.bendingKneeR === "2"
      ? 2
      : functionSco.bendingKneeR === "3"
      ? 1
      : functionSco.bendingKneeR === "4"
      ? 0
      : 0;
  const pediabendingKneeL =
    functionSco.bendingKneeL === "0"
      ? 4
      : functionSco.bendingKneeL === "1"
      ? 3
      : functionSco.bendingKneeL === "2"
      ? 2
      : functionSco.bendingKneeL === "3"
      ? 1
      : functionSco.bendingKneeL === "4"
      ? 0
      : 0;
  const pediasquatBasketballR =
    functionSco.squatBasketballR === "0"
      ? 4
      : functionSco.squatBasketballR === "1"
      ? 3
      : functionSco.squatBasketballR === "2"
      ? 2
      : functionSco.squatBasketballR === "3"
      ? 1
      : functionSco.squatBasketballR === "4"
      ? 0
      : 0;
  const pediasquatBasketballL =
    functionSco.squatBasketballL === "0"
      ? 4
      : functionSco.squatBasketballL === "1"
      ? 3
      : functionSco.squatBasketballL === "2"
      ? 2
      : functionSco.squatBasketballL === "3"
      ? 1
      : functionSco.squatBasketballL === "4"
      ? 0
      : 0;
  const pediasitOnChairWithKneeBentR =
    functionSco.sitOnChairWithKneeBentR === "0"
      ? 4
      : functionSco.sitOnChairWithKneeBentR === "1"
      ? 3
      : functionSco.sitOnChairWithKneeBentR === "2"
      ? 2
      : functionSco.sitOnChairWithKneeBentR === "3"
      ? 1
      : functionSco.sitOnChairWithKneeBentR === "4"
      ? 0
      : 0;
  const pediasitOnChairWithKneeBentL =
    functionSco.sitOnChairWithKneeBentL === "0"
      ? 4
      : functionSco.sitOnChairWithKneeBentL === "1"
      ? 3
      : functionSco.sitOnChairWithKneeBentL === "2"
      ? 2
      : functionSco.sitOnChairWithKneeBentL === "3"
      ? 1
      : functionSco.sitOnChairWithKneeBentL === "4"
      ? 0
      : 0;
  const pediagetUpFromChairR =
    functionSco.getUpFromChairR === "0"
      ? 4
      : functionSco.getUpFromChairR === "1"
      ? 3
      : functionSco.getUpFromChairR === "2"
      ? 2
      : functionSco.getUpFromChairR === "3"
      ? 1
      : functionSco.getUpFromChairR === "4"
      ? 0
      : 0;
  const pediagetUpFromChairL =
    functionSco.getUpFromChairL === "0"
      ? 4
      : functionSco.getUpFromChairL === "1"
      ? 3
      : functionSco.getUpFromChairL === "2"
      ? 2
      : functionSco.getUpFromChairL === "3"
      ? 1
      : functionSco.getUpFromChairL === "4"
      ? 0
      : 0;
  const pediarunDurPlayR =
    functionSco.runDurPlayR === "0"
      ? 4
      : functionSco.runDurPlayR === "1"
      ? 3
      : functionSco.runDurPlayR === "2"
      ? 2
      : functionSco.runDurPlayR === "3"
      ? 1
      : functionSco.runDurPlayR === "4"
      ? 0
      : 0;
  const pediarunDurPlayL =
    functionSco.runDurPlayL === "0"
      ? 4
      : functionSco.runDurPlayL === "1"
      ? 3
      : functionSco.runDurPlayL === "2"
      ? 2
      : functionSco.runDurPlayL === "3"
      ? 1
      : functionSco.runDurPlayL === "4"
      ? 0
      : 0;
  const pediajumpDurPlayR =
    functionSco.jumpDurPlayR === "0"
      ? 4
      : functionSco.jumpDurPlayR === "1"
      ? 3
      : functionSco.jumpDurPlayR === "2"
      ? 2
      : functionSco.jumpDurPlayR === "3"
      ? 1
      : functionSco.jumpDurPlayR === "4"
      ? 0
      : 0;

  const pediajumpDurPlayL =
    functionSco.jumpDurPlayL === "0"
      ? 4
      : functionSco.jumpDurPlayL === "1"
      ? 3
      : functionSco.jumpDurPlayL === "2"
      ? 2
      : functionSco.jumpDurPlayL === "3"
      ? 1
      : functionSco.jumpDurPlayL === "4"
      ? 0
      : 0;

  const pediastartAndStopMoveR =
    functionSco.startAndStopMoveR === "0"
      ? 4
      : functionSco.startAndStopMoveR === "1"
      ? 3
      : functionSco.startAndStopMoveR === "2"
      ? 2
      : functionSco.startAndStopMoveR === "3"
      ? 1
      : functionSco.startAndStopMoveR === "4"
      ? 0
      : 0;

  const pediastartAndStopMoveL =
    functionSco.startAndStopMoveL === "0"
      ? 4
      : functionSco.startAndStopMoveL === "1"
      ? 3
      : functionSco.startAndStopMoveL === "2"
      ? 2
      : functionSco.startAndStopMoveL === "3"
      ? 1
      : functionSco.startAndStopMoveL === "4"
      ? 0
      : 0;

  const pediahowKneeWorkR = Math.floor(functionSco.howKneeWorkR);
  const pediahowKneeWorkL = Math.floor(functionSco.howKneeWorkL);

  // Pain Subscale ===========================
  const painPastFourWeeksR =
    painSco.painPastFourWeeksR === "0"
      ? 4
      : painSco.painPastFourWeeksR === "1"
      ? 3
      : painSco.painPastFourWeeksR === "2"
      ? 3
      : painSco.painPastFourWeeksR === "3"
      ? 3
      : painSco.painPastFourWeeksR === "4"
      ? 2
      : painSco.painPastFourWeeksR === "5"
      ? 2
      : painSco.painPastFourWeeksR === "6"
      ? 2
      : painSco.painPastFourWeeksR === "7"
      ? 1
      : painSco.painPastFourWeeksR === "8"
      ? 1
      : painSco.painPastFourWeeksR === "9"
      ? 1
      : painSco.painPastFourWeeksR === "10"
      ? 0
      : 0;
  const painPastFourWeeksL =
    painSco.painPastFourWeeksL === "0"
      ? 4
      : painSco.painPastFourWeeksL === "1"
      ? 3
      : painSco.painPastFourWeeksL === "2"
      ? 3
      : painSco.painPastFourWeeksL === "3"
      ? 3
      : painSco.painPastFourWeeksL === "4"
      ? 2
      : painSco.painPastFourWeeksL === "5"
      ? 2
      : painSco.painPastFourWeeksL === "6"
      ? 2
      : painSco.painPastFourWeeksL === "7"
      ? 1
      : painSco.painPastFourWeeksL === "8"
      ? 1
      : painSco.painPastFourWeeksL === "9"
      ? 1
      : painSco.painPastFourWeeksL === "10"
      ? 0
      : 0;

  const paintwistingR =
    painSco.twistingR === "0"
      ? 4
      : painSco.twistingR === "1"
      ? 3
      : painSco.twistingR === "2"
      ? 2
      : painSco.twistingR === "3"
      ? 1
      : painSco.twistingR === "4"
      ? 0
      : 0;
  const paintwistingL =
    painSco.twistingL === "0"
      ? 4
      : painSco.twistingL === "1"
      ? 3
      : painSco.twistingL === "2"
      ? 2
      : painSco.twistingL === "3"
      ? 1
      : painSco.twistingL === "4"
      ? 0
      : 0;
  const painstraighteningR =
    painSco.straighteningR === "0"
      ? 4
      : painSco.straighteningR === "1"
      ? 3
      : painSco.straighteningR === "2"
      ? 2
      : painSco.straighteningR === "3"
      ? 1
      : painSco.straighteningR === "4"
      ? 0
      : 0;
  const painstraighteningL =
    painSco.straighteningL === "0"
      ? 4
      : painSco.straighteningL === "1"
      ? 3
      : painSco.straighteningL === "2"
      ? 2
      : painSco.straighteningL === "3"
      ? 1
      : painSco.straighteningL === "4"
      ? 0
      : 0;
  const painfullyBendKneeR =
    painSco.fullyBendKneeR === "0"
      ? 4
      : painSco.fullyBendKneeR === "1"
      ? 3
      : painSco.fullyBendKneeR === "2"
      ? 2
      : painSco.fullyBendKneeR === "3"
      ? 1
      : painSco.fullyBendKneeR === "4"
      ? 0
      : 0;
  const painfullyBendKneeL =
    painSco.fullyBendKneeL === "0"
      ? 4
      : painSco.fullyBendKneeL === "1"
      ? 3
      : painSco.fullyBendKneeL === "2"
      ? 2
      : painSco.fullyBendKneeL === "3"
      ? 1
      : painSco.fullyBendKneeL === "4"
      ? 0
      : 0;
  const painwalkingUpStairsR =
    painSco.walkingUpStairsR === "0"
      ? 4
      : painSco.walkingUpStairsR === "1"
      ? 3
      : painSco.walkingUpStairsR === "2"
      ? 2
      : painSco.walkingUpStairsR === "3"
      ? 1
      : painSco.walkingUpStairsR === "4"
      ? 0
      : 0;
  const painwalkingUpStairsL =
    painSco.walkingUpStairsL === "0"
      ? 4
      : painSco.walkingUpStairsL === "1"
      ? 3
      : painSco.walkingUpStairsL === "2"
      ? 2
      : painSco.walkingUpStairsL === "3"
      ? 1
      : painSco.walkingUpStairsL === "4"
      ? 0
      : 0;
  const painwalkingDownStairsR =
    painSco.walkingDownStairsR === "0"
      ? 4
      : painSco.walkingDownStairsR === "1"
      ? 3
      : painSco.walkingDownStairsR === "2"
      ? 2
      : painSco.walkingDownStairsR === "3"
      ? 1
      : painSco.walkingDownStairsR === "4"
      ? 0
      : 0;
  const painwalkingDownStairsL =
    painSco.walkingDownStairsL === "0"
      ? 4
      : painSco.walkingDownStairsL === "1"
      ? 3
      : painSco.walkingDownStairsL === "2"
      ? 2
      : painSco.walkingDownStairsL === "3"
      ? 1
      : painSco.walkingDownStairsL === "4"
      ? 0
      : 0;
  const painsittingKneeBentR =
    painSco.sittingKneeBentR === "0"
      ? 4
      : painSco.sittingKneeBentR === "1"
      ? 3
      : painSco.sittingKneeBentR === "2"
      ? 2
      : painSco.sittingKneeBentR === "3"
      ? 1
      : painSco.sittingKneeBentR === "4"
      ? 0
      : 0;
  const painsittingKneeBentL =
    painSco.sittingKneeBentL === "0"
      ? 4
      : painSco.sittingKneeBentL === "1"
      ? 3
      : painSco.sittingKneeBentL === "2"
      ? 2
      : painSco.sittingKneeBentL === "3"
      ? 1
      : painSco.sittingKneeBentL === "4"
      ? 0
      : 0;
  const painsittingKneeBentUprightR =
    painSco.sittingKneeBentUprightR === "0"
      ? 4
      : painSco.sittingKneeBentUprightR === "1"
      ? 3
      : painSco.sittingKneeBentUprightR === "2"
      ? 2
      : painSco.sittingKneeBentUprightR === "3"
      ? 1
      : painSco.sittingKneeBentUprightR === "4"
      ? 0
      : 0;
  const painsittingKneeBentUprightL =
    painSco.sittingKneeBentUprightL === "0"
      ? 4
      : painSco.sittingKneeBentUprightL === "1"
      ? 3
      : painSco.sittingKneeBentUprightL === "2"
      ? 2
      : painSco.sittingKneeBentUprightL === "3"
      ? 1
      : painSco.sittingKneeBentUprightL === "4"
      ? 0
      : 0;

  //Symptoms Subscale =====================

  const symptomkneeMakingNoiseR =
    symptomsSco.kneeMakingNoiseR === "0"
      ? 4
      : symptomsSco.kneeMakingNoiseR === "1"
      ? 3
      : symptomsSco.kneeMakingNoiseR === "2"
      ? 2
      : symptomsSco.kneeMakingNoiseR === "3"
      ? 1
      : symptomsSco.kneeMakingNoiseR === "4"
      ? 0
      : 0;
  const symptomkneeMakingNoiseL =
    symptomsSco.kneeMakingNoiseL === "0"
      ? 4
      : symptomsSco.kneeMakingNoiseL === "1"
      ? 3
      : symptomsSco.kneeMakingNoiseL === "2"
      ? 2
      : symptomsSco.kneeMakingNoiseL === "3"
      ? 1
      : symptomsSco.kneeMakingNoiseL === "4"
      ? 0
      : 0;

  const symptomkneeGettingStuckR =
    symptomsSco.kneeGettingStuckR === "0"
      ? 4
      : symptomsSco.kneeGettingStuckR === "1"
      ? 3
      : symptomsSco.kneeGettingStuckR === "2"
      ? 2
      : symptomsSco.kneeGettingStuckR === "3"
      ? 1
      : symptomsSco.kneeGettingStuckR === "4"
      ? 0
      : 0;
  const symptomkneeGettingStuckL =
    symptomsSco.kneeGettingStuckL === "0"
      ? 4
      : symptomsSco.kneeGettingStuckL === "1"
      ? 3
      : symptomsSco.kneeGettingStuckL === "2"
      ? 2
      : symptomsSco.kneeGettingStuckL === "3"
      ? 1
      : symptomsSco.kneeGettingStuckL === "4"
      ? 0
      : 0;

  const symptompuffyKneeR =
    symptomsSco.puffyKneeR === "0"
      ? 4
      : symptomsSco.puffyKneeR === "1"
      ? 3
      : symptomsSco.puffyKneeR === "2"
      ? 2
      : symptomsSco.puffyKneeR === "3"
      ? 1
      : symptomsSco.puffyKneeR === "4"
      ? 0
      : 0;
  const symptompuffyKneeL =
    symptomsSco.puffyKneeL === "0"
      ? 4
      : symptomsSco.puffyKneeL === "1"
      ? 3
      : symptomsSco.puffyKneeL === "2"
      ? 2
      : symptomsSco.puffyKneeL === "3"
      ? 1
      : symptomsSco.puffyKneeL === "4"
      ? 0
      : 0;
  const symptomstraighteningR =
    functionSco.straighteningR === "0"
      ? 4
      : functionSco.straighteningR === "1"
      ? 3
      : functionSco.straighteningR === "2"
      ? 2
      : functionSco.straighteningR === "3"
      ? 1
      : functionSco.straighteningR === "4"
      ? 0
      : 0;
  const symptomstraighteningL =
    functionSco.straighteningL === "0"
      ? 4
      : functionSco.straighteningL === "1"
      ? 3
      : functionSco.straighteningL === "2"
      ? 2
      : functionSco.straighteningL === "3"
      ? 1
      : functionSco.straighteningL === "4"
      ? 0
      : 0;
  const symptombendingKneeR =
    functionSco.bendingKneeR === "0"
      ? 4
      : functionSco.bendingKneeR === "1"
      ? 3
      : functionSco.bendingKneeR === "2"
      ? 2
      : functionSco.bendingKneeR === "3"
      ? 1
      : functionSco.bendingKneeR === "4"
      ? 0
      : 0;
  const symptombendingKneeL =
    functionSco.bendingKneeL === "0"
      ? 4
      : functionSco.bendingKneeL === "1"
      ? 3
      : functionSco.bendingKneeL === "2"
      ? 2
      : functionSco.bendingKneeL === "3"
      ? 1
      : functionSco.bendingKneeL === "4"
      ? 0
      : 0;
  const symptomTwistDurPlayInMorningtR =
    functionSco.TwistDurPlayInMorningtR === "0"
      ? 4
      : functionSco.TwistDurPlayInMorningtR === "1"
      ? 3
      : functionSco.TwistDurPlayInMorningtR === "2"
      ? 2
      : functionSco.TwistDurPlayInMorningtR === "3"
      ? 1
      : functionSco.TwistDurPlayInMorningtR === "4"
      ? 0
      : 0;
  const symptomTwistDurPlayInMorningtL =
    functionSco.TwistDurPlayInMorningtL === "0"
      ? 4
      : functionSco.TwistDurPlayInMorningtL === "1"
      ? 3
      : functionSco.TwistDurPlayInMorningtL === "2"
      ? 2
      : functionSco.TwistDurPlayInMorningtL === "3"
      ? 1
      : functionSco.TwistDurPlayInMorningtL === "4"
      ? 0
      : 0;
  const symptommoveDayAfterR =
    functionSco.moveDayAfterR === "0"
      ? 4
      : functionSco.moveDayAfterR === "1"
      ? 3
      : functionSco.moveDayAfterR === "2"
      ? 2
      : functionSco.moveDayAfterR === "3"
      ? 1
      : functionSco.moveDayAfterR === "4"
      ? 0
      : 0;
  const symptommoveDayAfterL =
    functionSco.moveDayAfterL === "0"
      ? 4
      : functionSco.moveDayAfterL === "1"
      ? 3
      : functionSco.moveDayAfterL === "2"
      ? 2
      : functionSco.moveDayAfterL === "3"
      ? 1
      : functionSco.moveDayAfterL === "4"
      ? 0
      : 0;

  //KOOS-C ADL =====================

  const koosgoUpStairsR =
    functionSco.goUpStairsR === "0"
      ? 4
      : functionSco.goUpStairsR === "1"
      ? 3
      : functionSco.goUpStairsR === "2"
      ? 2
      : functionSco.goUpStairsR === "3"
      ? 1
      : functionSco.goUpStairsR === "4"
      ? 0
      : 0;
  const koosgoUpStairsL =
    functionSco.goUpStairsL === "0"
      ? 4
      : functionSco.goUpStairsL === "1"
      ? 3
      : functionSco.goUpStairsL === "2"
      ? 2
      : functionSco.goUpStairsL === "3"
      ? 1
      : functionSco.goUpStairsL === "4"
      ? 0
      : 0;
  const koosgoDownStairsR =
    functionSco.goDownStairsR === "0"
      ? 4
      : functionSco.goDownStairsR === "1"
      ? 3
      : functionSco.goDownStairsR === "2"
      ? 2
      : functionSco.goDownStairsR === "3"
      ? 1
      : functionSco.goDownStairsR === "4"
      ? 0
      : 0;
  const koosgoDownStairsL =
    functionSco.goDownStairsL === "0"
      ? 4
      : functionSco.goDownStairsL === "1"
      ? 3
      : functionSco.goDownStairsL === "2"
      ? 2
      : functionSco.goDownStairsL === "3"
      ? 1
      : functionSco.goDownStairsL === "4"
      ? 0
      : 0;
  const koossitOnChairWithKneeBentR =
    functionSco.sitOnChairWithKneeBentR === "0"
      ? 4
      : functionSco.sitOnChairWithKneeBentR === "1"
      ? 3
      : functionSco.sitOnChairWithKneeBentR === "2"
      ? 2
      : functionSco.sitOnChairWithKneeBentR === "3"
      ? 1
      : functionSco.sitOnChairWithKneeBentR === "4"
      ? 0
      : 0;
  const koossitOnChairWithKneeBentL =
    functionSco.sitOnChairWithKneeBentL === "0"
      ? 4
      : functionSco.sitOnChairWithKneeBentL === "1"
      ? 3
      : functionSco.sitOnChairWithKneeBentL === "2"
      ? 2
      : functionSco.sitOnChairWithKneeBentL === "3"
      ? 1
      : functionSco.sitOnChairWithKneeBentL === "4"
      ? 0
      : 0;
  const koosgetUpFromChairR =
    functionSco.getUpFromChairR === "0"
      ? 4
      : functionSco.getUpFromChairR === "1"
      ? 3
      : functionSco.getUpFromChairR === "2"
      ? 2
      : functionSco.getUpFromChairR === "3"
      ? 1
      : functionSco.getUpFromChairR === "4"
      ? 0
      : 0;
  const koosgetUpFromChairL =
    functionSco.getUpFromChairL === "0"
      ? 4
      : functionSco.getUpFromChairL === "1"
      ? 3
      : functionSco.getUpFromChairL === "2"
      ? 2
      : functionSco.getUpFromChairL === "3"
      ? 1
      : functionSco.getUpFromChairL === "4"
      ? 0
      : 0;

  const koosbendingToFloorR =
    functionSco.bendingToFloorR === "0"
      ? 4
      : functionSco.bendingToFloorR === "1"
      ? 3
      : functionSco.bendingToFloorR === "2"
      ? 2
      : functionSco.bendingToFloorR === "3"
      ? 1
      : functionSco.bendingToFloorR === "4"
      ? 0
      : 0;
  const koosbendingToFloorL =
    functionSco.bendingToFloorL === "0"
      ? 4
      : functionSco.bendingToFloorL === "1"
      ? 3
      : functionSco.bendingToFloorL === "2"
      ? 2
      : functionSco.bendingToFloorL === "3"
      ? 1
      : functionSco.bendingToFloorL === "4"
      ? 0
      : 0;
  const koosgetOutCarR =
    functionSco.getOutCarR === "0"
      ? 4
      : functionSco.getOutCarR === "1"
      ? 3
      : functionSco.getOutCarR === "2"
      ? 2
      : functionSco.getOutCarR === "3"
      ? 1
      : functionSco.getOutCarR === "4"
      ? 0
      : 0;
  const koosgetOutCarL =
    functionSco.getOutCarL === "0"
      ? 4
      : functionSco.getOutCarL === "1"
      ? 3
      : functionSco.getOutCarL === "2"
      ? 2
      : functionSco.getOutCarL === "3"
      ? 1
      : functionSco.getOutCarL === "4"
      ? 0
      : 0;
  const koosgetOutBedR =
    functionSco.getOutBedR === "0"
      ? 4
      : functionSco.getOutBedR === "1"
      ? 3
      : functionSco.getOutBedR === "2"
      ? 2
      : functionSco.getOutBedR === "3"
      ? 1
      : functionSco.getOutBedR === "4"
      ? 0
      : 0;
  const koosgetOutBedL =
    functionSco.getOutBedL === "0"
      ? 4
      : functionSco.getOutBedL === "1"
      ? 3
      : functionSco.getOutBedL === "2"
      ? 2
      : functionSco.getOutBedL === "3"
      ? 1
      : functionSco.getOutBedL === "4"
      ? 0
      : 0;
  const kooschangePositionLyingR =
    functionSco.changePositionLyingR === "0"
      ? 4
      : functionSco.changePositionLyingR === "1"
      ? 3
      : functionSco.changePositionLyingR === "2"
      ? 2
      : functionSco.changePositionLyingR === "3"
      ? 1
      : functionSco.changePositionLyingR === "4"
      ? 0
      : 0;
  const kooschangePositionLyingL =
    functionSco.changePositionLyingL === "0"
      ? 4
      : functionSco.changePositionLyingL === "1"
      ? 3
      : functionSco.changePositionLyingL === "2"
      ? 2
      : functionSco.changePositionLyingL === "3"
      ? 1
      : functionSco.changePositionLyingL === "4"
      ? 0
      : 0;
  const koosgettingBathR =
    functionSco.gettingBathR === "0"
      ? 4
      : functionSco.gettingBathR === "1"
      ? 3
      : functionSco.gettingBathR === "2"
      ? 2
      : functionSco.gettingBathR === "3"
      ? 1
      : functionSco.gettingBathR === "4"
      ? 0
      : 0;
  const koosgettingBathL =
    functionSco.gettingBathL === "0"
      ? 4
      : functionSco.gettingBathL === "1"
      ? 3
      : functionSco.gettingBathL === "2"
      ? 2
      : functionSco.gettingBathL === "3"
      ? 1
      : functionSco.gettingBathL === "4"
      ? 0
      : 0;
  const kooscarryHeavyBagsR =
    functionSco.carryHeavyBagsR === "0"
      ? 4
      : functionSco.carryHeavyBagsR === "1"
      ? 3
      : functionSco.carryHeavyBagsR === "2"
      ? 2
      : functionSco.carryHeavyBagsR === "3"
      ? 1
      : functionSco.carryHeavyBagsR === "4"
      ? 0
      : 0;
  const kooscarryHeavyBagsL =
    functionSco.carryHeavyBagsL === "0"
      ? 4
      : functionSco.carryHeavyBagsL === "1"
      ? 3
      : functionSco.carryHeavyBagsL === "2"
      ? 2
      : functionSco.carryHeavyBagsL === "3"
      ? 1
      : functionSco.carryHeavyBagsL === "4"
      ? 0
      : 0;

  const koosdoLightChoresR =
    functionSco.doLightChoresR === "0"
      ? 4
      : functionSco.doLightChoresR === "1"
      ? 3
      : functionSco.doLightChoresR === "2"
      ? 2
      : functionSco.doLightChoresR === "3"
      ? 1
      : functionSco.doLightChoresR === "4"
      ? 0
      : 0;
  const koosdoLightChoresL =
    functionSco.doLightChoresL === "0"
      ? 4
      : functionSco.doLightChoresL === "1"
      ? 3
      : functionSco.doLightChoresL === "2"
      ? 2
      : functionSco.doLightChoresL === "3"
      ? 1
      : functionSco.doLightChoresL === "4"
      ? 0
      : 0;

  //KOOS-C Sport/Play ==========================

  const kooskneelFrontR =
    functionSco.kneelFrontR === "0"
      ? 4
      : functionSco.kneelFrontR === "1"
      ? 3
      : functionSco.kneelFrontR === "2"
      ? 2
      : functionSco.kneelFrontR === "3"
      ? 1
      : functionSco.kneelFrontR === "4"
      ? 0
      : 0;

  const kooskneelFrontL =
    functionSco.kneelFrontL === "0"
      ? 4
      : functionSco.kneelFrontL === "1"
      ? 3
      : functionSco.kneelFrontL === "2"
      ? 2
      : functionSco.kneelFrontL === "3"
      ? 1
      : functionSco.kneelFrontL === "4"
      ? 0
      : 0;
  const kooskneelsquatBasketballR =
    functionSco.squatBasketballR === "0"
      ? 4
      : functionSco.squatBasketballR === "1"
      ? 3
      : functionSco.squatBasketballR === "2"
      ? 2
      : functionSco.squatBasketballR === "3"
      ? 1
      : functionSco.squatBasketballR === "4"
      ? 0
      : 0;
  const kooskneelsquatBasketballL =
    functionSco.squatBasketballL === "0"
      ? 4
      : functionSco.squatBasketballL === "1"
      ? 3
      : functionSco.squatBasketballL === "2"
      ? 2
      : functionSco.squatBasketballL === "3"
      ? 1
      : functionSco.squatBasketballL === "4"
      ? 0
      : 0;
  const koosPlaygoUpStairsR =
    functionSco.goUpStairsR === "0"
      ? 4
      : functionSco.goUpStairsR === "1"
      ? 3
      : functionSco.goUpStairsR === "2"
      ? 2
      : functionSco.goUpStairsR === "3"
      ? 1
      : functionSco.goUpStairsR === "4"
      ? 0
      : 0;
  const koosPlaygoUpStairsL =
    functionSco.goUpStairsL === "0"
      ? 4
      : functionSco.goUpStairsL === "1"
      ? 3
      : functionSco.goUpStairsL === "2"
      ? 2
      : functionSco.goUpStairsL === "3"
      ? 1
      : functionSco.goUpStairsL === "4"
      ? 0
      : 0;
  const koosPlayrunDurPlayR =
    functionSco.runDurPlayR === "0"
      ? 4
      : functionSco.runDurPlayR === "1"
      ? 3
      : functionSco.runDurPlayR === "2"
      ? 2
      : functionSco.runDurPlayR === "3"
      ? 1
      : functionSco.runDurPlayR === "4"
      ? 0
      : 0;
  const koosPlayrunDurPlayL =
    functionSco.runDurPlayL === "0"
      ? 4
      : functionSco.runDurPlayL === "1"
      ? 3
      : functionSco.runDurPlayL === "2"
      ? 2
      : functionSco.runDurPlayL === "3"
      ? 1
      : functionSco.runDurPlayL === "4"
      ? 0
      : 0;
  const koosPlayjumpDurPlayR =
    functionSco.jumpDurPlayR === "0"
      ? 4
      : functionSco.jumpDurPlayR === "1"
      ? 3
      : functionSco.jumpDurPlayR === "2"
      ? 2
      : functionSco.jumpDurPlayR === "3"
      ? 1
      : functionSco.jumpDurPlayR === "4"
      ? 0
      : 0;
  const koosPlayjumpDurPlayL =
    functionSco.jumpDurPlayL === "0"
      ? 4
      : functionSco.jumpDurPlayL === "1"
      ? 3
      : functionSco.jumpDurPlayL === "2"
      ? 2
      : functionSco.jumpDurPlayL === "3"
      ? 1
      : functionSco.jumpDurPlayL === "4"
      ? 0
      : 0;

  const koosPlaytwistDurPlayR =
    functionSco.twistDurPlayR === "0"
      ? 4
      : functionSco.twistDurPlayR === "1"
      ? 3
      : functionSco.twistDurPlayR === "2"
      ? 2
      : functionSco.twistDurPlayR === "3"
      ? 1
      : functionSco.twistDurPlayR === "4"
      ? 0
      : 0;
  const koosPlaytwistDurPlayL =
    functionSco.twistDurPlayL === "0"
      ? 4
      : functionSco.twistDurPlayL === "1"
      ? 3
      : functionSco.twistDurPlayL === "2"
      ? 2
      : functionSco.twistDurPlayL === "3"
      ? 1
      : functionSco.twistDurPlayL === "4"
      ? 0
      : 0;

  const koosPlaykeepBalWalkR =
    functionSco.keepBalWalkR === "0"
      ? 4
      : functionSco.keepBalWalkR === "1"
      ? 3
      : functionSco.keepBalWalkR === "2"
      ? 2
      : functionSco.keepBalWalkR === "3"
      ? 1
      : functionSco.keepBalWalkR === "4"
      ? 0
      : 0;
  const koosPlaykeepBalWalkL =
    functionSco.keepBalWalkL === "0"
      ? 4
      : functionSco.keepBalWalkL === "1"
      ? 3
      : functionSco.keepBalWalkL === "2"
      ? 2
      : functionSco.keepBalWalkL === "3"
      ? 1
      : functionSco.keepBalWalkL === "4"
      ? 0
      : 0;
  const koosPlayplaySportsR =
    functionSco.playSportsR === "0"
      ? 4
      : functionSco.playSportsR === "1"
      ? 3
      : functionSco.playSportsR === "2"
      ? 2
      : functionSco.playSportsR === "3"
      ? 1
      : functionSco.playSportsR === "4"
      ? 0
      : 0;
  const koosPlayplaySportsL =
    functionSco.playSportsL === "0"
      ? 4
      : functionSco.playSportsL === "1"
      ? 3
      : functionSco.playSportsL === "2"
      ? 2
      : functionSco.playSportsL === "3"
      ? 1
      : functionSco.playSportsL === "4"
      ? 0
      : 0;

  //KOOS-C QOL ===================

  const koocQOLgettingToSchoolR =
    functionSco.gettingToSchoolR === "0"
      ? 4
      : functionSco.gettingToSchoolR === "1"
      ? 3
      : functionSco.gettingToSchoolR === "2"
      ? 2
      : functionSco.gettingToSchoolR === "3"
      ? 1
      : functionSco.gettingToSchoolR === "4"
      ? 0
      : 0;

  const koocQOLgettingToSchoolL =
    functionSco.gettingToSchoolL === "0"
      ? 4
      : functionSco.gettingToSchoolL === "1"
      ? 3
      : functionSco.gettingToSchoolL === "2"
      ? 2
      : functionSco.gettingToSchoolL === "3"
      ? 1
      : functionSco.gettingToSchoolL === "4"
      ? 0
      : 0;

  const koocQOLdoThingsFriendsR =
    functionSco.doThingsFriendsR === "0"
      ? 4
      : functionSco.doThingsFriendsR === "1"
      ? 3
      : functionSco.doThingsFriendsR === "2"
      ? 2
      : functionSco.doThingsFriendsR === "3"
      ? 1
      : functionSco.doThingsFriendsR === "4"
      ? 0
      : 0;

  const koocQOLdoThingsFriendsL =
    functionSco.doThingsFriendsL === "0"
      ? 4
      : functionSco.doThingsFriendsL === "1"
      ? 3
      : functionSco.doThingsFriendsL === "2"
      ? 2
      : functionSco.doThingsFriendsL === "3"
      ? 1
      : functionSco.doThingsFriendsL === "4"
      ? 0
      : 0;

  const koocQOLhowOftenR =
    qolSco.howOftenR === "0"
      ? 4
      : qolSco.howOftenR === "1"
      ? 3
      : qolSco.howOftenR === "2"
      ? 2
      : qolSco.howOftenR === "3"
      ? 1
      : qolSco.howOftenR === "4"
      ? 0
      : 0;

  const koocQOLhowOftenL =
    qolSco.howOftenL === "0"
      ? 4
      : qolSco.howOftenL === "1"
      ? 3
      : qolSco.howOftenL === "2"
      ? 2
      : qolSco.howOftenL === "3"
      ? 1
      : qolSco.howOftenL === "4"
      ? 0
      : 0;

  const koocQOLhowKneeWorkR =
    qolSco.lifestyleR === "0"
      ? 4
      : qolSco.lifestyleR === "1"
      ? 3
      : qolSco.lifestyleR === "2"
      ? 2
      : qolSco.lifestyleR === "3"
      ? 1
      : qolSco.lifestyleR === "4"
      ? 0
      : 0;

  const koocQOLhowKneeWorkL =
    qolSco.lifestyleL === "0"
      ? 4
      : qolSco.lifestyleL === "1"
      ? 3
      : qolSco.lifestyleL === "2"
      ? 2
      : qolSco.lifestyleL === "3"
      ? 1
      : qolSco.lifestyleL === "4"
      ? 0
      : 0;
  const koocQOLHowMuchInjuredR =
    qolSco.HowMuchInjuredR === "0"
      ? 4
      : qolSco.HowMuchInjuredR === "1"
      ? 3
      : qolSco.HowMuchInjuredR === "2"
      ? 2
      : qolSco.HowMuchInjuredR === "3"
      ? 1
      : qolSco.HowMuchInjuredR === "4"
      ? 0
      : 0;
  const koocQOLHowMuchInjuredL =
    qolSco.HowMuchInjuredL === "0"
      ? 4
      : qolSco.HowMuchInjuredL === "1"
      ? 3
      : qolSco.HowMuchInjuredL === "2"
      ? 2
      : qolSco.HowMuchInjuredL === "3"
      ? 1
      : qolSco.HowMuchInjuredL === "4"
      ? 0
      : 0;

  const koocQOLdifficultyR =
    qolSco.difficultyR === "0"
      ? 4
      : qolSco.difficultyR === "1"
      ? 3
      : qolSco.difficultyR === "2"
      ? 2
      : qolSco.difficultyR === "3"
      ? 1
      : qolSco.difficultyR === "4"
      ? 0
      : 0;

  const koocQOLdifficultyL =
    qolSco.difficultyL === "0"
      ? 4
      : qolSco.difficultyL === "1"
      ? 3
      : qolSco.difficultyL === "2"
      ? 2
      : qolSco.difficultyL === "3"
      ? 1
      : qolSco.difficultyL === "4"
      ? 0
      : 0;

  // VAS =====================

  const vaspainHurtTodayR =
    painSco.painHurtTodayR === "0"
      ? 0
      : painSco.painHurtTodayR === "1"
      ? 1
      : painSco.painHurtTodayR === "2"
      ? 2
      : painSco.painHurtTodayR === "3"
      ? 3
      : painSco.painHurtTodayR === "4"
      ? 4
      : painSco.painHurtTodayR === "5"
      ? 5
      : painSco.painHurtTodayR === "6"
      ? 6
      : painSco.painHurtTodayR === "7"
      ? 7
      : painSco.painHurtTodayR === "8"
      ? 8
      : painSco.painHurtTodayR === "9"
      ? 9
      : painSco.painHurtTodayR === "10"
      ? 10
      : 0;
  const vaspainHurtTodayL =
    painSco.painHurtTodayL === "0"
      ? 0
      : painSco.painHurtTodayL === "1"
      ? 1
      : painSco.painHurtTodayL === "2"
      ? 2
      : painSco.painHurtTodayL === "3"
      ? 3
      : painSco.painHurtTodayL === "4"
      ? 4
      : painSco.painHurtTodayL === "5"
      ? 5
      : painSco.painHurtTodayL === "6"
      ? 6
      : painSco.painHurtTodayL === "7"
      ? 7
      : painSco.painHurtTodayL === "8"
      ? 8
      : painSco.painHurtTodayL === "9"
      ? 9
      : painSco.painHurtTodayL === "10"
      ? 10
      : 0;

  // SANE ===================
  const SanehowKneeWorkR = mapValueToPercentage(functionSco.howKneeWorkR);
  const SanehowKneeWorkL = mapValueToPercentage(functionSco.howKneeWorkL);

  //before

  const SanehowKneeWork_beforeR = mapValueToPercentage(
    functionSco.howKneeWork_beforeR
  );
  const SanehowKneeWork_beforeL = mapValueToPercentage(
    functionSco.howKneeWork_beforeL
  );

  // Norwich Score
  const MaxvalueNorwich = (norwichtitle) => {
    let array = [
      { title: "twisting", max: 5 },
      { title: "changeDirection", max: 7 },
      { title: "runningStraightline", max: 7 },
      { title: "walkingSlippery", max: 7 },
      { title: "runningSideways", max: 10 },
      { title: "hopping", max: 10 },
      { title: "jumping", max: 10 },
      { title: "runningOnEvenSurface", max: 10 },
      { title: "kneeling", max: 10 },
      { title: "squatting", max: 10 },
      { title: "walkingUnevenSurfaces", max: 15 },
      { title: "goingDownStairs", max: 10 },
      { title: "climbingStairs", max: 15 },
      { title: "steppingOverHigh", max: 15 },
      { title: "crossingWhenSitting", max: 15 },
      { title: "walkingEvenSurface", max: 22 },
      { title: "gettingIntoCar", max: 22 },
      { title: "supermarketAisle", max: 25 },
      { title: "turningYourShoulder", max: 25 },
    ];
    let value = array.filter((item) => item.title === norwichtitle)[0].max;
    return value;
  };
  let ConstvalueNorwich = 0;
  let maxtwistingvalue = MaxvalueNorwich("twisting");
  let maxchangeDirectionvalue = MaxvalueNorwich("changeDirection");
  let maxrunningStraightlinevalue = MaxvalueNorwich("runningStraightline");
  let maxwalkingSlipperyvalue = MaxvalueNorwich("walkingSlippery");
  let maxrunningSidewaysvalue = MaxvalueNorwich("runningSideways");
  let maxhoppingvalue = MaxvalueNorwich("hopping");
  let maxjumpingvalue = MaxvalueNorwich("jumping");
  let maxrunningOnEvenSurfacevalue = MaxvalueNorwich("runningOnEvenSurface");
  let maxkneelingvalue = MaxvalueNorwich("kneeling");
  let maxsquattingvalue = MaxvalueNorwich("squatting");
  let maxwalkingUnevenSurfacesvalue = MaxvalueNorwich("walkingUnevenSurfaces");
  let maxgoingDownStairsvalue = MaxvalueNorwich("goingDownStairs");
  let maxclimbingStairsvalue = MaxvalueNorwich("climbingStairs");
  let maxsteppingOverHighvalue = MaxvalueNorwich("steppingOverHigh");
  let maxcrossingWhenSittingvalue = MaxvalueNorwich("crossingWhenSitting");
  let maxwalkingEvenSurfacevalue = MaxvalueNorwich("walkingEvenSurface");
  let maxgettingIntoCarvalue = MaxvalueNorwich("gettingIntoCar");
  let maxsupermarketAislevalue = MaxvalueNorwich("supermarketAisle");
  let maxturningYourShouldervalue = MaxvalueNorwich("turningYourShoulder");

  let twcheck = Norwich.twcheck ? 0 : maxtwistingvalue;
  let directioncheck = Norwich.directioncheck ? 0 : maxchangeDirectionvalue;
  let straightcheck = Norwich.straightcheck ? 0 : maxrunningStraightlinevalue;
  let slipperycheck = Norwich.slipperycheck ? 0 : maxwalkingSlipperyvalue;
  let sidewayscheck = Norwich.sidewayscheck ? 0 : maxrunningSidewaysvalue;
  let Hoppingcheck = Norwich.Hoppingcheck ? 0 : maxhoppingvalue;
  let Jumpingcheck = Norwich.Jumpingcheck ? 0 : maxjumpingvalue;
  let scaleLabelcheck = Norwich.scaleLabelcheck
    ? 0
    : maxrunningOnEvenSurfacevalue;
  let downcheck = Norwich.downcheck ? 0 : maxgoingDownStairsvalue;
  let Squattingcheck = Norwich.Squattingcheck ? 0 : maxsquattingvalue;
  let Kneelingcheck = Norwich.Kneelingcheck ? 0 : maxkneelingvalue;
  let Surfacescheck = Norwich.Surfacescheck ? 0 : maxwalkingUnevenSurfacesvalue;
  let Climbingcheck = Norwich.Climbingcheck ? 0 : maxclimbingStairsvalue;
  let Steppingcheck = Norwich.Steppingcheck ? 0 : maxsteppingOverHighvalue;
  let Crossingcheck = Norwich.Crossingcheck ? 0 : maxcrossingWhenSittingvalue;
  let walkingEvencheck = Norwich.walkingEvencheck
    ? 0
    : maxwalkingEvenSurfacevalue;
  let IntoCarcheck = Norwich.IntoCarcheck ? 0 : maxgettingIntoCarvalue;
  let heavycheck = Norwich.heavycheck ? 0 : maxsupermarketAislevalue;
  let turningcheck = Norwich.turningcheck ? 0 : maxturningYourShouldervalue;
  const norwichTwistR =
    Norwich.twistingR === "0"
      ? 0
      : Norwich.twistingR === "1"
      ? 2
      : Norwich.twistingR === "2"
      ? 3
      : Norwich.twistingR === "3"
      ? 4
      : Norwich.twistingR === "4"
      ? 5
      : 0;

  // console.log("Norwich.twistingR ",Norwich.twistingR);
  // console.log("Norwich.twistingL ",Norwich.twistingL);

  const norwichTwistL =
    Norwich.twistingL === "0"
      ? 0
      : Norwich.twistingL === "1"
      ? 2
      : Norwich.twistingL === "2"
      ? 3
      : Norwich.twistingL === "3"
      ? 4
      : Norwich.twistingL === "4"
      ? 5
      : 0;

  const norwichChangeDirectionR =
    Norwich.changeDirectionR === "0"
      ? 0
      : Norwich.changeDirectionR === "1"
      ? 2
      : Norwich.changeDirectionR === "2"
      ? 3
      : Norwich.changeDirectionR === "3"
      ? 5
      : Norwich.changeDirectionR === "4"
      ? 7
      : 0;

  const norwichChangeDirectionL =
    Norwich.changeDirectionL === "0"
      ? 0
      : Norwich.changeDirectionL === "1"
      ? 2
      : Norwich.changeDirectionL === "2"
      ? 3
      : Norwich.changeDirectionL === "3"
      ? 5
      : Norwich.changeDirectionL === "4"
      ? 7
      : 0;

  const norwichRunningR =
    Norwich.runningStraightlineR === "0"
      ? 0
      : Norwich.runningStraightlineR === "1"
      ? 2
      : Norwich.runningStraightlineR === "2"
      ? 3
      : Norwich.runningStraightlineR === "3"
      ? 5
      : Norwich.runningStraightlineR === "4"
      ? 7
      : 0;

  const norwichRunningL =
    Norwich.runningStraightlineL === "0"
      ? 0
      : Norwich.runningStraightlineL === "1"
      ? 2
      : Norwich.runningStraightlineL === "2"
      ? 3
      : Norwich.runningStraightlineL === "3"
      ? 5
      : Norwich.runningStraightlineL === "4"
      ? 7
      : 0;

  const norwichWalkingR =
    Norwich.walkingSlipperyR === "0"
      ? 0
      : Norwich.walkingSlipperyR === "1"
      ? 2
      : Norwich.walkingSlipperyR === "2"
      ? 3
      : Norwich.walkingSlipperyR === "3"
      ? 5
      : Norwich.walkingSlipperyR === "4"
      ? 7
      : 0;

  const norwichWalkingL =
    Norwich.walkingSlipperyL === "0"
      ? 0
      : Norwich.walkingSlipperyL === "1"
      ? 2
      : Norwich.walkingSlipperyL === "2"
      ? 3
      : Norwich.walkingSlipperyL === "3"
      ? 5
      : Norwich.walkingSlipperyL === "4"
      ? 7
      : 0;

  const norwichRunningSideR =
    Norwich.runningSidewaysR === "0"
      ? 0
      : Norwich.runningSidewaysR === "1"
      ? 3
      : Norwich.runningSidewaysR === "2"
      ? 5
      : Norwich.runningSidewaysR === "3"
      ? 7
      : Norwich.runningSidewaysR === "4"
      ? 10
      : 0;

  const norwichRunningSideL =
    Norwich.runningSidewaysL === "0"
      ? 0
      : Norwich.runningSidewaysL === "1"
      ? 3
      : Norwich.runningSidewaysL === "2"
      ? 5
      : Norwich.runningSidewaysL === "3"
      ? 7
      : Norwich.runningSidewaysL === "4"
      ? 10
      : 0;

  const norwichHopingR =
    Norwich.hoppingR === "0"
      ? 0
      : Norwich.hoppingR === "1"
      ? 3
      : Norwich.hoppingR === "2"
      ? 5
      : Norwich.hoppingR === "3"
      ? 7
      : Norwich.hoppingR === "4"
      ? 10
      : 0;

  const norwichHopingL =
    Norwich.hoppingL === "0"
      ? 0
      : Norwich.hoppingL === "1"
      ? 3
      : Norwich.hoppingL === "2"
      ? 5
      : Norwich.hoppingL === "3"
      ? 7
      : Norwich.hoppingL === "4"
      ? 10
      : 0;

  const norwichJumpingR =
    Norwich.jumpingR === "0"
      ? 0
      : Norwich.jumpingR === "1"
      ? 3
      : Norwich.jumpingR === "2"
      ? 5
      : Norwich.jumpingR === "3"
      ? 7
      : Norwich.jumpingR === "4"
      ? 10
      : 0;

  const norwichJumpingL =
    Norwich.jumpingL === "0"
      ? 0
      : Norwich.jumpingL === "1"
      ? 3
      : Norwich.jumpingL === "2"
      ? 5
      : Norwich.jumpingL === "3"
      ? 7
      : Norwich.jumpingL === "4"
      ? 10
      : 0;

  const norwichRunningStraightR =
    Norwich.runningOnEvenSurfaceR === "0"
      ? 0
      : Norwich.runningOnEvenSurfaceR === "1"
      ? 3
      : Norwich.runningOnEvenSurfaceR === "2"
      ? 5
      : Norwich.runningOnEvenSurfaceR === "3"
      ? 7
      : Norwich.runningOnEvenSurfaceR === "4"
      ? 10
      : 0;

  const norwichRunningStraightL =
    Norwich.runningOnEvenSurfaceL === "0"
      ? 0
      : Norwich.runningOnEvenSurfaceL === "1"
      ? 3
      : Norwich.runningOnEvenSurfaceL === "2"
      ? 5
      : Norwich.runningOnEvenSurfaceL === "3"
      ? 7
      : Norwich.runningOnEvenSurfaceL === "4"
      ? 10
      : 0;

  const norwichGoingDownR =
    Norwich.goingDownStairsR === "0"
      ? 0
      : Norwich.goingDownStairsR === "1"
      ? 3
      : Norwich.goingDownStairsR === "2"
      ? 5
      : Norwich.goingDownStairsR === "3"
      ? 7
      : Norwich.goingDownStairsR === "4"
      ? 10
      : 0;

  const norwichGoingDownL =
    Norwich.goingDownStairsL === "0"
      ? 0
      : Norwich.goingDownStairsL === "1"
      ? 3
      : Norwich.goingDownStairsL === "2"
      ? 5
      : Norwich.goingDownStairsL === "3"
      ? 7
      : Norwich.goingDownStairsL === "4"
      ? 10
      : 0;

  const norwichSquattingR =
    Norwich.squattingR === "0"
      ? 0
      : Norwich.squattingR === "1"
      ? 3
      : Norwich.squattingR === "2"
      ? 5
      : Norwich.squattingR === "3"
      ? 7
      : Norwich.squattingR === "4"
      ? 10
      : 0;

  const norwichSquattingL =
    Norwich.squattingL === "0"
      ? 0
      : Norwich.squattingL === "1"
      ? 3
      : Norwich.squattingL === "2"
      ? 5
      : Norwich.squattingL === "3"
      ? 7
      : Norwich.squattingL === "4"
      ? 10
      : 0;

  const norwichKneelingR =
    Norwich.kneelingR === "0"
      ? 0
      : Norwich.kneelingR === "1"
      ? 3
      : Norwich.kneelingR === "2"
      ? 5
      : Norwich.kneelingR === "3"
      ? 7
      : Norwich.kneelingR === "4"
      ? 10
      : 0;

  const norwichKneelingL =
    Norwich.kneelingL === "0"
      ? 0
      : Norwich.kneelingL === "1"
      ? 3
      : Norwich.kneelingL === "2"
      ? 5
      : Norwich.kneelingL === "3"
      ? 7
      : Norwich.kneelingL === "4"
      ? 10
      : 0;

  const norwichWalkingUnevenR =
    Norwich.walkingUnevenSurfacesR === "0"
      ? 0
      : Norwich.walkingUnevenSurfacesR === "1"
      ? 5
      : Norwich.walkingUnevenSurfacesR === "2"
      ? 7
      : Norwich.walkingUnevenSurfacesR === "3"
      ? 10
      : Norwich.walkingUnevenSurfacesR === "4"
      ? 15
      : 0;

  const norwichWalkingUnevenL =
    Norwich.walkingUnevenSurfacesL === "0"
      ? 0
      : Norwich.walkingUnevenSurfacesL === "1"
      ? 5
      : Norwich.walkingUnevenSurfacesL === "2"
      ? 7
      : Norwich.walkingUnevenSurfacesL === "3"
      ? 10
      : Norwich.walkingUnevenSurfacesL === "4"
      ? 15
      : 0;

  const norwichClimbingR =
    Norwich.climbingStairsR === "0"
      ? 0
      : Norwich.climbingStairsR === "1"
      ? 5
      : Norwich.climbingStairsR === "2"
      ? 7
      : Norwich.climbingStairsR === "3"
      ? 10
      : Norwich.climbingStairsR === "4"
      ? 15
      : 0;

  const norwichClimbingL =
    Norwich.climbingStairsL === "0"
      ? 0
      : Norwich.climbingStairsL === "1"
      ? 5
      : Norwich.climbingStairsL === "2"
      ? 7
      : Norwich.climbingStairsL === "3"
      ? 10
      : Norwich.climbingStairsL === "4"
      ? 15
      : 0;

  const norwichSteppingR =
    Norwich.steppingOverHighR === "0"
      ? 0
      : Norwich.steppingOverHighR === "1"
      ? 5
      : Norwich.steppingOverHighR === "2"
      ? 7
      : Norwich.steppingOverHighR === "3"
      ? 10
      : Norwich.steppingOverHighR === "4"
      ? 15
      : 0;

  const norwichSteppingL =
    Norwich.steppingOverHighL === "0"
      ? 0
      : Norwich.steppingOverHighL === "1"
      ? 5
      : Norwich.steppingOverHighL === "2"
      ? 7
      : Norwich.steppingOverHighL === "3"
      ? 10
      : Norwich.steppingOverHighL === "4"
      ? 15
      : 0;

  const norwichCrossingR =
    Norwich.crossingWhenSittingR === "0"
      ? 0
      : Norwich.crossingWhenSittingR === "1"
      ? 5
      : Norwich.crossingWhenSittingR === "2"
      ? 7
      : Norwich.crossingWhenSittingR === "3"
      ? 10
      : Norwich.crossingWhenSittingR === "4"
      ? 15
      : 0;

  const norwichCrossingL =
    Norwich.crossingWhenSittingL === "0"
      ? 0
      : Norwich.crossingWhenSittingL === "1"
      ? 5
      : Norwich.crossingWhenSittingL === "2"
      ? 7
      : Norwich.crossingWhenSittingL === "3"
      ? 10
      : Norwich.crossingWhenSittingL === "4"
      ? 15
      : 0;

  const norwichEvenSurfaceR =
    Norwich.walkingEvenSurfaceR === "0"
      ? 0
      : Norwich.walkingEvenSurfaceR === "1"
      ? 7
      : Norwich.walkingEvenSurfaceR === "2"
      ? 10
      : Norwich.walkingEvenSurfaceR === "3"
      ? 15
      : Norwich.walkingEvenSurfaceR === "4"
      ? 22
      : 0;

  const norwichEvenSurfaceL =
    Norwich.walkingEvenSurfaceL === "0"
      ? 0
      : Norwich.walkingEvenSurfaceL === "1"
      ? 7
      : Norwich.walkingEvenSurfaceL === "2"
      ? 10
      : Norwich.walkingEvenSurfaceL === "3"
      ? 15
      : Norwich.walkingEvenSurfaceL === "4"
      ? 22
      : 0;

  const norwichGetinOutR =
    Norwich.gettingIntoCarR === "0"
      ? 0
      : Norwich.gettingIntoCarR === "1"
      ? 7
      : Norwich.gettingIntoCarR === "2"
      ? 10
      : Norwich.gettingIntoCarR === "3"
      ? 15
      : Norwich.gettingIntoCarR === "4"
      ? 22
      : 0;

  const norwichGetinOutL =
    Norwich.gettingIntoCarL === "0"
      ? 0
      : Norwich.gettingIntoCarL === "1"
      ? 7
      : Norwich.gettingIntoCarL === "2"
      ? 10
      : Norwich.gettingIntoCarL === "3"
      ? 15
      : Norwich.gettingIntoCarL === "4"
      ? 22
      : 0;

  const norwichTurningHeavyR =
    Norwich.supermarketAisleR === "0"
      ? 0
      : Norwich.supermarketAisleR === "1"
      ? 10
      : Norwich.supermarketAisleR === "2"
      ? 15
      : Norwich.supermarketAisleR === "3"
      ? 22
      : Norwich.supermarketAisleR === "4"
      ? 25
      : 0;

  const norwichTurningHeavyL =
    Norwich.supermarketAisleL === "0"
      ? 0
      : Norwich.supermarketAisleL === "1"
      ? 10
      : Norwich.supermarketAisleL === "2"
      ? 15
      : Norwich.supermarketAisleL === "3"
      ? 22
      : Norwich.supermarketAisleL === "4"
      ? 25
      : 0;

  const norwichTurningLookR =
    Norwich.turningYourShoulderR === "0"
      ? 0
      : Norwich.turningYourShoulderR === "1"
      ? 10
      : Norwich.turningYourShoulderR === "2"
      ? 15
      : Norwich.turningYourShoulderR === "3"
      ? 22
      : Norwich.turningYourShoulderR === "4"
      ? 25
      : 0;

  const norwichTurningLookL =
    Norwich.turningYourShoulderL === "0"
      ? 0
      : Norwich.turningYourShoulderL === "1"
      ? 10
      : Norwich.turningYourShoulderL === "2"
      ? 15
      : Norwich.turningYourShoulderL === "3"
      ? 22
      : Norwich.turningYourShoulderL === "4"
      ? 25
      : 0;

  const NorwichSumR = sumAll(
    norwichTwistR,
    norwichChangeDirectionR,
    norwichRunningR,
    norwichWalkingR,
    norwichRunningSideR,
    norwichHopingR,
    norwichJumpingR,
    norwichRunningStraightR,
    norwichGoingDownR,
    norwichSquattingR,
    norwichKneelingR,
    norwichWalkingUnevenR,
    norwichClimbingR,
    norwichSteppingR,
    norwichCrossingR,
    norwichEvenSurfaceR,
    norwichGetinOutR,
    norwichTurningHeavyR,
    norwichTurningLookR
  );

  const NorwichSumL = sumAll(
    norwichTwistL,
    norwichChangeDirectionL,
    norwichRunningL,
    norwichWalkingL,
    norwichRunningSideL,
    norwichHopingL,
    norwichJumpingL,
    norwichRunningStraightL,
    norwichGoingDownL,
    norwichSquattingL,
    norwichKneelingL,
    norwichWalkingUnevenL,
    norwichClimbingL,
    norwichSteppingL,
    norwichCrossingL,
    norwichEvenSurfaceL,
    norwichGetinOutL,
    norwichTurningHeavyL,
    norwichTurningLookL
  );

  const numberOfSelectionR = selectedQuestion(
    Norwich.twistingR,
    Norwich.changeDirectionR,
    Norwich.runningStraightlineR,
    Norwich.walkingSlipperyR,
    Norwich.runningSidewaysR,
    Norwich.hoppingR,
    Norwich.jumpingR,
    Norwich.runningOnEvenSurfaceR,
    Norwich.goingDownStairsR,
    Norwich.squattingR,
    Norwich.kneelingR,
    Norwich.walkingUnevenSurfacesR,
    Norwich.climbingStairsR,
    Norwich.steppingOverHighR,
    Norwich.crossingWhenSittingR,
    Norwich.walkingEvenSurfaceR,
    Norwich.gettingIntoCarR,
    Norwich.supermarketAisleR,
    Norwich.turningYourShoulderR
  );

  const numberOfSelectionL = selectedQuestion(
    Norwich.twistingL,
    Norwich.changeDirectionL,
    Norwich.runningStraightlineL,
    Norwich.walkingSlipperyL,
    Norwich.runningSidewaysL,
    Norwich.hoppingL,
    Norwich.jumpingL,
    Norwich.runningOnEvenSurfaceL,
    Norwich.goingDownStairsL,
    Norwich.squattingL,
    Norwich.kneelingL,
    Norwich.walkingUnevenSurfacesL,
    Norwich.climbingStairsL,
    Norwich.steppingOverHighL,
    Norwich.crossingWhenSittingL,
    Norwich.walkingEvenSurfaceL,
    Norwich.gettingIntoCarL,
    Norwich.supermarketAisleL,
    Norwich.turningYourShoulderL
  );

  // Norwich End

  // ============== END ==================

  // PEDI IKDC

  let IKDCR = sumAll(
    pediapainPastFourWeeksR,
    pediapainHurtTodayR,
    pediakneeGettingStuckR,
    pediahandleStillMoveR,
    pediaswollenKneeR,
    pediawithoutHurtLotR,
    pediawithoutPuffyR,
    pediacantHoldR,
    pediamostOfTimeR,
    pediagoUpStairsR,
    pediagoDownStairsR,
    pediakneelFrontR,
    pediabendingKneeR,
    pediasquatBasketballR,
    pediasitOnChairWithKneeBentR,
    pediagetUpFromChairR,
    pediarunDurPlayR,
    pediajumpDurPlayR,
    pediastartAndStopMoveR,
    pediahowKneeWorkR
  );

  let IKDCL = sumAll(
    pediapainPastFourWeeksL,
    pediapainHurtTodayL,
    pediakneeGettingStuckL,
    pediahandleStillMoveL,
    pediaswollenKneeL,
    pediawithoutHurtLotL,
    pediawithoutPuffyL,
    pediacantHoldL,
    pediamostOfTimeL,
    pediagoUpStairsL,
    pediagoDownStairsL,
    pediakneelFrontL,
    pediabendingKneeL,
    pediasquatBasketballL,
    pediasitOnChairWithKneeBentL,
    pediagetUpFromChairL,
    pediarunDurPlayL,
    pediajumpDurPlayL,
    pediastartAndStopMoveL,
    pediahowKneeWorkL
  );
  const IKDCTR = ((IKDCR / 92) * 100).toFixed();
  const IKDCTL = ((IKDCL / 92) * 100).toFixed();

  //  Pain Subscale

  let painSubScaleR = sumAll(
    painPastFourWeeksR,
    paintwistingR,
    painstraighteningR,
    painfullyBendKneeR,
    painwalkingUpStairsR,
    painwalkingDownStairsR,
    painsittingKneeBentR,
    painsittingKneeBentUprightR
  );

  let painSubScaleL = sumAll(
    painPastFourWeeksL,
    paintwistingL,
    painstraighteningL,
    painfullyBendKneeL,
    painwalkingUpStairsL,
    painwalkingDownStairsL,
    painsittingKneeBentL,
    painsittingKneeBentUprightL
  );

  const painSubScaleTR = ((painSubScaleR / 32) * 100).toFixed();
  const painSubScaleTL = ((painSubScaleL / 32) * 100).toFixed();

  // Symptoms Subscale

  let symptomsSubscaleR = sumAll(
    symptomkneeMakingNoiseR,
    symptomkneeGettingStuckR,
    symptompuffyKneeR,
    symptomstraighteningR,
    symptombendingKneeR,
    symptomTwistDurPlayInMorningtR,
    symptommoveDayAfterR
  );

  let symptomsSubscaleL = sumAll(
    symptomkneeMakingNoiseL,
    symptomkneeGettingStuckL,
    symptompuffyKneeL,
    symptomstraighteningL,
    symptombendingKneeL,
    symptomTwistDurPlayInMorningtL,
    symptommoveDayAfterL
  );
  const symptomsSubscaleTR = ((symptomsSubscaleR / 28) * 100).toFixed();
  const symptomsSubscaleTL = ((symptomsSubscaleL / 28) * 100).toFixed();

  // KOOS-C ADL
  let koosAdlR = sumAll(
    koosgoUpStairsR,
    koosgoDownStairsR,
    koossitOnChairWithKneeBentR,
    koosgetUpFromChairR,
    koosbendingToFloorR,
    koosgetOutCarR,
    koosgetOutBedR,
    kooschangePositionLyingR,
    koosgettingBathR,
    kooscarryHeavyBagsR,
    koosdoLightChoresR
  );

  let koosAdlL = sumAll(
    koosgoUpStairsL,
    koosgoDownStairsL,
    koossitOnChairWithKneeBentL,
    koosgetUpFromChairL,
    koosbendingToFloorL,
    koosgetOutCarL,
    koosgetOutBedL,
    kooschangePositionLyingL,
    koosgettingBathL,
    kooscarryHeavyBagsL,
    koosdoLightChoresL
  );

  const koosAdlTR = ((koosAdlR / 44) * 100).toFixed();
  const koosAdlTL = ((koosAdlL / 44) * 100).toFixed();

  //KOOS-C Sport/Play

  let koosPlayR = sumAll(
    kooskneelFrontR,
    kooskneelsquatBasketballR,
    koosPlayrunDurPlayR,
    koosPlayjumpDurPlayR,
    koosPlaytwistDurPlayR,
    koosPlaykeepBalWalkR,
    koosPlayplaySportsR
  );

  let koosPlayL = sumAll(
    kooskneelFrontL,
    kooskneelsquatBasketballL,
    koosPlayrunDurPlayL,
    koosPlayjumpDurPlayL,
    koosPlaytwistDurPlayL,
    koosPlaykeepBalWalkL,
    koosPlayplaySportsL
  );

  const koosPlayTR = ((koosPlayR / 28) * 100).toFixed();
  const koosPlayTL = ((koosPlayL / 28) * 100).toFixed();

  // KOOS-C QOL

  let koosQOLR = sumAll(
    koocQOLgettingToSchoolR,
    koocQOLdoThingsFriendsR,
    koocQOLhowOftenR,
    koocQOLhowKneeWorkR,
    koocQOLHowMuchInjuredR,
    koocQOLdifficultyR
  );

  let koosQOLL = sumAll(
    koocQOLgettingToSchoolL,
    koocQOLdoThingsFriendsL,
    koocQOLhowOftenL,
    koocQOLhowKneeWorkL,
    koocQOLHowMuchInjuredL,
    koocQOLdifficultyL
  );

  // console.log("NorwichSumR ",NorwichSumR);
  // console.log("NorwichSumL ",NorwichSumL);

  const koosQOLTR = ((koosQOLR / 24) * 100).toFixed();
  const koosQOLTL = ((koosQOLL / 24) * 100).toFixed();

  // console.log(koocQOLgettingToSchoolR,
  //   koocQOLdoThingsFriendsR,
  //   koocQOLhowOftenR,
  //   koocQOLhowKneeWorkR,
  //   koocQOLHowMuchInjuredR,
  //   koocQOLdifficultyR,"=========",koosQOLTR,"right");

  // console.log(koocQOLgettingToSchoolL,
  //   koocQOLdoThingsFriendsL,
  //   koocQOLhowOftenL,
  //   koocQOLhowKneeWorkL,
  //   koocQOLHowMuchInjuredL,
  //   koocQOLdifficultyL,"===========",koosQOLTL,"left");

  // VAS

  let vasR = parseInt(vaspainHurtTodayR);
  let vasL = parseInt(vaspainHurtTodayL);

  // SANE

  let saneR = parseInt(SanehowKneeWorkR);
  let saneL = parseInt(SanehowKneeWorkL);

  let sane_beforeR = parseInt(SanehowKneeWork_beforeR);
  let sane_beforeL = parseInt(SanehowKneeWork_beforeL);

  //SAPS_OVERALL
  const SAPS_OVERALLR =
    satisScore.overallR === "1"
      ? 25
      : satisScore.overallR === "2"
      ? 50
      : satisScore.overallR === "3"
      ? 75
      : satisScore.overallR === "4"
      ? 100
      : 0;

  const SAPS_OVERALLL =
    satisScore.overallL === "1"
      ? 25
      : satisScore.overallL === "2"
      ? 50
      : satisScore.overallL === "3"
      ? 75
      : satisScore.overallL === "4"
      ? 100
      : 0;

  //SAPS_Pain
  const SAPS_PAINR =
    satisScore.improvingPainR === "1"
      ? 25
      : satisScore.improvingPainR === "2"
      ? 50
      : satisScore.improvingPainR === "3"
      ? 75
      : satisScore.improvingPainR === "4"
      ? 100
      : 0;

  const SAPS_PAINL =
    satisScore.improvingPainL === "1"
      ? 25
      : satisScore.improvingPainL === "2"
      ? 50
      : satisScore.improvingPainL === "3"
      ? 75
      : satisScore.improvingPainL === "4"
      ? 100
      : 0;

  //SAPS_Work

  const SAPS_WORKR =
    satisScore.improvingHomeR === "1"
      ? 25
      : satisScore.improvingHomeR === "2"
      ? 50
      : satisScore.improvingHomeR === "3"
      ? 75
      : satisScore.improvingHomeR === "4"
      ? 100
      : 0;

  const SAPS_WORKL =
    satisScore.improvingHomeL === "1"
      ? 25
      : satisScore.improvingHomeL === "2"
      ? 50
      : satisScore.improvingHomeL === "3"
      ? 75
      : satisScore.improvingHomeL === "4"
      ? 100
      : 0;

  //SAPS_Recreation
  const SAPS_RECREATIONR =
    satisScore.improvingActivitiesR === "1"
      ? 25
      : satisScore.improvingActivitiesR === "2"
      ? 50
      : satisScore.improvingActivitiesR === "3"
      ? 75
      : satisScore.improvingActivitiesR === "4"
      ? 100
      : 0;

  const SAPS_RECREATIONL =
    satisScore.improvingActivitiesL === "1"
      ? 25
      : satisScore.improvingActivitiesL === "2"
      ? 50
      : satisScore.improvingActivitiesL === "3"
      ? 75
      : satisScore.improvingActivitiesL === "4"
      ? 100
      : 0;

  // Norwich
  const norwichScoreR = Math.round(
    (NorwichSumR /
      (ConstvalueNorwich +
        twcheck +
        directioncheck +
        straightcheck +
        slipperycheck +
        sidewayscheck +
        Hoppingcheck +
        Jumpingcheck +
        scaleLabelcheck +
        downcheck +
        Squattingcheck +
        Kneelingcheck +
        Surfacescheck +
        Climbingcheck +
        Steppingcheck +
        Crossingcheck +
        walkingEvencheck +
        IntoCarcheck +
        heavycheck +
        turningcheck)) *
      100
  );

  const norwichScoreL = Math.round(
    (NorwichSumL /
      (ConstvalueNorwich +
        twcheck +
        directioncheck +
        straightcheck +
        slipperycheck +
        sidewayscheck +
        Hoppingcheck +
        Jumpingcheck +
        scaleLabelcheck +
        downcheck +
        Squattingcheck +
        Kneelingcheck +
        Surfacescheck +
        Climbingcheck +
        Steppingcheck +
        Crossingcheck +
        walkingEvencheck +
        IntoCarcheck +
        heavycheck +
        turningcheck)) *
      100
  );
  // const norwichScoreL = Math.round((NorwichSumL/25)*100);

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const json = useSelector((state) => state.pediaKnee);
  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (isdoctorform === 1) {
          setDisplayConfirmationModal(true);
          // window.location.href =
          //   process.env.REACT_APP_DOCTOR_URL 
          //   "patient-view/" +
          //   window.btoa(patientID);
        } else {
          navigate("/pedia-knee-result/" + rId);
        }
      });
  };
  const [submit, setSubmit] = useState(false);
  const continueRedirect = (e) => {
    // updateDate();
    dispatch(
      updatePediaTotalScores([
        {
          IKDCTR,
          IKDCTL,
          painSubScaleTR,
          painSubScaleTL,
          symptomsSubscaleTR,
          symptomsSubscaleTL,
          koosAdlTR,
          koosAdlTL,
          koosPlayTR,
          koosPlayTL,
          koosQOLTR,
          koosQOLTL,
          vasR,
          vasL,
          saneR,
          saneL,
          sane_beforeR,
          sane_beforeL,

          SAPS_OVERALLR: SAPS_OVERALLR,
          SAPS_OVERALLL: SAPS_OVERALLL,
          SAPS_PAINR: SAPS_PAINR,
          SAPS_PAINL: SAPS_PAINL,
          SAPS_WORKR: SAPS_WORKR,
          SAPS_WORKL: SAPS_WORKL,
          SAPS_RECREATIONR: SAPS_RECREATIONR,
          SAPS_RECREATIONL: SAPS_RECREATIONL,

          norwichScoreR,
          norwichScoreL,
        },
      ])
    );

    setSubmit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (submit) {
      updateDate();
    }
  }, [json]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="complete_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <NameCustom />
              <h4 className="ph_title f_40 ">{`${translation.patient_pedia_knee_complete}`}</h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                  <p className="thanks_txt">
                    {translation.patient_pedia_knee_complete_text1}
                    <br /> {translation.patient_pedia_knee_complete_text2}
                  </p>
                  <Button
                    onClick={continueRedirect}
                    value={translation.submit}
                    buttonStyle="btn_fill cmp_submit_btn"
                  />
                  <span className="answer_txt">
                    {translation.patient_pedia_knee_complete_text3}
                  </span>
                </div>
              </div>

              <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
                  {initialAssesment == 0 ? (
                    <Link
                      to={"/pedia-knee-satisfaction/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  ) : (
                    <Link
                      to={"/pedia-knee-quality-of-life/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SubmitModel showModal={displayConfirmationModal}  patientID={window.btoa(patientID)} />
    </>
  );
}

export default PediaKneeComplete;

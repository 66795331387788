import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import useAxios from "../../../../axiosinstance";
import RadioButton from "../../../custom/radio-button/RadioButton";
import {
  updateHipfunctionFieldScore,
  updateHIPfunctionScores,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHipScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import useTranslation from "../../../customHooks/translations";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HipFunction() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosInstance = useAxios();
  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  const functionScore = useSelector(
    (state) => state.hipScoreReducer.functionScore[0]
  );
  // console.log(functionScore)
  const json = useSelector((state) => state.hipScoreReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [desstairsR, setDesStairsR] = useState("0");
  const [desstairsL, setDesStairsL] = useState("0");
  const [ascstairsR, setAscStairsR] = useState("0");
  const [ascstairsL, setAscStairsL] = useState("0");
  const [sittingR, setSittingR] = useState("0");
  const [sittingL, setSittingL] = useState("0");
  const [risingSittingR, setRisingSittingR] = useState("0");
  const [risingSittingL, setRisingSittingL] = useState("0");
  const [floorR, setFloorR] = useState("0");
  const [floorL, setFloorL] = useState("0");
  const [standingR, setStandingR] = useState("0");
  const [standingL, setStandingL] = useState("0");
  const [standingMinR, setStandingMinR] = useState("0");
  const [standingMinL, setStandingMinL] = useState("0");
  const [spreadingR, setSpreadingR] = useState("0");
  const [spreadingL, setSpreadingL] = useState("0");
  const [strideR, setStrideR] = useState("0");
  const [strideL, setStrideL] = useState("0");
  const [funBendlinR, setBendingR] = useState("0");
  const [funBendlinL, setBendingL] = useState("0");
  const [walkingR, setWalkingR] = useState("0");
  const [walkingL, setWalkingL] = useState("0");
  const [walkUnevenR, setWalkUnevenR] = useState("0");
  const [walkUnevenL, setWalkUnevenL] = useState("0");
  const [walkLongR, setWalkLongR] = useState("0");
  const [walkLongL, setWalkLongL] = useState("0");
  const [walkExeR, setWalkExeR] = useState("0");
  const [walkExeL, setWalkExeL] = useState("0");
  const [walkSteepR, setWalkSteepR] = useState("0");
  const [walkSteepL, setWalkSteepL] = useState("0");
  const [walkHillsR, setWalkHillsR] = useState("0");
  const [walkHillsL, setWalkHillsL] = useState("0");
  const [walkInitR, setWalkInitR] = useState("0");
  const [walkInitL, setWalkInitL] = useState("0");
  const [walkAppTenR, setWalkAppTenR] = useState("0");
  const [walkAppTenL, setWalkAppTenL] = useState("0");
  const [walkFiftR, setWalkFiftR] = useState("0");
  const [walkFiftL, setWalkFiftL] = useState("0");
  const [steppingR, setSteppingR] = useState("0");
  const [steppingL, setSteppingL] = useState("0");
  const [publicTransR, setPublicTransR] = useState("0");
  const [publicTransL, setPublicTransL] = useState("0");
  const [goingShopR, setGoingShopR] = useState("0");
  const [goingShopL, setGoingShopL] = useState("0");
  const [puttingR, setPuttingR] = useState("0");
  const [puttingL, setPuttingL] = useState("0");
  const [takingR, setTakingR] = useState("0");
  const [takingL, setTakingL] = useState("0");
  const [lyingR, setLyingR] = useState("0");
  const [lyingL, setLyingL] = useState("0");
  const [rollingR, setRollingR] = useState("0");
  const [rollingL, setRollingL] = useState("0");
  const [risingR, setRisingR] = useState("0");
  const [risingL, setRisingL] = useState("0");

  const [getbathR, setGetBathR] = useState("0");
  const [getbathL, setGetBathL] = useState("0");

  const [gettoiletR, setGetToiletR] = useState("0");
  const [gettoiletL, setGetToiletL] = useState("0");

  const [washingR, setWashingR] = useState("0");
  const [washingL, setWashingL] = useState("0");

  const [lightR, setLightR] = useState("0");
  const [lightL, setLightL] = useState("0");

  const [heavyR, setHeavyR] = useState("0");
  const [heavyL, setHeavyL] = useState("0");

  const [lightDomesticR, setLightDomesticR] = useState("0");
  const [lightDomesticL, setLightDomesticL] = useState("0");

  const [pickingR, setPickingR] = useState("0");
  const [pickingL, setPickingL] = useState("0");

  const [squattingR, setSquattingR] = useState("0");
  const [squattingL, setSquattingL] = useState("0");

  const [joggingR, setJoggingR] = useState("0");
  const [joggingL, setJoggingL] = useState("0");

  const [runningR, setRunningR] = useState("0");
  const [runningL, setRunningL] = useState("0");

  const [runningOneR, setRunningOneR] = useState("0");
  const [runningOneL, setRunningOneL] = useState("0");

  const [twistingR, setTwistingR] = useState("0");
  const [twistingL, setTwistingL] = useState("0");

  const [jumpingR, setJumpingR] = useState("0");
  const [jumpingL, setJumpingL] = useState("0");

  const [landingR, setLandingR] = useState("0");
  const [landingL, setLandingL] = useState("0");

  const [swingingR, setSwingingR] = useState("0");
  const [swingingL, setSwingingL] = useState("0");

  const [startingR, setStartingR] = useState("0");
  const [startingL, setStartingL] = useState("0");

  const [cuttingR, setCuttingR] = useState("0");
  const [cuttingL, setCuttingL] = useState("0");

  const [sexualR, setSexualR] = useState("0");
  const [sexualL, setSexualL] = useState("0");

  const [sportsR, setSportsR] = useState("0");
  const [sportsL, setSportsL] = useState("0");

  const [lowR, setLowR] = useState("0");
  const [lowL, setLowL] = useState("0");

  const [recreationalR, setRecreationalR] = useState("0");
  const [recreationalL, setRecreationalL] = useState("0");

  const [fastWalkR, setFastWalkR] = useState("0");
  const [fastWalkL, setFastWalkL] = useState("0");

  const [abilityPerformR, setAbilityPerformR] = useState("0");
  const [abilityPerformL, setAbilityPerformL] = useState("0");

  const [abilityPartR, setAbilityPartR] = useState("0");
  const [abilityPartL, setAbilityPartL] = useState("0");

  const [hipPer, setHipPer] = useState("100");
  const [hipPerL, setHipPerL] = useState("100");

  const navigate = useNavigate();

  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);

  const [select1, setSelect1] = useState(false);
  const [select2, setSelect2] = useState(false);

  const [specifyHighestActivityL, setspecifyHighestActivityL] = useState("0");
  const [specifyHighestActivityR, setspecifyHighestActivityR] = useState("0");

  const [evalutedValue, setEvalutedValue] = useState(0);

  const [initialAssesment, setInitialAssesment] = useState(0);

  const [highestActivity, setHighestActivity] = useState("0");
  const [highestActivityL, setHighestActivityL] = useState("0");

  const [sports, setSports] = useState("");
  const [currsports, currSetSports] = useState("");

  useEffect(() => {
    setDesStairsR(functionScore.desstairsR);
    setDesStairsL(functionScore.desstairsL);
    setAscStairsR(functionScore.ascstairsR);
    setAscStairsL(functionScore.ascstairsL);
    setSittingR(functionScore.sittingR);
    setSittingL(functionScore.sittingL);
    setRisingSittingR(functionScore.risingSittingR);
    setRisingSittingL(functionScore.risingSittingL);
    setFloorR(functionScore.floorR);
    setFloorL(functionScore.floorL);
    setStandingR(functionScore.standingR);
    setStandingL(functionScore.standingL);
    setStandingMinR(functionScore.standingMinR);
    setStandingMinL(functionScore.standingMinL);
    setSpreadingR(functionScore.spreadingR);
    setSpreadingL(functionScore.spreadingL);
    setStrideR(functionScore.strideR);
    setStrideL(functionScore.strideL);
    setBendingR(functionScore.funBendlinR);
    setBendingL(functionScore.funBendlinL);
    setWalkingR(functionScore.walkingR);
    setWalkingL(functionScore.walkingL);
    setWalkUnevenR(functionScore.walkUnevenR);
    setWalkUnevenL(functionScore.walkUnevenL);
    setWalkLongR(functionScore.walkLongR);
    setWalkLongL(functionScore.walkLongL);
    setWalkExeR(functionScore.walkExeR);
    setWalkExeL(functionScore.walkExeL);
    setWalkSteepR(functionScore.walkSteepR);
    setWalkSteepL(functionScore.walkSteepL);
    setWalkHillsR(functionScore.walkHillsR);
    setWalkHillsL(functionScore.walkHillsL);
    setWalkInitR(functionScore.walkInitR);
    setWalkInitL(functionScore.walkInitL);
    setWalkAppTenR(functionScore.walkAppTenR);
    setWalkAppTenL(functionScore.walkAppTenL);
    setWalkFiftR(functionScore.walkFiftR);
    setWalkFiftL(functionScore.walkFiftL);
    setSteppingR(functionScore.steppingR);
    setSteppingL(functionScore.steppingL);
    setPublicTransR(functionScore.publicTransR);
    setPublicTransL(functionScore.publicTransL);
    setGoingShopR(functionScore.goingShopR);
    setGoingShopL(functionScore.goingShopL);
    setPuttingR(functionScore.puttingR);
    setPuttingL(functionScore.puttingL);
    setTakingR(functionScore.takingR);
    setTakingL(functionScore.takingL);
    setLyingR(functionScore.lyingR);
    setLyingL(functionScore.lyingL);
    setRollingR(functionScore.rollingR);
    setRollingL(functionScore.rollingL);
    setRisingR(functionScore.risingR);
    setRisingL(functionScore.risingL);
    setGetBathR(functionScore.getbathR);
    setGetBathL(functionScore.getbathL);
    setGetToiletR(functionScore.gettoiletR);
    setGetToiletL(functionScore.gettoiletL);
    setWashingR(functionScore.washingR);
    setWashingL(functionScore.washingL);
    setLightR(functionScore.lightR);
    setLightL(functionScore.lightL);
    setHeavyR(functionScore.heavyR);
    setHeavyL(functionScore.heavyL);
    setLightDomesticR(functionScore.lightDomesticR);
    setLightDomesticL(functionScore.lightDomesticL);
    setPickingR(functionScore.pickingR);
    setPickingL(functionScore.pickingL);
    setSquattingR(functionScore.squattingR);
    setSquattingL(functionScore.squattingL);
    setJoggingR(functionScore.joggingR);
    setJoggingL(functionScore.joggingL);
    setRunningR(functionScore.runningR);
    setRunningL(functionScore.runningL);
    setRunningOneR(functionScore.runningOneR);
    setRunningOneL(functionScore.runningOneL);
    setTwistingR(functionScore.twistingR);
    setTwistingL(functionScore.twistingL);
    setJumpingR(functionScore.jumpingR);
    setJumpingL(functionScore.jumpingL);
    setLandingR(functionScore.landingR);
    setLandingL(functionScore.landingL);
    setSwingingR(functionScore.swingingR);
    setSwingingL(functionScore.swingingL);
    setStartingR(functionScore.startingR);
    setStartingL(functionScore.startingL);
    setCuttingR(functionScore.cuttingR);
    setCuttingL(functionScore.cuttingL);
    setSexualR(functionScore.sexualR);
    setSexualL(functionScore.sexualL);
    setSportsR(functionScore.sportsR);
    setSportsL(functionScore.sportsL);
    setLowR(functionScore.lowR);
    setLowL(functionScore.lowL);
    setRecreationalR(functionScore.recreationalR);
    setRecreationalL(functionScore.recreationalL);
    setFastWalkR(functionScore.fastWalkR);
    setFastWalkL(functionScore.fastWalkL);
    setAbilityPerformR(functionScore.abilityPerformR);
    setAbilityPerformL(functionScore.abilityPerformL);
    setAbilityPartR(functionScore.abilityPartR);
    setAbilityPartL(functionScore.abilityPartL);
    setHipPer(functionScore.hipPer);
    setHipPerL(functionScore.hipPerL);
    setSelect1(functionScore.select1);
    setSelect2(functionScore.select2);
    setspecifyHighestActivityL(functionScore.specifyHighestActivityL);
    setspecifyHighestActivityR(functionScore.specifyHighestActivityR);
    setHighestActivity(functionScore.highestActivity);
    setHighestActivityL(functionScore.highestActivityL);
    setSports(functionScore.sports);
    currSetSports(functionScore.currsports);
  }, [functionScore]);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setEvalutedValue(res.data.data2[0].evaluted_value);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [functionScore, updateTotal]);

  const handleDesStairsR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "desstairsR",
        value: e,
      })
    );
    setDesStairsR(e);
  };
  const handleDesStairsL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "desstairsL",
        value: e,
      })
    );
    setDesStairsL(e);
  };
  const handleAscStairsR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "ascstairsR",
        value: e,
      })
    );
    setAscStairsR(e);
  };
  const handleAscStairsL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "ascstairsL",
        value: e,
      })
    );
    setAscStairsL(e);
  };
  const handleSittingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sittingR",
        value: e,
      })
    );
    setSittingR(e);
  };
  const handleSittingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sittingL",
        value: e,
      })
    );
    setSittingL(e);
  };
  const handleRisingSittingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "risingSittingR",
        value: e,
      })
    );
    setRisingSittingR(e);
  };
  const handleRisingSittingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "risingSittingL",
        value: e,
      })
    );
    setRisingSittingL(e);
  };
  const handleFloorR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "floorR",
        value: e,
      })
    );
    setFloorR(e);
  };
  const handleFloorL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "floorL",
        value: e,
      })
    );
    setFloorL(e);
  };
  const handleStandingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "standingR",
        value: e,
      })
    );
    setStandingR(e);
  };
  const handleStandingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "standingL",
        value: e,
      })
    );
    setStandingL(e);
  };
  const handleStandingMinR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "standingMinR",
        value: e,
      })
    );
    setStandingMinR(e);
  };
  const handleStandingMinL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "standingMinL",
        value: e,
      })
    );
    setStandingMinL(e);
  };
  const handleSpreadingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "spreadingR",
        value: e,
      })
    );
    setSpreadingR(e);
  };
  const handleSpreadingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "spreadingL",
        value: e,
      })
    );
    setSpreadingL(e);
  };
  const handleStrideR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "strideR",
        value: e,
      })
    );
    setStrideR(e);
  };
  const handleStrideL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "strideL",
        value: e,
      })
    );
    setStrideL(e);
  };
  const handleBendingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "funBendlinR",
        value: e,
      })
    );
    setBendingR(e);
  };
  const handleBendingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "funBendlinL",
        value: e,
      })
    );
    setBendingL(e);
  };
  const handleWalkingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkingR",
        value: e,
      })
    );
    setWalkingR(e);
  };
  const handleWalkingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkingL",
        value: e,
      })
    );
    setWalkingL(e);
  };
  const handleWalkUnevenR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkUnevenR",
        value: e,
      })
    );
    setWalkUnevenR(e);
  };
  const handleWalkUnevenL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkUnevenL",
        value: e,
      })
    );
    setWalkUnevenL(e);
  };
  const handleWalkLongR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkLongR",
        value: e,
      })
    );
    setWalkLongR(e);
  };
  const handleWalkLongL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkLongL",
        value: e,
      })
    );
    setWalkLongL(e);
  };
  const handleWalkExeR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkExeR",
        value: e,
      })
    );
    setWalkExeR(e);
  };
  const handleWalkExeL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkExeL",
        value: e,
      })
    );
    setWalkExeL(e);
  };
  const handleWalkSteepR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkSteepR",
        value: e,
      })
    );
    setWalkSteepR(e);
  };
  const handleWalkSteepL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkSteepL",
        value: e,
      })
    );
    setWalkSteepL(e);
  };
  const handleWalkHillsR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkHillsR",
        value: e,
      })
    );
    setWalkHillsR(e);
  };
  const handleWalkHillsL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkHillsL",
        value: e,
      })
    );
    setWalkHillsL(e);
  };
  const handleWalkInitR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkInitR",
        value: e,
      })
    );
    setWalkInitR(e);
  };
  const handleWalkInitL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkInitL",
        value: e,
      })
    );
    setWalkInitL(e);
  };
  const handleWalkAppTenR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkAppTenR",
        value: e,
      })
    );
    setWalkAppTenR(e);
  };
  const handleWalkAppTenL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkAppTenL",
        value: e,
      })
    );
    setWalkAppTenL(e);
  };
  const handleWalkFiftR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkFiftR",
        value: e,
      })
    );
    setWalkFiftR(e);
  };
  const handleWalkFiftL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "walkFiftL",
        value: e,
      })
    );
    setWalkFiftL(e);
  };
  const handleSteppingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "steppingR",
        value: e,
      })
    );
    setSteppingR(e);
  };
  const handleSteppingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "steppingL",
        value: e,
      })
    );
    setSteppingL(e);
  };
  const handlePublicTransR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "publicTransR",
        value: e,
      })
    );
    setPublicTransR(e);
  };
  const handlePublicTransL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "publicTransL",
        value: e,
      })
    );
    setPublicTransL(e);
  };
  const handleGoingShopR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "goingShopR",
        value: e,
      })
    );
    setGoingShopR(e);
  };
  const handleGoingShopL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "goingShopL",
        value: e,
      })
    );
    setGoingShopL(e);
  };
  const handlePuttingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "puttingR",
        value: e,
      })
    );
    setPuttingR(e);
  };
  const handlePuttingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "puttingL",
        value: e,
      })
    );
    setPuttingL(e);
  };
  const handleTakingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "takingR",
        value: e,
      })
    );
    setTakingR(e);
  };
  const handleTakingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "takingL",
        value: e,
      })
    );
    setTakingL(e);
  };
  const handleLyingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lyingR",
        value: e,
      })
    );
    setLyingR(e);
  };
  const handleLyingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lyingL",
        value: e,
      })
    );
    setLyingL(e);
  };
  const handleRollingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "rollingR",
        value: e,
      })
    );
    setRollingR(e);
  };
  const handleRollingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "rollingL",
        value: e,
      })
    );
    setRollingL(e);
  };
  const handleRisingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "risingR",
        value: e,
      })
    );
    setRisingR(e);
  };
  const handleRisingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "risingL",
        value: e,
      })
    );
    setRisingL(e);
  };
  const handleGetBathR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "getbathR",
        value: e,
      })
    );
    setGetBathR(e);
  };
  const handleGetBathL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "getbathL",
        value: e,
      })
    );
    setGetBathL(e);
  };
  const handleGetToiletR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "gettoiletR",
        value: e,
      })
    );
    setGetToiletR(e);
  };
  const handleGetToiletL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "gettoiletL",
        value: e,
      })
    );
    setGetToiletL(e);
  };
  const handleWashingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "washingR",
        value: e,
      })
    );
    setWashingR(e);
  };
  const handleWashingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "washingL",
        value: e,
      })
    );
    setWashingL(e);
  };
  const handleLightR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lightR",
        value: e,
      })
    );
    setLightR(e);
  };
  const handleLightL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lightL",
        value: e,
      })
    );
    setLightL(e);
  };
  const handleLightDomesticR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lightDomesticR",
        value: e,
      })
    );
    setLightDomesticR(e);
  };
  const handleLightDomesticL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lightDomesticL",
        value: e,
      })
    );
    setLightDomesticL(e);
  };
  const handleHeavyR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "heavyR",
        value: e,
      })
    );
    setHeavyR(e);
  };
  const handleHeavyL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "heavyL",
        value: e,
      })
    );
    setHeavyL(e);
  };
  const handlePickingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "pickingR",
        value: e,
      })
    );
    setPickingR(e);
  };
  const handlePickingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "pickingL",
        value: e,
      })
    );
    setPickingL(e);
  };
  const handleSquattingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "squattingR",
        value: e,
      })
    );
    setSquattingR(e);
  };
  const handleSquattingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "squattingL",
        value: e,
      })
    );
    setSquattingL(e);
  };
  const handleJoggingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "joggingR",
        value: e,
      })
    );
    setJoggingR(e);
  };
  const handleJoggingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "joggingL",
        value: e,
      })
    );
    setJoggingL(e);
  };
  const handleRunningR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "runningR",
        value: e,
      })
    );
    setRunningR(e);
  };
  const handleRunningL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "runningL",
        value: e,
      })
    );
    setRunningL(e);
  };
  const handleRunningOneR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "runningOneR",
        value: e,
      })
    );
    setRunningOneR(e);
  };
  const handleRunningOneL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "runningOneL",
        value: e,
      })
    );
    setRunningOneL(e);
  };
  const handleTwistingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "twistingR",
        value: e,
      })
    );
    setTwistingR(e);
  };
  const handleTwistingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "twistingL",
        value: e,
      })
    );
    setTwistingL(e);
  };
  const handleJumpingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "jumpingR",
        value: e,
      })
    );
    setJumpingR(e);
  };
  const handleJumpingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "jumpingL",
        value: e,
      })
    );
    setJumpingL(e);
  };
  const handleLandingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "landingR",
        value: e,
      })
    );
    setLandingR(e);
  };
  const handleLandingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "landingL",
        value: e,
      })
    );
    setLandingL(e);
  };
  const handleSwingingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "swingingR",
        value: e,
      })
    );
    setSwingingR(e);
  };
  const handleSwingingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "swingingL",
        value: e,
      })
    );
    setSwingingL(e);
  };
  const handleStartingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "startingR",
        value: e,
      })
    );
    setStartingR(e);
  };
  const handleStartingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "startingL",
        value: e,
      })
    );
    setStartingL(e);
  };
  const handleCuttingR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "cuttingR",
        value: e,
      })
    );
    setCuttingR(e);
  };
  const handleCuttingL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "cuttingL",
        value: e,
      })
    );
    setCuttingL(e);
  };
  const handleSexualR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sexualR",
        value: e,
      })
    );
    setSexualR(e);
  };
  const handleSexualL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sexualL",
        value: e,
      })
    );
    setSexualL(e);
  };
  const handleSportsR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sportsR",
        value: e,
      })
    );
    setSportsR(e);
  };
  const handleSportsL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sportsL",
        value: e,
      })
    );
    setSportsL(e);
  };
  const handleLowR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lowR",
        value: e,
      })
    );
    setLowR(e);
  };
  const handleLowL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "lowL",
        value: e,
      })
    );
    setLowL(e);
  };
  const handleRecreationalR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "recreationalR",
        value: e,
      })
    );
    setRecreationalR(e);
  };
  const handleRecreationalL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "recreationalL",
        value: e,
      })
    );
    setRecreationalL(e);
  };
  const handleFastWalkR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "fastWalkR",
        value: e,
      })
    );
    setFastWalkR(e);
  };
  const handleFastWalkL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "fastWalkL",
        value: e,
      })
    );
    setFastWalkL(e);
  };
  const handleAbilityPerformR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "abilityPerformR",
        value: e,
      })
    );
    setAbilityPerformR(e);
  };
  const handleAbilityPerformL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "abilityPerformL",
        value: e,
      })
    );
    setAbilityPerformL(e);
  };
  const handleAbilityPartR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "abilityPartR",
        value: e,
      })
    );
    setAbilityPartR(e);
  };
  const handleAbilityPartL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "abilityPartL",
        value: e,
      })
    );
    setAbilityPartL(e);
  };
  const handleHipPer = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "hipPer",
        value: e,
      })
    );
    setHipPer(e);
  };
  const handleHipPerL = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "hipPerL",
        value: e,
      })
    );
    setHipPerL(e);
  };

  const handleSelect1 = (e) => {
    setSelect1(!select1);
    dispatch(
      updateHipfunctionFieldScore({
        key: "select1",
        value: !select1,
      })
    );

  };

  useEffect(() => {
    if (select1) {
      dispatch(
        updateHipfunctionFieldScore({
          key: "sexualR",
          value: "",
        })
      );
      dispatch(
        updateHipfunctionFieldScore({
          key: "sexualL",
          value: "",
        })
      );
    }
  }, [select1])
  const handleSelect2 = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "select2",
        value: !select2,
      })
    );
    setSelect2(!select2);
    dispatch(
      updateHipfunctionFieldScore({
        key: "sexualL",
        value: "",
      })
    );
  };

  // const handleSpecifyHighestActivityL = (e) => {
  //   setspecifyHighestActivityL(e)
  //   dispatch(
  //     updateHipfunctionFieldScore({
  //       key: "specifyHighestActivityL",
  //       value: e,
  //     })
  //   );

  // }
  const handleSpecifyHighestActivityR = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "specifyHighestActivityR",
        value: e,
      })
    );
    setspecifyHighestActivityR(e);
  };

  const handleHighestActivity = (e) => {
    setHighestActivity(e);
    dispatch(
      updateHipfunctionFieldScore({
        key: "highestActivity",
        value: e,
      })
    );
  };

  const handleHighestActivityL = (e) => {
    setHighestActivityL(e);
    dispatch(
      updateHipfunctionFieldScore({
        key: "highestActivityL",
        value: e,
      })
    );
  };

  // const handlePatellarInstability = (e) => {
  //   setPatellarInstability(e);

  //   dispatch(
  //     updateHipfunctionFieldScore({
  //       key: "patellarInstability",
  //       value: e,
  //     })
  //   );
  // };

  const handleSports = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "sports",
        value: e,
      })
    );
    setSports(e);
  };

  const currhandleSports = (e) => {
    dispatch(
      updateHipfunctionFieldScore({
        key: "currsports",
        value: e,
      })
    );
    currSetSports(e);
  };

  console.log("select1,select2", select1, select2);

  const continueRedirect = (e) => {
    dispatch(
      updateHIPfunctionScores([
        {
          desstairsR: desstairsR,
          desstairsL: desstairsL,
          ascstairsR: ascstairsR,
          ascstairsL: ascstairsL,
          sittingR: sittingR,
          sittingL: sittingL,
          risingSittingR: risingSittingR,
          risingSittingL: risingSittingL,
          floorR: floorR,
          floorL: floorL,
          standingR: standingR,
          standingL: standingL,
          standingMinR: standingMinR,
          standingMinL: standingMinL,
          spreadingR: spreadingR,
          spreadingL: spreadingL,
          strideR: strideR,
          strideL: strideL,
          funBendlinR: funBendlinR,
          funBendlinL: funBendlinL,
          walkingR: walkingR,
          walkingL: walkingL,
          walkUnevenR: walkUnevenR,
          walkUnevenL: walkUnevenL,
          walkLongR: walkLongR,
          walkLongL: walkLongL,
          walkExeR: walkExeR,
          walkExeL: walkExeL,
          walkSteepR: walkSteepR,
          walkSteepL: walkSteepL,
          walkHillsR: walkHillsR,
          walkHillsL: walkHillsL,
          walkInitR: walkInitR,
          walkInitL: walkInitL,
          walkAppTenR: walkAppTenR,
          walkAppTenL: walkAppTenL,
          walkFiftR: walkFiftR,
          walkFiftL: walkFiftL,
          steppingR: steppingR,
          steppingL: steppingL,
          publicTransR: publicTransR,
          publicTransL: publicTransL,
          goingShopR: goingShopR,
          goingShopL: goingShopL,
          puttingR: puttingR,
          puttingL: puttingL,
          takingR: takingR,
          takingL: takingL,
          lyingR: lyingR,
          lyingL: lyingL,
          rollingR: rollingR,
          rollingL: rollingL,
          risingR: risingR,
          risingL: risingL,
          getbathR: getbathR,
          getbathL: getbathL,
          gettoiletR: gettoiletR,
          gettoiletL: gettoiletL,
          washingR: washingR,
          washingL: washingL,
          lightR: lightR,
          lightL: lightL,
          heavyR: heavyR,
          heavyL: heavyL,
          lightDomesticR: lightDomesticR,
          lightDomesticL: lightDomesticL,
          pickingR: pickingR,
          pickingL: pickingL,
          squattingR: squattingR,
          squattingL: squattingL,
          joggingR: joggingR,
          joggingL: joggingL,
          runningR: runningR,
          runningL: runningL,
          runningOneR: runningOneR,
          runningOneL: runningOneL,
          twistingR: twistingR,
          twistingL: twistingL,
          jumpingR: jumpingR,
          jumpingL: jumpingL,
          landingR: landingR,
          landingL: landingL,
          swingingR: swingingR,
          swingingL: swingingL,
          startingR: startingR,
          startingL: startingL,
          cuttingR: cuttingR,
          cuttingL: cuttingL,
          sexualR: sexualR,
          sexualL: sexualL,
          sportsR: sportsR,
          sportsL: sportsL,
          lowR: lowR,
          lowL: lowL,
          recreationalR: recreationalR,
          recreationalL: recreationalL,
          fastWalkR: fastWalkR,
          fastWalkL: fastWalkL,
          abilityPerformR: abilityPerformR,
          abilityPerformL: abilityPerformL,
          abilityPartR: abilityPartR,
          hipPer: hipPer,
          hipPerL: hipPerL,
          select1: select1,
          select2: select2,
          specifyHighestActivityL: specifyHighestActivityL,
          specifyHighestActivityR: specifyHighestActivityR,
          highestActivity: highestActivity,
          highestActivityL: highestActivityL,
          sports: sports,
          currsports: currsports,
        },
      ])
    );
    navigate("/hip-quality-of-life/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HipTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
          
              <h4 className="ph_title f_40">{translation.patient_hip_function}</h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>{translation.patient_hip_function_instruction}</label> <br />
                    <span dir={lg === "ara" ? "rtl" :" "}>
                      (<strong>0</strong> = {translation.patient_hip_function_instruction_0},{" "}
                      <strong>1</strong> = {translation.patient_hip_function_instruction_1},{" "}
                      <strong>2</strong> = {translation.patient_hip_function_instruction_2},{" "}
                      <strong>3</strong> = {translation.patient_hip_function_instruction_3},{" "}
                      <strong>4</strong> = {translation.patient_hip_function_instruction_4})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                    <p>
                      {translation.patient_hip_function_note}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Hip_Function_R} */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q1 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={desstairsR}
                        onChange={handleDesStairsR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q1_a1 },
                          { score: "1", label: translation.patient_hip_function_q1_a2 },
                          { score: "2", label: translation.patient_hip_function_q1_a3 },
                          { score: "3", label: translation.patient_hip_function_q1_a4 },
                          { score: "4", label: translation.patient_hip_function_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Hip_Function_L} */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q1 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={desstairsL}
                        onChange={handleDesStairsL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q1_a1 },
                          { score: "1", label: translation.patient_hip_function_q1_a2 },
                          { score: "2", label: translation.patient_hip_function_q1_a3 },
                          { score: "3", label: translation.patient_hip_function_q1_a4 },
                          { score: "4", label: translation.patient_hip_function_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q2 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={ascstairsR}
                        onChange={handleAscStairsR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q2_a1 },
                          { score: "1", label: translation.patient_hip_function_q2_a2 },
                          { score: "2", label: translation.patient_hip_function_q2_a3 },
                          { score: "3", label: translation.patient_hip_function_q2_a4 },
                          { score: "4", label: translation.patient_hip_function_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q2 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={ascstairsL}
                        onChange={handleAscStairsL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q2_a1 },
                          { score: "1", label: translation.patient_hip_function_q2_a2 },
                          { score: "2", label: translation.patient_hip_function_q2_a3 },
                          { score: "3", label: translation.patient_hip_function_q2_a4 },
                          { score: "4", label: translation.patient_hip_function_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q3 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={sittingR}
                        onChange={handleSittingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q3_a1 },
                          { score: "1", label: translation.patient_hip_function_q3_a2 },
                          { score: "2", label: translation.patient_hip_function_q3_a3 },
                          { score: "3", label: translation.patient_hip_function_q3_a4 },
                          { score: "4", label: translation.patient_hip_function_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q3 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={sittingL}
                        onChange={handleSittingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q3_a1 },
                          { score: "1", label: translation.patient_hip_function_q3_a2 },
                          { score: "2", label: translation.patient_hip_function_q3_a3 },
                          { score: "3", label: translation.patient_hip_function_q3_a4 },
                          { score: "4", label: translation.patient_hip_function_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q4 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={risingSittingR}
                        onChange={handleRisingSittingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q4_a1 },
                          { score: "1", label: translation.patient_hip_function_q4_a2 },
                          { score: "2", label: translation.patient_hip_function_q4_a3 },
                          { score: "3", label: translation.patient_hip_function_q4_a4 },
                          { score: "4", label: translation.patient_hip_function_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q4 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={risingSittingL}
                        onChange={handleRisingSittingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q4_a1 },
                          { score: "1", label: translation.patient_hip_function_q4_a2 },
                          { score: "2", label: translation.patient_hip_function_q4_a3 },
                          { score: "3", label: translation.patient_hip_function_q4_a4 },
                          { score: "4", label: translation.patient_hip_function_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q5 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={floorR}
                        onChange={handleFloorR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q5_a1 },
                          { score: "1", label: translation.patient_hip_function_q5_a2 },
                          { score: "2", label: translation.patient_hip_function_q5_a3 },
                          { score: "3", label: translation.patient_hip_function_q5_a4 },
                          { score: "4", label: translation.patient_hip_function_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q5 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={floorL}
                        onChange={handleFloorL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q5_a1 },
                          { score: "1", label: translation.patient_hip_function_q5_a2 },
                          { score: "2", label: translation.patient_hip_function_q5_a3 },
                          { score: "3", label: translation.patient_hip_function_q5_a4 },
                          { score: "4", label: translation.patient_hip_function_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q6 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={standingR}
                        onChange={handleStandingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q6_a1 },
                          { score: "1", label: translation.patient_hip_function_q6_a2 },
                          { score: "2", label: translation.patient_hip_function_q6_a3 },
                          { score: "3", label: translation.patient_hip_function_q6_a4 },
                          { score: "4", label: translation.patient_hip_function_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q6 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={standingL}
                        onChange={handleStandingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q6_a1 },
                          { score: "1", label: translation.patient_hip_function_q6_a2 },
                          { score: "2", label: translation.patient_hip_function_q6_a3 },
                          { score: "3", label: translation.patient_hip_function_q6_a4 },
                          { score: "4", label: translation.patient_hip_function_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q7 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={standingMinR}
                        onChange={handleStandingMinR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q7_a1 },
                          { score: "1", label: translation.patient_hip_function_q7_a2 },
                          { score: "2", label: translation.patient_hip_function_q7_a3 },
                          { score: "3", label: translation.patient_hip_function_q7_a4 },
                          { score: "4", label: translation.patient_hip_function_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q7 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={standingMinL}
                        onChange={handleStandingMinL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q7_a1 },
                          { score: "1", label: translation.patient_hip_function_q7_a2 },
                          { score: "2", label: translation.patient_hip_function_q7_a3 },
                          { score: "3", label: translation.patient_hip_function_q7_a4 },
                          { score: "4", label: translation.patient_hip_function_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q8 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={spreadingR}
                        onChange={handleSpreadingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q8_a1 },
                          { score: "1", label: translation.patient_hip_function_q8_a2 },
                          { score: "2", label: translation.patient_hip_function_q8_a3 },
                          { score: "3", label: translation.patient_hip_function_q8_a4 },
                          { score: "4", label: translation.patient_hip_function_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q8 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={spreadingL}
                        onChange={handleSpreadingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q8_a1 },
                          { score: "1", label: translation.patient_hip_function_q8_a2 },
                          { score: "2", label: translation.patient_hip_function_q8_a3 },
                          { score: "3", label: translation.patient_hip_function_q8_a4 },
                          { score: "4", label: translation.patient_hip_function_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q9 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={strideR}
                        onChange={handleStrideR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q9_a1 },
                          { score: "1", label: translation.patient_hip_function_q9_a2 },
                          { score: "2", label: translation.patient_hip_function_q9_a3 },
                          { score: "3", label: translation.patient_hip_function_q9_a4 },
                          { score: "4", label: translation.patient_hip_function_q9_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q9 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={strideL}
                        onChange={handleStrideL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q9_a1 },
                          { score: "1", label: translation.patient_hip_function_q9_a2 },
                          { score: "2", label: translation.patient_hip_function_q9_a3 },
                          { score: "3", label: translation.patient_hip_function_q9_a4 },
                          { score: "4", label: translation.patient_hip_function_q9_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q10 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={funBendlinR}
                        onChange={handleBendingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q10_a1 },
                          { score: "1", label: translation.patient_hip_function_q10_a2 },
                          { score: "2", label: translation.patient_hip_function_q10_a3 },
                          { score: "3", label: translation.patient_hip_function_q10_a4 },
                          { score: "4", label: translation.patient_hip_function_q10_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q10 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={funBendlinL}
                        onChange={handleBendingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q10_a1 },
                          { score: "1", label: translation.patient_hip_function_q10_a2 },
                          { score: "2", label: translation.patient_hip_function_q10_a3 },
                          { score: "3", label: translation.patient_hip_function_q10_a4 },
                          { score: "4", label: translation.patient_hip_function_q10_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q11 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkingR}
                        onChange={handleWalkingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q11_a1 },
                          { score: "1", label: translation.patient_hip_function_q11_a2 },
                          { score: "2", label: translation.patient_hip_function_q11_a3 },
                          { score: "3", label: translation.patient_hip_function_q11_a4 },
                          { score: "4", label: translation.patient_hip_function_q11_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q11 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkingL}
                        onChange={handleWalkingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q11_a1 },
                          { score: "1", label: translation.patient_hip_function_q11_a2 },
                          { score: "2", label: translation.patient_hip_function_q11_a3 },
                          { score: "3", label: translation.patient_hip_function_q11_a4 },
                          { score: "4", label: translation.patient_hip_function_q11_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q12 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkUnevenR}
                        onChange={handleWalkUnevenR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q12_a1 },
                          { score: "1", label: translation.patient_hip_function_q12_a2 },
                          { score: "2", label: translation.patient_hip_function_q12_a3 },
                          { score: "3", label: translation.patient_hip_function_q12_a4 },
                          { score: "4", label: translation.patient_hip_function_q12_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q12 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkUnevenL}
                        onChange={handleWalkUnevenL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q12_a1 },
                          { score: "1", label: translation.patient_hip_function_q12_a2 },
                          { score: "2", label: translation.patient_hip_function_q12_a3 },
                          { score: "3", label: translation.patient_hip_function_q12_a4 },
                          { score: "4", label: translation.patient_hip_function_q12_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q13 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkLongR}
                        onChange={handleWalkLongR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q13_a1 },
                          { score: "1", label: translation.patient_hip_function_q13_a2 },
                          { score: "2", label: translation.patient_hip_function_q13_a3 },
                          { score: "3", label: translation.patient_hip_function_q13_a4 },
                          { score: "4", label: translation.patient_hip_function_q13_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q13 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkLongL}
                        onChange={handleWalkLongL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q13_a1 },
                          { score: "1", label: translation.patient_hip_function_q13_a2 },
                          { score: "2", label: translation.patient_hip_function_q13_a3 },
                          { score: "3", label: translation.patient_hip_function_q13_a4 },
                          { score: "4", label: translation.patient_hip_function_q13_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q14 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkExeR}
                        onChange={handleWalkExeR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q14_a1 },
                          { score: "1", label: translation.patient_hip_function_q14_a2 },
                          { score: "2", label: translation.patient_hip_function_q14_a3 },
                          { score: "3", label: translation.patient_hip_function_q14_a4 },
                          { score: "4", label: translation.patient_hip_function_q14_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q14 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkExeL}
                        onChange={handleWalkExeL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q14_a1 },
                          { score: "1", label: translation.patient_hip_function_q14_a2 },
                          { score: "2", label: translation.patient_hip_function_q14_a3 },
                          { score: "3", label: translation.patient_hip_function_q14_a4 },
                          { score: "4", label: translation.patient_hip_function_q14_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q15 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkSteepR}
                        onChange={handleWalkSteepR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q15_a1 },
                          { score: "1", label: translation.patient_hip_function_q15_a2 },
                          { score: "2", label: translation.patient_hip_function_q15_a3 },
                          { score: "3", label: translation.patient_hip_function_q15_a4 },
                          { score: "4", label: translation.patient_hip_function_q15_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q15 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkSteepL}
                        onChange={handleWalkSteepL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q15_a1 },
                          { score: "1", label: translation.patient_hip_function_q15_a2 },
                          { score: "2", label: translation.patient_hip_function_q15_a3 },
                          { score: "3", label: translation.patient_hip_function_q15_a4 },
                          { score: "4", label: translation.patient_hip_function_q15_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q16 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkHillsR}
                        onChange={handleWalkHillsR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q16_a1 },
                          { score: "1", label: translation.patient_hip_function_q16_a2 },
                          { score: "2", label: translation.patient_hip_function_q16_a3 },
                          { score: "3", label: translation.patient_hip_function_q16_a4 },
                          { score: "4", label: translation.patient_hip_function_q16_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q16 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkHillsL}
                        onChange={handleWalkHillsL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q16_a1 },
                          { score: "1", label: translation.patient_hip_function_q16_a2 },
                          { score: "2", label: translation.patient_hip_function_q16_a3 },
                          { score: "3", label: translation.patient_hip_function_q16_a4 },
                          { score: "4", label: translation.patient_hip_function_q16_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q17 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkInitR}
                        onChange={handleWalkInitR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q17_a1 },
                          { score: "1", label: translation.patient_hip_function_q17_a2 },
                          { score: "2", label: translation.patient_hip_function_q17_a3 },
                          { score: "3", label: translation.patient_hip_function_q17_a4 },
                          { score: "4", label: translation.patient_hip_function_q17_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q17 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkInitL}
                        onChange={handleWalkInitL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q17_a1 },
                          { score: "1", label: translation.patient_hip_function_q17_a2 },
                          { score: "2", label: translation.patient_hip_function_q17_a3 },
                          { score: "3", label: translation.patient_hip_function_q17_a4 },
                          { score: "4", label: translation.patient_hip_function_q17_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q18 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkAppTenR}
                        onChange={handleWalkAppTenR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q18_a1 },
                          { score: "1", label: translation.patient_hip_function_q18_a2 },
                          { score: "2", label: translation.patient_hip_function_q18_a3 },
                          { score: "3", label: translation.patient_hip_function_q18_a4 },
                          { score: "4", label: translation.patient_hip_function_q18_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q18 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkAppTenL}
                        onChange={handleWalkAppTenL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q18_a1 },
                          { score: "1", label: translation.patient_hip_function_q18_a2 },
                          { score: "2", label: translation.patient_hip_function_q18_a3 },
                          { score: "3", label: translation.patient_hip_function_q18_a4 },
                          { score: "4", label: translation.patient_hip_function_q18_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q19 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={walkFiftR}
                        onChange={handleWalkFiftR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q19_a1 },
                          { score: "1", label: translation.patient_hip_function_q19_a2 },
                          { score: "2", label: translation.patient_hip_function_q19_a3 },
                          { score: "3", label: translation.patient_hip_function_q19_a4 },
                          { score: "4", label: translation.patient_hip_function_q19_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q19 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={walkFiftL}
                        onChange={handleWalkFiftL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q19_a1 },
                          { score: "1", label: translation.patient_hip_function_q19_a2 },
                          { score: "2", label: translation.patient_hip_function_q19_a3 },
                          { score: "3", label: translation.patient_hip_function_q19_a4 },
                          { score: "4", label: translation.patient_hip_function_q19_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q20 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={steppingR}
                        onChange={handleSteppingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q20_a1 },
                          { score: "1", label: translation.patient_hip_function_q20_a2 },
                          { score: "2", label: translation.patient_hip_function_q20_a3 },
                          { score: "3", label: translation.patient_hip_function_q20_a4 },
                          { score: "4", label: translation.patient_hip_function_q20_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q20 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={steppingL}
                        onChange={handleSteppingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q20_a1 },
                          { score: "1", label: translation.patient_hip_function_q20_a2 },
                          { score: "2", label: translation.patient_hip_function_q20_a3 },
                          { score: "3", label: translation.patient_hip_function_q20_a4 },
                          { score: "4", label: translation.patient_hip_function_q20_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q21 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={publicTransR}
                        onChange={handlePublicTransR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q21_a1 },
                          { score: "1", label: translation.patient_hip_function_q21_a2 },
                          { score: "2", label: translation.patient_hip_function_q21_a3 },
                          { score: "3", label: translation.patient_hip_function_q21_a4 },
                          { score: "4", label: translation.patient_hip_function_q21_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q21 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={publicTransL}
                        onChange={handlePublicTransL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q21_a1 },
                          { score: "1", label: translation.patient_hip_function_q21_a2 },
                          { score: "2", label: translation.patient_hip_function_q21_a3 },
                          { score: "3", label: translation.patient_hip_function_q21_a4 },
                          { score: "4", label: translation.patient_hip_function_q21_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q22 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={goingShopR}
                        onChange={handleGoingShopR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q22_a1 },
                          { score: "1", label: translation.patient_hip_function_q22_a2 },
                          { score: "2", label: translation.patient_hip_function_q22_a3 },
                          { score: "3", label: translation.patient_hip_function_q22_a4 },
                          { score: "4", label: translation.patient_hip_function_q22_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q22 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={goingShopL}
                        onChange={handleGoingShopL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q22_a1 },
                          { score: "1", label: translation.patient_hip_function_q22_a2 },
                          { score: "2", label: translation.patient_hip_function_q22_a3 },
                          { score: "3", label: translation.patient_hip_function_q22_a4 },
                          { score: "4", label: translation.patient_hip_function_q22_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q23 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={puttingR}
                        onChange={handlePuttingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q23_a1 },
                          { score: "1", label: translation.patient_hip_function_q23_a2 },
                          { score: "2", label: translation.patient_hip_function_q23_a3 },
                          { score: "3", label: translation.patient_hip_function_q23_a4 },
                          { score: "4", label: translation.patient_hip_function_q23_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q23 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={puttingL}
                        onChange={handlePuttingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q23_a1 },
                          { score: "1", label: translation.patient_hip_function_q23_a2 },
                          { score: "2", label: translation.patient_hip_function_q23_a3 },
                          { score: "3", label: translation.patient_hip_function_q23_a4 },
                          { score: "4", label: translation.patient_hip_function_q23_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q24 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={takingR}
                        onChange={handleTakingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q24_a1 },
                          { score: "1", label: translation.patient_hip_function_q24_a2 },
                          { score: "2", label: translation.patient_hip_function_q24_a3 },
                          { score: "3", label: translation.patient_hip_function_q24_a4 },
                          { score: "4", label: translation.patient_hip_function_q24_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q24 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={takingL}
                        onChange={handleTakingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q24_a1 },
                          { score: "1", label: translation.patient_hip_function_q24_a2 },
                          { score: "2", label: translation.patient_hip_function_q24_a3 },
                          { score: "3", label: translation.patient_hip_function_q24_a4 },
                          { score: "4", label: translation.patient_hip_function_q24_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q25 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={lyingR}
                        onChange={handleLyingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q25_a1 },
                          { score: "1", label: translation.patient_hip_function_q25_a2 },
                          { score: "2", label: translation.patient_hip_function_q25_a3 },
                          { score: "3", label: translation.patient_hip_function_q25_a4 },
                          { score: "4", label: translation.patient_hip_function_q25_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q25 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={lyingL}
                        onChange={handleLyingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q25_a1 },
                          { score: "1", label: translation.patient_hip_function_q25_a2 },
                          { score: "2", label: translation.patient_hip_function_q25_a3 },
                          { score: "3", label: translation.patient_hip_function_q25_a4 },
                          { score: "4", label: translation.patient_hip_function_q25_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q26 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={rollingR}
                        onChange={handleRollingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q26_a1 },
                          { score: "1", label: translation.patient_hip_function_q26_a2 },
                          { score: "2", label: translation.patient_hip_function_q26_a3 },
                          { score: "3", label: translation.patient_hip_function_q26_a4 },
                          { score: "4", label: translation.patient_hip_function_q26_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q26 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={rollingL}
                        onChange={handleRollingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q26_a1 },
                          { score: "1", label: translation.patient_hip_function_q26_a2 },
                          { score: "2", label: translation.patient_hip_function_q26_a3 },
                          { score: "3", label: translation.patient_hip_function_q26_a4 },
                          { score: "4", label: translation.patient_hip_function_q26_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q27 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={risingR}
                        onChange={handleRisingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q27_a1 },
                          { score: "1", label: translation.patient_hip_function_q27_a2 },
                          { score: "2", label: translation.patient_hip_function_q27_a3 },
                          { score: "3", label: translation.patient_hip_function_q27_a4 },
                          { score: "4", label: translation.patient_hip_function_q27_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q27 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={risingL}
                        onChange={handleRisingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q27_a1 },
                          { score: "1", label: translation.patient_hip_function_q27_a2 },
                          { score: "2", label: translation.patient_hip_function_q27_a3 },
                          { score: "3", label: translation.patient_hip_function_q27_a4 },
                          { score: "4", label: translation.patient_hip_function_q27_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q28 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={getbathR}
                        onChange={handleGetBathR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q28_a1 },
                          { score: "1", label: translation.patient_hip_function_q28_a2 },
                          { score: "2", label: translation.patient_hip_function_q28_a3 },
                          { score: "3", label: translation.patient_hip_function_q28_a4 },
                          { score: "4", label: translation.patient_hip_function_q28_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q28 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={getbathL}
                        onChange={handleGetBathL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q28_a1 },
                          { score: "1", label: translation.patient_hip_function_q28_a2 },
                          { score: "2", label: translation.patient_hip_function_q28_a3 },
                          { score: "3", label: translation.patient_hip_function_q28_a4 },
                          { score: "4", label: translation.patient_hip_function_q28_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q29 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={gettoiletR}
                        onChange={handleGetToiletR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q29_a1 },
                          { score: "1", label: translation.patient_hip_function_q29_a2 },
                          { score: "2", label: translation.patient_hip_function_q29_a3 },
                          { score: "3", label: translation.patient_hip_function_q29_a4 },
                          { score: "4", label: translation.patient_hip_function_q29_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q29 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={gettoiletL}
                        onChange={handleGetToiletL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q29_a1 },
                          { score: "1", label: translation.patient_hip_function_q29_a2 },
                          { score: "2", label: translation.patient_hip_function_q29_a3 },
                          { score: "3", label: translation.patient_hip_function_q29_a4 },
                          { score: "4", label: translation.patient_hip_function_q29_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q30 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={washingR}
                        onChange={handleWashingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q30_a1 },
                          { score: "1", label: translation.patient_hip_function_q30_a2 },
                          { score: "2", label: translation.patient_hip_function_q30_a3 },
                          { score: "3", label: translation.patient_hip_function_q30_a4 },
                          { score: "4", label: translation.patient_hip_function_q30_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q30 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={washingL}
                        onChange={handleWashingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q30_a1 },
                          { score: "1", label: translation.patient_hip_function_q30_a2 },
                          { score: "2", label: translation.patient_hip_function_q30_a3 },
                          { score: "3", label: translation.patient_hip_function_q30_a4 },
                          { score: "4", label: translation.patient_hip_function_q30_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q31 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={lightR}
                        onChange={handleLightR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q31_a1 },
                          { score: "1", label: translation.patient_hip_function_q31_a2 },
                          { score: "2", label: translation.patient_hip_function_q31_a3 },
                          { score: "3", label: translation.patient_hip_function_q31_a4 },
                          { score: "4", label: translation.patient_hip_function_q31_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q31 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={lightL}
                        onChange={handleLightL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q31_a1 },
                          { score: "1", label: translation.patient_hip_function_q31_a2 },
                          { score: "2", label: translation.patient_hip_function_q31_a3 },
                          { score: "3", label: translation.patient_hip_function_q31_a4 },
                          { score: "4", label: translation.patient_hip_function_q31_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q32 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={heavyR}
                        onChange={handleHeavyR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q32_a1 },
                          { score: "1", label: translation.patient_hip_function_q32_a2 },
                          { score: "2", label: translation.patient_hip_function_q32_a3 },
                          { score: "3", label: translation.patient_hip_function_q32_a4 },
                          { score: "4", label: translation.patient_hip_function_q32_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q32 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={heavyL}
                        onChange={handleHeavyL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q32_a1 },
                          { score: "1", label: translation.patient_hip_function_q32_a2 },
                          { score: "2", label: translation.patient_hip_function_q32_a3 },
                          { score: "3", label: translation.patient_hip_function_q32_a4 },
                          { score: "4", label: translation.patient_hip_function_q32_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q33 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={lightDomesticR}
                        onChange={handleLightDomesticR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q33_a1 },
                          { score: "1", label: translation.patient_hip_function_q33_a2 },
                          { score: "2", label: translation.patient_hip_function_q33_a3 },
                          { score: "3", label: translation.patient_hip_function_q33_a4 },
                          { score: "4", label: translation.patient_hip_function_q33_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q33 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={lightDomesticL}
                        onChange={handleLightDomesticL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q33_a1 },
                          { score: "1", label: translation.patient_hip_function_q33_a2 },
                          { score: "2", label: translation.patient_hip_function_q33_a3 },
                          { score: "3", label: translation.patient_hip_function_q33_a4 },
                          { score: "4", label: translation.patient_hip_function_q33_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q34 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={pickingR}
                        onChange={handlePickingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q34_a1 },
                          { score: "1", label: translation.patient_hip_function_q34_a2 },
                          { score: "2", label: translation.patient_hip_function_q34_a3 },
                          { score: "3", label: translation.patient_hip_function_q34_a4 },
                          { score: "4", label: translation.patient_hip_function_q34_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q34 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={pickingL}
                        onChange={handlePickingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q34_a1 },
                          { score: "1", label: translation.patient_hip_function_q34_a2 },
                          { score: "2", label: translation.patient_hip_function_q34_a3 },
                          { score: "3", label: translation.patient_hip_function_q34_a4 },
                          { score: "4", label: translation.patient_hip_function_q34_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q35 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={squattingR}
                        onChange={handleSquattingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q35_a1 },
                          { score: "1", label: translation.patient_hip_function_q35_a2 },
                          { score: "2", label: translation.patient_hip_function_q35_a3 },
                          { score: "3", label: translation.patient_hip_function_q35_a4 },
                          { score: "4", label: translation.patient_hip_function_q35_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q35 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={squattingL}
                        onChange={handleSquattingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q35_a1 },
                          { score: "1", label: translation.patient_hip_function_q35_a2 },
                          { score: "2", label: translation.patient_hip_function_q35_a3 },
                          { score: "3", label: translation.patient_hip_function_q35_a4 },
                          { score: "4", label: translation.patient_hip_function_q35_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q36 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={joggingR}
                        onChange={handleJoggingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q36_a1 },
                          { score: "1", label: translation.patient_hip_function_q36_a2 },
                          { score: "2", label: translation.patient_hip_function_q36_a3 },
                          { score: "3", label: translation.patient_hip_function_q36_a4 },
                          { score: "4", label: translation.patient_hip_function_q36_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q36 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={joggingL}
                        onChange={handleJoggingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q36_a1 },
                          { score: "1", label: translation.patient_hip_function_q36_a2 },
                          { score: "2", label: translation.patient_hip_function_q36_a3 },
                          { score: "3", label: translation.patient_hip_function_q36_a4 },
                          { score: "4", label: translation.patient_hip_function_q36_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q37 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={runningR}
                        onChange={handleRunningR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q37_a1 },
                          { score: "1", label: translation.patient_hip_function_q37_a2 },
                          { score: "2", label: translation.patient_hip_function_q37_a3 },
                          { score: "3", label: translation.patient_hip_function_q37_a4 },
                          { score: "4", label: translation.patient_hip_function_q37_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q37 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={runningL}
                        onChange={handleRunningL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q37_a1 },
                          { score: "1", label: translation.patient_hip_function_q37_a2 },
                          { score: "2", label: translation.patient_hip_function_q37_a3 },
                          { score: "3", label: translation.patient_hip_function_q37_a4 },
                          { score: "4", label: translation.patient_hip_function_q37_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Running_one_mile_R : translation.Running_one_mile}
                        classes=""
                        value={runningOneR}
                        onChange={handleRunningOneR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Running_one_mile_L : translation.Running_one_mile}
                        classes=""
                        value={runningOneL}
                        onChange={handleRunningOneL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q38 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={twistingR}
                        onChange={handleTwistingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q38_a1 },
                          { score: "1", label: translation.patient_hip_function_q38_a2 },
                          { score: "2", label: translation.patient_hip_function_q38_a3 },
                          { score: "3", label: translation.patient_hip_function_q38_a4 },
                          { score: "4", label: translation.patient_hip_function_q38_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q38 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={twistingL}
                        onChange={handleTwistingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q38_a1 },
                          { score: "1", label: translation.patient_hip_function_q38_a2 },
                          { score: "2", label: translation.patient_hip_function_q38_a3 },
                          { score: "3", label: translation.patient_hip_function_q38_a4 },
                          { score: "4", label: translation.patient_hip_function_q38_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Hip_Fuction_Jumping_R : translation.Jumping}
                        classes=""
                        value={jumpingR}
                        onChange={handleJumpingR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Hip_Fuction_Jumping_L : translation.Jumping}
                        classes=""
                        value={jumpingL}
                        onChange={handleJumpingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}

                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Hip_Fuction_Landing_R : translation.Landing}
                        classes=""
                        value={landingR}
                        onChange={handleLandingR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Hip_Fuction_Landing_L : translation.Landing}
                        classes=""
                        value={landingL}
                        onChange={handleLandingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Swinging_objects_like_a_golf_clubnning_R : translation.Swinging_objects_like_a_golf_clubnning}
                        classes=""
                        value={swingingR}
                        onChange={handleSwingingR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Swinging_objects_like_a_golf_clubnning_L : translation.Swinging_objects_like_a_golf_clubnning}
                        classes=""
                        value={swingingL}
                        onChange={handleSwingingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Starting_and_stopping_quickly_R : translation.Starting_and_stopping_quickly}
                        classes=""
                        value={startingR}
                        onChange={handleStartingR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Starting_and_stopping_quickly_L : translation.Starting_and_stopping_quickly}
                        classes=""
                        value={startingL}
                        onChange={handleStartingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Cutting_lateral_movements_R : translation.Cutting_lateral_movements}
                        classes=""
                        value={cuttingR}
                        onChange={handleCuttingR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Cutting_lateral_movements_L : translation.Cutting_lateral_movements}
                        classes=""
                        value={cuttingL}
                        onChange={handleCuttingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q39 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={sexualR}
                        onChange={handleSexualR}
                        onClick={handleSexualR}
                        view={select1 === true ? true : false}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q39_a1 },
                          { score: "1", label: translation.patient_hip_function_q39_a2 },
                          { score: "2", label: translation.patient_hip_function_q39_a3 },
                          { score: "3", label: translation.patient_hip_function_q39_a4 },
                          { score: "4", label: translation.patient_hip_function_q39_a5 },
                        ]}
                      />

                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q39 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={sexualL}
                        onChange={handleSexualL}
                        onClick={handleSexualL}
                        view={select1 === true ? true : false}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q39_a1 },
                          { score: "1", label: translation.patient_hip_function_q39_a2 },
                          { score: "2", label: translation.patient_hip_function_q39_a3 },
                          { score: "3", label: translation.patient_hip_function_q39_a4 },
                          { score: "4", label: translation.patient_hip_function_q39_a5 },
                        ]}
                      />
                      {/* <input
                        type="checkbox"
                        name="sexual_activity_L"
                        value={select2}
                        checked={select2}
                        onChange={handleSelect2}
                      />{" "}
                      <label>{translation.This_is_not_relevant_to_me}</label> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div style={{ marginTop: "-16px" }}>
                    <input
                      type="checkbox"
                      name="sexual_activity_R"
                      value={select1}
                      checked={select1}
                      onChange={handleSelect1}
                    />{" "}
                    <label>{translation.patient_hip_function_q39_not_relevant}</label>
                  </div>
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q40 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={sportsR}
                        onChange={handleSportsR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q40_a1 },
                          { score: "1", label: translation.patient_hip_function_q40_a2 },
                          { score: "2", label: translation.patient_hip_function_q40_a3 },
                          { score: "3", label: translation.patient_hip_function_q40_a4 },
                          { score: "4", label: translation.patient_hip_function_q40_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q40 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={sportsL}
                        onChange={handleSportsL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q40_a1 },
                          { score: "1", label: translation.patient_hip_function_q40_a2 },
                          { score: "2", label: translation.patient_hip_function_q40_a3 },
                          { score: "3", label: translation.patient_hip_function_q40_a4 },
                          { score: "4", label: translation.patient_hip_function_q40_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q41 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={lowR}
                        onChange={handleLowR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q41_a1 },
                          { score: "1", label: translation.patient_hip_function_q41_a2 },
                          { score: "2", label: translation.patient_hip_function_q41_a3 },
                          { score: "3", label: translation.patient_hip_function_q41_a4 },
                          { score: "4", label: translation.patient_hip_function_q41_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q41 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={lowL}
                        onChange={handleLowL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q41_a1 },
                          { score: "1", label: translation.patient_hip_function_q41_a2 },
                          { score: "2", label: translation.patient_hip_function_q41_a3 },
                          { score: "3", label: translation.patient_hip_function_q41_a4 },
                          { score: "4", label: translation.patient_hip_function_q41_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q42 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={recreationalR}
                        onChange={handleRecreationalR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q42_a1 },
                          { score: "1", label: translation.patient_hip_function_q42_a2 },
                          { score: "2", label: translation.patient_hip_function_q42_a3 },
                          { score: "3", label: translation.patient_hip_function_q42_a4 },
                          { score: "4", label: translation.patient_hip_function_q42_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_function_q42 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={recreationalL}
                        onChange={handleRecreationalL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_function_q42_a1 },
                          { score: "1", label: translation.patient_hip_function_q42_a2 },
                          { score: "2", label: translation.patient_hip_function_q42_a3 },
                          { score: "3", label: translation.patient_hip_function_q42_a4 },
                          { score: "4", label: translation.patient_hip_function_q42_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Low_impact_activities_R : translation.Low_impact_activities_hip}
                        classes=""
                        value={fastWalkR}
                        onChange={handleFastWalkR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Low_impact_activities_L : translation.Low_impact_activities_hip}
                        classes=""
                        value={fastWalkL}
                        onChange={handleFastWalkL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Ability_to_perform_activity_R : translation.Ability_to_perform_activity}
                        classes=""
                        value={abilityPerformR}
                        onChange={handleAbilityPerformR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Ability_to_perform_activity_L : translation.Ability_to_perform_activity}
                        classes=""
                        value={abilityPerformL}
                        onChange={handleAbilityPerformL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Ability_to_participate_R : translation.Ability_to_participate_in_your_desired_sport_as_long_as_you_like}
                        classes=""
                        value={abilityPartR}
                        onChange={handleAbilityPartR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlHipPain === "3" ? translation.Ability_to_participate_L : translation.Ability_to_participate_in_your_desired_sport_as_long_as_you_like}
                        classes=""
                        value={abilityPartL}
                        onChange={handleAbilityPartL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="row">
                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+RightSide()}>
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="100"
                        step="1"
                        type="image"
                        colorcode="1"
                        label={translation.patient_hip_function_q43 + RigthTrans(evlHipPain, translation)}
                        value={hipPer}
                        onChange={handleHipPer}

                      />
                    </div>

                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+LeftSide()}>
                        <RangeSlider
                          id="hipPer"
                          min="0"
                          max="100"
                          step="1"
                          type="image"
                          colorcode="1"
                          label={translation.patient_hip_function_q43 + LeftTrans(evlHipPain, translation)}
                          value={hipPerL}
                          onChange={handleHipPerL}

                        />
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <h4 className="mb-3">{translation.patient_hip_function_q44}</h4>
                  {initialAssesment === 1 || String(initialAssesment )=== "undefined" ? (
                    <div className="col-md-6 col-sm-6 mb_20">
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label: translation.patient_hip_function_q44_q1_a1,
                          },
                          {
                            value: "3",
                            label: translation.patient_hip_function_q44_q1_a2,
                          },
                          {
                            value: "2",
                            label: translation.patient_hip_function_q44_q1_a3,
                          },
                          {
                            value: "1",
                            label: translation.patient_hip_function_q44_q1_a4,
                          },
                          {
                            value: "0",
                            label:
                              translation.patient_hip_function_q44_q1_a5,
                          },
                        ]}
                        label={translation.patient_hip_function_q44_q1}
                        styleClass=""
                        placeholder={translation.none}
                        value={highestActivity}
                        onChange={handleHighestActivity}
                      />
                      <div>
                        {highestActivity === "4" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a1_o1
                              }
                              selected={sports === "1"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="1"
                              id="sports1"
                              name="sports"
                            />

                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a1_o2
                              }
                              selected={sports === "2"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="2"
                              id="sports2"
                              name="sports"
                            />

                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a1_o3
                              }
                              selected={sports === "3"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="3"
                              id="sports3"
                              name="sports"
                            />
                          </>
                        )}
                        {highestActivity === "3" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a2_o1
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "4"}
                              value="4"
                              id="sports4"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a2_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "5"}
                              value="5"
                              id="sports5"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={translation.patient_hip_function_q44_q1_a2_o3}
                              styleClass="custom_radio_box large"
                              selected={sports === "6"}
                              value="6"
                              id="sports6"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={translation.patient_hip_function_q44_q1_a2_o4}
                              styleClass="custom_radio_box large"
                              selected={sports === "7"}
                              value="7"
                              id="sports7"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={translation.patient_hip_function_q44_q1_a2_o5}
                              styleClass="custom_radio_box large"
                              selected={sports === "8"}
                              value="8"
                              id="sports8"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}

                        {highestActivity === "2" && (
                          <>
                            <RadioButton
                              label={translation.patient_hip_function_q44_q1_a3_o1}
                              styleClass="custom_radio_box large"
                              selected={sports === "9"}
                              value="9"
                              id="sports9"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a3_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "10"}
                              value="10"
                              id="sports10"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={translation.patient_hip_function_q44_q1_a3_o3}
                              styleClass="custom_radio_box large"
                              selected={sports === "11"}
                              value="11"
                              id="sports11"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a3_o4
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "12"}
                              value="12"
                              id="sports12"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}

                        {highestActivity === "1" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a4_o1
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "13"}
                              value="13"
                              id="sports13"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a4_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "14"}
                              value="14"
                              id="sports14"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_hip_function_q44_q1_a4_o3
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "15"}
                              value="15"
                              id="sports15"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ):""}
                  <div className="col-md-6 col-sm-6 mb_20">
                    <FullDropdown
                      data={[
                        {
                          value: "4",
                          label: translation.patient_hip_function_q44_q2_a1,
                        },
                        {
                          value: "3",
                          label: translation.patient_hip_function_q44_q2_a2,
                        },
                        {
                          value: "2",
                          label: translation.patient_hip_function_q44_q2_a3,
                        },
                        {
                          value: "1",
                          label: translation.patient_hip_function_q44_q2_a4,
                        },
                        {
                          value: "0",
                          label:
                            translation.patient_hip_function_q44_q2_a5,
                        },
                      ]}
                      label={translation.patient_hip_function_q44_q2}
                      styleClass=""
                      placeholder={translation.none}
                      value={highestActivityL}
                      onChange={handleHighestActivityL}
                    />
                    <div>
                      {highestActivityL === "4" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a1_o1
                            }
                            selected={currsports === "16"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="16"
                            id="sports16"
                            name="currsports"
                          />

                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a1_o2
                            }
                            selected={currsports === "17"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="17"
                            id="sports17"
                            name="currsports"
                          />

                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a1_o3
                            }
                            selected={currsports === "18"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="18"
                            id="sports18"
                            name="currsports"
                          />
                        </>
                      )}

                      {highestActivityL === "3" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a2_o1
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "19"}
                            value="19"
                            id="sports19"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a2_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "20"}
                            value="20"
                            id="sports20"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={translation.patient_hip_function_q44_q2_a2_o3}
                            styleClass="custom_radio_box large"
                            selected={currsports === "21"}
                            value="21"
                            id="sports21"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={translation.patient_hip_function_q44_q2_a2_o4}
                            styleClass="custom_radio_box large"
                            selected={currsports === "22"}
                            value="22"
                            id="sports22"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={translation.patient_hip_function_q44_q2_a2_o5}
                            styleClass="custom_radio_box large"
                            selected={currsports === "23"}
                            value="23"
                            id="sports23"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}

                      {highestActivityL === "2" && (
                        <>
                          <RadioButton
                            label={translation.patient_hip_function_q44_q2_a3_o1}
                            styleClass="custom_radio_box large"
                            selected={currsports === "24"}
                            value="24"
                            id="sports24"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a3_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "25"}
                            value="25"
                            id="sports25"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={translation.patient_hip_function_q44_q2_a3_o3}
                            styleClass="custom_radio_box large"
                            selected={currsports === "26"}
                            value="26"
                            id="sports26"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a3_o4
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "27"}
                            value="27"
                            id="sports27"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}

                      {highestActivityL === "1" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a4_o1
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "28"}
                            value="28"
                            id="sports28"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a4_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "29"}
                            value="29"
                            id="sports29"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_hip_function_q44_q2_a4_o3
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "30"}
                            value="30"
                            id="sports30"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>



              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={`/hip-symptoms/${rId}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HipFunction;

import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import FormVerticalMenu from "./form-vertical-menu/FormVerticalMenu";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import InputField from "../../../custom/inputfield/InputField";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import assessmentStyles from "./PatientAssessment.module.css";
import { Validators } from "../../../../utilities/Validator";
import AssessmentPowerKgWidget from "../../../custom/assessment-widgets/AssessmentPowerKgWidget";
import AssessmentKgKgWidget from "../../../custom/assessment-widgets/AssessmentKgKgWidget";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import ScoreRangeSlider from "../../../custom/score-range-slider/ScoreRangeSlider";
import { useSearchParams } from "react-router-dom";
import Tab from "../../tab-section/ShoulderTab";
import ShoulderRangeSlider from "../../../custom/score-range-slider/ShoulderRangeSlider";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import { useDispatch, useSelector } from "react-redux";
import {
  updateFeedbackFunctionFieldScore,
  updateFeedbackFunctionScores,
  updateSACSFunctionScores,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
} from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/doctorTranslation";
import useTransition from "../../../customHooks/translations";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import CustomToaster from "../../../custom/toaster/CustomToaster";
function PatientAssessmentStep2() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const akpt = queryParams.get("akpt");
  const axiosInstance = useAxios();
  // const json = useSelector((state) => state.shoulderScore.doctorFeedback);
  const [painSco] = useSelector((state) => state.shoulderScore.painScore);
  const [funScore] = useSelector((state) => state.shoulderScore.functionScore);
  const [wosiScore] = useSelector((state) => state.shoulderScore.wosiScore);
  const [sacsScore] = useSelector((state) => state.shoulderScore.sacsScore);
  const [safisScore] = useSelector((state) => state.shoulderScore.safisScore);
  const [subscapularisPowerR, setSubscapularisPowerR] = useState(0);
  const [subscapularisKgR, setSubscapularisKgR] = useState(0);
  const [subscapularisPowerL, setSubscapularisPowerL] = useState(0);
  const [subscapularisKgL, setSubscapularisKgL] = useState(0);

  const [bearHugRightVal, setBearHugRightVal] = useState("");
  const [bearHugLeftVal, setBearHugLeftVal] = useState("");
  const [gerbersLiftRightVal, setGerbersLiftRightVal] = useState("");
  const [gerbersLiftLeftVal, setGerbersLiftLeftVal] = useState("");
  const [napoleonRightVal, setNapoleonRightVal] = useState("");
  const [napoleonLeftVal, setNapoleonLeftVal] = useState("");

  const [supraspinatusPowerR, setSupraspinatusPowerR] = useState(0);
  const [supraspinatusKgR, setSupraspinatusKgR] = useState(0);
  const [supraspinatusPowerL, setSupraspinatusPowerL] = useState(0);
  const [supraspinatusKgL, setSupraspinatusKgL] = useState(0);

  const [jobesTestRightVal, setJobesTestRightVal] = useState("");
  const [jobesTestLeftVal, setJobesTestLeftVal] = useState("");
  const [yocumTestRightVal, setYocumTestRightVal] = useState("");
  const [yocumTestLeftVal, setYocumTestLeftVal] = useState("");

  const [infraspinatusPowerR, setInfraspinatusPowerR] = useState(0);
  const [infraspinatusKgR, setInfraspinatusKgR] = useState(0);
  const [infraspinatusPowerL, setInfraspinatusPowerL] = useState(0);
  const [infraspinatusKgL, setInfraspinatusKgL] = useState(0);

  const [teresMinorPowerR, setTeresMinorPowerR] = useState(0);
  const [teresMinorKgR, setTeresMinorKgR] = useState(0);
  const [teresMinorPowerL, setTeresMinorPowerL] = useState(0);
  const [teresMinorKgL, setTeresMinorKgL] = useState(0);

  const [hornBlowerRightVal, setHornBlowerRightVal] = useState("");
  const [hornBlowerLeftVal, setHornBlowerLeftVal] = useState("");

  const [tendernessRightVal, setTendernessRightVal] = useState("");
  const [tendernessLeftVal, setTendernessLeftVal] = useState("");
  const [apInstabilityRightVal, setApInstabilityRightVal] = useState("");
  const [apInstabilityLeftVal, setApInstabilityLeftVal] = useState("");
  const [paxinosTestRightVal, setPaxinosTestRightVal] = useState("");
  const [paxinosTestLeftVal, setPaxinosTestLeftVal] = useState("");

  const [whippleRightVal, setWhippleRightVal] = useState("");
  const [whippleLeftVal, setWhippleLeftVal] = useState("");
  const [briensTestRightVal, setBriensTestRightVal] = useState("");
  const [briensTestLeftVal, setBriensTestLeftVal] = useState("");

  const [bicepsTendernessRightVal, setBicepsTendernessRightVal] = useState("");
  const [bicepsTendernessLeftVal, setBicepsTendernessLeftVal] = useState("");
  const [bicepsYergasonTestRightVal, setBicepsYergasonTestRightVal] =
    useState("");
  const [bicepsYergasonTestLeftVal, setBicepsYergasonTestLeftVal] =
    useState("");
  const [speedsTestRightVal, setSpeedsTestRightVal] = useState("");
  const [speedsTestLeftVal, setSpeedsTestLeftVal] = useState("");
  const [popeyeTestRightVal, setPopeyeTestRightVal] = useState("");
  const [popeyeTestLeftVal, setPopeyeTestLeftVal] = useState("");
  const [aersRightVal, setAersRightVal] = useState("");
  const [aersLeftVal, setAersLeftVal] = useState("");

  const [tricepsStrenthRightVal, setTricepsStrenthRightVal] = useState("");
  const [tricepsStrenthLeftVal, setTricepsStrenthLeftVal] = useState("");

  const [deltoidPowerStrenthR, setDeltoidPowerStrenthR] = useState(0);
  const [deltoidKgStrenthR, setDeltoidKgStrenthR] = useState(0);
  const [deltoidPowerStrenthL, setDeltoidPowerStrenthL] = useState(0);
  const [deltoidKgStrenthL, setDeltoidKgStrenthL] = useState(0);

  const [midPowerDeltoidStrenthR, setMidPowerDeltoidStrenthR] = useState(0);
  const [midKgDeltoidStrenthR, setMidKgDeltoidStrenthR] = useState(0);
  const [midPowerDeltoidStrenthL, setMidPowerDeltoidStrenthL] = useState(0);
  const [midKgDeltoidStrenthL, setMidKgDeltoidStrenthL] = useState(0);

  const [postPowerDeltoidStrenthR, setPostPowerDeltoidStrenthR] = useState(0);
  const [postKgDeltoidStrenthR, setPostKgDeltoidStrenthR] = useState(0);
  const [postPowerDeltoidStrenthL, setPostPowerDeltoidStrenthL] = useState(0);
  const [postKgDeltoidStrenthL, setPostKgDeltoidStrenthL] = useState(0);

  const [cspineVal, setCspineVal] = useState("");

  const [ssnStretchRightVal, setSsnStretchRightVal] = useState("");
  const [ssnStretchLeftVal, setSsnStretchLeftVal] = useState("");

  const [heriDegreesR, setHeriDegreesR] = useState(0);
  const [heriDegreesL, setHeriDegreesL] = useState(0);
  const [gageyDegreesR, setGageyDegreesR] = useState(0);
  const [gageyDegreesL, setGageyDegreesL] = useState(0);

  const [scapulaRightVal, setScapulaRightVal] = useState("");
  const [scapulaLeftVal, setScapulaLeftVal] = useState("");
  const [chk, setChk] = useState(false);

  const [ligamentousLaxityOptions, setLigamentousLaxityOptions] = useState({
    min: 1,
    max: 6,
    label: "",
  });
  const [ligamentousLaxityValue, setLigamentousLaxityValue] = useState("0");

  const [thoracicRightVal, setThoracicRightVal] = useState("");
  const [thoracicLeftVal, setThoracicLeftVal] = useState("");

  const [gripStrenthRightVal, setGripStrenthRightVal] = useState(0);
  const [gripStrenthLeftVal, setGripStrenthLeftVal] = useState(0);

  const [note, setNote] = useState("");
  const [leftArmDiagnosis, setLeftArmDiagnosis] = useState("");
  const [rightArmDiagnosis, setRightArmDiagnosis] = useState("");
  const [procedureValue, setProcedureValue] = useState("");
  const [outcomeValue, setOutcomeValue] = useState("");

  const [vmenuFix, setVmenuFix] = useState(false);
  const [numChracter,setnumChracter]=useState(500)
  useEffect(()=>{
    // setnumChracter(500-numOfChars)
    setnumChracter(500-note.length)
  },[note])
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setVmenuFix(window.pageYOffset > 140);
  };
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(
          updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
          // updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
        );
        // setActiveForwardDegreeR(res.data.data2[0].doctorFeedback[0].prom_forward_right)
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }
  const docFeedback= useSelector(
    (state) => state.shoulderScore.doctorFeedback[0]
  );

  // //Constant Score

  // const armposition_right =
  //   docFeedback.armposition_right === "1"
  //     ? 2
  //     : docFeedback.armposition_right === "2"
  //       ? 4
  //       : docFeedback.armposition_right === "3"
  //         ? 6
  //         : docFeedback.armposition_right === "4"
  //           ? 8
  //           : docFeedback.armposition_right === "5"
  //             ? 10
  //             : 0;

  // const armposition_left =
  //   docFeedback.armposition_left === "1"
  //     ? 2
  //     : docFeedback.armposition_left === "2"
  //       ? 4
  //       : docFeedback.armposition_left === "3"
  //         ? 6
  //         : docFeedback.armposition_left === "4"
  //           ? 8
  //           : docFeedback.armposition_left === "5"
  //             ? 10
  //             : 0;

  // const prom_forward_right =
  //   docFeedback.prom_forward_right <= 30
  //     ? 0
  //     : docFeedback.prom_forward_right === 31 ||
  //       docFeedback.prom_forward_right < 61
  //       ? 2
  //       : docFeedback.prom_forward_right === 61 ||
  //         docFeedback.prom_forward_right < 91
  //         ? 4
  //         : docFeedback.prom_forward_right === 91 ||
  //           docFeedback.prom_forward_right < 121
  //           ? 6
  //           : docFeedback.prom_forward_right === 121 ||
  //             docFeedback.prom_forward_right < 151
  //             ? 8
  //             : docFeedback.prom_forward_right === 151 ||
  //               docFeedback.prom_forward_right < 181
  //               ? 10
  //               : 0;

  // const prom_forward_left =
  //   docFeedback.prom_forward_left <= 30
  //     ? 0
  //     : docFeedback.prom_forward_left === 31 ||
  //       docFeedback.prom_forward_left < 61
  //       ? 2
  //       : docFeedback.prom_forward_left === 61 ||
  //         docFeedback.prom_forward_left < 91
  //         ? 4
  //         : docFeedback.prom_forward_left === 91 ||
  //           docFeedback.prom_forward_left < 121
  //           ? 6
  //           : docFeedback.prom_forward_left === 121 ||
  //             docFeedback.prom_forward_left < 151
  //             ? 8
  //             : docFeedback.prom_forward_left === 151 ||
  //               docFeedback.prom_forward_left < 181
  //               ? 10
  //               : 0;

  // const lateralelevation_right =
  //   docFeedback.lateralelevation_right <= 30
  //     ? 0
  //     : docFeedback.lateralelevation_right === 31 ||
  //       docFeedback.lateralelevation_right < 61
  //       ? 2
  //       : docFeedback.lateralelevation_right === 61 ||
  //         docFeedback.lateralelevation_right < 91
  //         ? 4
  //         : docFeedback.lateralelevation_right === 91 ||
  //           docFeedback.lateralelevation_right < 121
  //           ? 6
  //           : docFeedback.lateralelevation_right === 121 ||
  //             docFeedback.lateralelevation_right < 151
  //             ? 8
  //             : docFeedback.lateralelevation_right === 151 ||
  //               docFeedback.lateralelevation_right < 181
  //               ? 10
  //               : 0;

  // const lateralelevation_left =
  //   docFeedback.lateralelevation_left <= 30
  //     ? 0
  //     : docFeedback.lateralelevation_left === 31 ||
  //       docFeedback.lateralelevation_left < 61
  //       ? 2
  //       : docFeedback.lateralelevation_left === 61 ||
  //         docFeedback.lateralelevation_left < 91
  //         ? 4
  //         : docFeedback.lateralelevation_left === 91 ||
  //           docFeedback.lateralelevation_left < 121
  //           ? 6
  //           : docFeedback.lateralelevation_left === 121 ||
  //             docFeedback.lateralelevation_left < 151
  //             ? 8
  //             : docFeedback.lateralelevation_left === 151 ||
  //               docFeedback.lateralelevation_left < 181
  //               ? 10
  //               : 0;

  // const ir1_right =
  //   docFeedback.ir1_right === "1"
  //     ? 0
  //     : docFeedback.ir1_right === "2"
  //       ? 2
  //       : docFeedback.ir1_right === "3"
  //         ? 4
  //         : docFeedback.ir1_right === "4"
  //           ? 6
  //           : docFeedback.ir1_right === "5"
  //             ? 8
  //             : docFeedback.ir1_right === "6"
  //               ? 10
  //               : 0;

  // const ir1_left =
  //   docFeedback.ir1_left === "1"
  //     ? 0
  //     : docFeedback.ir1_left === "2"
  //       ? 2
  //       : docFeedback.ir1_left === "3"
  //         ? 4
  //         : docFeedback.ir1_left === "4"
  //           ? 6
  //           : docFeedback.ir1_left === "5"
  //             ? 8
  //             : docFeedback.ir1_left === "6"
  //               ? 10
  //               : 0;

  // const er2_right =
  //   docFeedback.er2_right === "1"
  //     ? 2
  //     : docFeedback.er2_right === "2"
  //       ? 4
  //       : docFeedback.er2_right === "3"
  //         ? 6
  //         : docFeedback.er2_right === "4"
  //           ? 8
  //           : docFeedback.er2_right === "5"
  //             ? 10
  //             : 0;

  // const er2_left =
  //   docFeedback.er2_left === "1"
  //     ? 2
  //     : docFeedback.er2_left === "2"
  //       ? 4
  //       : docFeedback.er2_left === "3"
  //         ? 6
  //         : docFeedback.er2_left === "4"
  //           ? 8
  //           : docFeedback.er2_left === "5"
  //             ? 10
  //             : 0;

  // const lateralelevatioRight =
  //   docFeedback.lateralelevation_right === 0
  //     ? 0
  //     : docFeedback.lateralelevation_right === 1 ||
  //       docFeedback.lateralelevation_right < 4
  //       ? 2
  //       : docFeedback.lateralelevation_right === 4 ||
  //         docFeedback.lateralelevation_right < 7
  //         ? 5
  //         : docFeedback.lateralelevation_right === 7 ||
  //           docFeedback.lateralelevation_right < 10
  //           ? 8
  //           : docFeedback.lateralelevation_right === 10 ||
  //             docFeedback.lateralelevation_right < 13
  //             ? 11
  //             : docFeedback.lateralelevation_right === 13 ||
  //               docFeedback.lateralelevation_right < 16
  //               ? 14
  //               : docFeedback.lateralelevation_right === 16 ||
  //                 docFeedback.lateralelevation_right < 19
  //                 ? 17
  //                 : docFeedback.lateralelevation_right === 19 ||
  //                   docFeedback.lateralelevation_right < 22
  //                   ? 20
  //                   : docFeedback.lateralelevation_right === 22 ||
  //                     docFeedback.lateralelevation_right < 25
  //                     ? 23
  //                     : docFeedback.lateralelevation_right > 24
  //                       ? 25
  //                       : 0;

  // const lateralelevatioLeft =
  //   docFeedback.lateralelevation_left === 0
  //     ? 0
  //     : docFeedback.lateralelevation_left === 1 ||
  //       docFeedback.lateralelevation_left < 4
  //       ? 2
  //       : docFeedback.lateralelevation_left === 4 ||
  //         docFeedback.lateralelevation_left < 7
  //         ? 5
  //         : docFeedback.lateralelevation_left === 7 ||
  //           docFeedback.lateralelevation_left < 10
  //           ? 8
  //           : docFeedback.lateralelevation_left === 10 ||
  //             docFeedback.lateralelevation_left < 13
  //             ? 11
  //             : docFeedback.lateralelevation_left === 13 ||
  //               docFeedback.lateralelevation_left < 16
  //               ? 14
  //               : docFeedback.lateralelevation_left === 16 ||
  //                 docFeedback.lateralelevation_left < 19
  //                 ? 17
  //                 : docFeedback.lateralelevation_left === 19 ||
  //                   docFeedback.lateralelevation_left < 22
  //                   ? 20
  //                   : docFeedback.lateralelevation_left === 22 ||
  //                     docFeedback.lateralelevation_left < 25
  //                     ? 23
  //                     : docFeedback.lateralelevation_left > 24
  //                       ? 25
  //                       : 0;

  // // const constant_score_right = sumAll(
  // //   armposition_right,
  // //   prom_forward_right,
  // //   lateralelevation_right,
  // //   ir1_right,
  // //   er2_right,
  // //   lateralelevatioRight
  // // );
  // const constant_score_right = 6

  // // const constant_score_Left = sumAll(
  // //   armposition_left,
  // //   prom_forward_left,
  // //   lateralelevation_left,
  // //   ir1_left,
  // //   er2_left,
  // //   lateralelevatioLeft
  // // );
  // const constant_score_Left = 6

  // // UCLA Score

  // //UCLA SCORE CALCULATION
  // const PaindescribesR =
  //   painSco.best_statment_pain_right === "0"
  //     ? 10
  //     : painSco.best_statment_pain_right === "1"
  //       ? 8
  //       : painSco.best_statment_pain_right === "2"
  //         ? 6
  //         : painSco.best_statment_pain_right === "3"
  //           ? 4
  //           : painSco.best_statment_pain_right === "4"
  //             ? 2
  //             : painSco.best_statment_pain_right === "5"
  //               ? 1
  //               : 0;

  // const PaindescribesL =
  //   painSco.best_statment_pain_left === "0"
  //     ? 10
  //     : painSco.best_statment_pain_left === "1"
  //       ? 8
  //       : painSco.best_statment_pain_left === "2"
  //         ? 6
  //         : painSco.best_statment_pain_left === "3"
  //           ? 4
  //           : painSco.best_statment_pain_left === "4"
  //             ? 2
  //             : painSco.best_statment_pain_left === "5"
  //               ? 1
  //               : 0;

  // const PainaffshoulderR =
  //   painSco.affected_shoulder_right === "0"
  //     ? 10
  //     : painSco.affected_shoulder_right === "1"
  //       ? 8
  //       : painSco.affected_shoulder_right === "2"
  //         ? 6
  //         : painSco.affected_shoulder_right === "3"
  //           ? 4
  //           : painSco.affected_shoulder_right === "4"
  //             ? 2
  //             : painSco.affected_shoulder_right === "5"
  //               ? 1
  //               : 0;

  // const PainaffshoulderL =
  //   painSco.affected_shoulder_left === "0"
  //     ? 10
  //     : painSco.affected_shoulder_left === "1"
  //       ? 8
  //       : painSco.affected_shoulder_left === "2"
  //         ? 6
  //         : painSco.affected_shoulder_left === "3"
  //           ? 4
  //           : painSco.affected_shoulder_left === "4"
  //             ? 2
  //             : painSco.affected_shoulder_left === "5"
  //               ? 1
  //               : 0;

  // const SatisfactionL =
  //   safisScore.satisfaction_left === "0"
  //     ? 0
  //     : safisScore.satisfaction_left === "1"
  //       ? 5
  //       : 0;
  // const SatisfactionR =
  //   safisScore.satisfaction_right === "0"
  //     ? 0
  //     : safisScore.satisfaction_left === "1"
  //       ? 5
  //       : 0;

  // // const ucla_score_right = sumAll(

  // // )
  // // const ucla_score_left = sumAll(

  // // )

  // const UCLAprom_forward_right =
  //   docFeedback.prom_forward_right <= 30
  //     ? 0
  //     : docFeedback.prom_forward_right === 31 ||
  //       docFeedback.prom_forward_right < 45
  //       ? 1
  //       : docFeedback.prom_forward_right === 45 ||
  //         docFeedback.prom_forward_right < 90
  //         ? 2
  //         : docFeedback.prom_forward_right === 90 ||
  //           docFeedback.prom_forward_right < 120
  //           ? 3
  //           : docFeedback.prom_forward_right === 120 ||
  //             docFeedback.prom_forward_right < 150
  //             ? 4
  //             : docFeedback.prom_forward_right > 150
  //               ? 5
  //               : 0;

  // const UCLAprom_forward_left =
  //   docFeedback.prom_forward_left <= 30
  //     ? 0
  //     : docFeedback.prom_forward_left === 31 ||
  //       docFeedback.prom_forward_left < 45
  //       ? 1
  //       : docFeedback.prom_forward_left === 45 ||
  //         docFeedback.prom_forward_left < 90
  //         ? 2
  //         : docFeedback.prom_forward_left === 90 ||
  //           docFeedback.prom_forward_left < 120
  //           ? 3
  //           : docFeedback.prom_forward_left === 120 ||
  //             docFeedback.prom_forward_left < 150
  //             ? 4
  //             : docFeedback.prom_forward_left > 150
  //               ? 5
  //               : 0;

  // const active_forward_degree_right =
  //   docFeedback.active_forward_degree_right === "0"
  //     ? 0
  //     : docFeedback.active_forward_degree_right === "1"
  //       ? 1
  //       : docFeedback.active_forward_degree_right === "2"
  //         ? 2
  //         : docFeedback.active_forward_degree_right === "3"
  //           ? 3
  //           : docFeedback.active_forward_degree_right === "4"
  //           ? 4
  //           : docFeedback.active_forward_degree_right === "5"
  //           ? 5
  //           : 0;
  // const active_forward_degree_left =
  //   docFeedback.active_forward_degree_left === "0"
  //     ? 1
  //     : docFeedback.active_forward_degree_left === "1"
  //       ? 2
  //       : docFeedback.active_forward_degree_left === "2"
  //         ? 3
  //         : docFeedback.active_forward_degree_left === "3"
  //           ? 4
  //           : 0;

  // // const ucla_score_right = sumAll(
  // //   UCLAprom_forward_right,
  // //   active_forward_degree_right,
  // //   PaindescribesR,
  // //   PainaffshoulderR,
  // //   SatisfactionR
  // // );
  // const ucla_score_right = 5
  // // const ucla_score_left = sumAll(
  // //   UCLAprom_forward_left,
  // //   active_forward_degree_left,
  // //   PaindescribesL,
  // //   PainaffshoulderL,
  // //   SatisfactionL
  // // );
  // const ucla_score_left = 5

  // //SST
  // let totalNum = 0;
  // if (painSco.best_statment_pain_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (painSco.shoulder_pain_night_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.lift05_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.lift45_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.lift10_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.throwunder_hand_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.throwover_hand_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.wash_back_right !== 0) {
  //   totalNum = totalNum + 1;
  // }
  // if (funScore.fullusual_work_right !== 0) {
  //   totalNum = totalNum + 1;
  // } else {
  //   totalNum = 0;
  // }

  // let totalNumL = 0;
  // if (painSco.best_statment_pain_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (painSco.shoulder_pain_night_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.lift05_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.lift45_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.lift10_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.throwunder_hand_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.throwover_hand_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.wash_back_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // }
  // if (funScore.fullusual_work_left !== 0) {
  //   totalNumL = totalNumL + 1;
  // } else {
  //   totalNumL = 0;
  // }

  // const sster2_right =
  //   docFeedback.er2_right === "1"
  //     ? 0
  //     : docFeedback.er2_right === "2"
  //       ? 0
  //       : docFeedback.er2_right === "3"
  //         ? 0
  //         : docFeedback.er2_right === "4"
  //           ? 1
  //           : docFeedback.er2_right === "5"
  //             ? 1
  //             : 0;

  // const sster2_left =
  //   docFeedback.er2_left === "1"
  //     ? 0
  //     : docFeedback.er2_left === "2"
  //       ? 0
  //       : docFeedback.er2_left === "3"
  //         ? 0
  //         : docFeedback.er2_left === "4"
  //           ? 1
  //           : docFeedback.er2_left === "5"
  //             ? 1
  //             : 0;

  // const sstarmposition_right =
  //   docFeedback.armposition_right === "1"
  //     ? 0
  //     : docFeedback.armposition_right === "2"
  //       ? 0
  //       : docFeedback.armposition_right === "3"
  //         ? 1
  //         : docFeedback.armposition_right === "4"
  //           ? 1
  //           : docFeedback.armposition_right === "5"
  //             ? 1
  //             : 0;

  // const sstarmposition_left =
  //   docFeedback.armposition_left === "1"
  //     ? 0
  //     : docFeedback.armposition_left === "2"
  //       ? 0
  //       : docFeedback.armposition_left === "3"
  //         ? 1
  //         : docFeedback.armposition_left === "4"
  //           ? 1
  //           : docFeedback.armposition_left === "5"
  //             ? 1
  //             : 0;

  // const sstir1_right =
  //   docFeedback.ir1_right === "1"
  //     ? 0
  //     : docFeedback.ir1_right === "2"
  //       ? 0
  //       : docFeedback.ir1_right === "3"
  //         ? 1
  //         : docFeedback.ir1_right === "4"
  //           ? 1
  //           : docFeedback.ir1_right === "5"
  //             ? 1
  //             : 0;
  // const sstir1_left =
  //   docFeedback.ir1_left === "1"
  //     ? 0
  //     : docFeedback.ir1_left === "2"
  //       ? 0
  //       : docFeedback.ir1_left === "3"
  //         ? 1
  //         : docFeedback.ir1_left === "4"
  //           ? 1
  //           : docFeedback.ir1_left === "5"
  //             ? 1
  //             : 0;
  // const SstdescribesR =
  //   painSco.best_statment_pain_right === "0"
  //     ? 1
  //     : painSco.best_statment_pain_right === "1"
  //       ? 1
  //       : painSco.best_statment_pain_right === "2"
  //         ? 0
  //         : painSco.best_statment_pain_right === "3"
  //           ? 0
  //           : painSco.best_statment_pain_right === "4"
  //             ? 0
  //             : painSco.best_statment_pain_right === "5"
  //               ? 0
  //               : 0;

  // const SstdescribesL =
  //   painSco.best_statment_pain_left === "0"
  //     ? 1
  //     : painSco.best_statment_pain_left === "1"
  //       ? 1
  //       : painSco.best_statment_pain_left === "2"
  //         ? 0
  //         : painSco.best_statment_pain_left === "3"
  //           ? 0
  //           : painSco.best_statment_pain_left === "4"
  //             ? 0
  //             : painSco.best_statment_pain_left === "5"
  //               ? 0
  //               : 0;

  // const shoulder_pain_night_left = parseInt(painSco.shoulder_pain_night_left);

  // const shoulder_pain_night_right = parseInt(painSco.shoulder_pain_night_right);

  // const lift05_right =
  //   funScore.lift05_right === "0"
  //     ? 0
  //     : funScore.lift05_right === "1"
  //       ? 0
  //       : funScore.lift05_right === "2"
  //         ? 0
  //         : funScore.lift05_right === "3"
  //           ? 1
  //           : 0;

  // const lift45_right =
  //   funScore.lift45_right === "0"
  //     ? 0
  //     : funScore.lift45_right === "1"
  //       ? 0
  //       : funScore.lift45_right === "2"
  //         ? 0
  //         : funScore.lift45_right === "3"
  //           ? 1
  //           : 0;

  // const lift10_right =
  //   funScore.lift10_right === "0"
  //     ? 0
  //     : funScore.lift10_right === "1"
  //       ? 0
  //       : funScore.lift10_right === "2"
  //         ? 0
  //         : funScore.lift10_right === "3"
  //           ? 1
  //           : 0;

  // const throwunder_hand_right =
  //   funScore.throwunder_hand_right === "0"
  //     ? 0
  //     : funScore.throwunder_hand_right === "1"
  //       ? 0
  //       : funScore.throwunder_hand_right === "2"
  //         ? 0
  //         : funScore.throwunder_hand_right === "3"
  //           ? 1
  //           : 0;

  // const throwover_hand_right =
  //   funScore.throwover_hand_right === "0"
  //     ? 0
  //     : funScore.throwover_hand_right === "1"
  //       ? 0
  //       : funScore.throwover_hand_right === "2"
  //         ? 0
  //         : funScore.throwover_hand_right === "3"
  //           ? 1
  //           : 0;

  // const wash_back_right =
  //   funScore.wash_back_right === "0"
  //     ? 0
  //     : funScore.wash_back_right === "1"
  //       ? 0
  //       : funScore.wash_back_right === "2"
  //         ? 0
  //         : funScore.wash_back_right === "3"
  //           ? 1
  //           : 0;

  // const fullusual_work_right =
  //   funScore.fullusual_work_right === "0"
  //     ? 0
  //     : funScore.fullusual_work_right === "1"
  //       ? 0
  //       : funScore.fullusual_work_right === "2"
  //         ? 0
  //         : funScore.fullusual_work_right === "3"
  //           ? 1
  //           : 0;

  // const lift05_left =
  //   funScore.lift05_left === "0"
  //     ? 0
  //     : funScore.lift05_left === "1"
  //       ? 0
  //       : funScore.lift05_left === "2"
  //         ? 0
  //         : funScore.lift05_left === "3"
  //           ? 1
  //           : 0;

  // const lift45_left =
  //   funScore.lift45_left === "0"
  //     ? 0
  //     : funScore.lift45_left === "1"
  //       ? 0
  //       : funScore.lift45_left === "2"
  //         ? 0
  //         : funScore.lift45_left === "3"
  //           ? 1
  //           : 0;

  // const lift10_left =
  //   funScore.lift10_left === "0"
  //     ? 0
  //     : funScore.lift10_left === "1"
  //       ? 0
  //       : funScore.lift10_left === "2"
  //         ? 0
  //         : funScore.lift10_left === "3"
  //           ? 1
  //           : 0;

  // const throwunder_hand_left =
  //   funScore.throwunder_hand_left === "0"
  //     ? 0
  //     : funScore.throwunder_hand_left === "1"
  //       ? 0
  //       : funScore.throwunder_hand_left === "2"
  //         ? 0
  //         : funScore.throwunder_hand_left === "3"
  //           ? 1
  //           : 0;

  // const throwover_hand_left =
  //   funScore.throwover_hand_left === "0"
  //     ? 0
  //     : funScore.throwover_hand_left === "1"
  //       ? 0
  //       : funScore.throwover_hand_left === "2"
  //         ? 0
  //         : funScore.throwover_hand_left === "3"
  //           ? 1
  //           : 0;

  // const wash_back_left =
  //   funScore.wash_back_left === "0"
  //     ? 0
  //     : funScore.wash_back_left === "1"
  //       ? 0
  //       : funScore.wash_back_left === "2"
  //         ? 0
  //         : funScore.wash_back_left === "3"
  //           ? 1
  //           : 0;

  // const fullusual_work_left =
  //   funScore.fullusual_work_left === "0"
  //     ? 0
  //     : funScore.fullusual_work_left === "1"
  //       ? 0
  //       : funScore.fullusual_work_left === "2"
  //         ? 0
  //         : funScore.fullusual_work_left === "3"
  //           ? 1
  //           : 0;

  // const SSTR = sumAll(
  //   SstdescribesR,
  //   shoulder_pain_night_right,
  //   lift05_right,
  //   lift45_right,
  //   lift10_right,
  //   throwunder_hand_right,
  //   throwover_hand_right,
  //   wash_back_right,
  //   fullusual_work_right,
  //   sster2_right,
  //   sstarmposition_right,
  //   sstir1_right
  // )
  // // const SSTcR = Math.floor(((SSTR / totalNum) * 100))
  // const SSTcR = Math.floor(((12 / 12) * 100))
  // let sst_score_right;
  // if (SSTcR === "NaN") {
  //   sst_score_right = null;
  // } else {
  //   sst_score_right = SSTcR;
  // }
  // const SSTL = sumAll(
  //   SstdescribesL,
  //   shoulder_pain_night_left,
  //   lift05_left,
  //   lift45_left,
  //   lift10_left,
  //   throwunder_hand_left,
  //   throwover_hand_left,
  //   wash_back_left,
  //   fullusual_work_left,
  //   sster2_left,
  //   sstarmposition_left,
  //   sstir1_left
  // )
  // // const SSTcL = Math.floor(((SSTL / totalNumL) * 100))
  // const SSTcL = Math.floor(((12 / 12) * 100))
  // let sst_score_left;
  // if (SSTcL === "NaN") {
  //   sst_score_left = null;
  // } else {
  //   sst_score_left = SSTcL;
  // }

  // subscapularis code for update state
  const subscapularisPowerChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "subscapularis_power_right",
        value: e,
      })
    );
    setSubscapularisPowerR(e);
  };

  const subscapularisPowerChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "subscapularis_power_left",
        value: e,
      })
    );
    setSubscapularisPowerL(e);
  };
  const subscapularisKgChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "subscapularis_kg_right",
        value: e,
      })
    );
    setSubscapularisKgR(e);
  };

  const subscapularisKgChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "subscapularis_kg_left",
        value: e,
      })
    );
    setSubscapularisKgL(e);
  };

  const bearHugChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "bearhug_right",
        value: e,
      })
    );
    setBearHugRightVal(e);
  };

  const bearHugChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "bearhug_left",
        value: e,
      })
    );
    setBearHugLeftVal(e);
  };

  const gerbersLiftChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "gerbers_lift_right",
        value: e,
      })
    );
    setGerbersLiftRightVal(e);
  };
  const gerbersLiftChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "gerbers_lift_left",
        value: e,
      })
    );
    setGerbersLiftLeftVal(e);
  };
  const napoleonChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "napoleon_right",
        value: e,
      })
    );
    setNapoleonRightVal(e);
  };
  const napoleonChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "napoleon_left",
        value: e,
      })
    );
    setNapoleonLeftVal(e);
  };

  // supraspinatus code for update state
  const supraspinatusPowerChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "supraspinatus_power_right",
        value: e,
      })
    );
    setSupraspinatusPowerR(e);
  };

  const supraspinatusKgChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "supraspinatus_kg_right",
        value: e,
      })
    );
    setSupraspinatusKgR(e);
  };
  const supraspinatusPowerChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "supraspinatus_power_left",
        value: e,
      })
    );
    setSupraspinatusPowerL(e);
  };
  const supraspinatusKgChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "supraspinatus_kg_left",
        value: e,
      })
    );
    setSupraspinatusKgL(e);
  };

  const jobesTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "jobes_right",
        value: e,
      })
    );
    setJobesTestRightVal(e);
  };

  const jobesTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "jobes_left",
        value: e,
      })
    );
    setJobesTestLeftVal(e);
  };
  const yocumTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "yocum_right",
        value: e,
      })
    );
    setYocumTestRightVal(e);
  };
  const yocumTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "yocum_left",
        value: e,
      })
    );
    setYocumTestLeftVal(e);
  };

  // infraspinatus code for update state
  const infraspinatusPowerChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "infraspinatus_power_right",
        value: e,
      })
    );
    setInfraspinatusPowerR(e);
  };
  const infraspinatusKgChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "infraspinatus_kg_right",
        value: e,
      })
    );
    setInfraspinatusKgR(e);
  };
  const infraspinatusPowerChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "infraspinatus_power_left",
        value: e,
      })
    );
    setInfraspinatusPowerL(e);
  };
  const infraspinatusKgChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "infraspinatus_kg_left",
        value: e,
      })
    );
    setInfraspinatusKgL(e);
  };

  // Teres Mminor code for update state

  const teresMinorPowerChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "teresminor_right",
        value: e,
      })
    );
    setTeresMinorPowerR(e);
  };
  const teresMinorKgChangeR = (e) => {
    /////////////////////////////////////////////////////////
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "",
        value: e,
      })
    );
    setTeresMinorKgR(e);
  };
  const teresMinorPowerChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "teresminor_left",
        value: e,
      })
    );
    setTeresMinorPowerL(e);
  };
  const teresMinorKgChangeL = (e) => {
    ///////////////////////////////////////////////////////////
    console.log("teresMinorKgChangeL", e);
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "",
        value: e,
      })
    );
    setTeresMinorKgL(e);
  };

  const hornBlowerChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "hornblower_right",
        value: e,
      })
    );
    setHornBlowerRightVal(e);
  };
  const hornBlowerChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "hornblower_left",
        value: e,
      })
    );
    setHornBlowerLeftVal(e);
  };

  // Tenderness Mminor code for update state

  const tendernessChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "tenderness_right",
        value: e,
      })
    );
    setTendernessRightVal(e);
  };
  const tendernessChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "tenderness_left",
        value: e,
      })
    );
    setTendernessLeftVal(e);
  };
  const apInstabilityChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "apinstability_right",
        value: e,
      })
    );
    setApInstabilityRightVal(e);
  };
  const apInstabilityChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "apinstability_left",
        value: e,
      })
    );
    setApInstabilityLeftVal(e);
  };
  const paxinosTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "paxinos_right",
        value: e,
      })
    );
    setPaxinosTestRightVal(e);
  };
  const paxinosTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "paxinos_left",
        value: e,
      })
    );
    setPaxinosTestLeftVal(e);
  };

  // Labrum Mminor code for update state

  const whippleChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "whipple_right",
        value: e,
      })
    );
    setWhippleRightVal(e);
  };
  const whippleChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "whipple_left",
        value: e,
      })
    );
    setWhippleLeftVal(e);
  };
  const briensTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "briens_right",
        value: e,
      })
    );
    setBriensTestRightVal(e);
  };
  const briensTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "briens_left",
        value: e,
      })
    );
    setBriensTestLeftVal(e);
  };

  // Biceps Mminor code for update state

  const bicepsTendernessChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "biceps_tenderness_right",
        value: e,
      })
    );
    setBicepsTendernessRightVal(e);
  };
  const bicepsTendernessChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "biceps_tenderness_left",
        value: e,
      })
    );
    setBicepsTendernessLeftVal(e);
  };
  const bicepsYergasonTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "biceps_yergason_test_right",
        value: e,
      })
    );
    setBicepsYergasonTestRightVal(e);
  };
  const bicepsYergasonTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "biceps_yergason_test_left",
        value: e,
      })
    );
    setBicepsYergasonTestLeftVal(e);
  };
  const speedsTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "speeds_right",
        value: e,
      })
    );
    setSpeedsTestRightVal(e);
  };
  const speedsTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "speeds_left",
        value: e,
      })
    );
    setSpeedsTestLeftVal(e);
  };
  const popeyeTestChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "popeye_right",
        value: e,
      })
    );
    setPopeyeTestRightVal(e);
  };
  const popeyeTestChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "popeye_left",
        value: e,
      })
    );
    setPopeyeTestLeftVal(e);
  };
  const aersChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "aers_right",
        value: e,
      })
    );
    setAersRightVal(e);
  };
  const aersChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "aers_left",
        value: e,
      })
    );
    setAersLeftVal(e);
  };

  // TricepsStrenth Mminor code for update state

  const tricepsStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "triceps_right",
        value: e,
      })
    );
    setTricepsStrenthRightVal(e);
  };
  const tricepsStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "triceps_left",
        value: e,
      })
    );
    setTricepsStrenthLeftVal(e);
  };

  // Deltoid strenth code for update state

  const deltoidPowerStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "deltoidpower_right",
        value: e,
      })
    );
    setDeltoidPowerStrenthR(e);
  };
  const deltoidKgStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "deltoid_right",
        value: e,
      })
    );
    setDeltoidKgStrenthR(e);
  };
  const deltoidPowerStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "deltoidpower_left",
        value: e,
      })
    );
    setDeltoidPowerStrenthL(e);
  };
  const deltoidKgStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "deltoid_left",
        value: e,
      })
    );
    setDeltoidKgStrenthL(e);
  };

  const midPowerDeltoidStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "midpower_deltoid_right",
        value: e,
      })
    );
    setMidPowerDeltoidStrenthR(e);
  };
  const midKgDeltoidStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "middeltoid_right",
        value: e,
      })
    );
    setMidKgDeltoidStrenthR(e);
  };
  const midPowerDeltoidStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "midpower_deltoid_left",
        value: e,
      })
    );
    setMidPowerDeltoidStrenthL(e);
  };
  const midKgDeltoidStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "middeltoid_left",
        value: e,
      })
    );
    setMidKgDeltoidStrenthL(e);
  };

  const postPowerDeltoidStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "postpower_deltoid_right",
        value: e,
      })
    );
    setPostPowerDeltoidStrenthR(e);
  };
  const postKgDeltoidStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "postdeltoid_right",
        value: e,
      })
    );
    setPostKgDeltoidStrenthR(e);
  };
  const postPowerDeltoidStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "postpower_deltoid_left",
        value: e,
      })
    );
    setPostPowerDeltoidStrenthL(e);
  };
  const postKgDeltoidStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "postdeltoid_left",
        value: e,
      })
    );
    setPostKgDeltoidStrenthL(e);
  };

  // cspine strenth code for update state

  const cspineChange = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "cspine",
        value: e,
      })
    );
    setCspineVal(e);
  };

  // ssnStretch code for update state

  const ssnStretchChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ssn_stretch_right",
        value: e,
      })
    );
    setSsnStretchRightVal(e);
  };
  const ssnStretchChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ssn_stretch_left",
        value: e,
      })
    );
    setSsnStretchLeftVal(e);
  };

  // HeriDegrees code for update state
  const heriDegreesChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "heri_right",
        value: e,
      })
    );
    setHeriDegreesR(e);
  };
  const heriDegreesChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "heri_left",
        value: e,
      })
    );
    setHeriDegreesL(e);
  };

  // HeriDegrees code for update state
  const gageyDegreesChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "gagey_right",
        value: e,
      })
    );
    setGageyDegreesR(e);
  };
  const gageyDegreesChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "gagey_left",
        value: e,
      })
    );
    setGageyDegreesL(e);
  };

  // Scapula Dyskinesia code for update state

  const scapulaChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "scapula_right",
        value: e,
      })
    );
    setScapulaRightVal(e);
  };
  const scapulaChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "scapula_left",
        value: e,
      })
    );
    setScapulaLeftVal(e);
  };

  // ligamentous Laxity Dyskinesia code for update state

  const ligamentousLaxityChange = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ligamentous",
        value: e,
      })
    );
    setLigamentousLaxityValue(e);
  };

  // Scapula Dyskinesia code for update state

  const thoracicChangeRight = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "thoracic_right",
        value: e,
      })
    );
    setThoracicRightVal(e);
  };
  const thoracicChangeLeft = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "thoracic_left",
        value: e,
      })
    );
    setThoracicLeftVal(e);
  };

  // grip Strenth Mminor code for update state

  const gripStrenthChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "grip_right",
        value: e,
      })
    );
    setGripStrenthRightVal(e);
  };
  const gripStrenthChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "grip_left",
        value: e,
      })
    );
    setGripStrenthLeftVal(e);
  };

  const updateNote = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "treatment_notes2value",
        value: e,
      })
    );
    setNote(e);
  };
  const DiagnosisR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "right_diagnosis",
        value: e,
      })
    );
    setRightArmDiagnosis(e);
  };
  const DiagnosisL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "left_diagnosis",
        value: e,
      })
    );
    setLeftArmDiagnosis(e);
  };

  const Procedure = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "procedure",
        value: e,
      })
    );
    setProcedureValue(e);
  };
  const Outcome = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "outcome_treatment",
        value: e,
      })
    );
    setOutcomeValue(e);
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);
  const json = useSelector((state) => state.shoulderScore.doctorFeedback);
  const updateDate = async () => {
    const response =await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  
    if (response.status===200) {
      setMsg(translation.update)
      setmsgType('success')
      setTimeout(() => {
        setMsg('')
        setmsgType('success')
      }, 2000);
      setDisplayConfirmationModal(true)
     
    }
  };
  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
      }
    }
  },[json])
  // const json1 = {
  //   constant_score_right,
  //   constant_score_Left,
  //   ucla_score_left,
  //   ucla_score_right,
  //   sst_score_right,
  //   sst_score_left,
  // };


  const updateDate1 = async () => {
    const response = await axiosInstance.current.put(
      `extapp/forms/update_total_scores?resource_id=${rId}`,
      // json1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(response)
    if (response) {
      // window.open(process.env.REACT_APP_DOCTOR_URL , "_blank")
      if (pId) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "doctor-score-list" + `/${pId}`,
          "_blank"
        );
      }
      if (akpt) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "doctor-score-list" + `/${akpt}`,
          "_blank"
        );
      }
      window.close();
      // window.location.href =
      //   process.env.REACT_APP_DOCTOR_URL ;
    }
  };

  const handleSubmit = () => {
    updateDate();
    // updateDate1();
  };

 
  useEffect(() => {
    setSubscapularisPowerR(docFeedback.subscapularis_power_right);
    setSubscapularisPowerL(docFeedback.subscapularis_power_left);
    setSubscapularisKgR(docFeedback.subscapularis_kg_right);
    setSubscapularisKgL(docFeedback.subscapularis_kg_left);
    setBearHugRightVal(docFeedback.bearhug_right);
    setBearHugLeftVal(docFeedback.bearhug_left);
    setGerbersLiftRightVal(docFeedback.gerbers_lift_right);
    setGerbersLiftLeftVal(docFeedback.gerbers_lift_left);
    setNapoleonRightVal(docFeedback.napoleon_right);
    setNapoleonLeftVal(docFeedback.napoleon_left);
    setSupraspinatusPowerR(docFeedback.supraspinatus_power_right);
    setSupraspinatusKgR(docFeedback.supraspinatus_kg_right);
    setSupraspinatusPowerL(docFeedback.supraspinatus_power_left);
    setSupraspinatusKgL(docFeedback.supraspinatus_kg_left);
    setJobesTestRightVal(docFeedback.jobes_right);
    setJobesTestLeftVal(docFeedback.jobes_left);
    setYocumTestRightVal(docFeedback.yocum_right);
    setYocumTestLeftVal(docFeedback.yocum_left);
    setInfraspinatusPowerR(docFeedback.infraspinatus_power_right);
    setInfraspinatusKgR(docFeedback.infraspinatus_kg_right);
    setInfraspinatusPowerL(docFeedback.infraspinatus_power_left);
    setInfraspinatusKgL(docFeedback.infraspinatus_kg_left);
    setTeresMinorPowerR(docFeedback.teresminor_right);
    setTeresMinorPowerL(docFeedback.teresminor_left);
    setHornBlowerLeftVal(docFeedback.hornblower_left);
    setHornBlowerRightVal(docFeedback.hornblower_right);
    setTendernessRightVal(docFeedback.tenderness_right);
    setTendernessLeftVal(docFeedback.tenderness_left);
    setApInstabilityRightVal(docFeedback.apinstability_right);
    setApInstabilityLeftVal(docFeedback.apinstability_left);
    setPaxinosTestRightVal(docFeedback.paxinos_right);
    setPaxinosTestLeftVal(docFeedback.paxinos_left);
    setWhippleRightVal(docFeedback.whipple_right);
    setWhippleLeftVal(docFeedback.whipple_left);
    setBriensTestRightVal(docFeedback.briens_right);
    setBriensTestLeftVal(docFeedback.briens_left);
    setBicepsTendernessRightVal(docFeedback.biceps_tenderness_right);
    setBicepsTendernessLeftVal(docFeedback.biceps_tenderness_left);
    setBicepsYergasonTestRightVal(docFeedback.biceps_yergason_test_right);
    setBicepsYergasonTestLeftVal(docFeedback.biceps_yergason_test_left);
    setSpeedsTestRightVal(docFeedback.speeds_right);
    setSpeedsTestLeftVal(docFeedback.speeds_left);
    setPopeyeTestRightVal(docFeedback.popeye_right);
    setPopeyeTestLeftVal(docFeedback.popeye_left);
    setAersRightVal(docFeedback.aers_right);
    setAersLeftVal(docFeedback.aers_left);
    setTricepsStrenthRightVal(docFeedback.triceps_right);
    setTricepsStrenthLeftVal(docFeedback.triceps_left);
    setDeltoidPowerStrenthR(docFeedback.deltoidpower_right);
    setDeltoidKgStrenthR(docFeedback.deltoid_right);
    setDeltoidPowerStrenthL(docFeedback.deltoidpower_left);
    setDeltoidKgStrenthL(docFeedback.deltoid_left);
    setMidPowerDeltoidStrenthR(docFeedback.midpower_deltoid_right);
    setMidKgDeltoidStrenthR(docFeedback.middeltoid_right);
    setMidPowerDeltoidStrenthL(docFeedback.midpower_deltoid_left);
    setMidKgDeltoidStrenthL(docFeedback.middeltoid_left);
    setPostPowerDeltoidStrenthR(docFeedback.postpower_deltoid_right);
    setPostKgDeltoidStrenthR(docFeedback.postdeltoid_right);
    setPostPowerDeltoidStrenthL(docFeedback.postpower_deltoid_left);
    setPostKgDeltoidStrenthL(docFeedback.postdeltoid_left);
    setCspineVal(docFeedback.cspine);
    setSsnStretchRightVal(docFeedback.ssn_stretch_right);
    setSsnStretchLeftVal(docFeedback.ssn_stretch_left);
    setHeriDegreesR(docFeedback.heri_right);
    setHeriDegreesL(docFeedback.heri_left);
    setGageyDegreesR(docFeedback.gagey_right);
    setGageyDegreesL(docFeedback.gagey_left);
    setScapulaRightVal(docFeedback.scapula_right);
    setScapulaLeftVal(docFeedback.scapula_left);
    setLigamentousLaxityValue(docFeedback.ligamentous);
    setThoracicRightVal(docFeedback.thoracic_right);
    setThoracicLeftVal(docFeedback.thoracic_left);
    setGripStrenthRightVal(docFeedback.grip_right);
    setGripStrenthLeftVal(docFeedback.grip_left);
    setNote(docFeedback.treatment_notes2value);
    setRightArmDiagnosis(docFeedback.right_diagnosis);
    setLeftArmDiagnosis(docFeedback.left_diagnosis);
    setProcedureValue(docFeedback.procedure);
    setOutcomeValue(docFeedback.outcome_treatment);
  }, [docFeedback]);

  // ---
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 10,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);
 
  return (
    <>
     <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="pain_screen mb-5">
        <AssessmentHeader />
        <HeaderInnerBreadcrumb />
        {doctorEnable === "true" && <Tab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
        
      </div>
    
      <div
        className={`patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w ${
          vmenuFix ? "fixed_vertical_menu" : ""
        }`}
      >
        <div className={`${assessmentStyles.inner_content_box}`}>
          <div className={`${assessmentStyles.accordion_sidebar}`}>
            <FormVerticalMenu  setGetId={setGetId}/>
          </div>
          <div
            className={`accordion_tab_content ${assessmentStyles.accordion_tab_content}`}
          >
            <div
              id="subscapularis"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Subscapularis}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                  
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          powerAction="true"
                          kgAction="true"
                          power={subscapularisPowerR}
                          kg={subscapularisKgR}
                          onChangeP={subscapularisPowerChangeR}
                          onChangeK={subscapularisKgChangeR}
                          label={translation.Right_Arm}
                          nameR="subscapularisPowerR"
                          nameL="subscapularisKgR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={subscapularisPowerL}
                          kg={subscapularisKgL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={subscapularisPowerChangeL}
                          onChangeK={subscapularisKgChangeL}
                          label={translation.Left_Arm}
                          nameR="subscapularisPowerL"
                          nameL="subscapularisKgL"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Bear_Hug}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="bearHugnveR"
                          idRight="bearHugPveR"
                          name="bearHugR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={bearHugRightVal}
                          onChange={bearHugChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="bearHugnveL"
                          idRight="bearHugPveL"
                          name="bearHugL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={bearHugLeftVal}
                          onChange={bearHugChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Gerbers_LiftOff}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="gerbersLiftNveR"
                          idRight="gerbersLiftPveR"
                          name="gerbersLiftR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={gerbersLiftRightVal}
                          onChange={gerbersLiftChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="gerbersLiftNveL"
                          idRight="gerbersLiftPveL"
                          name="gerbersLiftL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={gerbersLiftLeftVal}
                          onChange={gerbersLiftChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Napoleon_Belly_Press}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="napoleonNveR"
                          idRight="napoleonPveR"
                          name="napoleonR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={napoleonRightVal}
                          onChange={napoleonChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="napoleonNveL"
                          idRight="napoleonPveL"
                          name="napoleonL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={napoleonLeftVal}
                          onChange={napoleonChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="supraspinatus"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Supraspinatus}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                  
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={supraspinatusPowerR}
                          kg={supraspinatusKgR}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={supraspinatusPowerChangeR}
                          onChangeK={supraspinatusKgChangeR}
                          label={translation.Right_Arm}
                          nameR="supraspinatusPowerR"
                          nameL="supraspinatusKgR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={supraspinatusPowerL}
                          kg={supraspinatusKgL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={supraspinatusPowerChangeL}
                          onChangeK={supraspinatusKgChangeL}
                          label={translation.Left_Arm}
                          nameR="supraspinatusPowerL"
                          nameL="supraspinatusKgL"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Jobes_Test}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="jobesTestNveR"
                          idRight="jobesTestPveR"
                          name="jobesTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={jobesTestRightVal}
                          onChange={jobesTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="jobesTestNveL"
                          idRight="jobesTestPveL"
                          name="jobesTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={jobesTestLeftVal}
                          onChange={jobesTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Gerbers_Lift_Off}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="yocumTestNveR"
                          idRight="yocumTestPveR"
                          name="yocumTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={yocumTestRightVal}
                          onChange={yocumTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="yocumTestNveL"
                          idRight="yocumTestPveL"
                          name="yocumTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={yocumTestLeftVal}
                          onChange={yocumTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="infraspinatus"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Infraspinatus}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                    
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={infraspinatusPowerR}
                          kg={infraspinatusKgR}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={infraspinatusPowerChangeR}
                          onChangeK={infraspinatusKgChangeR}
                          label={translation.Right_Arm}
                          nameR="infraspinatusPowerR"
                          nameL="infraspinatusKgR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={infraspinatusPowerL}
                          kg={infraspinatusKgL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={infraspinatusPowerChangeL}
                          onChangeK={infraspinatusKgChangeL}
                          label={translation.Left_Arm}
                          nameR="infraspinatusPowerL"
                          nameL="infraspinatusKgL"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="teres-minor"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>{translation.Teres_Minor}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                    
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={teresMinorPowerR}
                          kg={teresMinorKgR}
                          powerAction="true"
                          onChangeP={teresMinorPowerChangeR}
                          onChangeK={teresMinorKgChangeR}
                          label={translation.Right_Arm}
                          nameR="teresMinorPowerR"
                          nameL="teresMinorKgR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={teresMinorPowerL}
                          kg={teresMinorKgL}
                          powerAction="true"
                          onChangeP={teresMinorPowerChangeL}
                          onChangeK={teresMinorKgChangeL}
                          label={translation.Left_Arm}
                          nameR="teresMinorPowerL"
                          nameL="teresMinorKgL"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Horn_Blowers}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="hornBlowerNveR"
                          idRight="hornBlowerPveR"
                          name="hornBlowerR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={hornBlowerRightVal}
                          onChange={hornBlowerChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="hornBlowerNveL"
                          idRight="hornBlowerPveL"
                          name="hornBlowerL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={hornBlowerLeftVal}
                          onChange={hornBlowerChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="acj"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>{translation.ACJ}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Tenderness}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft="No"
                          labelRight="Yes"
                          idLeft="tendernessNveR"
                          idRight="tendernessPveR"
                          name="tendernessR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={tendernessRightVal}
                          onChange={tendernessChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft="No"
                          labelRight="Yes"
                          idLeft="tendernessNveL"
                          idRight="tendernessPveL"
                          name="tendernessL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={tendernessLeftVal}
                          onChange={tendernessChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.AP_Instablility}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="apInstabilityNveR"
                          idRight="apInstabilityPveR"
                          name="apInstabilityR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={apInstabilityRightVal}
                          onChange={apInstabilityChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="apInstabilityNveL"
                          idRight="apInstabilityPveL"
                          name="apInstabilityL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={apInstabilityLeftVal}
                          onChange={apInstabilityChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Paxinos_Test}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="paxinosTestNveR"
                          idRight="paxinosTestPveR"
                          name="paxinosTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={paxinosTestRightVal}
                          onChange={paxinosTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="paxinosTestNveL"
                          idRight="paxinosTestPveL"
                          name="paxinosTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={paxinosTestLeftVal}
                          onChange={paxinosTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="labrum"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>{translation.Labrum}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Whipple}
                      </h4>
                       
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="whippleNveR"
                          idRight="whipplePveR"
                          name="whippleR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={whippleRightVal}
                          onChange={whippleChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="whippleNveL"
                          idRight="whipplePveL"
                          name="whippleL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={whippleLeftVal}
                          onChange={whippleChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.O_Briens_Test}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="briensTestNveR"
                          idRight="briensTestPveR"
                          name="briensTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={briensTestRightVal}
                          onChange={briensTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="briensTestNveL"
                          idRight="briensTestPveL"
                          name="briensTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={briensTestLeftVal}
                          onChange={briensTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="biceps"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>{translation.Biceps}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Tenderness}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft="No"
                          labelRight="Yes"
                          idLeft="bicepsTendernessNveR"
                          idRight="bicepsTendernessPveR"
                          name="bicepsTendernessR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={bicepsTendernessRightVal}
                          onChange={bicepsTendernessChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft="No"
                          labelRight="Yes"
                          idLeft="bicepsTendernessNveL"
                          idRight="bicepsTendernessPveL"
                          name="bicepsTendernessL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={bicepsTendernessLeftVal}
                          onChange={bicepsTendernessChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Yergasons_Test}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="bicepsYergasonTestNveR"
                          idRight="bicepsYergasonTestPveR"
                          name="bicepsYergasonTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={bicepsYergasonTestRightVal}
                          onChange={bicepsYergasonTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="bicepsYergasonTestNveL"
                          idRight="bicepsYergasonTestPveL"
                          name="bicepsYergasonTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={bicepsYergasonTestLeftVal}
                          onChange={bicepsYergasonTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Speeds_Test}
                      </h4>
                  
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="speedsTestNveR"
                          idRight="speedsTestPveR"
                          name="speedsTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={speedsTestRightVal}
                          onChange={speedsTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="speedsTestNveL"
                          idRight="speedsTestPveL"
                          name="speedsTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={speedsTestLeftVal}
                          onChange={speedsTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Popeye}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="popeyeTestNveR"
                          idRight="popeyeTestPveR"
                          name="popeyeTestR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={popeyeTestRightVal}
                          onChange={popeyeTestChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="popeyeTestNveL"
                          idRight="popeyeTestPveL"
                          name="popeyeTestL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={popeyeTestLeftVal}
                          onChange={popeyeTestChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.A_ER_S}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="aersNveR"
                          idRight="aersPveR"
                          name="aersR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={aersRightVal}
                          onChange={aersChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="aersNveL"
                          idRight="aersPveL"
                          name="aersL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={aersLeftVal}
                          onChange={aersChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="triceps-strength"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Triceps Strength</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <div className="col-md-6 col-sm-6">
                        <AssessmentKgKgWidget
                          power={tricepsStrenthRightVal}
                          kg={tricepsStrenthLeftVal}
                          rightArmkgAction="true"
                          leftArmkgAction="true"
                          onChangeP={tricepsStrenthChangeR}
                          onChangeK={tricepsStrenthChangeL}
                          labelR={translation.Right_Arm}
                          labelL={translation.Left_Arm}
                          nameR="tricepsStrenthR"
                          nameL="tricepsStrenthL"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="deltoid"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Deltoid_Power_Strength}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Ant_Deltoid}
                      </h4>
                     
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={deltoidPowerStrenthR}
                          kg={deltoidKgStrenthR}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={deltoidPowerStrenthChangeR}
                          onChangeK={deltoidKgStrenthChangeR}
                          label={translation.Right_Arm}
                          nameR="deltoidPowerStrenthR"
                          nameL="deltoidKgStrenthR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={deltoidPowerStrenthL}
                          kg={deltoidKgStrenthL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={deltoidPowerStrenthChangeL}
                          onChangeK={deltoidKgStrenthChangeL}
                          label={translation.Left_Arm}
                          nameR="deltoidPowerStrenthL"
                          nameL="deltoidKgStrenthL"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Mid_Deltoid}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={midPowerDeltoidStrenthR}
                          kg={midKgDeltoidStrenthR}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={midPowerDeltoidStrenthChangeR}
                          onChangeK={midKgDeltoidStrenthChangeR}
                          label={translation.Right_Arm}
                          nameR="midPowerDeltoidStrenthR"
                          nameL="midKgDeltoidStrenthR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={midPowerDeltoidStrenthL}
                          kg={midKgDeltoidStrenthL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={midPowerDeltoidStrenthChangeL}
                          onChangeK={midKgDeltoidStrenthChangeL}
                          label={translation.Left_Arm}
                          nameR="midPowerDeltoidStrenthL"
                          nameL="midKgDeltoidStrenthL"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        {translation.Post_Deltoid}
                      </h4>
                      
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={postPowerDeltoidStrenthR}
                          kg={postKgDeltoidStrenthR}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={postPowerDeltoidStrenthChangeR}
                          onChangeK={postKgDeltoidStrenthChangeR}
                          label={translation.Right_Arm}
                          nameR="postPowerDeltoidStrenthR"
                          nameL="postKgDeltoidStrenthR"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <AssessmentPowerKgWidget
                          power={postPowerDeltoidStrenthL}
                          kg={postKgDeltoidStrenthL}
                          powerAction="true"
                          kgAction="true"
                          onChangeP={postPowerDeltoidStrenthChangeL}
                          onChangeK={postKgDeltoidStrenthChangeL}
                          label={translation.Left_Arm}
                          nameR="postPowerDeltoidStrenthL"
                          nameL="postKgDeltoidStrenthL"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div
                  id="cspine"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>
                        {translation.C_Spine_Mobility}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30">
                          <div className="col-md-12 col-sm-12">
                            <RadioSwitch
                              labelLeft="Normal"
                              labelRight="Restricted"
                              idLeft="cspineNveR"
                              idRight="cspinePveR"
                              name="cspine"
                              title=""
                              styleClass=""
                              value={cspineVal}
                              onChange={cspineChange}
                              placeholder="select"
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <div
              id="ssn-stretch"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Suprascapular_Nerve_Stretch_Test}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                   
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="ssnStretchNveR"
                          idRight="ssnStretchPveR"
                          name="ssnStretchR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={ssnStretchRightVal}
                          onChange={ssnStretchChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="ssnStretchNveL"
                          idRight="ssnStretchPveL"
                          name="ssnStretchL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={ssnStretchLeftVal}
                          onChange={ssnStretchChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div
                  id="heri"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header className="text_height">
                        {translation.Hyper_Extension_Rotation_HERI_Test}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30">
                          <div className="col-md-12 col-sm-12">
                            <AssessmentDegreeWidget
                              valueR={heriDegreesR}
                              valueL={heriDegreesL}
                              onChangeR={heriDegreesChangeR}
                              onChangeL={heriDegreesChangeL}
                              labelL={translation.Left_ArS}
                              labelR={translation.Right_ArS}
                              nameR="heriDegrees Right"
                              nameL="heriDegrees Left"
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div
                  id="gagey"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>
                        {translation.GAGEY_Hyperabduction_Test}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30">
                          <div className="col-md-12 col-sm-12">
                            <AssessmentDegreeWidget
                              valueR={gageyDegreesR}
                              valueL={gageyDegreesL}
                              onChangeR={gageyDegreesChangeR}
                              onChangeL={gageyDegreesChangeL}
                              labelL={translation.Left_ArS}
                              labelR={translation.Right_ArS}
                              nameR="gageyDegrees Right"
                              nameL="gageyDegrees Left"
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <div
              id="scapula-dyskinesia"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Scapula_Dyskinesia}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                    
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="scapulaNveR"
                          idRight="scapulaPveR"
                          name="scapulaR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={scapulaRightVal}
                          onChange={scapulaChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="scapulaNveL"
                          idRight="scapulaPveL"
                          name="scapulaL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={scapulaLeftVal}
                          onChange={scapulaChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="ligamentous-laxity"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Ligamentous_Laxity}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <div className="col-md-12 col-sm-12">
                        <ShoulderRangeSlider
                          {...ligamentousLaxityOptions}
                          classes=""
                          onChange={ligamentousLaxityChange}
                          value={ligamentousLaxityValue}
                          name="name"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              id="thoracic-outlet"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>
                    {translation.Thoracic_Outlet_Obstruction}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                   
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="thoracicNveR"
                          idRight="thoracicPveR"
                          name="thoracicR"
                          title={translation.Right_Arm}
                          styleClass=""
                          value={thoracicRightVal}
                          onChange={thoracicChangeRight}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="thoracicNveL"
                          idRight="thoracicPveL"
                          name="thoracicL"
                          title={translation.Left_Arm}
                          styleClass=""
                          value={thoracicLeftVal}
                          onChange={thoracicChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div
                  id="grip-strength"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>
                        {translation.Grip_Strength}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30">
                          <div className="col-md-12 col-sm-12">
                            <AssessmentKgKgWidget
                              power={gripStrenthRightVal}
                              kg={gripStrenthLeftVal}
                              rightArmkgAction="true"
                              leftArmkgAction="true"
                              onChangeP={gripStrenthChangeR}
                              onChangeK={gripStrenthChangeL}
                              labelR="Right Hand"
                              labelL="Left Hand"
                              nameR="gripStrenthR"
                              nameL="gripStrenthL"
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="row mb_30">
              <div className="col-md-12 col-sm-10">
                <h6 className="label_title">{translation.Note}</h6>
                <InputField
                  label=""
                  value={note}
                  type="textarea"
                  placeholder="Type notes here"
                  rows="4"
                  cols="20"
                  styleClass="h_150"
              maxLength={500}
                  onChange={updateNote}
                />
                <p className="char_limit_txt">
                  <span id="task-mainChars">{numChracter}</span>{" "}
                  {translation.characters_remaining}
                </p>
              </div>
            </div>
            <div className="row mb_30">
              <h6 className="label_title">{translation.Diagnosis}</h6>
              <div className="col-md-6 col-sm-6">
                <InputField
                  label=""
                  value={rightArmDiagnosis}
                  type="text"
                  placeholder="Right Arm Diagnosis"
                  styleClass="mxw_100"
                  onChange={(e) => DiagnosisR(e)}
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <InputField
                  label=""
                  value={leftArmDiagnosis}
                  type="text"
                  placeholder="Left Arm Diagnosis"
                  styleClass="mxw_100"
                  onChange={(e) => DiagnosisL(e)}
                />
              </div>
              
            </div>
            <div className="row mb_30">
              <div className="col-md-6 col-sm-6">
                <h6 className="label_title">{translation.Procedure}</h6>
                <InputField
                  label=""
                  value={procedureValue}
                  type="text"
                  placeholder="Procedure"
                  styleClass="mxw_100"
                  onChange={(e) => Procedure(e)}
                />
              </div>
            </div>
            <div className="row mb_30">
              <div className="col-md-6 col-sm-6">
                <h6 className="label_title">{translation.Outcome}</h6>
                <FullDropdown
                  data={[
                    { value: "0", label: "Select Outcome type" },
                    { value: "1", label: "Treatment" },
                    { value: "2", label: "Discharge/Deceased" },
                    { value: "3", label: "No Action Needed" },
                  ]}
                  label=""
                  styleClass="mxw_100"
                  value={outcomeValue}
                  placeholder="Select Outcome type"
                  onChange={(e) => Outcome(e)}
                />
              </div>
            </div>
            <div className={`row mb_30 button_box`}>
              <div className="col-md-6 col-sm-6 col-6">
                {doctorEnable !== "true" && (
                  <Link
                    to={
                      "/shoulder-treatment-form/step-1/" +
                      rId +
                      `?doctor=${dId}&patient=${pId}`
                    }
                    className="assm_back_btn"
                  >
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </Link>
                )}
              </div>

              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}

                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
     {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
     <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"4"}
     patientuserID={pId}
     />
    </>
  );
}

export default PatientAssessmentStep2;

import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

function PersonalInformation({ profileAllData, AllData }) {
  const translations = useTranslation();
  // console.log(AllData)
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translations.patient_personal_info}</h2>
          <div className="treatment-header-right">
            <p>{translations.Assessment_Date} : {AllData.assessment_date}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Name}:</label>
              {profileAllData.ing_firstname} {profileAllData.ing_lastname}
            </div>

            <div className="treatment-col">
              <label>{translations.Date_of_Birth}:</label>
              {profileAllData.ing_dob}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.patient_sex}:</label>
              {/* {profileAllData.ing_gender === "2" ? "Female" : "Male"} */}
              {profileAllData.ing_gender === "1"
                ?  translations.Male
                : profileAllData.ing_gender === "2"
                ? translations.Female
                : profileAllData.ing_gender === "3"
                ? translations.want_to_disclose
                : ""}
            </div>

            {/* <div className="treatment-col">
              <label>Hip arthritis:</label>
              {AllData.dominant === 0
                ? "Don't Know"
                : AllData.dominant === 1
                ? "Yes"
                : "No"}
            </div> */}
            <div className="treatment-col">
              <label>{translations.patient_Initial_Assessment}:</label>{" "}
              {AllData.initial_assessment === 1 ? translations.Yes : translations.No}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Evaluated_Hip}:</label>{" "}
              {AllData.evlHipPain === "3"
                ? translations.Both
                : AllData.evlHipPain === "2"
                ? translations.Left
                : translations.Right}
            </div>

            <div className="treatment-col">
              <label>{translations.patient_Treatment_Category}:</label>
              {translations.Hip_Assessment}
            </div>
          </div>

          <div className="treatment-row">
            {AllData.evlHipPain !== "3" && (
              <div className="treatment-col">
                <label>
                  {translations.Is_your_unaffected_hip_pain}:
                </label>
                {AllData.unaffected_hip === "0" ? translations.No : translations.Yes}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;

import React, { useMemo, useState, useEffect } from "react";
import Button from "../custom/button/Button";
import useTransition from "../customHooks/translations";
function Share({ type, rId }) {
  const translation = useTransition();
  const continueRedirect1 = (e) => {
    window.open(
      process.env.REACT_APP_PATIENT_URL + `/doctor-code?type=${type}&rId=${rId}`
    );
  };
  const continueRedirect = (e) => {
    // if (goback === "true") {
    //   window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-list', "_blank");
    //   localStorage.removeItem("URL")

    //   window.close()

    // } else {
    window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
    localStorage.removeItem("URL");

    window.close();

    // }
  };

  return (
    <>
      {/* <div className="shadow p-3 mb-5 bg-white rounded"> */}
      <div className="pt_70 pb_50 text-center border-1 border_code">
        <h3 lassName="mb_20 f_600">
          {translation.Share_results_with_your_doctor}
        </h3>
        <h6 className="mb_20 f_600">
          {translation.If_you_would_like_to_share_your_results_with}
          <br></br>
          {translation.your_doctor_select_Share_NOW_below}
        </h6>
        <div>
          <Button
            onClick={continueRedirect}
            value="Share LATER"
            height="45px"
            // {translation.Sign_out}
            buttonStyle="btn_fill result_submit_btn m-2"
          />

          <Button
            //  style={{ marginLeft: '20px', marginTop: '0px' }}
            onClick={continueRedirect1}
            value="Share NOW"
            height="45px"
            // {translation.Sign_out}
            buttonStyle="btn_fill result_submit_btn"
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Share;

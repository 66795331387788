import React from "react";
import { useState } from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function FootAnkleFunction({ FootfunctionScore, AllData }) {
  const translations = useTranslation();

  const DatagetSport = (inputArray) => {
    const sportNames = {
      0: "Aerobics, fitness",
      1: "Alpine skiing and snowboarding",
      2: "American football",
      3: "Badminton",
      4: "Baseball",
      5: "Basketball",
      6: "Bowling/curling",
      7: "Boxing",
      8: "Cross-country running",
      9: "Cycling",
      10: "Dancing",
      11: "Diving",
      12: "Equestrian",
      13: "Fencing",
      14: "Floorball",
      15: "Freestyle snowboarding",
      16: "Golf",
      17: "Gymnastics",
      18: "Handball",
      19: "Hockey",
      20: "Ice hockey",
      21: "Korfball",
      22: "Martial arts",
      23: "Modern pentathlon",
      24: "Motorsports, technical sports",
      25: "Mountain and hill climbing",
      26: "Mountain biking/bmx",
      27: "Nordic skiing",
      28: "Orienteering",
      29: "Parachuting",
      30: "Power lifting",
      31: "Rhythmic gymnastics",
      32: "Rowing, kayaking",
      33: "Rugby",
      34: "Sailing",
      35: "Scuba diving",
      36: "Shooting, archery",
      37: "Skating, in-line skating",
      38: "Soccer",
      39: "Softball",
      40: "Squash",
      41: "Surfing, windsurfing",
      42: "Table tennis",
      43: "Tennis",
      44: "Track and field: field events",
      45: "Track and field: track events",
      46: "Triathlon",
      47: "Volleyball",
      48: "Water polo and swimming",
      49: "Water skiing",
      50: "Weightlifting, body building",
      51: "Wrestling",
    };
    const mappedNames =
      Array.isArray(inputArray) &&
      inputArray.map((value) => sportNames[value]).join(", ");
    return mappedNames;
  };
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translations.patient_foot_ankle_function}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q1} (
                {translations.Right})
              </label>
              <p>
                {FootfunctionScore.footActivityR === "3"
                  ? translations.patient_foot_ankle_function_q1_a4
                  : FootfunctionScore.footActivityR === "2"
                  ? translations.patient_foot_ankle_function_q1_a3
                  : FootfunctionScore.footActivityR === "1"
                  ? translations.patient_foot_ankle_function_q1_a2
                  : FootfunctionScore.footActivityR === "0"
                  ? translations.patient_foot_ankle_function_q1_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q1} (
                {translations.Left})
              </label>
              <p>
                {FootfunctionScore.footActivityL === "3"
                  ? translations.patient_foot_ankle_function_q1_a4
                  : FootfunctionScore.footActivityL === "2"
                  ? translations.patient_foot_ankle_function_q1_a3
                  : FootfunctionScore.footActivityL === "1"
                  ? translations.patient_foot_ankle_function_q1_a2
                  : FootfunctionScore.footActivityL === "0"
                  ? translations.patient_foot_ankle_function_q1_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q2} (
                {translations.Right})
              </label>
              <p>
                {FootfunctionScore.footwearR === "2"
                  ? translations.patient_foot_ankle_function_q2_a3
                  : FootfunctionScore.footwearR === "1"
                  ? translations.patient_foot_ankle_function_q2_a2
                  : FootfunctionScore.footwearR === "0"
                  ? translations.patient_foot_ankle_function_q2_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q2} (
                {translations.Left})
              </label>
              <p>
                {FootfunctionScore.footwearL === "2"
                  ? translations.patient_foot_ankle_function_q2_a3
                  : FootfunctionScore.footwearL === "1"
                  ? translations.patient_foot_ankle_function_q2_a2
                  : FootfunctionScore.footwearL === "0"
                  ? translations.patient_foot_ankle_function_q2_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q3} (
                {translations.Right})
              </label>
              <p>
                {FootfunctionScore.walkingR === "2"
                  ? translations.patient_foot_ankle_function_q3_a3
                  : FootfunctionScore.walkingR === "1"
                  ? translations.patient_foot_ankle_function_q3_a2
                  : FootfunctionScore.walkingR === "0"
                  ? translations.patient_foot_ankle_function_q3_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q3} (
                {translations.Left})
              </label>
              <p>
                {FootfunctionScore.walkingL === "2"
                  ? translations.patient_foot_ankle_function_q3_a3
                  : FootfunctionScore.walkingL === "1"
                  ? translations.patient_foot_ankle_function_q3_a2
                  : FootfunctionScore.walkingL === "0"
                  ? translations.patient_foot_ankle_function_q3_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>
                      {translations.patient_foot_ankle_function_instruction}
                    </th>
                    <th>{translations.Right_Foot_and_Ankle}</th>
                    <th>{translations.Left_Foot_and_Ankle}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q4} :</td>
                    <td>{FootfunctionScore.footStraightR}</td>
                    <td>{FootfunctionScore.footStraightL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q5} :</td>
                    <td>{FootfunctionScore.footBendingR}</td>
                    <td>{FootfunctionScore.footBendingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q6} :</td>
                    <td>{FootfunctionScore.desStairR}</td>
                    <td>{FootfunctionScore.desStairL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q7} :</td>
                    <td>{FootfunctionScore.ascStairR}</td>
                    <td>{FootfunctionScore.ascStairL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q8}:</td>
                    <td>{FootfunctionScore.risingR}</td>
                    <td>{FootfunctionScore.risingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q9} :</td>
                    <td>{FootfunctionScore.standingR}</td>
                    <td>{FootfunctionScore.standingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q10} :</td>
                    <td>{FootfunctionScore.bendingFloorR}</td>
                    <td>{FootfunctionScore.bendingFloorL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q11} :</td>
                    <td>{FootfunctionScore.walkingFlatR}</td>
                    <td>{FootfunctionScore.walkingFlatL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q12} :</td>
                    <td>
                      {FootfunctionScore.maxWalkingR === "0"
                        ? translations.patient_foot_ankle_function_q12_a1
                        : FootfunctionScore.maxWalkingR === "1"
                        ? translations.patient_foot_ankle_function_q12_a2
                        : FootfunctionScore.maxWalkingR === "2"
                        ? translations.patient_foot_ankle_function_q12_a3
                        : FootfunctionScore.maxWalkingR === "3"
                        ? translations.patient_foot_ankle_function_q12_a4
                        : ""}
                    </td>
                    <td>
                      {FootfunctionScore.maxWalkingL === "0"
                        ? translations.patient_foot_ankle_function_q12_a1
                        : FootfunctionScore.maxWalkingL === "1"
                        ? translations.patient_foot_ankle_function_q12_a2
                        : FootfunctionScore.maxWalkingL === "2"
                        ? translations.patient_foot_ankle_function_q12_a3
                        : FootfunctionScore.maxWalkingL === "3"
                        ? translations.patient_foot_ankle_function_q12_a4
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q13} :</td>
                    <td>{FootfunctionScore.walkingWithoutShoesR}</td>
                    <td>{FootfunctionScore.walkingWithoutShoesL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q14}:</td>
                    <td>{FootfunctionScore.walkingHillsR}</td>
                    <td>{FootfunctionScore.walkingHillsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q15}:</td>
                    <td>{FootfunctionScore.walkingDownHillsR}</td>
                    <td>{FootfunctionScore.walkingDownHillsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q16}:</td>
                    <td>{FootfunctionScore.walkingUnevenR}</td>
                    <td>{FootfunctionScore.walkingUnevenL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q17} :</td>
                    <td>{FootfunctionScore.steppingUpR}</td>
                    <td>{FootfunctionScore.steppingUpL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q18} :</td>
                    <td>{FootfunctionScore.comingUpR}</td>
                    <td>{FootfunctionScore.comingUpL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q19} :</td>
                    <td>{FootfunctionScore.walkIntiallyR}</td>
                    <td>{FootfunctionScore.walkIntiallyL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q20} :</td>
                    <td>{FootfunctionScore.walkFiveR}</td>
                    <td>{FootfunctionScore.walkFiveL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q21} :</td>
                    <td>{FootfunctionScore.walkTenR}</td>
                    <td>{FootfunctionScore.walkTenL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q22} :</td>
                    <td>{FootfunctionScore.walkFifteenR}</td>
                    <td>{FootfunctionScore.walkFifteenL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q23} :</td>
                    <td>{FootfunctionScore.gettingCarR}</td>
                    <td>{FootfunctionScore.gettingCarL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q24} :</td>
                    <td>{FootfunctionScore.goingShoppingR}</td>
                    <td>{FootfunctionScore.goingShoppingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q25} :</td>
                    <td>{FootfunctionScore.puttingSocksR}</td>
                    <td>{FootfunctionScore.puttingSocksL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q26} :</td>
                    <td>{FootfunctionScore.takingOffR}</td>
                    <td>{FootfunctionScore.takingOffL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q27} :</td>
                    <td>{FootfunctionScore.risingBedR}</td>
                    <td>{FootfunctionScore.risingBedL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q28} :</td>
                    <td>{FootfunctionScore.lyingR}</td>
                    <td>{FootfunctionScore.lyingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q29} :</td>
                    <td>{FootfunctionScore.sleepingR}</td>
                    <td>{FootfunctionScore.sleepingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q30} :</td>
                    <td>{FootfunctionScore.getBathR}</td>
                    <td>{FootfunctionScore.getBathL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q31} :</td>
                    <td>{FootfunctionScore.sittingR}</td>
                    <td>{FootfunctionScore.sittingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q32} :</td>
                    <td>{FootfunctionScore.getToiletR}</td>
                    <td>{FootfunctionScore.getToiletL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q33} :</td>
                    <td>{FootfunctionScore.homeR}</td>
                    <td>{FootfunctionScore.homeL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q34} :</td>
                    <td>{FootfunctionScore.activitiesR}</td>
                    <td>{FootfunctionScore.activitiesL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q35} :</td>
                    <td>{FootfunctionScore.personalCareR}</td>
                    <td>{FootfunctionScore.personalCareL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q36} :</td>
                    <td>{FootfunctionScore.heavyWorkR}</td>
                    <td>{FootfunctionScore.heavyWorkL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q37} :</td>
                    <td>{FootfunctionScore.lightWorkR}</td>
                    <td>{FootfunctionScore.lightWorkL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q38}</td>
                    <td>{FootfunctionScore.moderateWorkR}</td>
                    <td>{FootfunctionScore.moderateWorkL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q39} :</td>
                    <td>{FootfunctionScore.recreationalR}</td>
                    <td>{FootfunctionScore.recreationalL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q40} :</td>
                    <td>{FootfunctionScore.squattingR}</td>
                    <td>{FootfunctionScore.squattingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q41} :</td>
                    <td>{FootfunctionScore.twistingR}</td>
                    <td>{FootfunctionScore.twistingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q42} :</td>
                    <td>{FootfunctionScore.kneelingR}</td>
                    <td>{FootfunctionScore.kneelingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q43} :</td>
                    <td>
                      {FootfunctionScore.runSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.runningR}
                    </td>
                    <td>
                      {FootfunctionScore.runSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.runningL}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_foot_ankle_function_q44} :</td>
                    <td>
                      {FootfunctionScore.jumpSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.jumpingR}
                    </td>
                    <td>
                      {FootfunctionScore.jumpSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.jumpingL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q45} :</td>
                    <td>
                      {FootfunctionScore.landingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.landingR}
                    </td>
                    <td>
                      {FootfunctionScore.landingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.landingL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q46} :</td>
                    <td>
                      {FootfunctionScore.startingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.stoppingR}
                    </td>
                    <td>
                      {FootfunctionScore.startingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.stoppingL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q47} :</td>
                    <td>
                      {FootfunctionScore.cuttingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.cuttingR}
                    </td>
                    <td>
                      {FootfunctionScore.cuttingSelectL === true
                        ? translations.patient_foot_ankle_function_q43_not_applicable
                        : FootfunctionScore.cuttingL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q48} :</td>
                    <td>
                      {FootfunctionScore.impactSelectL === true
                        ? translations.patient_foot_ankle_function_q48_not_applicable
                        : FootfunctionScore.lowActR}
                    </td>
                    <td>
                      {FootfunctionScore.impactSelectL === true
                        ? translations.patient_foot_ankle_function_q48_not_applicable
                        : FootfunctionScore.lowActL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q49} :</td>
                    <td>
                      {FootfunctionScore.performSelectL === true
                        ? translations.patient_foot_ankle_function_q49_not_applicable
                        : FootfunctionScore.abilityPerformR}
                    </td>
                    <td>
                      {FootfunctionScore.performSelectL === true
                        ? translations.patient_foot_ankle_function_q49_not_applicable
                        : FootfunctionScore.abilityPerformL}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_function_q50}:</td>
                    <td>
                      {FootfunctionScore.participateSelectL === true
                        ? translations.patient_foot_ankle_function_q50_not_applicable
                        : FootfunctionScore.abilityParticipateR}
                    </td>
                    <td>
                      {FootfunctionScore.participateSelectL === true
                        ? translations.patient_foot_ankle_function_q50_not_applicable
                        : FootfunctionScore.abilityParticipateL}
                    </td>
                  </tr>
                  <br></br>
                  <b>{translations.patient_foot_ankle_function_q52} :</b>

                  {AllData.initial_assessment === 1 ||
                  AllData.initial_assessment === 0 ? (
                    <>
                      <tr>
                        <td>
                          {translations.patient_foot_ankle_function_q52_q2} :
                        </td>
                        <td>
                          {FootfunctionScore.highestActL === "1"
                            ? translations.patient_foot_ankle_function_q52_q1_a1
                            : FootfunctionScore.highestActL === "2"
                            ? translations.patient_foot_ankle_function_q52_q1_a2
                            : FootfunctionScore.highestActL === "3"
                            ? translations.patient_foot_ankle_function_q52_q1_a3
                            : FootfunctionScore.highestActL === "4"
                            ? translations.patient_foot_ankle_function_q52_q1_a4
                            : FootfunctionScore.highestActL === "5"
                            ? translations.patient_foot_ankle_function_q52_q1_a5
                            : translations.patient_foot_ankle_function_q52_q1_a4_o7}
                        </td>
                      </tr>
                      {/* <tr> */}

                      {FootfunctionScore.highestActL === "1" ? (
                        <tr>
                          <td>
                            {
                              translations.patient_foot_ankle_function_q52_q1_a1_title
                            }
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.topLevelL)}</td>
                          {/* <td>
                            {FootfunctionScore.topLevelL === "0"
                              ? "Aerobics, fitness"
                              : FootfunctionScore.topLevelL === "1"
                                ? "Alpine skiing and snowboarding"
                                : FootfunctionScore.topLevelL === "2"
                                  ? "American football"
                                  : FootfunctionScore.topLevelL === "3"
                                    ? "Badminton"
                                    : FootfunctionScore.topLevelL === "4"
                                      ? "Baseball"
                                      : FootfunctionScore.topLevelL === "5"
                                        ? "Basketball"
                                        : FootfunctionScore.topLevelL === "6"
                                          ? "Bowling/curling"
                                          : FootfunctionScore.topLevelL === "7"
                                            ? "Boxing"
                                            : FootfunctionScore.topLevelL === "8"
                                              ? "Cross-country running"
                                              : FootfunctionScore.topLevelL === "9"
                                                ? "Cycling"
                                                : FootfunctionScore.topLevelL === "10"
                                                  ? "Dancing"
                                                  : FootfunctionScore.topLevelL === "11"
                                                    ? "Diving"
                                                    : FootfunctionScore.topLevelL === "12"
                                                      ? "Equestrian"
                                                      : FootfunctionScore.topLevelL === "13"
                                                        ? "Fencing"
                                                        : FootfunctionScore.topLevelL === "14"
                                                          ? "Floorball"
                                                          : FootfunctionScore.topLevelL === "15"
                                                            ? "Freestyle snowboarding"
                                                            : FootfunctionScore.topLevelL === "16"
                                                              ? "Golf"
                                                              : FootfunctionScore.topLevelL === "17"
                                                                ? "Gymnstics"
                                                                : FootfunctionScore.topLevelL === "18"
                                                                  ? "Handball"
                                                                  : FootfunctionScore.topLevelL === "19"
                                                                    ? "Hockey"
                                                                    : FootfunctionScore.topLevelL === "20"
                                                                      ? "Ice hockey"
                                                                      : FootfunctionScore.topLevelL === "21"
                                                                        ? "Korfball"
                                                                        : FootfunctionScore.topLevelL === "22"
                                                                          ? "Martial arts"
                                                                          : FootfunctionScore.topLevelL === "23"
                                                                            ? "Modern pentathlon"
                                                                            : FootfunctionScore.topLevelL === "24"
                                                                              ? "Motorsports, technical sports"
                                                                              : FootfunctionScore.topLevelL === "25"
                                                                                ? "Mountain and hill climbing"
                                                                                : FootfunctionScore.topLevelL === "26"
                                                                                  ? "Mountain biking/bmx"
                                                                                  : FootfunctionScore.topLevelL === "27"
                                                                                    ? "Nordic skiing"
                                                                                    : FootfunctionScore.topLevelL === "28"
                                                                                      ? "Orienteering"
                                                                                      : FootfunctionScore.topLevelL === "29"
                                                                                        ? "Parachuting"
                                                                                        : FootfunctionScore.topLevelL === "30"
                                                                                          ? "Power lifting"
                                                                                          : FootfunctionScore.topLevelL === "31"
                                                                                            ? "Rhythmic gymnastics"
                                                                                            : FootfunctionScore.topLevelL === "32"
                                                                                              ? "Rowing, kayaking"
                                                                                              : FootfunctionScore.topLevelL === "33"
                                                                                                ? "Rugby"
                                                                                                : FootfunctionScore.topLevelL === "34"
                                                                                                  ? "Sailing"
                                                                                                  : FootfunctionScore.topLevelL === "35"
                                                                                                    ? "Scuba diving"
                                                                                                    : FootfunctionScore.topLevelL === "36"
                                                                                                      ? "Shooting, archery"
                                                                                                      : FootfunctionScore.topLevelL === "37"
                                                                                                        ? "Skating, in-line skating"
                                                                                                        : FootfunctionScore.topLevelL === "38"
                                                                                                          ? "Soccer"
                                                                                                          : FootfunctionScore.topLevelL === "39"
                                                                                                            ? "Softball"
                                                                                                            : FootfunctionScore.topLevelL === "40"
                                                                                                              ? "Squash"
                                                                                                              : FootfunctionScore.topLevelL === "41"
                                                                                                                ? "Surfing, windsurfing"
                                                                                                                : FootfunctionScore.topLevelL === "42"
                                                                                                                  ? "Table tennis"
                                                                                                                  : FootfunctionScore.topLevelL === "43"
                                                                                                                    ? "Tennis"
                                                                                                                    : FootfunctionScore.topLevelL === "44"
                                                                                                                      ? "Track and field: field events"
                                                                                                                      : FootfunctionScore.topLevelL === "45"
                                                                                                                        ? "Track and field: track events"
                                                                                                                        : FootfunctionScore.topLevelL === "46"
                                                                                                                          ? "Triathlon"
                                                                                                                          : FootfunctionScore.topLevelL === "47"
                                                                                                                            ? "Volleyball"
                                                                                                                            : FootfunctionScore.topLevelL === "48"
                                                                                                                              ? "Water polo and swimming"
                                                                                                                              : FootfunctionScore.topLevelL === "49"
                                                                                                                                ? "Water skiing"
                                                                                                                                : FootfunctionScore.topLevelL === "50"
                                                                                                                                  ? "Weightlifting, body building"
                                                                                                                                  : "Wrestling"}
                          </td> */}
                        </tr>
                      ) : (
                        ""
                      )}

                      {FootfunctionScore.highestActL === "2" ? (
                        <tr>
                          <td>
                            {translations.patient_foot_ankle_function_q52_q2_a2}
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.lowLevelL)}</td>
                          {/* <td>
                            {FootfunctionScore.lowLevelL === "0"
                              ? "Aerobics, fitness"
                              : FootfunctionScore.lowLevelL === "1"
                                ? "Alpine skiing and snowboarding"
                                : FootfunctionScore.lowLevelL === "2"
                                  ? "American football"
                                  : FootfunctionScore.lowLevelL === "3"
                                    ? "Badminton"
                                    : FootfunctionScore.lowLevelL === "4"
                                      ? "Baseball"
                                      : FootfunctionScore.lowLevelL === "5"
                                        ? "Basketball"
                                        : FootfunctionScore.lowLevelL === "6"
                                          ? "Bowling/curling"
                                          : FootfunctionScore.lowLevelL === "7"
                                            ? "Boxing"
                                            : FootfunctionScore.lowLevelL === "8"
                                              ? "Cross-country running"
                                              : FootfunctionScore.lowLevelL === "9"
                                                ? "Cycling"
                                                : FootfunctionScore.lowLevelL === "10"
                                                  ? "Dancing"
                                                  : FootfunctionScore.lowLevelL === "11"
                                                    ? "Diving"
                                                    : FootfunctionScore.lowLevelL === "12"
                                                      ? "Equestrian"
                                                      : FootfunctionScore.lowLevelL === "13"
                                                        ? "Fencing"
                                                        : FootfunctionScore.lowLevelL === "14"
                                                          ? "Floorball"
                                                          : FootfunctionScore.lowLevelL === "15"
                                                            ? "Freestyle snowboarding"
                                                            : FootfunctionScore.lowLevelL === "16"
                                                              ? "Golf"
                                                              : FootfunctionScore.lowLevelL === "17"
                                                                ? "Gymnstics"
                                                                : FootfunctionScore.lowLevelL === "18"
                                                                  ? "Handball"
                                                                  : FootfunctionScore.lowLevelL === "19"
                                                                    ? "Hockey"
                                                                    : FootfunctionScore.lowLevelL === "20"
                                                                      ? "Ice hockey"
                                                                      : FootfunctionScore.lowLevelL === "21"
                                                                        ? "Korfball"
                                                                        : FootfunctionScore.lowLevelL === "22"
                                                                          ? "Martial arts"
                                                                          : FootfunctionScore.lowLevelL === "23"
                                                                            ? "Modern pentathlon"
                                                                            : FootfunctionScore.lowLevelL === "24"
                                                                              ? "Motorsports, technical sports"
                                                                              : FootfunctionScore.lowLevelL === "25"
                                                                                ? "Mountain and hill climbing"
                                                                                : FootfunctionScore.lowLevelL === "26"
                                                                                  ? "Mountain biking/bmx"
                                                                                  : FootfunctionScore.lowLevelL === "27"
                                                                                    ? "Nordic skiing"
                                                                                    : FootfunctionScore.lowLevelL === "28"
                                                                                      ? "Orienteering"
                                                                                      : FootfunctionScore.lowLevelL === "29"
                                                                                        ? "Parachuting"
                                                                                        : FootfunctionScore.lowLevelL === "30"
                                                                                          ? "Power lifting"
                                                                                          : FootfunctionScore.lowLevelL === "31"
                                                                                            ? "Rhythmic gymnastics"
                                                                                            : FootfunctionScore.lowLevelL === "32"
                                                                                              ? "Rowing, kayaking"
                                                                                              : FootfunctionScore.lowLevelL === "33"
                                                                                                ? "Rugby"
                                                                                                : FootfunctionScore.lowLevelL === "34"
                                                                                                  ? "Sailing"
                                                                                                  : FootfunctionScore.lowLevelL === "35"
                                                                                                    ? "Scuba diving"
                                                                                                    : FootfunctionScore.lowLevelL === "36"
                                                                                                      ? "Shooting, archery"
                                                                                                      : FootfunctionScore.lowLevelL === "37"
                                                                                                        ? "Skating, in-line skating"
                                                                                                        : FootfunctionScore.lowLevelL === "38"
                                                                                                          ? "Soccer"
                                                                                                          : FootfunctionScore.lowLevelL === "39"
                                                                                                            ? "Softball"
                                                                                                            : FootfunctionScore.lowLevelL === "40"
                                                                                                              ? "Squash"
                                                                                                              : FootfunctionScore.lowLevelL === "41"
                                                                                                                ? "Surfing, windsurfing"
                                                                                                                : FootfunctionScore.lowLevelL === "42"
                                                                                                                  ? "Table tennis"
                                                                                                                  : FootfunctionScore.lowLevelL === "43"
                                                                                                                    ? "Tennis"
                                                                                                                    : FootfunctionScore.lowLevelL === "44"
                                                                                                                      ? "Track and field: field events"
                                                                                                                      : FootfunctionScore.lowLevelL === "45"
                                                                                                                        ? "Track and field: track events"
                                                                                                                        : FootfunctionScore.lowLevelL === "46"
                                                                                                                          ? "Triathlon"
                                                                                                                          : FootfunctionScore.lowLevelL === "47"
                                                                                                                            ? "Volleyball"
                                                                                                                            : FootfunctionScore.lowLevelL === "48"
                                                                                                                              ? "Water polo and swimming"
                                                                                                                              : FootfunctionScore.lowLevelL === "49"
                                                                                                                                ? "Water skiing"
                                                                                                                                : FootfunctionScore.lowLevelL === "50"
                                                                                                                                  ? "Weightlifting, body building"
                                                                                                                                  : "Wrestling"}
                          </td> */}
                        </tr>
                      ) : (
                        ""
                      )}

                      {FootfunctionScore.highestActL === "3" ? (
                        <tr>
                          <td>
                            {translations.patient_foot_ankle_function_q52_q2_a3}
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.recreatL)}</td>
                          {/* <td>
                            {FootfunctionScore.recreatL === "0"
                              ? "Aerobics, fitness"
                              : FootfunctionScore.recreatL === "1"
                                ? "Alpine skiing and snowboarding"
                                : FootfunctionScore.recreatL === "2"
                                  ? "American football"
                                  : FootfunctionScore.recreatL === "3"
                                    ? "Badminton"
                                    : FootfunctionScore.recreatL === "4"
                                      ? "Baseball"
                                      : FootfunctionScore.recreatL === "5"
                                        ? "Basketball"
                                        : FootfunctionScore.recreatL === "6"
                                          ? "Bowling/curling"
                                          : FootfunctionScore.recreatL === "7"
                                            ? "Boxing"
                                            : FootfunctionScore.recreatL === "8"
                                              ? "Cross-country running"
                                              : FootfunctionScore.recreatL === "9"
                                                ? "Cycling"
                                                : FootfunctionScore.recreatL === "10"
                                                  ? "Dancing"
                                                  : FootfunctionScore.recreatL === "11"
                                                    ? "Diving"
                                                    : FootfunctionScore.recreatL === "12"
                                                      ? "Equestrian"
                                                      : FootfunctionScore.recreatL === "13"
                                                        ? "Fencing"
                                                        : FootfunctionScore.recreatL === "14"
                                                          ? "Floorball"
                                                          : FootfunctionScore.recreatL === "15"
                                                            ? "Freestyle snowboarding"
                                                            : FootfunctionScore.recreatL === "16"
                                                              ? "Golf"
                                                              : FootfunctionScore.recreatL === "17"
                                                                ? "Gymnstics"
                                                                : FootfunctionScore.recreatL === "18"
                                                                  ? "Handball"
                                                                  : FootfunctionScore.recreatL === "19"
                                                                    ? "Hockey"
                                                                    : FootfunctionScore.recreatL === "20"
                                                                      ? "Ice hockey"
                                                                      : FootfunctionScore.recreatL === "21"
                                                                        ? "Korfball"
                                                                        : FootfunctionScore.recreatL === "22"
                                                                          ? "Martial arts"
                                                                          : FootfunctionScore.recreatL === "23"
                                                                            ? "Modern pentathlon"
                                                                            : FootfunctionScore.recreatL === "24"
                                                                              ? "Motorsports, technical sports"
                                                                              : FootfunctionScore.recreatL === "25"
                                                                                ? "Mountain and hill climbing"
                                                                                : FootfunctionScore.recreatL === "26"
                                                                                  ? "Mountain biking/bmx"
                                                                                  : FootfunctionScore.recreatL === "27"
                                                                                    ? "Nordic skiing"
                                                                                    : FootfunctionScore.recreatL === "28"
                                                                                      ? "Orienteering"
                                                                                      : FootfunctionScore.recreatL === "29"
                                                                                        ? "Parachuting"
                                                                                        : FootfunctionScore.recreatL === "30"
                                                                                          ? "Power lifting"
                                                                                          : FootfunctionScore.recreatL === "31"
                                                                                            ? "Rhythmic gymnastics"
                                                                                            : FootfunctionScore.recreatL === "32"
                                                                                              ? "Rowing, kayaking"
                                                                                              : FootfunctionScore.recreatL === "33"
                                                                                                ? "Rugby"
                                                                                                : FootfunctionScore.recreatL === "34"
                                                                                                  ? "Sailing"
                                                                                                  : FootfunctionScore.recreatL === "35"
                                                                                                    ? "Scuba diving"
                                                                                                    : FootfunctionScore.recreatL === "36"
                                                                                                      ? "Shooting, archery"
                                                                                                      : FootfunctionScore.recreatL === "37"
                                                                                                        ? "Skating, in-line skating"
                                                                                                        : FootfunctionScore.recreatL === "38"
                                                                                                          ? "Soccer"
                                                                                                          : FootfunctionScore.recreatL === "39"
                                                                                                            ? "Softball"
                                                                                                            : FootfunctionScore.recreatL === "40"
                                                                                                              ? "Squash"
                                                                                                              : FootfunctionScore.recreatL === "41"
                                                                                                                ? "Surfing, windsurfing"
                                                                                                                : FootfunctionScore.recreatL === "42"
                                                                                                                  ? "Table tennis"
                                                                                                                  : FootfunctionScore.recreatL === "43"
                                                                                                                    ? "Tennis"
                                                                                                                    : FootfunctionScore.recreatL === "44"
                                                                                                                      ? "Track and field: field events"
                                                                                                                      : FootfunctionScore.recreatL === "45"
                                                                                                                        ? "Track and field: track events"
                                                                                                                        : FootfunctionScore.recreatL === "46"
                                                                                                                          ? "Triathlon"
                                                                                                                          : FootfunctionScore.recreatL === "47"
                                                                                                                            ? "Volleyball"
                                                                                                                            : FootfunctionScore.recreatL === "48"
                                                                                                                              ? "Water polo and swimming"
                                                                                                                              : FootfunctionScore.recreatL === "49"
                                                                                                                                ? "Water skiing"
                                                                                                                                : FootfunctionScore.recreatL === "50"
                                                                                                                                  ? "Weightlifting, body building"
                                                                                                                                  : FootfunctionScore.recreatL === "51"
                                                                                                                                    ? "Wrestling" : ""}
                          </td> */}
                        </tr>
                      ) : (
                        ""
                      )}

                      {/* {FootfunctionScore.highestActL === "4" ? (
                        <tr>
                          <td>I do not play sports but I work (Left)</td>
                          <td>
                            {FootfunctionScore.idoSpotL === "2"
                              ? "Special professions: ballet dancer, professional soldier, special rescue worker, stuntman"
                              : FootfunctionScore.idoSpotL === "1"
                                ? "Physical work"
                                : "Heavy physical work"}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )} */}

                      {FootfunctionScore.highestActL === "4" ? (
                        <tr>
                          <td>
                            {translations.patient_foot_ankle_function_q52_q2_a4}
                            :
                          </td>
                          <td>
                            {FootfunctionScore.idoSpotL === "1"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o1
                              : FootfunctionScore.idoSpotL === "2"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o2
                              : FootfunctionScore.idoSpotL === "3"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o3
                              : FootfunctionScore.idoSpotL === "4"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o4
                              : FootfunctionScore.idoSpotL === "5"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o5
                              : FootfunctionScore.idoSpotL === "6"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o6
                              : FootfunctionScore.idoSpotL === "7"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o7
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {/* </tr> */}
                  {AllData.initial_assessment === 1 && (
                    <>
                      <tr>
                        <td>
                          {translations.patient_foot_ankle_function_q52_q1} :
                        </td>
                        <td>
                          {FootfunctionScore.highestActR === "1"
                            ? translations.patient_foot_ankle_function_q52_q1_a1
                            : FootfunctionScore.highestActR === "2"
                            ? translations.patient_foot_ankle_function_q52_q1_a2
                            : FootfunctionScore.highestActR === "3"
                            ? translations.patient_foot_ankle_function_q52_q1_a3
                            : FootfunctionScore.highestActR === "4"
                            ? translations.patient_foot_ankle_function_q52_q1_a4
                            : FootfunctionScore.highestActR === "5"
                            ? translations.patient_foot_ankle_function_q52_q1_a5
                            : translations.patient_foot_ankle_function_q52_q1_a4_o7}
                        </td>
                      </tr>

                      {/* <td> */}
                      {FootfunctionScore.highestActR === "1" ? (
                        <tr>
                          <td>
                            {
                              translations.patient_foot_ankle_function_q52_q1_a1_title
                            }
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.topLevelR)}</td>
                          {/* <td>
                            {FootfunctionScore.topLevelR === "0"
                              ? "Aerobics, fitness"
                              : FootfunctionScore.topLevelR === "1"
                                ? "Alpine skiing and snowboarding"
                                : FootfunctionScore.topLevelR === "2"
                                  ? "American football"
                                  : FootfunctionScore.topLevelR === "3"
                                    ? "Badminton"
                                    : FootfunctionScore.topLevelR === "4"
                                      ? "Baseball"
                                      : FootfunctionScore.topLevelR === "5"
                                        ? "Basketball"
                                        : FootfunctionScore.topLevelR === "6"
                                          ? "Bowling/curling"
                                          : FootfunctionScore.topLevelR === "7"
                                            ? "Boxing"
                                            : FootfunctionScore.topLevelR === "8"
                                              ? "Cross-country running"
                                              : FootfunctionScore.topLevelR === "9"
                                                ? "Cycling"
                                                : FootfunctionScore.topLevelR === "10"
                                                  ? "Dancing"
                                                  : FootfunctionScore.topLevelR === "11"
                                                    ? "Diving"
                                                    : FootfunctionScore.topLevelR === "12"
                                                      ? "Equestrian"
                                                      : FootfunctionScore.topLevelR === "13"
                                                        ? "Fencing"
                                                        : FootfunctionScore.topLevelR === "14"
                                                          ? "Floorball"
                                                          : FootfunctionScore.topLevelR === "15"
                                                            ? "Freestyle snowboarding"
                                                            : FootfunctionScore.topLevelR === "16"
                                                              ? "Golf"
                                                              : FootfunctionScore.topLevelR === "17"
                                                                ? "Gymnstics"
                                                                : FootfunctionScore.topLevelR === "18"
                                                                  ? "Handball"
                                                                  : FootfunctionScore.topLevelR === "19"
                                                                    ? "Hockey"
                                                                    : FootfunctionScore.topLevelR === "20"
                                                                      ? "Ice hockey"
                                                                      : FootfunctionScore.topLevelR === "21"
                                                                        ? "Korfball"
                                                                        : FootfunctionScore.topLevelR === "22"
                                                                          ? "Martial arts"
                                                                          : FootfunctionScore.topLevelR === "23"
                                                                            ? "Modern pentathlon"
                                                                            : FootfunctionScore.topLevelR === "24"
                                                                              ? "Motorsports, technical sports"
                                                                              : FootfunctionScore.topLevelR === "25"
                                                                                ? "Mountain and hill climbing"
                                                                                : FootfunctionScore.topLevelR === "26"
                                                                                  ? "Mountain biking/bmx"
                                                                                  : FootfunctionScore.topLevelR === "27"
                                                                                    ? "Nordic skiing"
                                                                                    : FootfunctionScore.topLevelR === "28"
                                                                                      ? "Orienteering"
                                                                                      : FootfunctionScore.topLevelR === "29"
                                                                                        ? "Parachuting"
                                                                                        : FootfunctionScore.topLevelR === "30"
                                                                                          ? "Power lifting"
                                                                                          : FootfunctionScore.topLevelR === "31"
                                                                                            ? "Rhythmic gymnastics"
                                                                                            : FootfunctionScore.topLevelR === "32"
                                                                                              ? "Rowing, kayaking"
                                                                                              : FootfunctionScore.topLevelR === "33"
                                                                                                ? "Rugby"
                                                                                                : FootfunctionScore.topLevelR === "34"
                                                                                                  ? "Sailing"
                                                                                                  : FootfunctionScore.topLevelR === "35"
                                                                                                    ? "Scuba diving"
                                                                                                    : FootfunctionScore.topLevelR === "36"
                                                                                                      ? "Shooting, archery"
                                                                                                      : FootfunctionScore.topLevelR === "37"
                                                                                                        ? "Skating, in-line skating"
                                                                                                        : FootfunctionScore.topLevelR === "38"
                                                                                                          ? "Soccer"
                                                                                                          : FootfunctionScore.topLevelR === "39"
                                                                                                            ? "Softball"
                                                                                                            : FootfunctionScore.topLevelR === "40"
                                                                                                              ? "Squash"
                                                                                                              : FootfunctionScore.topLevelR === "41"
                                                                                                                ? "Surfing, windsurfing"
                                                                                                                : FootfunctionScore.topLevelR === "42"
                                                                                                                  ? "Table tennis"
                                                                                                                  : FootfunctionScore.topLevelR === "43"
                                                                                                                    ? "Tennis"
                                                                                                                    : FootfunctionScore.topLevelR === "44"
                                                                                                                      ? "Track and field: field events"
                                                                                                                      : FootfunctionScore.topLevelR === "45"
                                                                                                                        ? "Track and field: track events"
                                                                                                                        : FootfunctionScore.topLevelR === "46"
                                                                                                                          ? "Triathlon"
                                                                                                                          : FootfunctionScore.topLevelR === "47"
                                                                                                                            ? "Volleyball"
                                                                                                                            : FootfunctionScore.topLevelR === "48"
                                                                                                                              ? "Water polo and swimming"
                                                                                                                              : FootfunctionScore.topLevelR === "49"
                                                                                                                                ? "Water skiing"
                                                                                                                                : FootfunctionScore.topLevelR === "50"
                                                                                                                                  ? "Weightlifting, body building"
                                                                                                                                  : "Wrestling"}
                          </td> */}
                        </tr>
                      ) : (
                        ""
                      )}

                      {FootfunctionScore.highestActR === "2" ? (
                        <tr>
                          <td>
                            {
                              translations.patient_foot_ankle_function_q52_q1_a2_title
                            }{" "}
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.lowLevelR)}</td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {FootfunctionScore.highestActR === "3" ? (
                        <tr>
                          <td>
                            {
                              translations.patient_foot_ankle_function_q52_q1_a3_title
                            }
                            :
                          </td>
                          <td>{DatagetSport(FootfunctionScore.recreatR)}</td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {/* {FootfunctionScore.highestActR === "4" ? (
                        <tr>
                          <td>I do not play sports but I work (Right)</td>
                          <td>
                            {FootfunctionScore.idoSpotR === "2"
                              ? "Special professions: ballet dancer, professional soldier, special rescue worker, stuntman"
                              : FootfunctionScore.idoSpotR === "1"
                                ? "Physical work"
                                : "Heavy physical work"}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )} */}

                      {FootfunctionScore.highestActR === "4" ? (
                        <tr>
                          <td>
                            {translations.patient_foot_ankle_function_q52_q2_a4}
                            :
                          </td>
                          <td>
                            {FootfunctionScore.idoSpotR === "1"
                              ? translations.patient_foot_ankle_function_q52_q1_a4_o1
                              : FootfunctionScore.idoSpotR === "2"
                              ? translations.patient_foot_ankle_function_q52_q1_a4_o2
                              : FootfunctionScore.idoSpotR === "3"
                              ? translations.patient_foot_ankle_function_q52_q1_a4_o3
                              : FootfunctionScore.idoSpotR === "4"
                              ? translations.patient_foot_ankle_function_q52_q1_a4_o4
                              : FootfunctionScore.idoSpotR === "5"
                              ? translations.patient_foot_ankle_function_q52_q1_a4_o5
                              : FootfunctionScore.idoSpotR === "6"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o6
                              : FootfunctionScore.idoSpotR === "7"
                              ? translations.patient_foot_ankle_function_q52_q2_a4_o7
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {/* </td> */}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q51}(
                {translations.Right}):
              </label>
              <p>{FootfunctionScore.footPercent}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_function_q51}(
                {translations.Left}):
              </label>
              <p>{FootfunctionScore.footPercentL}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

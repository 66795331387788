import React, { useState, useEffect } from "react";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import {
  updateHandWristbctqFieldScore,
  updateHandWristbctqScores,
  updateHandWristsatisfactionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristfunctionScores,
  updateHandWristqolScores,
  updateHandWristFeedbackScores,
  updateHandWristScores,
  updateHandWristUramScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";

import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import HandWristQolScoreRangeSlider from "../../../custom/hand-wrist-qol-score-range-slider/HandWristQolScoreRangeSlider";
import HandWristScoreRangeSlider from "../../../custom/hand-wrist-function-score-range-slider/HandWristScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

export default function Bctq() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const navigate = useNavigate();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const dispatch = useDispatch();
  const [severeR, setsevereR] = useState("0");
  const [severeL, setsevereL] = useState("0");
  const [oftenR, setoftenR] = useState("0");
  const [oftenL, setoftenL] = useState("0");
  const [typicallyR, settypicallyR] = useState("0");
  const [typicallyL, settypicallyL] = useState("0");
  const [daytimeR, setdaytimeR] = useState("0");
  const [daytimeL, setdaytimeL] = useState("0");
  const [averageR, setaverageR] = useState("0");
  const [averageL, setaverageL] = useState("0");
  const [numbnessR, setnumbnessR] = useState("0");
  const [numbnessL, setnumbnessL] = useState("0");
  const [weaknessR, setweaknessR] = useState("0");
  const [weaknessL, setweaknessL] = useState("0");
  const [tinglingR, settinglingR] = useState("0");
  const [tinglingL, settinglingL] = useState("0");
  const [nightR, setnightR] = useState("0");
  const [nightL, setnightL] = useState("0");
  const [duringR, setduringR] = useState("0");
  const [duringL, setduringL] = useState("0");
  const [DifficultyR, setDifficultyR] = useState("0");
  const [DifficultyL, setDifficultyL] = useState("0");
  const [WritingR, setWritingR] = useState("0"); //Function
  const [WritingL, setWritingL] = useState("0");
  const [ButtoningR, setButtoningR] = useState("0");
  const [ButtoningL, setButtoningL] = useState("0");
  const [HoldingR, setHoldingR] = useState("0");
  const [HoldingL, setHoldingL] = useState("0");
  const [GrippingR, setGrippingR] = useState("0");
  const [GrippingL, setGrippingL] = useState("0");
  const [OpeningR, setOpeningR] = useState("0");
  const [OpeningL, setOpeningL] = useState("0");
  const [HouseholdR, setHouseholdR] = useState("0");
  const [HouseholdL, setHouseholdL] = useState("0");
  const [CarryingR, setCarryingR] = useState("0");
  const [CarryingL, setCarryingL] = useState("0");
  const [BathingR, setBathingR] = useState("0");
  const [BathingL, setBathingL] = useState("0");
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  // const [syndrome, setSyndrome] = useState("0");
  const [disease, setDisease] = useState("0");

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        setDisease(res.data.data2[0].isDisease);

        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
    window.scrollTo(0, 0);
  }, []);
  const HandBctq = useSelector((state) => state.handWrist.bctq[0]);
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setsevereR(HandBctq.severeR);
    setsevereL(HandBctq.severeL);
    setoftenR(HandBctq.oftenR);
    setoftenL(HandBctq.oftenL);
    settypicallyR(HandBctq.typicallyR);
    settypicallyL(HandBctq.typicallyL);
    setdaytimeR(HandBctq.daytimeR);
    setdaytimeL(HandBctq.daytimeL);
    setaverageR(HandBctq.averageR);
    setaverageL(HandBctq.averageL);
    setnumbnessR(HandBctq.numbnessR);
    setnumbnessL(HandBctq.numbnessL);
    setweaknessR(HandBctq.weaknessR);
    setweaknessL(HandBctq.weaknessL);
    settinglingR(HandBctq.tinglingR);
    settinglingL(HandBctq.tinglingL);
    setnightR(HandBctq.nightR);
    setnightL(HandBctq.nightL);
    setduringR(HandBctq.duringR);
    setduringL(HandBctq.duringL);
    setDifficultyR(HandBctq.DifficultyR);
    setDifficultyL(HandBctq.DifficultyL);
    setWritingR(HandBctq.WritingR);
    setWritingL(HandBctq.WritingL);
    setButtoningR(HandBctq.ButtoningR);
    setButtoningL(HandBctq.ButtoningL);
    setHoldingR(HandBctq.HoldingR);
    setHoldingL(HandBctq.HoldingL);
    setGrippingR(HandBctq.GrippingR);
    setGrippingL(HandBctq.GrippingL);
    setOpeningR(HandBctq.OpeningR);
    setOpeningL(HandBctq.OpeningL);
    setHouseholdR(HandBctq.HouseholdR);
    setHouseholdL(HandBctq.HouseholdL);
    setCarryingR(HandBctq.CarryingR);
    setCarryingL(HandBctq.CarryingL);
    setBathingR(HandBctq.BathingR);
    setBathingL(HandBctq.BathingL);
  }, [HandBctq]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandBctq, updateTotal]);

  const handlesevereR = (e) => {
    setsevereR(e);
    dispatch(
      updateHandWristbctqFieldScore({
        key: "severeR",
        value: e,
      })
    );
  };
  const handlesevereL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "severeL",
        value: e,
      })
    );
    setsevereL(e);
  };
  const handleoftenR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "oftenR",
        value: e,
      })
    );
    setoftenR(e);
  };
  const handleoftenL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "oftenL",
        value: e,
      })
    );
    setoftenL(e);
  };
  const handletypicallyR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "typicallyR",
        value: e,
      })
    );
    settypicallyR(e);
  };
  const handletypicallyL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "typicallyL",
        value: e,
      })
    );
    settypicallyL(e);
  };
  const handledaytimeR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "daytimeR",
        value: e,
      })
    );
    setdaytimeR(e);
  };
  const handledaytimeL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "daytimeL",
        value: e,
      })
    );
    setdaytimeL(e);
  };
  const handleaverageR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "averageR",
        value: e,
      })
    );
    setaverageR(e);
  };
  const handleaverageL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "averageL",
        value: e,
      })
    );
    setaverageL(e);
  };
  const handlenumbnessR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "numbnessR",
        value: e,
      })
    );
    setnumbnessR(e);
  };
  const handlenumbnessL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "numbnessL",
        value: e,
      })
    );
    setnumbnessL(e);
  };
  const handleweaknessR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "weaknessR",
        value: e,
      })
    );
    setweaknessR(e);
  };
  const handleweaknessL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "weaknessL",
        value: e,
      })
    );
    setweaknessL(e);
  };
  const handletinglingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "tinglingR",
        value: e,
      })
    );
    settinglingR(e);
  };
  const handletinglingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "tinglingL",
        value: e,
      })
    );
    settinglingL(e);
  };
  const handlenightR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "nightR",
        value: e,
      })
    );
    setnightR(e);
  };
  const handlenightL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "nightL",
        value: e,
      })
    );
    setnightL(e);
  };
  const handleduringR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "duringR",
        value: e,
      })
    );
    setduringR(e);
  };
  const handleduringL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "duringL",
        value: e,
      })
    );
    setduringL(e);
  };

  const handleDifficultyR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "DifficultyR",
        value: e,
      })
    );
    setDifficultyR(e);
  };
  const handleDifficultyL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "DifficultyL",
        value: e,
      })
    );
    setDifficultyL(e);
  };
  const handleWritingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "WritingR",
        value: e,
      })
    );
    setWritingR(e);
  };
  const handleWritingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "WritingL",
        value: e,
      })
    );
    setWritingL(e);
  };
  const handleButtoningR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "ButtoningR",
        value: e,
      })
    );
    setButtoningR(e);
  };
  const handleButtoningL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "ButtoningL",
        value: e,
      })
    );
    setButtoningL(e);
  };
  const handleHoldingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "HoldingR",
        value: e,
      })
    );
    setHoldingR(e);
  };
  const handleHoldingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "HoldingL",
        value: e,
      })
    );
    setHoldingL(e);
  };
  const handleGrippingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "GrippingR",
        value: e,
      })
    );
    setGrippingR(e);
  };
  const handleGrippingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "GrippingL",
        value: e,
      })
    );
    setGrippingL(e);
  };
  const handleOpeningR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "OpeningR",
        value: e,
      })
    );
    setOpeningR(e);
  };
  const handleOpeningL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "OpeningL",
        value: e,
      })
    );
    setOpeningL(e);
  };
  const handleHouseholdR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "HouseholdR",
        value: e,
      })
    );
    setHouseholdR(e);
  };
  const handleHouseholdL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "HouseholdL",
        value: e,
      })
    );
    setHouseholdL(e);
  };
  const handleCarryingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "CarryingR",
        value: e,
      })
    );
    setCarryingR(e);
  };
  const handleCarryingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "CarryingL",
        value: e,
      })
    );
    setCarryingL(e);
  };

  const handleBathingR = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "BathingR",
        value: e,
      })
    );
    setBathingR(e);
  };
  const handleBathingL = (e) => {
    dispatch(
      updateHandWristbctqFieldScore({
        key: "BathingL",
        value: e,
      })
    );
    setBathingL(e);
  };
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };
  const continueRedirect = () => {
    dispatch(
      updateHandWristbctqScores([
        {
          severeR,
          severeL,
          oftenR,
          oftenL,
          typicallyR,
          typicallyL,
          daytimeR,
          daytimeL,
          averageR,
          averageL,
          numbnessR,
          numbnessL,
          weaknessR,
          weaknessL,
          tinglingR,
          tinglingL,
          nightR,
          nightL,
          duringR,
          duringL,
          DifficultyR,
          DifficultyL,
          WritingR,
          WritingL,
          ButtoningR,
          ButtoningL,
          HoldingR,
          HoldingL,
          GrippingR,
          GrippingL,
          OpeningR,
          OpeningL,
          HouseholdR,
          HouseholdL,
          CarryingR,
          CarryingL,
          BathingR,
          BathingL,
        },
      ])
    );

    if (disease === "2") {
      navigate("/hand-wrist-uram/" + rId);
    } else {
      navigate("/hand-wrist-qol/" + rId);
    }
  };
  return (
    <div className="pain_screen mb_100">
      <div class="d-flex justify-content-end">
        <NameCustom />
      </div>
      <div className="panel">
        <div className="panel-body p_25">
          {doctorEnable === "true" && <HandWristTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />

          <h4 className="ph_title f_40">
            {translation.patient_hand_questionnaire}
          </h4>

          <div className="function_formtop">
            <div className="row">
              <div className="col-md-6 col-sm-6 function_head">
                <h6 class="label-title">
                  {translation.patient_hand_questionnaire_symptom}
                </h6>
                <label>
                  {translation.patient_hand_questionnaire_symptom_instruction}
                </label>{" "}
                <br />
              </div>
            </div>
            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q1 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={severeR}
                    onChange={handlesevereR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q1 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={severeL}
                    onChange={handlesevereL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q1_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <HandWristScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q2 +
                      RigthTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={oftenR}
                    onChange={handleoftenR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <HandWristScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q2 +
                      LeftTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={oftenL}
                    onChange={handleoftenL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q2_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q3 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={typicallyR}
                    onChange={handletypicallyR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q3 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={typicallyL}
                    onChange={handletypicallyL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q3_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q4 +
                      RigthTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={daytimeR}
                    onChange={handledaytimeR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q4 +
                      LeftTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={daytimeL}
                    onChange={handledaytimeL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q4_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q5 +
                      RigthTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={averageR}
                    onChange={handleaverageR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q5 +
                      LeftTrans(evaluated, translation)
                    }
                    styleClass=""
                    value={averageL}
                    onChange={handleaverageL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q5_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q6 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={numbnessR}
                    onChange={handlenumbnessR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q6 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={numbnessL}
                    onChange={handlenumbnessL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q6_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q7 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={weaknessR}
                    onChange={handleweaknessR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q7 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={weaknessL}
                    onChange={handleweaknessL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q7_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q8 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={tinglingR}
                    onChange={handletinglingR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q8 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={tinglingL}
                    onChange={handletinglingL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q8_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q9 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={nightR}
                    onChange={handlenightR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q9 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={nightL}
                    onChange={handlenightL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q9_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q10 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={duringR}
                    onChange={handleduringR}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <KneeScoreRangeSlider
                    label={
                      translation.patient_hand_questionnaire_symptom_q10 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={duringL}
                    onChange={handleduringL}
                    sliderData={[
                      {
                        score: "0",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a1,
                      },
                      {
                        score: "1",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a2,
                      },
                      {
                        score: "2",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a3,
                      },
                      {
                        score: "3",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a4,
                      },
                      {
                        score: "4",
                        label:
                          translation.patient_hand_questionnaire_symptom_q10_a5,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="function_formtop">
            <h6 class="label-title">
              {translation.patient_hand_questionnaire_function}
            </h6>
            <div className="row">
              <div className="col-md-6 col-sm-6 function_head">
                <label>
                  {translation.patient_hand_questionnaire_function_instructions}
                </label>{" "}
                <br />
                <span dir={lg === "ara" ? "rtl" : " "}>
                  (<strong>0</strong> =
                  {
                    translation.patient_hand_questionnaire_function_instructions_0
                  }
                  , <strong>1</strong> ={" "}
                  {
                    translation.patient_hand_questionnaire_function_instructions_1
                  }
                  , <strong>2</strong> ={" "}
                  {
                    translation.patient_hand_questionnaire_function_instructions_2
                  }
                  , <strong>3</strong> =
                  {
                    translation.patient_hand_questionnaire_function_instructions_3
                  }
                  , <strong>4</strong> ={" "}
                  {
                    translation.patient_hand_questionnaire_function_instructions_4
                  }{" "}
                  )
                </span>
              </div>
            </div>
            <div className="row">
              <div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q1 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={DifficultyR}
                        onChange={handleDifficultyR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q1 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={DifficultyL}
                        onChange={handleDifficultyL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q2 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={WritingR}
                        onChange={handleWritingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q2 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={WritingL}
                        onChange={handleWritingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q3 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={ButtoningR}
                        onChange={handleButtoningR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q3 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={ButtoningL}
                        onChange={handleButtoningL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q4 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={HoldingR}
                        onChange={handleHoldingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q4 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={HoldingL}
                        onChange={handleHoldingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q5 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={GrippingR}
                        onChange={handleGrippingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q5 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={GrippingL}
                        onChange={handleGrippingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q6 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={OpeningR}
                        onChange={handleOpeningR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q6 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={OpeningL}
                        onChange={handleOpeningL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q7 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={HouseholdR}
                        onChange={handleHouseholdR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q7 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={HouseholdL}
                        onChange={handleHouseholdL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q8 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={CarryingR}
                        onChange={handleCarryingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q8 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={CarryingL}
                        onChange={handleCarryingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q9 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={BathingR}
                        onChange={handleBathingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_hand_questionnaire_function_q9 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={BathingL}
                        onChange={handleBathingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_hand_questionnaire_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {doctorEnable === "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6"></div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
            </div>
          )}
          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                <Link
                  to={"/hand-wrist-function/" + rId}
                  className="assm_back_btn"
                >
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

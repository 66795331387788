import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Badge } from "react-bootstrap";
import "./Tab.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
export default function FootAnkleTab({ rId }) {
    const instabilityACjoint = useSelector(
        (state) => state.shoulderScore.functionScore[0].instability_acjoint
    );
    const feel_unstable = useSelector(
        (state) => state.shoulderScore.functionScore[0].feel_unstable
    );

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const pId = queryParams.get("akpt");
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [key, setKey] = useState(splitLocation[1]);

    const axiosinstance = useAxios();
    const [patientStatus, setPatientStatus] = useState(false);
    const [doctorStatus, setDoctorStatus] = useState(false);
    const getTab = () => {
        axiosinstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
            .then((res) => {
                console.log(res.data.doctorStatus, res.data.status, "responce");
                setPatientStatus(res.data.status);
                setDoctorStatus(res.data.doctorStatus);
                if (res.data.status) {
                    // handleTabSelect("pain");
                } else if (res.data.doctorStatus) {
                    handleTabSelect("doctor-feedback/step-1");
                } else {
                    navigate("/un-authorized-access");
                }
            })
            .catch((err) => {
                // navigate('/un-authorized-access');
            });
    };
    useEffect(() => {
        getTab();
    }, []);

    const handleTabSelect = (selectedTab) => {
        setKey(selectedTab);
        switch (selectedTab) {
            case "pain":
                navigate(`/foot-ankle-pain/${rId}?d=true&akpt=${pId}`);
                break;
            case "instability":
                navigate(`/foot-ankle-instability/${rId}?d=true&akpt=${pId}`);
                break;
            case "symptoms":
                navigate(`/foot-ankle-other-symptoms/${rId}?d=true&akpt=${pId}`);   
                break;
            case "function":
                navigate(`/foot-ankle-function/${rId}?d=true&akpt=${pId}`);
                break;
            case "quality-of-life":
                navigate(`/foot-ankle-quality-of-life/${rId}?d=true&akpt=${pId}`);
                break;
            case "satisfaction":
                navigate(`/foot-ankle-satisfaction/${rId}?d=true&akpt=${pId}`);
                break;
            case "cumberland":
                navigate(`/cumberland-ankle/${rId}?d=true&akpt=${pId}`);
                break;

            case "doctor-feedback/step-1":
                navigate(`/foot-doctor-feedback/${rId}?d=true&akpt=${pId}`);
                break;

            case "doctor-feedback/step-2":
                navigate(`/foot-doctor-feedback-step-2/${rId}?d=true&akpt=${pId}`);
                break;
            case "doctor-feedback/step-3":
                navigate(`/foot-doctor-feedback-step-3/${rId}?d=true&akpt=${pId}`);
                break;

            default:
                break;
        }
    };
    return (
        <>
            <div className="col-md-12">
                <Tabs
                    id="doctor-reg-tabs"
                    activeKey={key}
                    // onSelect={handleSelect}
                    className="doctor_reg_tabs mb-1"
                    onSelect={(e) => handleTabSelect(e)}
                >
                    {patientStatus && (
                    <Tab
                        eventKey="pain"
                        title={<React.Fragment>Pain</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (
                    <Tab
                        eventKey="instability"
                        title={<React.Fragment>Instability</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (
                    <Tab
                        eventKey="symptoms"
                        title={<React.Fragment>Symptoms</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (

                    <Tab
                        eventKey="function"
                        title={<React.Fragment>Function</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (

                    <Tab
                        eventKey="quality-of-life"
                        title={<React.Fragment>QOL</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (
                    <Tab
                        eventKey="satisfaction"
                        title={<React.Fragment>Satisfaction</React.Fragment>}
                    ></Tab>
                    )}
                    {patientStatus && (
                    <Tab
                        eventKey="cumberland"
                        title={<React.Fragment>Cumberland</React.Fragment>}
                    ></Tab>
                    )}
                    {doctorStatus && (
                    <Tab
                        eventKey="doctor-feedback/step-1"
                        title={<React.Fragment>Patient-Assessment-Step 1</React.Fragment>}
                    ></Tab>
                    )}
                    {/* <Tab
                        eventKey="doctor-feedback/step-2"
                        title={<React.Fragment>Patient-Assessment-Step 2</React.Fragment>}
                    ></Tab>
                      <Tab
                        eventKey="doctor-feedback/step-3"
                        title={<React.Fragment>Patient-Assessment-Step 3</React.Fragment>}
                    ></Tab> */}
                </Tabs>
            </div>
        </>
    );
}

import React, { useState, useEffect } from "react";
import useTransition from "../../../customHooks/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import Dropdown from "../../../custom/dropdown/Dropdown";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import InputField from "../../../custom/inputfield/InputField";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import useAxios from "../../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import enUS from "date-fns/locale/en-US"; // Import the desired locale
import fr from "date-fns/locale/fr";
import ar from "date-fns/locale/ar";
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import es from "date-fns/locale/es";
import th from "date-fns/locale/th";
import NameCustom from "../../../custom/name/NameCustom";

import Select from "react-select";
import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintScore,
  updatePastComplaintScore,
  updateStatus,
} from "../../../../redux/actions/GeneralAction";
import { useSearchParams } from "react-router-dom";
registerLocale("en-US", enUS); // Register the locale with date-fns
registerLocale("fr", fr);
registerLocale("ar", ar);
registerLocale("de", de);
registerLocale("it", it);
registerLocale("es", es);
registerLocale("th", th);
export default function History() {
  let lang = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const translation = useTransition();
  const axiosinstance = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { rId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  let doctorEnable = searchParams.get("d");
  const [complaint, setComplaint] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [drop1, setDrop1] = useState("0");
  const [Investigation, setInvestigation] = useState("");
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState("");
  const [backDoc, setBackDoc] = useState("");
  let isdoctorForm = searchParams.get("is_doctor_form");
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm);
  }, [isdoctorForm]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const History = useSelector((state) => state.GeneralFormReduces.History[0]);
  const json = useSelector((state) => state.GeneralFormReduces);
  const akToken = localStorage.getItem("patientId");
  console.log("akTokenakTokenakToken",akToken)
  const History1 = useSelector((state) => state);
useEffect(() => {
    
  }, []);

console.log("statestatestatestatestatestatestate",History1)

  // const save = () => {
  //   axiosinstance.current
  //     .get(`extapp/forms/all_forms?resource_id=${rId}&type=33`)
  //     .then((res) => {
  //       setBackDoc(res.data.data2[0].is_doctor_form);
  //       dispatch(updateHistoryComplaintScore(res.data.data2[0].History));
  //       dispatch(updateSocialComplaintScore(res.data.data2[0].Social));

  //       dispatch(updatePastComplaintScore(res.data.data2[0].Past));

  //       setChk(true);
  //     })
  //     .catch((err) => {
  //       navigate("/un-authorized-access");
  //     });
  // };
  let doctordata = localStorage.getItem("isdoctorForm");
  let doctorid = sessionStorage.getItem("doctor_id");
  const continueRedirectMedical = async () => {
    try {
      await axiosinstance.current
        .put(
          `extapp/doctors/updateSummary?ak_id=${akToken}&doctor_id=${doctorid}&is_patient=${
            doctordata ? 0 : 1
          }`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //  if(res.status){
          //    navigate(`/medical-past/${ptId}?doctor=${doctorEnable}`);
          //  }
        });
    } catch (err) {
      // if(err.response!==undefined ?err.response.status===401:null){
      //   navigate("/un-authorized-access")
      // }
    }
  };

  const save = () => {
    axiosinstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${akToken}&doctor_id=${
          doctorid === "undefined" ? "" : doctorid
        }&is_patient=${doctorid === "undefined" ? 1 : 0}`
      )
      .then((res) => {
        dispatch(updateHistoryComplaintScore(res.data.data.History));
        // dispatch(updateSocialComplaintScore(res.data.data.Social));
        //  dispatch(updateStatus(res.data.data.status))
        // dispatch(updatePastComplaintScore(res.data.data.Past));

        setChk(true);
      })
      .catch((err) => {});
  };
  console.log(json);

  useEffect(() => {
    if (doctorid && akToken) {
      save();
    }
  }, [akToken]);
  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
        localStorage.setItem("patientId",res.data.data.ak_pt)
        sessionStorage.setItem("doctor_id", res.data.data.ak_dc);
      });
  };
  useEffect(() => {
    // save();
    backData();
  }, []);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [History]);

  useEffect(() => {
    setComplaint(History.complaint);
    setstartDate(new Date(History.startDate));
    setDrop1(History.drop1);
    setInvestigation(History.Investigation);
  }, [History]);
  const handleComplaint = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "complaint",
        value: e,
      })
    );
    setComplaint(e);
  };

  const handleDate = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "startDate",
        value: e,
      })
    );
    setstartDate(e);
  };

  const handleDrop = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "drop1",
        value: e,
      })
    );
    setDrop1(e);
  };
  console.log(drop1);

  const handleInvestigation = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "Investigation",
        value: e,
      })
    );
    setInvestigation(e);
  };

  const Medical = () => {
    // dispatch(
    //   updateHistoryComplaintScore({
    //     complaint,
    //     startDate,
    //     drop1,
    //     Investigation,
    //   })
    // );
    continueRedirectMedical();
    navigate("/general-past/" + rId + `?is_doctor_form=${doctordata}`);
  };

  const Back = () => {
    if (doctordata === "true") {
      window.open(process.env.REACT_APP_DOCTOR_URL);
      window.close();
    } else {
      window.open(process.env.REACT_APP_PATIENT_URL);
      window.close();
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <div className="row ">
                <div className=" col-md-12 col-sm-12 col-6">
                  <NameCustom />
                  <h4 className="ph_title f_40">
                    {translation.patient_general_history}0
                  </h4>
                </div>
                <div className="row pt_20">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="mb_10">
                      <InputField
                        label={translation.patient_general_history_q1}
                        value={complaint}
                        type="textarea"
                        placeholder=""
                        rows="4"
                        cols="20"
                        onChange={handleComplaint}
                        styleClass="h_150"
                      />
                      <br />
                      <label>{translation.patient_general_history_q2}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        // maxDate={maxDate}
                        className="form-control mb_0 br_10 w-25"
                        selected={new Date(startDate)}
                        onChange={handleDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={
                          lang === "en"
                            ? "en-US"
                            : lang === "ara"
                            ? "ar"
                            : lang === "ger"
                            ? "de"
                            : lang === "ita"
                            ? "it"
                            : lang === "spa"
                            ? "es"
                            : lang === "tha"
                            ? "th"
                            : lang === "fr"
                            ? "fr"
                            : "en-US"
                        }
                      />
                      <br />
                      <div className="mt-4 mb-4">
                        <label>{translation.patient_general_history_q3}</label>
                        <Select
                          isMulti
                          name="weekday"
                          options={[
                            {
                              value: "0",
                              label: translation.patient_general_history_q3_a1,
                            },
                            {
                              value: "1",
                              label: translation.patient_general_history_q3_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_general_history_q3_a3,
                            },
                            {
                              value: "3",
                              label: translation.patient_general_history_q3_a4,
                            },
                            {
                              value: "4",
                              label: translation.patient_general_history_q3_a5,
                            },
                            {
                              value: "5",
                              label: translation.patient_general_history_q3_a6,
                            },
                            {
                              value: "6",
                              label: translation.patient_general_history_q3_a7,
                            },
                          ]}
                          onChange={handleDrop}
                          value={drop1}
                          // className="form-control form-select"

                          classNamePrefix="select"
                          placeholder={
                            <div>
                              {
                                translation.patient_general_past_history_q28_a2_q1_placeholder
                              }
                            </div>
                          }
                        />
                        {/* <Dropdown
                          data={[
                            { value: "0", label: translation.patient_general_history_q3_a1 },
                            { value: "1", label: translation.patient_general_history_q3_a2 },
                            { value: "2", label: translation.patient_general_history_q3_a3 },
                            { value: "3", label: translation.patient_general_history_q3_a4 },
                            { value: "4", label: translation.patient_general_history_q3_a5 },
                            { value: "5", label: translation.patient_general_history_q3_a6 },
                            { value: "6", label: translation.patient_general_history_q3_a7 },
                          ]}
                          label={translation.patient_general_history_q3}
                          styleClass=""
                          value={drop1}
                          placeholder={translation.none}
                          onChange={handleDrop}
                        /> */}
                      </div>
                      <InputField
                        label={translation.patient_general_history_q4}
                        value={Investigation}
                        type="textarea"
                        placeholder={
                          translation.patient_general_history_q4_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handleInvestigation}
                        styleClass="h_150"
                      />
                      <div className="row mb_30 button_box">
                        <div className="col-md-6 col-sm-6 col-6">
                          <span
                            onClick={() => Back()}
                            className="assm_back_btn"
                          >
                            <img src={backIcon} alt="" />
                            <span>{translation.Close}</span>
                          </span>
                          {/* {backDoc !== 1 ? (
                            <Link
                              to={`/personal-data/${window.btoa(
                                backD.ak_pt
                              )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                                backD.org_id
                              )}/${window.btoa(backD.type)}?is_doctor_form=${doctordata}`}
                              className="assm_back_btn"
                            >
                              <img src={backIcon} alt="" />
                              <span onClick={() => Back()}>{translation.Close}</span>
                            </Link>
                          ) : (
                            <Link
                              to={`/personal-data/${window.btoa(
                                backD.ak_pt
                              )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                                backD.org_id
                              )}/${window.btoa(
                                backD.type
                              )}?is_doctor_form=${doctordata}&doctorvalue=0`}
                              className="assm_back_btn"
                            >
                              <img src={backIcon} alt="" />
                              <span>{translation.back_button}</span>
                            </Link>
                          )} */}
                        </div>
                        <div className="col-md-6 col-sm-6 col-6 text-end">
                          <button
                            className="next_btn btn_fill"
                            onClick={Medical}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import useTransition from "../../../customHooks/translations";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useAxios from "../../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShoulderFunctionFieldScore,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
  updateAllData,
  updateFeedbackFunctionScores,
} from "../../../../redux/actions/shoulderAction";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import Tab from "../../tab-section/ShoulderTab";
import NameCustom from "../../../custom/name/NameCustom";
export default function Instability() {
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  const functionScore = useSelector(
    (state) => state.shoulderScore.functionScore[0]
  );
  const translation = useTransition();
  const [isLoading, setIsLoading] = useState(true);
  const [feelUnstable, setFeelUnstable] = useState("");
  const [instabilityACjoint, setInstabilityACjoint] = useState("");
  const [unstableShoulderRangeL, setUnstableShoulderRangeL] = useState("0");
  const [unstableShoulderRangeR, setUnstableShoulderRangeR] = useState("0");
  const [updateTotal, setUpdateTotal] = useState(false);
  const json = useSelector((state) => state.shoulderScore);
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(
          updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setFeelUnstable(functionScore.feel_unstable);
    setInstabilityACjoint(functionScore.instability_acjoint);
    setUnstableShoulderRangeL(functionScore.unstable_shoulder_range_left);
    setUnstableShoulderRangeR(functionScore.unstable_shoulder_range_right);
  }, [functionScore]);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [functionScore, updateTotal,doctorEnable]);

  useEffect(() => {
    if (instabilityACjoint === "0") {
      dispatch(
        updateSACSFunctionScores([
          {
            sacs_q1_left: "0",
            sacs_q1_right: "0",
            sacs_q2_left: "0",
            sacs_q2_right: "0",
            sacs_q3_left: "0",
            sacs_q3_right: "0",
            sacs_q4_left: "0",
            sacs_q4_right: "0",
            sacs_q5_left: "0",
            sacs_q5_right: "0",
            sacs_q6_left: "0",
            sacs_q6_right: "0",
            sacs_q7_left: "0",
            sacs_q7_right: "0",
            sacs_q8_left: "0",
            sacs_q8_right: "0",
            sacs_q9_left: "0",
            sacs_q9_right: "0",
            sacs_q10_left: "0",
            sacs_q10_right: "0",
            sacs_q11_left: "0",
            sacs_q11_right: "0",
            sacs_q12_left: "0",
            sacs_q12_right: "0",
            sacs_q13_left: "0",
            sacs_q13_right: "0",
            sacs_q14_left: "0",
            sacs_q14_right: "0",
            sacs_q15_left: "0",
            sacs_q15_right: "0",
            sacs_q16_left: "0",
            sacs_q16_right: "0",
            sacs_q17_left: "0",
            sacs_q17_right: "0",
            sacs_q18_left: "0",
            sacs_q18_right: "0",
            sacs_q19_left: "0",
            sacs_q19_right: "0",
            sacs_q20_left: "0",
            sacs_q20_right: "0",
            sacs_q21_left: "0",
            sacs_q21_right: "0",
          },
        ])
      );
    }
  }, [instabilityACjoint === "0"]);

  const handleInstabilityACjoint = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "instability_acjoint",
        value: e,
      })
    );
    setInstabilityACjoint(e);
  };

  const continueRedirect = (e) => {
    dispatch(
      updateShoulderFunctionScores([
        {
          affected_shoulder_left: functionScore.affected_shoulder_left,
          affected_shoulder_right: functionScore.affected_shoulder_right,
          put_shirt_right: functionScore.put_shirt_right,
          put_shirt_left: functionScore.put_shirt_left,
          affected_side_right: functionScore.affected_side_right,
          affected_side_left: functionScore.affected_side_left,
          wash_back_right: functionScore.wash_back_right,
          wash_back_left: functionScore.wash_back_left,
          manage_toilet_right: functionScore.manage_toilet_right,
          manage_toilet_left: functionScore.manage_toilet_left,
          comb_hair_right: functionScore.comb_hair_right,
          comb_hair_left: functionScore.comb_hair_left,
          apply_makup_right: functionScore.apply_makup_right,
          apply_makup_left: functionScore.apply_makup_left,
          brush_teeth_right: functionScore.brush_teeth_right,
          brush_teeth_left: functionScore.brush_teeth_left,
          full_bottle_right: functionScore.full_bottle_right,
          full_bottle_left: functionScore.full_bottle_left,
          bring_mouth_right: functionScore.bring_mouth_right,
          bring_mouth_left: functionScore.bring_mouth_left,
          eat_soup_right: functionScore.eat_soup_right,
          eat_soup_left: functionScore.eat_soup_left,
          shake_hand_right: functionScore.shake_hand_right,
          shake_hand_left: functionScore.shake_hand_left,
          use_phone_right: functionScore.use_phone_right,
          use_phone_left: functionScore.use_phone_left,
          write_letter_right: functionScore.write_letter_right,
          write_letter_left: functionScore.write_letter_left,
          reach_shelf_right: functionScore.reach_shelf_right,
          reach_shelf_left: functionScore.reach_shelf_left,
          lift05_right: functionScore.lift05_right,
          lift05_left: functionScore.lift05_left,
          lift45_right: functionScore.lift45_right,
          lift45_left: functionScore.lift45_left,
          lift10_right: functionScore.lift10_right,
          lift10_left: functionScore.lift10_left,
          throwunder_hand_right: functionScore.throwunder_hand_right,
          throwunder_hand_left: functionScore.throwunder_hand_left,
          throwover_hand_right: functionScore.throwover_hand_right,
          throwover_hand_left: functionScore.throwover_hand_left,
          fullusual_work_right: functionScore.fullusual_work_right,
          fullusual_work_left: functionScore.fullusual_work_left,
          usual_hobbies: functionScore.usual_hobbies,
          usual_sport_right: functionScore.usual_sport_right,
          usual_sport_left: functionScore.usual_sport_left,
          hobbies: functionScore.hobbies,
          feel_unstable: feelUnstable,
          instability_acjoint: instabilityACjoint,
          unstable_shoulder_range_right: unstableShoulderRangeR,
          unstable_shoulder_range_left: unstableShoulderRangeL,
        },
      ])
    );
    if (instabilityACjoint === "0" || instabilityACjoint === "") {
      navigate("/satisfaction/" + rId);
    } else {
      // navigate("/ac-insta/" +rId)
      navigate("/sacs/" + rId);
    }
    // navigate("/ac-insta/" +rId)
    // if (feelUnstable === "1" && instabilityACjoint == "") {
    //   navigate("/wosi/" + rId);

    // }
    // if (feelUnstable === "0" && instabilityACjoint == "") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "0") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "1") {
    //   navigate("/sacs/" + rId);
    // }

    // if (feelUnstable === "0" && instabilityACjoint == "0") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "1" && instabilityACjoint == "0") {
    //   navigate("/wosi/" + rId);
    // }
    // if (instabilityACjoint == "1" && feelUnstable === "1") {
    //   navigate("/wosi/" + rId);
    // }
    // if (feelUnstable === "0" && instabilityACjoint == "1") {
    //   navigate("/sacs/" + rId);
    // }
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(true);
    setMsg(translation.update);
    setUpdateTotal(!updateTotal);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="function_screen mb_100">
          <div class="d-flex flex-row-reverse">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <Tab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_shoulder_ac_joint}
              </h4>
              <p className="info-note ">
                {translation.patient_shoulder_ac_joint_q1_note}
              </p>
              <div className="function_formbottom">
                <div className="row mt-5">
                  <div className="col-md-12 col-sm-12 mb_10">
                    <RadioSwitch
                      labelLeft={translation.patient_shoulder_ac_joint_q1_a1}
                      labelRight={translation.patient_shoulder_ac_joint_q1_a2}
                      idLeft="instabilityACjointNo"
                      idRight="instabilityACjointYes"
                      name="instabilityACjoint"
                      title={translation.patient_shoulder_ac_joint_q1}
                      styleClass=""
                      value={instabilityACjoint}
                      onChange={handleInstabilityACjoint}
                      placeholder={translation.Select}
                    />
                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={`${
                        feelUnstable === "0" || feelUnstable === ""
                          ? "/feeling-insta/" + rId
                          : "/wosi/" + rId
                      }`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                    {/* <span onClick={()=>Back()} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { updatePediaScores } from "../../../../../redux/actions/pediaKnee";
import { useDispatch, useSelector } from 'react-redux';
import FullDropdown from "../../../../custom/dropdown/FullDropdown";
import Checkbox from "../../../../custom/checkbox/Checkbox";
import {
  updateAdultKneeNorwichInstaFieldScore,
  updateAdultKneeNorwichInstaScore,
  updateAdultKneeQolScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeFunctionScore,
  updateAllData,
  updateAdultKneeFeedback

} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import AdultkneeTab from '../../../tab-section/AdultkneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import HipScoreRangeSlider from "../../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";




function NorwichPatellarInstability() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const evlPediaPain = useSelector(state => state.adultKneeScore.evlAdultKnee);
  const [twistingR, setTwistingR] = useState("");
  const [twistingL, setTwistingL] = useState("");
  const [changeDirectionR, setChangeDirectionR] = useState("");
  const [changeDirectionL, setChangeDirectionL] = useState("");
  const [runningStraightlineR, setRunningStraightlineR] = useState("");
  const [runningStraightlineL, setRunningStraightlineL] = useState("");
  const [walkingSlipperyR, setWalkingSlipperyR] = useState("");
  const [walkingSlipperyL, setWalkingSlipperyL] = useState("");
  const [runningSidewaysR, setRunningSidewaysR] = useState("");
  const [runningSidewaysL, setRunningSidewaysL] = useState("");
  const [hoppingR, setHoppingR] = useState("");
  const [hoppingL, setHoppingL] = useState("");
  const [jumpingR, setJumpingR] = useState("");
  const [jumpingL, setJumpingL] = useState("");
  const [runningOnEvenSurfaceR, setRunningOnEvenSurfaceR] = useState("");
  const [runningOnEvenSurfaceL, setRunningOnEvenSurfaceL] = useState("");
  const [goingDownStairsR, setGoingDownStairsR] = useState("");
  const [goingDownStairsL, setGoingDownStairsL] = useState("");
  const [squattingR, setSquattingR] = useState("");
  const [squattingL, setSquattingL] = useState("");
  const [kneelingR, setKneelingR] = useState("");
  const [kneelingL, setKneelingL] = useState("");
  const [walkingUnevenSurfacesR, setWalkingUnevenSurfacesR] = useState("");
  const [walkingUnevenSurfacesL, setWalkingUnevenSurfacesL] = useState("");
  const [climbingStairsR, setClimbingStairsR] = useState("");
  const [climbingStairsL, setClimbingStairsL] = useState("");
  const [steppingOverHighR, setSteppingOverHighR] = useState("");
  const [steppingOverHighL, setSteppingOverHighL] = useState("");
  const [crossingWhenSittingR, setCrossingWhenSittingR] = useState("");
  const [crossingWhenSittingL, setCrossingWhenSittingL] = useState("");
  const [walkingEvenSurfaceR, setWalkingEvenSurfaceR] = useState("");
  const [walkingEvenSurfaceL, setWalkingEvenSurfaceL] = useState("");
  const [gettingIntoCarR, setGettingIntoCarR] = useState("");
  const [gettingIntoCarL, setGettingIntoCarL] = useState("");
  const [supermarketAisleR, setSupermarketAisleR] = useState("");
  const [supermarketAisleL, setSupermarketAisleL] = useState("");
  const [turningYourShoulderR, setTurningYourShoulderR] = useState("");
  const [turningYourShoulderL, setTurningYourShoulderL] = useState("");
  const [twcheck, settwcheck] = useState(false);
  const [directioncheck, setdirectioncheck] = useState(false);
  const [straightcheck, setstraightcheck] = useState(false);
  const [slipperycheck, setslipperycheck] = useState(false);
  const [sidewayscheck, setsidewayscheck] = useState(false);
  const [Hoppingcheck, setHoppingcheck] = useState(false);
  const [Jumpingcheck, setJumpingcheck] = useState(false);
  const [scaleLabelcheck, setscaleLabelcheck] = useState(false);
  const [downcheck, setdowncheck] = useState(false);
  const [Squattingcheck, setSquattingcheck] = useState(false);
  const [Kneelingcheck, setKneelingcheck] = useState(false);
  const [Surfacescheck, setSurfacescheck] = useState(false);
  const [Climbingcheck, setClimbingcheck] = useState(false);
  const [Steppingcheck, setSteppingcheck] = useState(false);
  const [Crossingcheck, setCrossingcheck] = useState(false);
  const [walkingEvencheck, setwalkingEvencheck] = useState(false);
  const [IntoCarcheck, setIntoCarcheck] = useState(false);
  const [heavycheck, setheavycheck] = useState(false);
  const [turningcheck, setturningcheck] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);

  const json = useSelector((state) => state.adultKneeScore);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability));
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      }).catch((err) => {
        
        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const Kneenorwich = useSelector((state) => state.adultKneeScore.norwichInstability[0]);
  const pattelar = useSelector(state => state.adultKneeScore.functionScore[0].patellarInstability);

  useEffect(() => {
    setTwistingR(Kneenorwich.twistingR)
    setTwistingL(Kneenorwich.twistingL)
    setChangeDirectionR(Kneenorwich.changeDirectionR)
    setChangeDirectionL(Kneenorwich.changeDirectionL)
    setRunningStraightlineR(Kneenorwich.runningStraightlineR)
    setRunningStraightlineL(Kneenorwich.runningStraightlineL)
    setWalkingSlipperyR(Kneenorwich.walkingSlipperyR)
    setWalkingSlipperyL(Kneenorwich.walkingSlipperyL)
    setRunningSidewaysR(Kneenorwich.runningSidewaysR)
    setRunningSidewaysL(Kneenorwich.runningSidewaysL)
    setHoppingR(Kneenorwich.hoppingR)
    setHoppingL(Kneenorwich.hoppingL)
    setJumpingR(Kneenorwich.jumpingR)
    setJumpingL(Kneenorwich.jumpingL)
    setRunningOnEvenSurfaceR(Kneenorwich.runningOnEvenSurfaceR)
    setRunningOnEvenSurfaceL(Kneenorwich.runningOnEvenSurfaceL)
    setGoingDownStairsR(Kneenorwich.goingDownStairsR)
    setGoingDownStairsL(Kneenorwich.goingDownStairsL)
    setSquattingR(Kneenorwich.squattingR)
    setSquattingL(Kneenorwich.squattingL)
    setKneelingR(Kneenorwich.kneelingR)
    setKneelingL(Kneenorwich.kneelingL)
    setWalkingUnevenSurfacesR(Kneenorwich.walkingUnevenSurfacesR)
    setWalkingUnevenSurfacesL(Kneenorwich.walkingUnevenSurfacesL)
    setClimbingStairsR(Kneenorwich.climbingStairsR)
    setClimbingStairsL(Kneenorwich.climbingStairsL)
    setSteppingOverHighR(Kneenorwich.steppingOverHighR)
    setSteppingOverHighL(Kneenorwich.steppingOverHighL)
    setCrossingWhenSittingR(Kneenorwich.crossingWhenSittingR)
    setCrossingWhenSittingL(Kneenorwich.crossingWhenSittingL)
    setWalkingEvenSurfaceR(Kneenorwich.walkingEvenSurfaceR)
    setWalkingEvenSurfaceL(Kneenorwich.walkingEvenSurfaceL)
    setGettingIntoCarR(Kneenorwich.gettingIntoCarR)
    setGettingIntoCarL(Kneenorwich.gettingIntoCarL)
    setSupermarketAisleR(Kneenorwich.supermarketAisleR)
    setSupermarketAisleL(Kneenorwich.supermarketAisleL)
    setTurningYourShoulderR(Kneenorwich.turningYourShoulderR)
    setTurningYourShoulderL(Kneenorwich.turningYourShoulderL)
    settwcheck(Kneenorwich.twcheck)
    setdirectioncheck(Kneenorwich.directioncheck)
    setstraightcheck(Kneenorwich.straightcheck)
    setslipperycheck(Kneenorwich.slipperycheck)
    setsidewayscheck(Kneenorwich.sidewayscheck)
    setHoppingcheck(Kneenorwich.Hoppingcheck)
    setJumpingcheck(Kneenorwich.Jumpingcheck)
    setscaleLabelcheck(Kneenorwich.scaleLabelcheck)
    setdowncheck(Kneenorwich.downcheck)
    setSquattingcheck(Kneenorwich.Squattingcheck)
    setKneelingcheck(Kneenorwich.Kneelingcheck)
    setSurfacescheck(Kneenorwich.Surfacescheck)
    setClimbingcheck(Kneenorwich.Climbingcheck)
    setSteppingcheck(Kneenorwich.Steppingcheck)
    setCrossingcheck(Kneenorwich.Crossingcheck)
    setwalkingEvencheck(Kneenorwich.walkingEvencheck)
    setIntoCarcheck(Kneenorwich.IntoCarcheck)
    setheavycheck(Kneenorwich.heavycheck)
    setturningcheck(Kneenorwich.turningcheck)

  }, [Kneenorwich])
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [Kneenorwich,updateTotal]);

  const scaleLabels = [
    { score: "0", label: translation.never },
    { score: "1", label: translation.rarely },
    { score: "2", label: translation.sometimes },
    { score: "3", label: translation.often },
    { score: "4", label: translation.always },
  ]

  const handleTwistingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'twistingR',
      "value": e
    }))
    setTwistingR(e);
  };
  const handleTwistingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'twistingL',
      "value": e
    }))
    setTwistingL(e);
  };

  const handleChangeDirectionR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'changeDirectionR',
      "value": e
    }))
    setChangeDirectionR(e);
  };
  const handleChangeDirectionL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'changeDirectionL',
      "value": e
    }))
    setChangeDirectionL(e);
  };

  const handleRunningStraightlineR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningStraightlineR',
      "value": e
    }))
    setRunningStraightlineR(e);
  };
  const handleRunningStraightlineL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningStraightlineL',
      "value": e
    }))
    setRunningStraightlineL(e);
  };

  const handleWalkingSlipperyR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingSlipperyR',
      "value": e
    }))
    setWalkingSlipperyR(e);
  };
  const handleWalkingSlipperyL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingSlipperyL',
      "value": e
    }))
    setWalkingSlipperyL(e);
  };

  const handleRunningSidewaysR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningSidewaysR',
      "value": e
    }))
    setRunningSidewaysR(e);
  };
  const handleRunningSidewaysL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningSidewaysL',
      "value": e
    }))
    setRunningSidewaysL(e);
  };

  const handleHoppingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'hoppingR',
      "value": e
    }))
    setHoppingR(e);
  };
  const handleHoppingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'hoppingL',
      "value": e
    }))
    setHoppingL(e);
  };

  const handleJumpingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'jumpingR',
      "value": e
    }))
    setJumpingR(e);
  };
  const handleJumpingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'jumpingL',
      "value": e
    }))
    setJumpingL(e);
  };

  const handleRunningOnEvenSurfaceR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningOnEvenSurfaceR',
      "value": e
    }))
    setRunningOnEvenSurfaceR(e);
  };
  const handleRunningOnEvenSurfaceL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'runningOnEvenSurfaceL',
      "value": e
    }))
    setRunningOnEvenSurfaceL(e);
  };

  const handleGoingDownStairsR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'goingDownStairsR',
      "value": e
    }))
    setGoingDownStairsR(e);
  };
  const handleGoingDownStairsL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'goingDownStairsL',
      "value": e
    }))
    setGoingDownStairsL(e);
  };

  const handleSquattingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'squattingR',
      "value": e
    }))
    setSquattingR(e);
  };
  const handleSquattingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'squattingL',
      "value": e
    }))
    setSquattingL(e);
  };

  const handleKneelingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'kneelingR',
      "value": e
    }))
    setKneelingR(e);
  };
  const handleKneelingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'kneelingL',
      "value": e
    }))
    setKneelingL(e);
  };

  const handleWalkingUnevenSurfacesR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingUnevenSurfacesR',
      "value": e
    }))
    setWalkingUnevenSurfacesR(e);
  };
  const handleWalkingUnevenSurfacesL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingUnevenSurfacesL',
      "value": e
    }))
    setWalkingUnevenSurfacesL(e);
  };

  const handleClimbingStairsR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'climbingStairsR',
      "value": e
    }))
    setClimbingStairsR(e);
  };
  const handleClimbingStairsL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'climbingStairsL',
      "value": e
    }))
    setClimbingStairsL(e);
  };

  const handleSteppingOverHighR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'steppingOverHighR',
      "value": e
    }))
    setSteppingOverHighR(e);
  };
  const handleSteppingOverHighL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'steppingOverHighL',
      "value": e
    }))
    setSteppingOverHighL(e);
  };

  const handleCrossingWhenSittingR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'crossingWhenSittingR',
      "value": e
    }))
    setCrossingWhenSittingR(e);
  };
  const handleCrossingWhenSittingL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'crossingWhenSittingL',
      "value": e
    }))
    setCrossingWhenSittingL(e);
  };

  const handleWalkingEvenSurfaceR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingEvenSurfaceR',
      "value": e
    }))
    setWalkingEvenSurfaceR(e);
  };
  const handleWalkingEvenSurfaceL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingEvenSurfaceL',
      "value": e
    }))
    setWalkingEvenSurfaceL(e);
  };

  const handleGettingIntoCarR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'gettingIntoCarR',
      "value": e
    }))
    setGettingIntoCarR(e);
  };
  const handleGettingIntoCarL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'gettingIntoCarL',
      "value": e
    }))
    setGettingIntoCarL(e);
  };

  const handleSupermarketAisleR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'supermarketAisleR',
      "value": e
    }))
    setSupermarketAisleR(e);
  };
  const handleSupermarketAisleL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'supermarketAisleL',
      "value": e
    }))
    setSupermarketAisleL(e);
  };
  const handleTurningYourShoulderR = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'turningYourShoulderR',
      "value": e
    }))
    setTurningYourShoulderR(e);
  };
  const handleTurningYourShoulderL = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'turningYourShoulderL',
      "value": e
    }))
    setTurningYourShoulderL(e);
  };
  const handletwcheck = (e) => {

    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'twcheck',
      "value": e
    }))
    settwcheck(e)
  }
  const handledirectioncheck = (e) => {

    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'directioncheck',
      "value": e
    }))
    setdirectioncheck(e)
  }
  const handlestraightcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'straightcheck',
      "value": e
    }))
    setstraightcheck(e)
  }
  const handleslipperycheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'slipperycheck',
      "value": e
    }))
    setslipperycheck(e)
  }
  const handlesidewayscheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'sidewayscheck',
      "value": e
    }))
    setsidewayscheck(e)
  }
  const handleHoppingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Hoppingcheck',
      "value": e
    }))
    setHoppingcheck(e)
  }
  const handleJumpingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Jumpingcheck',
      "value": e
    }))
    setJumpingcheck(e)
  }
  const handlescaleLabelcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'scaleLabelcheck',
      "value": e
    }))
    setscaleLabelcheck(e)
  }
  const handledowncheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'downcheck',
      "value": e
    }))
    setdowncheck(e)
  }
  const handleSquattingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Squattingcheck',
      "value": e
    }))
    setSquattingcheck(e)
  }
  const handleKneelingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Kneelingcheck',
      "value": e
    }))
    setKneelingcheck(e)
  }
  const handleSurfacescheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Surfacescheck',
      "value": e
    }))
    setSurfacescheck(e)
  }
  const handleClimbingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Climbingcheck',
      "value": e
    }))
    setClimbingcheck(e)
  }
  const handleSteppingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Steppingcheck',
      "value": e
    }))
    setSteppingcheck(e)
  }
  const handleCrossingcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'Crossingcheck',
      "value": e
    }))
    setCrossingcheck(e)
  }

  const  handlewalkingEvencheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingEvencheck',
      "value": e
    }))
    setwalkingEvencheck(e)
  }
  const handleIntoCarcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'IntoCarcheck',
      "value": e
    }))
    setIntoCarcheck(e)
  }
  const handleheavycheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'heavycheck',
      "value": e
    }))
    setheavycheck(e)
  }
  const handleturningcheck = (e) => {
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'turningcheck',
      "value": e
    }))
    setturningcheck(e)
  }

  const continueRedirect = (e) => {
    dispatch(updateAdultKneeNorwichInstaScore(
      [{
        twistingR,
        twistingL,
        changeDirectionR,
        changeDirectionL,
        runningStraightlineR,
        runningStraightlineL,
        walkingSlipperyR,
        walkingSlipperyL,
        runningSidewaysR,
        runningSidewaysL,
        hoppingR,
        hoppingL,
        jumpingR,
        jumpingL,
        runningOnEvenSurfaceR,
        runningOnEvenSurfaceL,
        goingDownStairsR,
        goingDownStairsL,
        squattingR,
        squattingL,
        kneelingR,
        kneelingL,
        walkingUnevenSurfacesR,
        walkingUnevenSurfacesL,
        climbingStairsR,
        climbingStairsL,
        steppingOverHighR,
        steppingOverHighL,
        crossingWhenSittingR,
        crossingWhenSittingL,
        walkingEvenSurfaceR,
        walkingEvenSurfaceL,
        gettingIntoCarR,
        gettingIntoCarL,
        supermarketAisleR,
        supermarketAisleL,
        turningYourShoulderR,
        turningYourShoulderL,
        twcheck,
        directioncheck,
        straightcheck,
        slipperycheck,
        sidewayscheck,
        Hoppingcheck,
        Jumpingcheck,
        scaleLabelcheck,
        downcheck,
        Squattingcheck,
        Kneelingcheck,
        Surfacescheck,
        Climbingcheck,
        Steppingcheck,
        Crossingcheck,
        walkingEvencheck,
        IntoCarcheck,
        heavycheck,
        turningcheck
      }]
    ));
    navigate("/adult-knee-quality-of-life/" + rId);
  };
  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e))
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  useEffect(() => {
    if (twcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'twistingL',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'twistingR',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'twistingL',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'twistingR',
        "value": "0"
      }))
    }
  }, [twcheck])

  useEffect(() => {
    if (directioncheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'changeDirectionR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'changeDirectionL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'changeDirectionR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'changeDirectionL',
        "value": "0"
      }))
    }


  }, [directioncheck])
  useEffect(() => {
    if (straightcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningStraightlineR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningStraightlineL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningStraightlineR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningStraightlineL',
        "value": "0"
      }))

    }
  }, [straightcheck])
  useEffect(() => {
    if (slipperycheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingSlipperyR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingSlipperyL',
        "value": ""
      }))

    }else
    { dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingSlipperyR',
      "value": "0"
    }))
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'walkingSlipperyL',
      "value": "0"
    }))

    }
  }, [slipperycheck])
  useEffect(() => {
    if (sidewayscheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningSidewaysR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningSidewaysL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningSidewaysR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningSidewaysL',
        "value": "0"
      }))
    }
  }, [sidewayscheck])
  useEffect(() => {
    if (Hoppingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'hoppingR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'hoppingL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'hoppingR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'hoppingL',
        "value": "0"
      }))
    }
  }, [Hoppingcheck])
  useEffect(() => {
    if (Jumpingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'jumpingR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'jumpingL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'jumpingR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'jumpingL',
        "value": "0"
      }))
    }
  }, [Jumpingcheck])
  useEffect(() => {
    if (scaleLabelcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningOnEvenSurfaceR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningOnEvenSurfaceL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningOnEvenSurfaceR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'runningOnEvenSurfaceL',
        "value": "0"
      }))
    }
  }, [scaleLabelcheck])
  useEffect(() => {
    if (downcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'goingDownStairsR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'goingDownStairsL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'goingDownStairsR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'goingDownStairsL',
        "value": "0"
      }))
    }
  }, [downcheck])
  useEffect(() => {
    if (Squattingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'squattingR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'squattingL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'squattingR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'squattingL',
        "value": "0"
      }))
    }
  }, [Squattingcheck])
  useEffect(() => {
    if (Kneelingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'kneelingR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'kneelingL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'kneelingR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'kneelingL',
        "value": "0"
      }))
    }
  }, [Kneelingcheck])
  useEffect(() => {
    if (Surfacescheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingUnevenSurfacesR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingUnevenSurfacesL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingUnevenSurfacesR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingUnevenSurfacesL',
        "value": "0"
      }))
    }
  }, [Surfacescheck])
  useEffect(() => {
    if (Climbingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'climbingStairsR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'climbingStairsL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'climbingStairsR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'climbingStairsL',
        "value": "0"
      }))
    }
  }, [Climbingcheck])
  useEffect(() => {
    if (Steppingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'steppingOverHighR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'steppingOverHighL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'steppingOverHighR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'steppingOverHighL',
        "value": "0"
      }))

    }
  }, [Steppingcheck])
  useEffect(() => {
    if (Crossingcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'crossingWhenSittingR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'crossingWhenSittingL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'crossingWhenSittingR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'crossingWhenSittingL',
        "value": "0"
      }))

    }
  }, [Crossingcheck])
  useEffect(() => {
    if (walkingEvencheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingEvenSurfaceR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingEvenSurfaceL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingEvenSurfaceR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'walkingEvenSurfaceL',
        "value": "0"
      }))

    }
  }, [walkingEvencheck])
  useEffect(() => {
    if (IntoCarcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'gettingIntoCarR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'gettingIntoCarL',
        "value": ""
      }))

    }else
    {dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'gettingIntoCarR',
      "value": "0"
    }))
    dispatch(updateAdultKneeNorwichInstaFieldScore({
      "key": 'gettingIntoCarL',
      "value": "0"
    }))

    }
  }, [IntoCarcheck])
  useEffect(() => {
    if (heavycheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'supermarketAisleR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'supermarketAisleL',
        "value": ""
      }))

    }else
    {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'supermarketAisleR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'supermarketAisleL',
        "value": "0"
      }))

    }
  }, [heavycheck])
  useEffect(() => {
    if (turningcheck) {
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'turningYourShoulderR',
        "value": ""
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'turningYourShoulderL',
        "value": ""
      }))

    }else{
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'turningYourShoulderR',
        "value": "0"
      }))
      dispatch(updateAdultKneeNorwichInstaFieldScore({
        "key": 'turningYourShoulderL',
        "value": "0"
      }))
    }
  }, [turningcheck])

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
             <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
           
              <h4 className="ph_title f_40">{translation.patient_adult_knee_patellofemoral_instability}</h4>
              <div className="row">
                {/* <div className="mb_20">
                <FullDropdown
                  data={[
                    {
                      value: "1",
                      label: "Right",
                    },
                    {
                      value: "2",
                      label: "Left",
                    },
                    {
                      value: "3",
                      label: "Both",
                    },
                  ]}
                  label="Evaluated Pedia"
                  styleClass=""
                  value={evlPediaPain}
                  onChange={handleEvolutedPediaPain}
                />
              </div> */}
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_adult_knee_patellofemoral_instability_instruction}
                    </label> <br />
                    <span dir={lg === "ara" ? "rtl" :" "}>
                      (<strong>0</strong> = {translation.patient_adult_knee_patellofemoral_instability_instruction_0}, <strong>1</strong> = {translation.patient_adult_knee_patellofemoral_instability_instruction_1}, <strong>2</strong> = {translation.patient_adult_knee_patellofemoral_instability_instruction_2}, <strong>3</strong> = {translation.patient_adult_knee_patellofemoral_instability_instruction_3}, <strong>4</strong> = {translation.patient_adult_knee_patellofemoral_instability_instruction_4})
                    </span>
                  </div>
                
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q1_do_not_do}
                      value={twcheck}
                      selected={twcheck}
                      onChange={handletwcheck}
                    />
                  </div>
                  
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                          {/* <strong className="d-inline-block mb_15">Right Knee:</strong> */}
                          <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q1 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={twistingR}
                            onChange={handleTwistingR}
                            view={twcheck}
                           // onClick={handleTwistingR}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q1_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q1_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q1_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q1_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q1_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                          {/* <strong className="d-inline-block mb_15">Left Knee:</strong> */}
                          <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q1 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={twistingL}
                            onChange={handleTwistingL}
                            view={twcheck}
                           // onClick={handleTwistingL}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q1_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q1_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q1_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q1_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q1_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   

                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
                   
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q2_do_not_do}
                      value={directioncheck}
                      selected={directioncheck}
                      onChange={handledirectioncheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q2 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={changeDirectionR}
                            onChange={handleChangeDirectionR}
                            view={directioncheck}
                           // onClick={handleChangeDirectionR}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q2_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q2_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q2_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q2_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q2_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}

                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q2 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={changeDirectionL}
                            onChange={handleChangeDirectionL}
                            view={directioncheck}
                           // onClick={handleChangeDirectionL}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q2_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q2_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q2_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q2_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q2_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                 
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
             
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q3_do_not_do}
                      selected={straightcheck}
                      value={straightcheck}
                      onChange={handlestraightcheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q3 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={runningStraightlineR}
                            onChange={handleRunningStraightlineR}
                            view={straightcheck}
                           // onClick={handleRunningStraightlineR}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q3_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q3_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q3_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q3_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q3_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q3 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={runningStraightlineL}
                            onChange={handleRunningStraightlineL}
                           // onClick={handleRunningStraightlineL}
                           view={straightcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q3_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q3_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q3_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q3_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q3_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
             
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q4_do_not_do}
                      selected={slipperycheck}
                      value={slipperycheck}
                      onChange={handleslipperycheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q4 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={walkingSlipperyR}
                            onChange={handleWalkingSlipperyR}
                           // onClick={handleWalkingSlipperyR}
                           view={slipperycheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q4_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q4_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q4_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q4_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q4_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q4 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={walkingSlipperyL}
                            onChange={handleWalkingSlipperyL}
                           // onClick={handleWalkingSlipperyL}
                           view={slipperycheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q4_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q4_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q4_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q4_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q4_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
           
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q5_do_not_do}
                      selected={sidewayscheck}
                      value={sidewayscheck}
                      onChange={handlesidewayscheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q5 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={runningSidewaysR}
                            onChange={handleRunningSidewaysR}
                           // onClick={handleRunningSidewaysR}
                           view={sidewayscheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q5_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q5_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q5_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q5_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q5_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q5 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={runningSidewaysL}
                            onChange={handleRunningSidewaysL}
                           // onClick={handleRunningSidewaysL}
                           view={sidewayscheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q5_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q5_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q5_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q5_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q5_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q6_do_not_do}
                      selected={Hoppingcheck}
                      value={Hoppingcheck}
                      onChange={handleHoppingcheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q6 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={hoppingR}
                            onChange={handleHoppingR}
                           // onClick={handleHoppingR}
                           view={Hoppingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q6_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q6_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q6_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q6_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q6_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q6 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={hoppingL}
                            onChange={handleHoppingL}
                           // onClick={handleHoppingL}
                           view={Hoppingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q6_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q6_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q6_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q6_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q6_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
              
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q7_do_not_do}
                      selected={Jumpingcheck}
                      value={Jumpingcheck}
                      onChange={handleJumpingcheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q7 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            // sliderData={scaleLabels}
                            value={jumpingR}
                            onChange={handleJumpingR}
                           // onClick={handleJumpingR}
                           view={Jumpingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q7_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q7_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q7_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q7_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q7_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q7 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={jumpingL}
                            onChange={handleJumpingL}
                           // onClick={handleJumpingL}
                           view={Jumpingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q7_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q7_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q7_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q7_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q7_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
     
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q8_do_not_do}
                      selected={scaleLabelcheck}
                      value={scaleLabelcheck}
                      onChange={handlescaleLabelcheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q8 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                  
                            value={runningOnEvenSurfaceR}
                            onChange={handleRunningOnEvenSurfaceR}
                           // onClick={handleRunningOnEvenSurfaceR}
                           view={scaleLabelcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q8_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q8_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q8_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q8_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q8_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q8 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={runningOnEvenSurfaceL}
                            onChange={handleRunningOnEvenSurfaceL}
                           // onClick={handleRunningOnEvenSurfaceL}
                           view={scaleLabelcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q8_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q8_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q8_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q8_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q8_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
           
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q9_do_not_do}
                      selected={downcheck}
                      value={downcheck}
                      onChange={handledowncheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q9 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                           
                            value={goingDownStairsR}
                            onChange={handleGoingDownStairsR}
                           // onClick={handleGoingDownStairsR}
                           view={downcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q9_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q9_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q9_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q9_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q9_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}

                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q9 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                           
                            value={goingDownStairsL}
                            onChange={handleGoingDownStairsL}
                           // onClick={handleGoingDownStairsL}
                           view={downcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q9_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q9_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q9_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q9_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q9_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
        
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q10_do_not_do}
                      selected={Squattingcheck}
                      value={Squattingcheck}
                      onChange={handleSquattingcheck}
                    />
                  </div>
               
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q10 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={squattingR}
                            onChange={handleSquattingR}
                           // onClick={handleSquattingR}
                           view={Squattingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q10_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q10_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q10_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q10_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q10_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q10 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                           
                            value={squattingL}
                            onChange={handleSquattingL}
                           // onClick={handleSquattingL}
                           view={Squattingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q10_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q10_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q10_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q10_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q10_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
      
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q11_do_not_do}
                      selected={Kneelingcheck}
                      value={Kneelingcheck}
                      onChange={handleKneelingcheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q11 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={kneelingR}
                            onChange={handleKneelingR}
                           // onClick={handleKneelingR}
                           view={Kneelingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q11_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q11_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q11_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q11_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q11_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q11 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                           
                            value={kneelingL}
                            onChange={handleKneelingL}
                           // onClick={handleKneelingL}
                           view={Kneelingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q11_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q11_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q11_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q11_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q11_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
    
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q12_do_not_do}
                      selected={Surfacescheck}
                      value={Surfacescheck}
                      onChange={handleSurfacescheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q12 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                          
                            value={walkingUnevenSurfacesR}
                            onChange={handleWalkingUnevenSurfacesR}
                           // onClick={handleWalkingUnevenSurfacesR}
                           view={Surfacescheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q12_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q12_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q12_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q12_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q12_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q12 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={walkingUnevenSurfacesL}
                            onChange={handleWalkingUnevenSurfacesL}
                           // onClick={handleWalkingUnevenSurfacesL}
                           view={Surfacescheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q12_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q12_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q12_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q12_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q12_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
            
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q13_do_not_do}
                      selected={Climbingcheck}
                      value={Climbingcheck}
                      onChange={handleClimbingcheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q13 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={climbingStairsR}
                            onChange={handleClimbingStairsR}
                           // onClick={handleClimbingStairsR}
                           view={Climbingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q13_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q13_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q13_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q13_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q13_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q13 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={climbingStairsL}
                            onChange={handleClimbingStairsL}
                           // onClick={handleClimbingStairsL}
                           view={Climbingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q13_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q13_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q13_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q13_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q13_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
              
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q14_do_not_do}
                      selected={Steppingcheck}
                      value={Steppingcheck}
                      onChange={handleSteppingcheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q14 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={steppingOverHighR}
                            onChange={handleSteppingOverHighR}
                           // onClick={handleSteppingOverHighR}
                           view={Steppingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q14_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q14_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q14_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q14_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q14_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q14 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={steppingOverHighL}
                            onChange={handleSteppingOverHighL}
                           // onClick={handleSteppingOverHighL}
                           view={Steppingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q14_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q14_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q14_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q14_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q14_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                 
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">

                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q15_do_not_do}
                      selected={Crossingcheck}
                      value={Crossingcheck}
                      onChange={handleCrossingcheck}
                    />
                  </div>
               
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q15 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={crossingWhenSittingR}
                            onChange={handleCrossingWhenSittingR}
                           // onClick={handleCrossingWhenSittingR}
                           view={Crossingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q15_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q15_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q15_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q15_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q15_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q15 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={crossingWhenSittingL}
                            onChange={handleCrossingWhenSittingL}
                           // onClick={handleCrossingWhenSittingL}
                           view={Crossingcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q15_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q15_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q15_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q15_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q15_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
                
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q16_do_not_do}
                      selected={walkingEvencheck}
                      value={walkingEvencheck}
                      onChange={handlewalkingEvencheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q16 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={walkingEvenSurfaceR}
                            onChange={handleWalkingEvenSurfaceR}
                           // onClick={handleWalkingEvenSurfaceR}
                           view={walkingEvencheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q16_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q16_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q16_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q16_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q16_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q16 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={walkingEvenSurfaceL}
                            onChange={handleWalkingEvenSurfaceL}
                           // onClick={handleWalkingEvenSurfaceL}
                           view={walkingEvencheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q16_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q16_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q16_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q16_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q16_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
        
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q17_do_not_do}
                      selected={IntoCarcheck}
                      value={IntoCarcheck}
                      onChange={handleIntoCarcheck}
                    />
                  </div>
                 
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q17 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={gettingIntoCarR}
                            onChange={handleGettingIntoCarR}
                           // onClick={handleGettingIntoCarR}
                           view={IntoCarcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q17_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q17_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q17_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q17_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q17_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q17 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={gettingIntoCarL}
                            onChange={handleGettingIntoCarL}
                           // onClick={handleGettingIntoCarL}
                           view={IntoCarcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q17_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q17_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q17_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q17_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q17_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                  
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
     
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q18_do_not_do}
                      value={heavycheck}
                      selected={heavycheck}
                      onChange={handleheavycheck}
                    />
                  </div>
               
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q18 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                           
                            value={supermarketAisleR}
                            onChange={handleSupermarketAisleR}
                           // onClick={handleSupermarketAisleR}
                           view={heavycheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q18_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q18_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q18_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q18_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q18_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q18 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={supermarketAisleL}
                            onChange={handleSupermarketAisleL}
                           // onClick={handleSupermarketAisleL}
                           view={heavycheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q18_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q18_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q18_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q18_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q18_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
                <div className="row position-relative">
                  <div className="not_applyPedia">
       
                    <Checkbox
                      label={translation.patient_adult_knee_patellofemoral_instability_q19_do_not_do}
                      value={turningcheck}
                      selected={turningcheck}
                      onChange={handleturningcheck}
                    />
                  </div>
                
                      {evlPediaPain === "1" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q19 + RigthTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={turningYourShoulderR}
                            onChange={handleTurningYourShoulderR}
                           // onClick={handleTurningYourShoulderR}
                           view={turningcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q19_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q19_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q19_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q19_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q19_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                      {evlPediaPain === "2" || evlPediaPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                         <HipScoreRangeSlider
                            label={translation.patient_adult_knee_patellofemoral_instability_q19 + LeftTrans(evlPediaPain, translation)}
                            classes=""
                            
                            value={turningYourShoulderL}
                            onChange={handleTurningYourShoulderL}
                           // onClick={handleTurningYourShoulderL}
                           view={turningcheck}
                           sliderData={[
                            { score: "0", label: translation.patient_adult_knee_patellofemoral_instability_q19_a1 },
                            { score: "1", label: translation.patient_adult_knee_patellofemoral_instability_q19_a2 },
                            { score: "2", label: translation.patient_adult_knee_patellofemoral_instability_q19_a3 },
                            { score: "3", label: translation.patient_adult_knee_patellofemoral_instability_q19_a4 },
                            { score: "4", label: translation.patient_adult_knee_patellofemoral_instability_q19_a5 },
                          ]}
                          />
                        </div>
                      ) : ""}
                   
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                     onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {
                       pattelar==="1"? 
                       <Link to={"/adult-knee-function/" + rId} className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link>: <Link to={"/adult-knee-function/" + rId} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                    }
                   
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                     onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NorwichPatellarInstability;

import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../customHooks/translations";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import { useSearchParams } from "react-router-dom";
import {
  updateFootInstabilityFieldScore,
  updateFootInstabilityData,
  updateFootAnklePainData,
  updateFootSymptomsData,
  updateFootAnkle,
  updateFootQolData,
  updateFootFunctionData,
  updateFootSatisfactionData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
  updateAllData,
} from "../../../../redux/actions/footAnkle";
import useAxios from "../../../../axiosinstance";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function FootAnkleInstability() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  let doctordata =localStorage.getItem("isdoctorForm");
  let viewicon=localStorage.getItem("viewicon")
  const [isLoading, setIsLoading] = useState(true);
  const [ankleFeelL, setAnkleFeelL] = useState("");
  const [ankleFeelR, setAnkleFeelR] = useState("");
  const [ankleDiagnosedL, setAnkleDiagnosedL] = useState("");
  const [ankleDiagnosedR, setAnkleDiagnosedR] = useState("");
  //   const [doesUneffectedL, setDoesUneffectedL] = useState("");
  const [doesUneffectedR, setDoesUneffectedR] = useState("0");
  const [updateTotal, setUpdateTotal] = useState(false);
  // const [anklePain, setAnklePAin] = useState("1");

  const [chk, setChk] = useState(false);
  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const FootInsta = useSelector((state) => state.footAnkle.instabilityScore[0]);
  const json = useSelector((state) => state.footAnkle);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setAnkleFeelL(FootInsta.ankleFeelL);
    setAnkleFeelR(FootInsta.ankleFeelR);
    setAnkleDiagnosedL(FootInsta.ankleDiagnosedL);
    setAnkleDiagnosedR(FootInsta.ankleDiagnosedR);
    // setDoesUneffectedL(FootInsta.doesUneffectedL);
    setDoesUneffectedR(FootInsta.doesUneffectedR);
  }, [FootInsta]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [FootInsta, updateTotal]);

  const navigate = useNavigate();
  const continueRedirect = (e) => {
    dispatch(
      updateFootInstabilityData([
        {
          ankleFeelL: ankleFeelL,
          ankleFeelR: ankleFeelR,
          ankleDiagnosedL: ankleDiagnosedL,
          ankleDiagnosedR: ankleDiagnosedR,
          //   doesUneffectedL: doesUneffectedL,
          doesUneffectedR: doesUneffectedR,
        },
      ])
    );
    if (
      ankleDiagnosedR === "1" ||
      ankleDiagnosedL === "1" ||
      ankleFeelL === "1" ||
      ankleFeelR === "1"
    ) {
      navigate("/cumberland-ankle/" + rId);
    } else {
      navigate("/foot-ankle-other-symptoms/" + rId);
    }
  };
  const handleAnkleFeelL = (e) => {
    setAnkleFeelL(e);
    dispatch(
      updateFootInstabilityFieldScore({
        key: "ankleFeelL",
        value: e,
      })
    );
  };
  const handleAnkleFeelR = (e) => {
    setAnkleFeelR(e);
    dispatch(
      updateFootInstabilityFieldScore({
        key: "ankleFeelR",
        value: e,
      })
    );
  };
  const handleAnkleDiagnosedL = (e) => {
    setAnkleDiagnosedL(e);
    dispatch(
      updateFootInstabilityFieldScore({
        key: "ankleDiagnosedL",
        value: e,
      })
    );
  };
  const handleAnkleDiagnosedR = (e) => {
    setAnkleDiagnosedR(e);
    dispatch(
      updateFootInstabilityFieldScore({
        key: "ankleDiagnosedR",
        value: e,
      })
    );
  };

  //   const handleDoesUneffectedL = (e) => {
  //     setDoesUneffectedL(e);
  //     dispatch(
  //       updateFootInstabilityFieldScore({
  //         key: "doesUneffectedL",
  //         value: e,
  //       })
  //     );
  //   };
  const handleDoesUneffectedR = (e) => {
    setDoesUneffectedR(e);
    dispatch(
      updateFootInstabilityFieldScore({
        key: "doesUneffectedR",
        value: e,
      })
    );
  };
  // const handleAnklePain = (e) => {
  //     setAnklePAin(e);
  // }

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
             
              <h4 className="ph_title f_40">
                {translation.patient_foot_ankle_instability}
              </h4>
              <div className="row">
                {anklePain === "1" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6"+RightSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_foot_ankle_instability_q1_a1}
                        labelRight={translation.patient_foot_ankle_instability_q1_a2}
                        idLeft="ankleFeelRNo"
                        idRight="ankleFeelRYes"
                        name="ankleFeelR"
                        title={
                          translation.patient_foot_ankle_instability_q1+RigthTrans("3", translation)
                        }
                        styleClass=""
                        value={ankleFeelR}
                        onChange={handleAnkleFeelR}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6"+LeftSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_foot_ankle_instability_q1_a1}
                        labelRight={translation.patient_foot_ankle_instability_q1_a2}
                        idLeft="ankleFeelLNo"
                        idRight="ankleFeelLYes"
                        name="ankleFeelL"
                        title={
                          translation.patient_foot_ankle_instability_q1+LeftTrans("3", translation)
                        }
                        styleClass=""
                        value={ankleFeelL}
                        onChange={handleAnkleFeelL}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {anklePain === "1" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6"+RightSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_foot_ankle_instability_q2_a1}
                        labelRight={translation.patient_foot_ankle_instability_q2_a2}
                        idLeft="ankleDiagnosedRNo"
                        idRight="ankleDiagnosedRYes"
                        name="ankleDiagnosedR"
                        title={
                          translation.patient_foot_ankle_instability_q2+RigthTrans("3", translation)
                        }
                        styleClass=""
                        value={ankleDiagnosedR}
                        onChange={handleAnkleDiagnosedR}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {anklePain === "2" || anklePain === "3" ? (
                  <div className={"col-md-6 col-sm-6"+LeftSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_foot_ankle_instability_q2_a1}
                        labelRight={translation.patient_foot_ankle_instability_q2_a2}
                        idLeft="ankleDiagnosedLNo"
                        idRight="ankleDiagnosedLYes"
                        name="ankleDiagnosedL"
                        title={
                          translation.patient_foot_ankle_instability_q2+LeftTrans("3", translation)
                        }
                        styleClass=""
                        value={ankleDiagnosedL}
                        onChange={handleAnkleDiagnosedL}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {anklePain !== "3" && (
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_foot_ankle_instability_q3_a1}
                        labelRight={translation.patient_foot_ankle_instability_q3_a2}
                        idLeft="doesUneffectedRNo"
                        idRight="doesUneffectedRYes"
                        name="doesUneffectedR"
                        title={`${translation.patient_foot_ankle_instability_q3}  ${anklePain=== "1"? LeftTrans("3", translation) :RigthTrans("3", translation)} ` }
                        styleClass=""
                        value={doesUneffectedR}
                        onChange={handleDoesUneffectedR}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                </div>
              )}

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={`/foot-ankle-pain/${rId}?is_doctor_form=${doctordata}&edit=${viewicon}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FootAnkleInstability;

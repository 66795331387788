import React, { useState, useEffect } from 'react'
import InputField from '../../../custom/inputfield/InputField';
import { Link, useNavigate, useParams,useSearchParams } from "react-router-dom";
import useTranslation from "../../../customHooks/doctorTranslation"
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintScore,
  updatefeedbackFieldsScore,
  updatefeedbackComplaintScore,
  updatePastComplaintScore
} from "../../../../redux/actions/GeneralAction";
import useAxios from "../../../../axiosinstance";
import { useDispatch, useSelector } from 'react-redux'
import DoctorNameCustom from '../../../custom/name/DoctorNameCustom';
import SubmitModel from "../../SubmissionModel/SubmitModel"
import Promsschudle from '../../SubmissionModel/Promsschudle';
export default function Doctorefeedback() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const translation = useTranslation()
  const axiosinstance = useAxios();
  const queryParams = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
   useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  const [feedback, setFeedback] = useState("");
  const docFeedback1 = useSelector((state) => state.GeneralFormReduces.doctorFeedback);
  const { rId } = useParams();
  const dId = queryParams.get("doctor");
  const [chk, setChk] = useState(false);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=33`)
      .then((res) => {
        dispatch(updateHistoryComplaintScore(res.data.data2[0].History));
        dispatch(updateSocialComplaintScore(res.data.data2[0].Social));
        dispatch(updatePastComplaintScore(res.data.data2[0].Past));
        dispatch(updatefeedbackComplaintScore(res.data.data2[0].doctorFeedback))
        setChk(true);
        // setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(()=>{
    setFeedback(docFeedback1[0].feedback)
  },[docFeedback1])
  const handleFeedback = (e) => {
    setFeedback(e)
    dispatch(updatefeedbackFieldsScore({
      "key": 'feedback',
      "value": e
    }))
  }
  const updateDate = async () => {
    const response =await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      
      console.log(res);
    });
  }
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);
  const continueRedirect = async () => {
    const response =await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    if(response.status===200){
      setDisplayConfirmationModal(true)
      
      // window.open(
      //   process.env.REACT_APP_DOCTOR_URL + 'patient-list',
      //   "_blank"
      // );
      // window.close()
      // window.location.href=process.env.REACT_APP_DOCTOR_URL + 'patient-list'
     }
  }
  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    
    }
    }
  },[docFeedback1])

console.log(displayConfirmationModal,"displayConfirmationModal")
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');

 

  const handleSubmit = () => {
    updateDate()
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  return (
    <>
    <div>
      <DoctorNameCustom/>
    </div>
    <div>
      <InputField
        label="Feedback"
        value={feedback}
        type="textarea"
        placeholder="Description"
        rows="4"
        cols="20"
        onChange={handleFeedback}
        styleClass="h_150" />
            {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button
                className="next_btn btn_fill"
                onClick={() => handleSubmit()}
              >
                Submit
                <span>
                  <img src={nextIcon} alt="" />
                  {/* <img src={nextWhiteIcon} alt="" /> */}
                </span>
              </button>
            </div>
          </div>
        )}
        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              {/* <Link to="/" className="assm_back_btn">
            <img src={backIcon} alt="" />
            <span>Back</span>
          </Link> */}
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button className="next_btn btn_fill" onClick={continueRedirect}>
                {translation.submit}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
    </div>
    {/* <SubmitModel
     showModal={displayConfirmationModal}
     /> */}
      <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"33"}
     patientuserID={pId}
     />
    </>
  )
}

import React from "react";
import "../../../css/ViewTreatmentForm.css";
import Button from "../../custom/button/Button";
import FrontSvg from "../shoulder-treatment-form/FrontSvg";
import BackSvg from "./BackSvg";
import useTranslation from "../../customHooks/translations";
function ShoulderScoring({ shoulderPain, AllData, functionData }) {
  const translation = useTranslation();
  console.log("AllData", shoulderPain);
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translation.Shoulder_Scoring_Self_Evaluation_Questionnaire}</h2>
          <div className="treatment-header-right">
            <p>
              {translation.Assessment_Date} : {AllData.assessment_date}
            </p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header">
            1. {translation.patient_shoulder_pain}{" "}
          </div>

          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translation.patient_shoulder_pain_q1} :</td>
                    <td>
                      {shoulderPain.painshoulder_right === "0"
                        ? translation.no
                        : shoulderPain.painshoulder_right === "1"
                        ? translation.yes
                        : ""}
                    </td>
                    <td>
                      {shoulderPain.painshoulder_left === "0"
                        ? translation.no
                        : shoulderPain.painshoulder_left === "1"
                        ? translation.yes
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translation.patient_shoulder_pain_q2}:</td>
                    <td>{shoulderPain.describe_pain_right}</td>
                    <td>{shoulderPain.describe_pain_left}</td>
                  </tr>
                  {/* <tr>
                    <td>How bad is your pain today:</td>
                    <td>{shoulderPain.pain_today_rating_right}</td>
                    <td>{shoulderPain.pain_today_rating_left}</td>
                  </tr> */}
                  <tr>
                    <td>{translation.patient_shoulder_pain_q3}:</td>
                    <td>
                      {shoulderPain.shoulder_pain_night_right === "0"
                        ? translation.patient_shoulder_pain_q3_a1
                        : shoulderPain.shoulder_pain_night_right === "1"
                        ? translation.patient_shoulder_pain_q3_a2
                        : shoulderPain.shoulder_pain_night_right === "2"
                        ? translation.patient_shoulder_pain_q3_a3
                        : ""}
                    </td>
                    <td>
                      {shoulderPain.shoulder_pain_night_left === "0"
                        ? translation.patient_shoulder_pain_q3_a1
                        : shoulderPain.shoulder_pain_night_left === "1"
                        ? translation.patient_shoulder_pain_q3_a2
                        : shoulderPain.shoulder_pain_night_left === "2"
                        ? translation.patient_shoulder_pain_q3_a3
                        : ""}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      Which of the following statement best describes the pain:
                    </td>
                    <td>{shoulderPain.best_statment_pain_right === "5" ? "Always present, unbearable, strong medication required frequently" : shoulderPain.best_statment_pain_right === "4" ? "Always present, bearable, strong medication required occasionally" : shoulderPain.best_statment_pain_right === "3" ? "Present during light activity, little or none at rest, asprin frequently" : shoulderPain.best_statment_pain_right === "2" ? "Present during heavy or particular activity, aspirin occasionally" : shoulderPain.best_statment_pain_right === "1" ? "Occasional and slight" : shoulderPain.best_statment_pain_right === "0" ? "None" : ""} </td>
                    <td>{shoulderPain.best_statment_pain_left === "5" ? "Always present, unbearable, strong medication required frequently" : shoulderPain.best_statment_pain_left === "4" ? "Always present, bearable, strong medication required occasionally" : shoulderPain.best_statment_pain_left === "3" ? "Present during light activity, little or none at rest, asprin frequently" : shoulderPain.best_statment_pain_left === "2" ? "Present during heavy or particular activity, aspirin occasionally" : shoulderPain.best_statment_pain_left === "1" ? "Occasional and slight" : shoulderPain.best_statment_pain_left === "0" ? "None" : ""} </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      Do you get pins and needles or tingling down your arm :
                    </td>
                    <td>{shoulderPain.pin_tingling_right === "0" ? "No" : shoulderPain.pin_tingling_right === "1" ? "Yes" : ""}</td>
                    <td>{shoulderPain.Pin_tingling_left === "0" ? "No" : shoulderPain.Pin_tingling_left === "1" ? "Yes" : ""}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row view__pain__diagram">
            <div className="treatment-col">
              <label>{translation.patient_shoulder_pain_q9}:</label>
              <div className="pain-box">
                <div className="mark-row">
                  <div className="mark-col">
                    <FrontSvg front={shoulderPain.frontbody} />
                    <p>{translation.patient_shoulder_pain_q9_a1}</p>
                  </div>
                  <div className="mark-col">
                    <BackSvg backbody={shoulderPain.backbody} />
                    <p>{translation.patient_shoulder_pain_q9_a2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  <th>{translation.Right_Arm}</th>
                  <th>{translation.Left_Arm}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translation.patient_shoulder_pain_q4}:</td>
                  <td>{shoulderPain.pain_today_rating_right}</td>
                  <td>{shoulderPain.pain_today_rating_left}</td>
                </tr>

                {/* <tr>
                    <td>
                      Which of the following statement best describes the pain:
                    </td>
                    <td>{shoulderPain.best_statment_pain_right === "5" ? "Always present, unbearable, strong medication required frequently" : shoulderPain.best_statment_pain_right === "4" ? "Always present, bearable, strong medication required occasionally" : shoulderPain.best_statment_pain_right === "3" ? "Present during light activity, little or none at rest, asprin frequently" : shoulderPain.best_statment_pain_right === "2" ? "Present during heavy or particular activity, aspirin occasionally" : shoulderPain.best_statment_pain_right === "1" ? "Occasional and slight" : shoulderPain.best_statment_pain_right === "0" ? "None" : ""} </td>
                    <td>{shoulderPain.best_statment_pain_left === "5" ? "Always present, unbearable, strong medication required frequently" : shoulderPain.best_statment_pain_left === "4" ? "Always present, bearable, strong medication required occasionally" : shoulderPain.best_statment_pain_left === "3" ? "Present during light activity, little or none at rest, asprin frequently" : shoulderPain.best_statment_pain_left === "2" ? "Present during heavy or particular activity, aspirin occasionally" : shoulderPain.best_statment_pain_left === "1" ? "Occasional and slight" : shoulderPain.best_statment_pain_left === "0" ? "None" : ""} </td>
                  </tr> */}
                {/* <tr>
                    <td>
                      Do you get pins and needles or tingling down your arm :
                    </td>
                    <td>{shoulderPain.pin_tingling_right === "0" ? "No" : shoulderPain.pin_tingling_right === "1" ? "Yes" : ""}</td>
                    <td>{shoulderPain.Pin_tingling_left === "0" ? "No" : shoulderPain.Pin_tingling_left === "1" ? "Yes" : ""}</td>
                  </tr> */}
              </tbody>
            </table>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translation.patient_shoulder_pain_q5}:</label>
              {shoulderPain.smoker === "1" ? (
                <div className="treatment-col">
                  <p>{translation.patient_shoulder_pain_q5_a1}</p>
                </div>
              ) : shoulderPain.smoker === "0" ? (
                <div className="treatment-col">
                  <p>{translation.patient_shoulder_pain_q5_a2}</p>
                  <label>{translation.patient_shoulder_pain_q5_a2_q1}:</label>
                  {shoulderPain.cigrates_perday}
                </div>
              ) : shoulderPain.smoker === "2" ? (
                <div className="treatment-row ">
                  <div className="treatment-col">
                    <p>{translation.patient_shoulder_pain_q5_a3}</p>

                    <label>{translation.patient_shoulder_pain_q5_a3_q1}</label>
                    {shoulderPain.cigrates_month_left}

                    <label>{translation.patient_shoulder_pain_q5_a3_q2}</label>
                    {shoulderPain.smoke_years}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* <p> {shoulderPain.smoker === "1" ? "No" : shoulderPain.smoker === "0" ? "Yes" : shoulderPain.smoker === "2" ? "Ex-Smoker" : ""}</p> */}
            </div>

            <div className="treatment-col">
              {/* <label>How may cigarettes per day:</label>
              {shoulderPain.cigrates_perday} */}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translation.patient_shoulder_pain_q6} :</label>
              {shoulderPain.pain_medication === "0"
                ? translation.patient_shoulder_pain_q6_a1
                : shoulderPain.pain_medication === "1"
                ? translation.patient_shoulder_pain_q6_a2
                : ""}
            </div>
          </div>
          {shoulderPain.pain_medication === "1" && (
            <>
              <div className="treatment-col">
                <label>{translation.patient_shoulder_pain_q6_a2_q1}:</label>
                {shoulderPain.narotic_medication === "0"
                  ? translation.patient_shoulder_pain_q6_a2_q1_a1
                  : shoulderPain.narotic_medication === "1"
                  ? translation.patient_shoulder_pain_q6_a2_q1_a2
                  : ""}
              </div>
              <div className="treatment-row">
                <div className="treatment-col">
                  <label>{translation.patient_shoulder_pain_q6_a2_q2}:</label>
                  {shoulderPain.pills_average}
                </div>
              </div>
            </>
          )}

          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  <th>{translation.Right_Arm}</th>
                  <th>{translation.Left_Arm}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translation.patient_shoulder_pain_q7}:</td>
                  <td>
                    {shoulderPain.best_statment_pain_right === "5"
                      ? translation.patient_shoulder_pain_q7_a6
                      : shoulderPain.best_statment_pain_right === "4"
                      ? translation.patient_shoulder_pain_q7_a5
                      : shoulderPain.best_statment_pain_right === "3"
                      ? translation.patient_shoulder_pain_q7_a4
                      : shoulderPain.best_statment_pain_right === "2"
                      ? translation.patient_shoulder_pain_q7_a3
                      : shoulderPain.best_statment_pain_right === "1"
                      ? translation.patient_shoulder_pain_q7_a2
                      : shoulderPain.best_statment_pain_right === "0"
                      ? translation.patient_shoulder_pain_q7_a1
                      : ""}{" "}
                  </td>
                  <td>
                    {shoulderPain.best_statment_pain_left === "5"
                      ? translation.patient_shoulder_pain_q7_a6
                      : shoulderPain.best_statment_pain_left === "4"
                      ? translation.patient_shoulder_pain_q7_a5
                      : shoulderPain.best_statment_pain_left === "3"
                      ? translation.patient_shoulder_pain_q7_a4
                      : shoulderPain.best_statment_pain_left === "2"
                      ? translation.patient_shoulder_pain_q7_a3
                      : shoulderPain.best_statment_pain_left === "1"
                      ? translation.patient_shoulder_pain_q7_a2
                      : shoulderPain.best_statment_pain_left === "0"
                      ? translation.patient_shoulder_pain_q7_a1
                      : ""}{" "}
                  </td>
                </tr>
                <tr>
                  <td>{translation.patient_shoulder_pain_q8} :</td>

                  <td>
                    {shoulderPain.pin_tingling_right === "0"
                      ? translation.patient_shoulder_pain_q8_a1
                      : shoulderPain.pin_tingling_right === "1"
                      ? translation.patient_shoulder_pain_q8_a2
                      : ""}
                  </td>
                  <td>
                    {shoulderPain.Pin_tingling_left === "0"
                      ? translation.patient_shoulder_pain_q8_a1
                      : shoulderPain.Pin_tingling_left === "1"
                      ? translation.patient_shoulder_pain_q8_a2
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="treatment-row">
            <div className="treatment-col">
              <label>How long ago did you quit?</label>
              {shoulderPain.cigrates_month_left}
            </div>

            <div className="treatment-col">
              <label>How many years did you smoke?</label>
              {shoulderPain.smoke_years}
            </div>
          </div> */}
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header">
            2. {translation.patient_shoulder_results_function}
          </div>

          <div className="treatment-row">
            <div className="treatment-table bt_0">
              <table>
                <thead>
                  <tr>
                    <th></th>

                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translation.patient_shoulder_function_q1}</td>

                    <td>
                      {functionData.affected_shoulder_right === "0"
                        ? translation.patient_shoulder_function_q1_a1
                        : functionData.affected_shoulder_right === "1"
                        ? translation.patient_shoulder_function_q1_a2
                        : functionData.affected_shoulder_right === "2"
                        ? translation.patient_shoulder_function_q1_a3
                        : functionData.affected_shoulder_right === "3"
                        ? translation.patient_shoulder_function_q1_a4
                        : functionData.affected_shoulder_right === "4"
                        ? translation.patient_shoulder_function_q1_a5
                        : functionData.affected_shoulder_right === "5"
                        ? translation.patient_shoulder_function_q1_a6
                        : ""}
                    </td>
                    <td>
                      {functionData.affected_shoulder_left === "0"
                        ? translation.patient_shoulder_function_q1_a1
                        : functionData.affected_shoulder_left === "1"
                        ? translation.patient_shoulder_function_q1_a2
                        : functionData.affected_shoulder_left === "2"
                        ? translation.patient_shoulder_function_q1_a3
                        : functionData.affected_shoulder_left === "3"
                        ? translation.patient_shoulder_function_q1_a4
                        : functionData.affected_shoulder_left === "4"
                        ? translation.patient_shoulder_function_q1_a5
                        : functionData.affected_shoulder_left === "5"
                        ? translation.patient_shoulder_function_q1_a6
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>{translation.patient_shoulder_activity}</th>

                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. {translation.patient_shoulder_function_q2}</td>

                    <td>{functionData.put_shirt_right}</td>
                    <td>{functionData.put_shirt_left}</td>
                  </tr>

                  <tr>
                    <td>2. {translation.patient_shoulder_function_q3}</td>

                    <td>{functionData.affected_side_right}</td>
                    <td>{functionData.affected_side_left}</td>
                  </tr>

                  <tr>
                    <td>3. {translation.patient_shoulder_function_q4}</td>

                    <td>{functionData.wash_back_right}</td>
                    <td>{functionData.wash_back_left}</td>
                  </tr>

                  <tr>
                    <td>4. {translation.patient_shoulder_function_q5}</td>

                    <td>{functionData.manage_toilet_right}</td>
                    <td>{functionData.manage_toilet_left}</td>
                  </tr>

                  <tr>
                    <td>5. {translation.patient_shoulder_function_q6}</td>

                    <td>{functionData.comb_hair_right}</td>
                    <td>{functionData.comb_hair_left}</td>
                  </tr>

                  <tr>
                    <td>6. {translation.patient_shoulder_function_q7}</td>

                    <td>{functionData.apply_makup_right}</td>
                    <td>{functionData.apply_makup_left}</td>
                  </tr>

                  <tr>
                    <td>7. {translation.patient_shoulder_function_q8}</td>

                    <td>{functionData.brush_teeth_right}</td>
                    <td>{functionData.brush_teeth_left}</td>
                  </tr>

                  <tr>
                    <td>8. {translation.patient_shoulder_function_q9}</td>

                    <td>{functionData.full_bottle_right}</td>
                    <td>{functionData.full_bottle_left}</td>
                  </tr>

                  <tr>
                    <td>9. {translation.patient_shoulder_function_q10}</td>

                    <td>{functionData.bring_mouth_right}</td>
                    <td>{functionData.bring_mouth_left}</td>
                  </tr>

                  <tr>
                    <td>10. {translation.patient_shoulder_function_q11}</td>

                    <td>{functionData.eat_soup_right}</td>
                    <td>{functionData.eat_soup_left}</td>
                  </tr>

                  <tr>
                    <td>11. {translation.patient_shoulder_function_q12}</td>

                    <td>{functionData.shake_hand_right}</td>
                    <td>{functionData.shake_hand_left}</td>
                  </tr>

                  <tr>
                    <td>12. {translation.patient_shoulder_function_q13}</td>

                    <td>{functionData.use_phone_right}</td>
                    <td>{functionData.use_phone_left}</td>
                  </tr>

                  <tr>
                    <td>13. {translation.patient_shoulder_function_q14}</td>

                    <td>{functionData.write_letter_right}</td>
                    <td>{functionData.write_letter_left}</td>
                  </tr>

                  <tr>
                    <td>14. {translation.patient_shoulder_function_q15}</td>

                    <td>{functionData.reach_shelf_right}</td>
                    <td>{functionData.reach_shelf_left}</td>
                  </tr>

                  <tr>
                    <td>15. {translation.patient_shoulder_function_q16}</td>

                    <td>{functionData.lift05_right}</td>
                    <td>{functionData.lift05_left}</td>
                  </tr>

                  <tr>
                    <td>16. {translation.patient_shoulder_function_q17}</td>

                    <td>{functionData.lift45_right}</td>
                    <td>{functionData.lift45_left}</td>
                  </tr>

                  <tr>
                    <td>17. {translation.patient_shoulder_function_q18}</td>

                    <td>{functionData.lift10_right}</td>
                    <td>{functionData.lift10_left}</td>
                  </tr>

                  <tr>
                    <td>18. {translation.patient_shoulder_function_q19}</td>

                    <td>{functionData.throwunder_hand_right}</td>
                    <td>{functionData.throwunder_hand_left}</td>
                  </tr>

                  <tr>
                    <td>19. {translation.patient_shoulder_function_q20}</td>

                    <td>{functionData.throwover_hand_right}</td>
                    <td>{functionData.throwover_hand_left}</td>
                  </tr>

                  <tr>
                    <td>20. {translation.patient_shoulder_function_q21}</td>

                    <td>{functionData.fullusual_work_right}</td>
                    <td>{functionData.fullusual_work_left}</td>
                  </tr>

                  <tr>
                    <td>21. {translation.patient_shoulder_function_q22}</td>

                    <td>{functionData.normal_right}</td>
                    <td>{functionData.normal_left}</td>
                  </tr>
                  <tr>
                    <td>22. {translation.patient_shoulder_function_q23}</td>

                    <td>{functionData.usual_sport_right}</td>
                    <td>{functionData.usual_sport_left}</td>
                  </tr>
                  <tr>
                    <td>23. {translation.patient_shoulder_function_q24}</td>

                    <td>{functionData.recreational_right}</td>
                    <td>{functionData.recreational_left}</td>
                  </tr>

                  <tr>
                    <td>{translation.patient_shoulder_function_q25}</td>
                    <td>{functionData.usual_hobbies}</td>
                  </tr>
                  <tr>
                    <td>{translation.patient_shoulder_function_q26} </td>
                    <td>{functionData.hobbies}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translation.patient_shoulder_feeling_instability}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translation.patient_shoulder_feeling_instability_q1}:
              </label>
              {functionData.feel_unstable === "0"
                ? translation.patient_shoulder_feeling_instability_q1_a1
                : functionData.feel_unstable === "1"
                ? translation.patient_shoulder_feeling_instability_q1_a2
                : ""}
            </div>
          </div>
          <div className="treatment-row">
            {functionData.feel_unstable === "1" && (
              <>
                <div className="treatment-col">
                  <label>
                    {translation.patient_shoulder_feeling_instability_q2}:
                  </label>
                  {functionData.unstable_shoulder_range_right}
                </div>
                <div className="treatment-col">
                  <label>
                    {translation.patient_shoulder_feeling_instability_q3}:
                  </label>
                  {functionData.unstable_shoulder_range_left}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translation.patient_shoulder_SACS}{" "}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translation.patient_shoulder_ac_joint_q1}:</label>
              {functionData.instability_acjoint === "0"
                ? "No"
                : functionData.instability_acjoint === "1"
                ? "Yes"
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShoulderScoring;

import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function HipFunction({ functionData, AllData }) {
  const translations = useTranslation();
  console.log(AllData, "AllDatamiku==================================");
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translations.patient_hip_function}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <th>{translations.Hip_Function_R}</th>
                    {/* ) : (
                                            ""
                                        )} */}
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <th>{translations.Hip_Function_L}</th>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_hip_function_q1} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.desstairsR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.desstairsL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q2} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.ascstairsR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.ascstairsL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q3} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.sittingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.sittingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q4} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.risingSittingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.risingSittingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q5}:</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.floorR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.floorL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q6} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.standingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.standingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q7} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.standingMinR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.standingMinL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q8} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.spreadingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.spreadingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q9} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.strideR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.strideL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q10} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.funBendlinR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.funBendlinL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q11} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q12} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkUnevenR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkUnevenL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q13}:</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkLongR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkLongL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q14} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkExeR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkExeL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q15} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkSteepR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkSteepL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q16} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkHillsR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkHillsL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q17} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkInitR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkInitL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q18} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkAppTenR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkAppTenL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q19} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.walkFiftR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.walkFiftL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q20} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.steppingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.steppingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q21} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.publicTransR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.publicTransL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q22} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.goingShopR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.goingShopL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q23} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.puttingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.puttingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q24} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.takingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.takingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q25} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.lyingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.lyingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q26} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.rollingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.rollingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q27} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.risingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.risingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q28} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.getbathR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.getbathL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q29} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.gettoiletR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.gettoiletL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q30} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.washingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.washingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q31} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.lightR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.lightL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q32} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.heavyR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.heavyL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q33} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.lightDomesticR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.lightDomesticL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q34} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.pickingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.pickingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q35} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.squattingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.squattingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q36} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.joggingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.joggingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q37} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.runningR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.runningL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  {/* <tr>
                                        <td>
                                            Running one mile :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.runningOneR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.runningOneL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr> */}
                  <tr>
                    <td>{translations.patient_hip_function_q38} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.twistingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.twistingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  {/* <tr>
                                        <td>
                                            Jumping :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.jumpingR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.jumpingL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                    <tr>
                                        <td>
                                            Landing :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.landingR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.landingL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                    <tr>
                                        <td>
                                        Swinging objects like a golf club  :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.swingingR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.swingingL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                    <tr>
                                        <td>
                                            Starting and stopping quickly :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.startingR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.startingL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                    <tr>
                                        <td>Cutting/lateral movements :</td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.cuttingR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.cuttingL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr> */}
                  <tr>
                    <td>{translations.patient_hip_function_q39} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {functionData.sexualR === ""
                        ? translations.patient_hip_function_q39_not_relevant
                        : functionData.sexualR}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {functionData.sexualL === ""
                        ? translations.patient_hip_function_q39_not_relevant
                        : functionData.sexualL}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q40} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.sportsR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.sportsL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q41}:</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.lowR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.lowL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_function_q42} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {functionData.recreationalR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {functionData.recreationalL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  {/* <tr>
                                        <td>
                                            Low impact activities like fast walking :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.fastWalkR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.fastWalkL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                    <tr>
                                        <td>
                                            Ability to perform activity with your normal technique :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.abilityPerformR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.abilityPerformL}</td>
                                        ) : (
                                            ""
                                        )}
                                    </tr> */}
                  {/* <tr>
                                        <td>
                                            Ability to participate in your desired sport as long as you would like :
                                        </td>
                                        // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.abilityPartR}</td>
                                        ) : (
                                            ""
                                        )}
                                        // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                            <td> {functionData.abilityPartL}</td>
                                        ) : (
                                            ""
                                        )}


                                    </tr> */}
                  <tr>
                    <td>{translations.patient_hip_function_q43}:</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td>{functionData.hipPer}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td>{functionData.hipPerL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>

                  <br></br>
                  <b>{translations.patient_hip_function_q44} :</b>

                  <tr>
                    <td>{translations.patient_hip_function_q44_q2}:</td>
                    <td>
                      {functionData.highestActivityL === "0"
                        ? translations.patient_hip_function_q44_q2_a5
                        : functionData.highestActivityL === "1"
                        ? translations.patient_hip_function_q44_q2_a4
                        : functionData.highestActivityL === "2"
                        ? translations.patient_hip_function_q44_q2_a3
                        : functionData.highestActivityL === "3"
                        ? translations.patient_hip_function_q44_q2_a2
                        : functionData.highestActivityL === "4"
                        ? translations.patient_hip_function_q44_q2_a1
                        : ""}
                    </td>
                  </tr>

                  {functionData.highestActivityL === "4" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a1} :</td>
                      <td>
                        {functionData.currsports === "16"
                          ? translations.patient_hip_function_q44_q2_a1_o1
                          : functionData.currsports === "17"
                          ? translations.patient_hip_function_q44_q2_a1_o2
                          : functionData.currsports === "18"
                          ? translations.patient_hip_function_q44_q2_a1_o3
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivityL === "3" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a2} :</td>
                      <td>
                        {functionData.currsports === "19"
                          ? translations.patient_hip_function_q44_q2_a2_o1
                          : functionData.currsports === "20"
                          ? translations.patient_hip_function_q44_q2_a2_o2
                          : functionData.currsports === "21"
                          ? translations.patient_hip_function_q44_q2_a2_o3
                          : functionData.currsports === "22"
                          ? translations.patient_hip_function_q44_q2_a2_o4
                          : functionData.currsports === "23"
                          ? translations.patient_hip_function_q44_q2_a2_o5
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivityL === "2" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q1_a3} :</td>
                      <td>
                        {functionData.currsports === "24"
                          ? translations.patient_hip_function_q44_q1_a3_o1
                          : functionData.currsports === "25"
                          ? translations.patient_hip_function_q44_q1_a3_o2
                          : functionData.currsports === "26"
                          ? translations.patient_hip_function_q44_q1_a3_o3
                          : functionData.currsports === "27"
                          ? translations.patient_hip_function_q44_q1_a3_o4
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivityL === "1" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q1_a4} :</td>
                      <td>
                        {functionData.currsports === "28"
                          ? translations.patient_hip_function_q44_q1_a4_o1
                          : functionData.currsports === "29"
                          ? translations.patient_hip_function_q44_q1_a4_o2
                          : functionData.currsports === "30"
                          ? translations.patient_hip_function_q44_q1_a4_o3
                          : ""}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>{translations.patient_hip_function_q44_q1} :</td>
                    <td>
                      {functionData.highestActivity === "0"
                        ? translations.patient_hip_function_q44_q1_a5
                        : functionData.highestActivity === "1"
                        ? translations.patient_hip_function_q44_q1_a4
                        : functionData.highestActivity === "2"
                        ? translations.patient_hip_function_q44_q1_a3
                        : functionData.highestActivity === "3"
                        ? translations.patient_hip_function_q44_q1_a2
                        : functionData.highestActivity === "4"
                        ? translations.patient_hip_function_q44_q1_a1
                        : ""}
                    </td>
                  </tr>

                  {functionData.highestActivity === "4" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a1} :</td>
                      <td>
                        {functionData.sports === "1"
                          ? translations.patient_hip_function_q44_q2_a1_o1
                          : functionData.sports === "2"
                          ? translations.patient_hip_function_q44_q2_a1_o2
                          : functionData.sports === "3"
                          ? translations.patient_hip_function_q44_q2_a1_o3
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivity === "3" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a2} :</td>
                      <td>
                        {functionData.sports === "4"
                          ? translations.patient_hip_function_q44_q2_a2_o1
                          : functionData.sports === "5"
                          ? translations.patient_hip_function_q44_q2_a2_o2
                          : functionData.sports === "6"
                          ? translations.patient_hip_function_q44_q2_a2_o3
                          : functionData.sports === "7"
                          ? translations.patient_hip_function_q44_q2_a2_o4
                          : functionData.sports === "8"
                          ? translations.patient_hip_function_q44_q2_a2_o5
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivity === "2" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a3} :</td>
                      <td>
                        {functionData.sports === "9"
                          ? translations.patient_hip_function_q44_q2_a3_o1
                          : functionData.sports === "10"
                          ? translations.patient_hip_function_q44_q2_a3_o2
                          : functionData.sports === "11"
                          ? translations.patient_hip_function_q44_q2_a3_o3
                          : functionData.sports === "12"
                          ? translations.patient_hip_function_q44_q2_a3_o4
                          : ""}
                      </td>
                    </tr>
                  )}
                  {functionData.highestActivity === "1" && (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q2_a4} :</td>
                      <td>
                        {functionData.sports === "13"
                          ? translations.patient_hip_function_q44_q2_a4_o1
                          : functionData.sports === "14"
                          ? translations.patient_hip_function_q44_q2_a4_o2
                          : functionData.sports === "15"
                          ? translations.patient_hip_function_q44_q2_a4_o3
                          : ""}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* {AllData.initial_assessment !== 1 && (
                    <div className="treatment-row">
                        <div className="treatment-col">
                            <label>Please specify the highest level of activity that you can participate in:</label>
                            <p>{functionData.specifyHighestActivityR==="0" ? "PRIOR TO HIP INJURY" : "CURRENT STATUS"}</p>
                        </div>
                    </div>
                    
                    )}                 */}
        </div>
      </div>
    </>
  );
}

import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../custom/button/Button";
import Share from "../../Share";
import useAxios from "../../../../axiosinstance";
import {
  updateHipScores,
  updateHipPainFieldScore,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateHipTotalScores,
} from "../../../../redux/actions/hipAction";

import useTranslation from "../../../customHooks/translations";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel"
function HipResult() {
  const translation = useTranslation();
  const { rId } = useParams();
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  let type=6;
  const [chk, setChk] = useState(false);
  const [totalScore] = useSelector(
    (state) => state.hipScoreReducer.TotalScores
  );
  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  // const [allData] = useSelector((state) => state.hipScoreReducer);
  const navigate = useNavigate();
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [status, setStatus] = useState("");
  const [isdoctorform, setisdoctorform] = useState(Number())
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);
  const continueRedirect = (e) => {
    setDisplayConfirmationModal(true)
    // if (isdoctorform === 1) {

    //   window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {

    //   window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };

  const continueRedirect2 = (e) => {
    window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
 
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        dispatch(updateHipTotalScores(res.data.data2[0].TotalScores));
        setisdoctorform(res.data.data2[0].is_doctor_form)
        setChk(true);
        setStatus(res.data.data2[0].status);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);
  // console.log(allData);

  let eq4deq5dwalking = Math.max(
    totalScore.eq4deq5dwalkingR,
    totalScore.eq4deq5dwalkingL
  );
  let eqdSelf = Math.max(totalScore.eqdSelfR, totalScore.eqdSelfL);
  let eqdUsualmaxvalue = Math.max(totalScore.eqdUsual, totalScore.eqdUsualL);
  let eqdPainmaxvalue = Math.max(totalScore.eqdPainR, totalScore.eqdPain);
  let eqdAnximaxvalue = totalScore.eqdAnxi;

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <div>
                <NameCustom />
                <h3 className="text-center f_22 mt_30">
                  {translation.patient_hip_results}
                </h3>
                <p className="answer_txt">
                  {translation.patient_hip_results_text1}

                </p>
              </div>
              <div className="row result_scorebox">
                {/* vas */}

                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.patient_hip_results_pain}
                      type={translation.patient_hip_results_self_evaluation}
                      arm={translation.Hip_Function_L}
                      persent={totalScore.vashowBadPainRatingL * 10}
                      value={totalScore.vashowBadPainRatingL}
                      total="10"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.patient_hip_results_pain}
                      type={translation.patient_hip_results_self_evaluation}
                      arm={translation.Hip_Function_R}
                      persent={totalScore.vashowBadPainRatingR * 10}
                      value={totalScore.vashowBadPainRatingR}
                      total="10"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.patient_hip_results_function}
                      type={translation.patient_hip_results_self_evaluation}
                      arm={translation.Hip_Function_L}
                      value={totalScore.mhhsTL}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}

                {/* mhhs */}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.patient_hip_results_function}
                      type={translation.patient_hip_results_self_evaluation}
                      arm={translation.Hip_Function_R}
                      value={totalScore.mhhsTR}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}


                {/* EQ VAS */}

                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={0} reverse={false}
                    title={translation.patient_hip_results_health_perception}
                    type={translation.patient_hip_results_self_evaluation}
                    // arm={translation.hip_form}
                    value={totalScore.eqVas}
                    total="100"
                    date="22/09/2022"
                    color="#78b776"

                  />
                </div> */}
              </div>

              <div className="row result_scorebox d-none">
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Symptoms}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.hoosSymptomsTR}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Symptoms}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.hoosSymptomsTL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.hoosPainTR}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.hoosPainTL}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_ADL}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.hoosADLTR}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_ADL}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.hoosADLTL}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Sport}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.hossSportTR}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_Sport}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.hossSportTL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_QOL}
                      type={translation.hip_form}
                      arm={translation.right_quality_of_life}
                      value={totalScore.hoosQORT}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.Hoos_QOL}
                      type={translation.hip_form}
                      arm={translation.left_quality_of_life}
                      value={totalScore.hoosQOLT}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Function}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.ohsFuncTR}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Function}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.ohsFuncTL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.ohsPainTR}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.ohsPainTL}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Overall}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.overallohsR}
                      total="48"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.OHS_Overall}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.overallohsL}
                      total="48"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.womacPainTR}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Pain}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.womacPainTL}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Stiffness}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.womacstiffTR}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Stiffness}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.womacstiffTL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Function}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.womacFunctionTR}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.WOMAC_Function}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.womacFunctionTL}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.NAHS}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.nahsTR}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.NAHS}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.nahsTL}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.HOS_ADL}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.hosadlTR}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.HOS_ADL}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.hosadlTL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* {evlHipPain === "1" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.HOS_Sports} type="Hip" arm="Right Hip" value={totalScore.hossportTR} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}{evlHipPain === "2" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.HOS_Sports} type="Hip" arm="Left Hip" value={totalScore.hossportTL} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )} */}
                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.IHOT_12}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.ihotR}
                      total="100"
                      date="22/09/2022"
                      color="#ff0000"
                    />
                  </div>
                ) : (
                  ""
                )}
                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.IHOT_12}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.ihotL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"
                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.SANE}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_R}
                      value={totalScore.sane}
                      total="100"
                      date="22/09/2022"
                      color="#78b776"

                    />
                  </div>
                ) : (
                  ""
                )}

                {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                    <ResultCircleProgress min_score={0} reverse={false}
                      title={translation.SANE}
                      type={translation.hip_form}
                      arm={translation.Hip_Function_L}
                      value={totalScore.saneL}
                      total="100"
                      date="22/09/2022"
                      color="#006DFF"

                    />
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS_HIP} type="Hip" arm={translation.hip_form} value={totalScore.eqVas} total="100" date="22/09/2022" color="#78b776" />
                                </div> */}
                {/* 
                                {evlHipPain === "2" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS_HIP} type="Hip" arm={translation.hip_form} value={totalScore.eqVasL} total="100" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : ""}

                                {evlHipPain === "1" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SAPS_HIP} type="Hip" arm="Right-Hip" value={totalScore.SapsR} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlHipPain === "2" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SAPS_HIP} type="Hip" arm="Left-Hip" value={totalScore.SapsL} total="100" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )} */}

                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={1} reverse={true}
                    title={translation.EQ_5D_5L_Mobility_HIP}
                    hide={"1"}
                    type={translation.hip_form}
                    persent={eq4deq5dwalking * 20}
                    value={`${translation.Level} ${eq4deq5dwalking}`}
                    total="5"
                    date="22/09/2022"
                    color="#78b776"
                  />
                </div> */}
                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L_Mobility_HIP} type="Hip" arm="Right Hip" persent={totalScore.eq4deq5dwalkingL*20 } value={`Level ${totalScore.eq4deq5dwalkingL}`} total="5" date="22/09/2022" color="#78b776" />
                                </div> */}

                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={1} reverse={true}
                    title={translation.EQ5d_5L_Self_Care_HIP}
                    hide={"1"}
                    type={translation.hip_form}
                    persent={eqdSelf * 20}
                    value={`${translation.Level} ${eqdSelf}`}
                    total="5"
                    date="22/09/2022"
                    color="#78b776"
                  />
                </div> */}
                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={1} reverse={true}
                    title={translation.EQ5d_5L_Usual_Activities_HIP}
                    hide={"1"}
                    type={translation.hip_form}
                    persent={eqdUsualmaxvalue * 20}
                    value={`${translation.Level} ${eqdUsualmaxvalue}`}
                    total="5"
                    date="22/09/2022"
                    color="#78b776"
                  />
                </div> */}
                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={1} reverse={true}
                    title={translation.EQ5d_5L_Pain_Discomfort_HIP}
                    hide={"1"}
                    type={translation.hip_form}
                    persent={eqdPainmaxvalue * 20}
                    value={`${translation.Level} ${eqdPainmaxvalue}`}
                    total="5"
                    date="22/09/2022"
                    color="#78b776"
                  />
                </div> */}
                {/* <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={1} reverse={true}
                    title={translation.EQ5d_5L_anxious_HIP}
                    type={translation.hip_form}
                    hide={"1"}
                    persent={eqdAnximaxvalue * 20}
                    value={`${translation.Level} ${eqdAnximaxvalue}`}
                    total="5"
                    date="22/09/2022"
                    color="#78b776"
                  />
                </div> */}

                {/* {evlHipPain === "1" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Self_Care_HIP} type="Hip" arm="Right Hip" persent={totalScore.eqdSelfR * 20} value={`Level ${totalScore.eqdSelfR}`} total="5" date="22/09/2022" color="#78b776" />
                                    </div>
                                ) : (
                                    ""
                                )} */}
                {/* {evlHipPain === "2" || evlHipPain === "3" ? (

                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Self_Care_HIP} type="Hip" arm="Left Hip" persent={totalScore.eqdSelfL * 20} value={`Level ${totalScore.eqdSelfL}`} total="5" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )} */}
                {/* {evlHipPain === "1" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Usual_Activities_HIP} type="Hip" arm="Right Hip" persent={totalScore.eqdUsual * 20} value={`Level ${totalScore.eqdUsual}`} total="5" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlHipPain === "2" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Usual_Activities_HIP} type="Hip" arm="Left Hip" persent={totalScore.eqdUsualL * 20} value={`Level ${totalScore.eqdUsualL}`} total="5" date="22/09/2022" color="#ff0000" />
                                    </div>
                                ) : (
                                    ""
                                )} */}

                {/* {evlHipPain === "1" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Pain_Discomfort_HIP} type="Hip" arm="Right Hip" persent={totalScore.eqdPainR * 20} value={`Level ${totalScore.eqdPainR}`} total="5" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : (
                                    ""
                                )}
                                {evlHipPain === "2" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_Pain_Discomfort_HIP} type="Hip" arm="Left Hip" persent={totalScore.eqdPain * 20} value={`Level ${totalScore.eqdPain}`} total="5" date="22/09/2022" color="#006DFF" />
                                    </div>

                                ) : (
                                    ""
                                )} */}

                {/* {evlHipPain === "2" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_anxious_HIP} type="Hip" arm="Right Hip" persent={totalScore.eqdAnxi * 20} value={`Level ${totalScore.eqdAnxi}`} total="5" date="22/09/2022" color="#006DFF" />
                                    </div>

                                ) : (
                                    ""
                                )}

                                {evlHipPain === "2" || evlHipPain === "3" ? (
                                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ5d_5L_anxious_HIP} type="Hip" arm="Left Hip" persent={totalScore.eqdAnxiL * 20} value={`Level ${totalScore.eqdAnxiL}`} total="5" date="22/09/2022" color="#006DFF" />
                                    </div>

                                ) : (
                                    ""
                                )} */}

                {initialAssesment == 0 && (
                  <div>
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_OVERALL}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_R}
                          value={totalScore.SapsOverR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_OVERALL}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_L}
                          value={totalScore.SapsOverL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_PAIN}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_R}
                          value={totalScore.sapsPainR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_PAIN}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_L}
                          value={totalScore.sapsPainL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_WORK}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_R}
                          value={totalScore.sapsWorkR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_WORK}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_L}
                          value={totalScore.sapsWorkL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_RECREATION}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_R}
                          value={totalScore.sapsRecreR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SAPS_RECREATION}
                          type={translation.hip_form}
                          arm={translation.Hip_Function_L}
                          value={totalScore.sapsRecreL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>

              {/* <div className="pt_70 pb_50 text-center">
                <h6 className="mb_20 f_600">{translation.patient_hip_results_text2}</h6>
                <Button
                  onClick={continueRedirect}
                  value={translation.Sign_out}
                  buttonStyle="btn_fill result_submit_btn"
                />
              </div> */}

{status === "1" ? (
  <div className="pt_70 pb_50 text-center">
                  <h6 className="mb_20 f_600">
                    {/* {translation.patient_shoulder_results_text2} */}
                  </h6>
                  <Button
                  onClick={continueRedirect2}
                  value={translation.Sign_out}
                  buttonStyle="btn_fill result_submit_btn"
                />
                 
                </div>
              ) : (
              <Share
              type={type}
              rId={rId}
              />
              )}
            </div>
          </div>
        </div>
      )}
        <SubmitModel
     showModal={displayConfirmationModal}
     
     />
    </>
  );
}

export default HipResult;

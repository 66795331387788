import React from "react";
import "../../../css/ViewTreatmentForm.css";

function ClinicalExaminationBottom({ doctorefeedback, AllData }) {
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Shoulder Scoring Clinical Examination</h2>
          <div className="treatment-header-right">
          <p>Assessment Date : {AllData.doctorDate}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table bt-none">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                   
                    <th>Right Shoulder</th>
                    <th>Left Shoulder</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Supraspinatus</td>
                    
                    <td>Power {doctorefeedback.supraspinatus_power_right}/5 - {doctorefeedback.supraspinatus_kg_right} kg</td>
                    <td>Power {doctorefeedback.supraspinatus_power_left}/5 - {doctorefeedback.supraspinatus_kg_left} kg</td>
                  </tr>
                  <tr>
                    <td className="pL-20"> <span className="ms-4">Jobes Test</span></td>
                    
                    <td>{doctorefeedback.jobes_right === "0" ? "Negative" : doctorefeedback.jobes_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.jobes_left === "0" ? "Negative" : doctorefeedback.jobes_left === "1" ? "Positive" : "-"}</td>
                  </tr>
                  <tr>
                    <td className="pL-20"><span className="ms-4">Yocums Test</span></td>
                   
                    <td>{doctorefeedback.yocum_right === "0" ? "Negative" : doctorefeedback.yocum_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.yocum_left === "0" ? "Negative" : doctorefeedback.yocum_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td>Infraspinatus</td>
                    
                    <td>Power {doctorefeedback.infraspinatus_power_right}/5 - {doctorefeedback.infraspinatus_kg_right} kg</td>
                    <td>Power {doctorefeedback.infraspinatus_power_left}/5 - {doctorefeedback.infraspinatus_kg_left} kg</td>
                    
                  </tr>

                  <tr>
                    <td>Subscapularis</td>
                   
                    <td>Power {doctorefeedback.subscapularis_power_right}/5 - {doctorefeedback.subscapularis_kg_right} kg</td>
                    <td>Power {doctorefeedback.subscapularis_power_left}/5 - {doctorefeedback.subscapularis_kg_left} kg</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Bear Hug</span></td>
                  
                    <td>{doctorefeedback.bearhug_right === "0" ? "Negative" : doctorefeedback.bearhug_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.bearhug_left === "0" ? "Negative" : doctorefeedback.bearhug_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Gerbers Lift Off</span></td>
                   
                    <td>{doctorefeedback.gerbers_lift_right === "0" ? "Negative" : doctorefeedback.gerbers_lift_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.gerbers_lift_left === "0" ? "Negative" : doctorefeedback.gerbers_lift_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Napoleon Belly Press</span></td>
                    
                    <td>{doctorefeedback.napoleon_right === "0" ? "Negative" : doctorefeedback.napoleon_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.napoleon_left === "0" ? "Negative" : doctorefeedback.napoleon_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td>Teres Minor</td>
                    
                    <td>Power {doctorefeedback.teresminor_right}/5</td>
                    <td>Power {doctorefeedback.teresminor_left}/5</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Horn Blower</span></td>
                    
                    <td>{doctorefeedback.hornblower_right === "0" ? "Negative" : doctorefeedback.hornblower_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.hornblower_left === "0" ? "Negative" : doctorefeedback.hornblower_left === "1" ? "Positive" : "-"}</td>
                  </tr>
                  <tr>
                    <td>ACJ</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="pL-20"><span className="ms-4">Tenderness</span></td>
                   
                    <td>{doctorefeedback.tenderness_right === "0" ? "No" : doctorefeedback.tenderness_right === "1" ? "Yes" : "-"}</td>
                    <td>{doctorefeedback.tenderness_left === "0" ? "No" : doctorefeedback.tenderness_left === "1" ? "Yes" : "-"}</td>
                  </tr>
                  <tr>
                    <td className="pL-20"><span className="ms-4">AP Instability</span></td>
                  
                    <td>{doctorefeedback.apinstability_right === "0" ? "Negative" : doctorefeedback.apinstability_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.apinstability_left === "0" ? "Negative" : doctorefeedback.apinstability_left === "1" ? "Positive" : "-"}</td>
                  </tr>
                  <tr>
                    <td className="pL-20"><span className="ms-4">Paxinous Test</span></td>
                    
                    <td>{doctorefeedback.paxinos_right === "0" ? "Negative" : doctorefeedback.paxinos_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.paxinos_left === "0" ? "Negative" : doctorefeedback.paxinos_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td>Biceps</td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Tenderness</span></td>
                    
                    <td>{doctorefeedback.biceps_tenderness_right === "0" ? "No" : doctorefeedback.biceps_tenderness_right === "1" ? "Yes" : "-"}</td>
                    <td>{doctorefeedback.biceps_tenderness_left === "0" ? "No" : doctorefeedback.biceps_tenderness_left === "1" ? "Yes" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Yergasons Test</span></td>
                   
                    <td>{doctorefeedback.biceps_yergason_test_right === "0" ? "Negative" : doctorefeedback.biceps_yergason_test_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.biceps_yergason_test_left === "0" ? "Negative" : doctorefeedback.biceps_yergason_test_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Speeds Test</span></td>
                    
                    <td>{doctorefeedback.speeds_right === "0" ? "Negative" : doctorefeedback.speeds_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.speeds_left === "0" ? "Negative" : doctorefeedback.speeds_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Popeye</span></td>
                   
                    <td>{doctorefeedback.popeye_right === "0" ? "Negative" : doctorefeedback.popeye_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.popeye_left === "0" ? "Negative" : doctorefeedback.popeye_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">A,ER,S</span></td>
                   
                    <td>{doctorefeedback.aers_right === "0" ? "Negative" : doctorefeedback.aers_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.aers_left === "0" ? "Negative" : doctorefeedback.aers_left === "1" ? "Positive" : "-"}</td>
                  </tr>

                  <tr>
                    <td>Labrum</td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Whipple</span></td>
                    <td>{doctorefeedback.whipple_right === "0" ? "Negative" : doctorefeedback.whipple_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.whipple_left === "0" ? "Negative" : doctorefeedback.whipple_left === "1" ? "Positive" : "-"}</td>

                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">O’Briens Test</span></td>
                    <td>{doctorefeedback.briens_right === "0" ? "Negative" : doctorefeedback.briens_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.briens_left === "0" ? "Negative" : doctorefeedback.briens_left === "1" ? "Positive" : "-"}</td>

                  </tr>

                  <tr>
                    <td>Triceps Strength</td>
                    <td>{doctorefeedback.triceps_right} kg</td>
                    <td>{doctorefeedback.triceps_left} kg</td>

                  </tr>

                  <tr>
                    <td>Deltoid Strength</td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Ant Deltoid</span></td>
                    <td>Power {doctorefeedback.deltoidpower_right}/5 - {doctorefeedback.deltoid_right} kg</td>
                    <td>Power {doctorefeedback.deltoidpower_left}/5 - {doctorefeedback.deltoid_left} kg</td>

                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Mid Deltoid</span></td>
                    <td>Power {doctorefeedback.midpower_deltoid_right}/5 - {doctorefeedback.middeltoid_right} kg</td>
                    <td>Power {doctorefeedback.midpower_deltoid_left}/5 - {doctorefeedback.middeltoid_left} kg</td>

                  </tr>

                  <tr>
                    <td className="pL-20"><span className="ms-4">Post Deltoid</span></td>
                    <td>Power {doctorefeedback.postpower_deltoid_right}/5 - {doctorefeedback.postdeltoid_right} kg</td>
                    <td>Power {doctorefeedback.postpower_deltoid_left}/5 - {doctorefeedback.postdeltoid_left} kg</td>

                  </tr>

                  <tr>
                    <td>C Spine</td>
                    <td>{doctorefeedback.cspine === "0" ? "Normal" : doctorefeedback.cspine === "1" ? "Restricted" : "-"}</td>
                    
                  </tr>

                  <tr>
                    <td>SSN Stretch</td>
                    <td>{doctorefeedback.ssn_stretch_right === "0" ? "Negative" : doctorefeedback.ssn_stretch_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.ssn_stretch_left === "0" ? "Negative" : doctorefeedback.ssn_stretch_left === "1" ? "Positive" : "-"}</td>

                  </tr>

                  <tr>
                    <td>HERI</td>
                    <td>{doctorefeedback.heri_right === "" ? "-" : doctorefeedback.heri_right} degrees</td>
                    <td>{doctorefeedback.heri_left === "" ? "-" : doctorefeedback.heri_left } degrees</td>

                  </tr>

                  <tr>
                    <td>GAGEY</td>
                    <td>{doctorefeedback.gagey_right === "" ? "-" : doctorefeedback.gagey_right + ' degrees'}</td>
                    <td>{doctorefeedback.gagey_left === "" ? "-" : doctorefeedback.gagey_left + ' degrees'}</td>

                  </tr>

                  <tr>
                    <td>Scapula Dyskinesia</td>
                    <td>{doctorefeedback.scapula_right === "0" ? "Negative" : doctorefeedback.scapula_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.scapula_left === "0" ? "Negative" : doctorefeedback.scapula_left === "1" ? "Positive" : "-"}</td>

                  </tr>

                  <tr>
                    <td>Ligamentous Laxity</td>
                    <td>Signs {doctorefeedback.ligamentous ==="" ? "0": doctorefeedback.ligamentous}/5</td>
                    
                  </tr>

                  <tr>
                    <td>Thoracic Outlet Obstruction</td>
                    <td>{doctorefeedback.thoracic_right === "0" ? "Negative" : doctorefeedback.thoracic_right === "1" ? "Positive" : "-"}</td>
                    <td>{doctorefeedback.thoracic_left === "0" ? "Negative" : doctorefeedback.thoracic_left === "1" ? "Positive" : "-"}</td>

                  </tr>

                  <tr>
                    <td>Grip Strength</td>
                    <td>{doctorefeedback.grip_right === "" ? "-" : doctorefeedback.grip_right} kg</td>
                    <td>{doctorefeedback.grip_left === "" ? "-" : doctorefeedback.grip_left} kg</td>

                  </tr>
                  <tr>
                    <td>Diagnosis</td>
                    <td>{doctorefeedback.right_diagnosis === "" ? "-" : doctorefeedback.right_diagnosis}</td>
                    <td>{doctorefeedback.left_diagnosis === "" ? "-" : doctorefeedback.left_diagnosis}</td>

                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col col-6">
              <label>Procedure:</label>
              <p >{doctorefeedback.procedure === "" ? "-" : doctorefeedback.procedure }</p>
            </div>
            <div className="treatment-col col-6">
              <label>Note:</label>
              <p >{doctorefeedback.treatment_notes2value === "" ? "-" : doctorefeedback.treatment_notes2value}</p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>Outcome:</label>
              <p >{doctorefeedback.outcome_treatment === "0" ? "None" : doctorefeedback.outcome_treatment === "1" ? "Treatment" : doctorefeedback.outcome_treatment === "2" ? "Discharge/Deceased" :doctorefeedback.outcome_treatment === "3" ? "No Action Needed" : "-"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicalExaminationBottom;

import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function FootAnklePain({ FootAnklePaindata, AllData }) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>{translations.FootAnkle_Scoring_Self_Evaluation_Questionnaire}</h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} : {AllData.assessment_date}
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_foot_ankle_pain}
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q1}({translations.Right})
            </label>
            <p>{FootAnklePaindata.painRating}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q1}({translations.Left})
            </label>
            <p>{FootAnklePaindata.painRatingL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q2}({translations.Right})
            </label>
            <p>
              {FootAnklePaindata.descripR === "0"
                ? translations.patient_foot_ankle_pain_q2_a1
                : FootAnklePaindata.descripR === "1"
                ? translations.patient_foot_ankle_pain_q2_a2
                : FootAnklePaindata.descripR === "2"
                ? translations.patient_foot_ankle_pain_q2_a3
                : FootAnklePaindata.descripR === "3"
                ? translations.patient_foot_ankle_pain_q2_a4
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q2}({translations.Left})
            </label>
            <p>
              {FootAnklePaindata.descripL === "0"
                ? translations.patient_foot_ankle_pain_q2_a1
                : FootAnklePaindata.descripL === "1"
                ? translations.patient_foot_ankle_pain_q2_a2
                : FootAnklePaindata.descripL === "2"
                ? translations.patient_foot_ankle_pain_q2_a3
                : FootAnklePaindata.descripL === "3"
                ? translations.patient_foot_ankle_pain_q2_a4
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q3}({translations.Right})
            </label>
            <p>
              {FootAnklePaindata.oftenR === "0"
                ? translations.patient_foot_ankle_pain_q3_a1
                : FootAnklePaindata.oftenR === "1"
                ? translations.patient_foot_ankle_pain_q3_a2
                : FootAnklePaindata.oftenR === "2"
                ? translations.patient_foot_ankle_pain_q3_a3
                : FootAnklePaindata.oftenR === "3"
                ? translations.patient_foot_ankle_pain_q3_a4
                : FootAnklePaindata.oftenR === "4"
                ? translations.patient_foot_ankle_pain_q3_a5
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_pain_q3}({translations.Left})
            </label>
            <p>
              {FootAnklePaindata.oftenL === "0"
                ? translations.patient_foot_ankle_pain_q3_a1
                : FootAnklePaindata.oftenL === "1"
                ? translations.patient_foot_ankle_pain_q3_a2
                : FootAnklePaindata.oftenL === "2"
                ? translations.patient_foot_ankle_pain_q3_a3
                : FootAnklePaindata.oftenL === "3"
                ? translations.patient_foot_ankle_pain_q3_a4
                : FootAnklePaindata.oftenL === "4"
                ? translations.patient_foot_ankle_pain_q3_a5
                : ""}
            </p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th>{translations.patient_foot_ankle_pain_instruction}</th>

                  <th>{translations.Right_Foot_and_Ankle}</th>

                  <th>{translations.Left_Foot_and_Ankle}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q4}:</td>
                  <td>{FootAnklePaindata.genPainR}</td>
                  <td>{FootAnklePaindata.genPainL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q5}:</td>
                  <td>{FootAnklePaindata.restR}</td>
                  <td>{FootAnklePaindata.restL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q6}:</td>
                  <td>{FootAnklePaindata.normalR}</td>
                  <td>{FootAnklePaindata.normalL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q7}:</td>
                  <td>{FootAnklePaindata.firstR}</td>
                  <td>{FootAnklePaindata.firstL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q8}:</td>
                  <td>{FootAnklePaindata.twistedR}</td>
                  <td>{FootAnklePaindata.twistedL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q9}:</td>
                  <td>{FootAnklePaindata.straightR}</td>
                  <td>{FootAnklePaindata.straightL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q10}:</td>
                  <td>{FootAnklePaindata.bendingR}</td>
                  <td>{FootAnklePaindata.bendingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q11}:</td>
                  <td>{FootAnklePaindata.walkingR}</td>
                  <td>{FootAnklePaindata.walkingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q12}:</td>
                  <td>{FootAnklePaindata.goingR}</td>
                  <td>{FootAnklePaindata.goingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q13}:</td>
                  <td>{FootAnklePaindata.nightR}</td>
                  <td>{FootAnklePaindata.nightL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q14} :</td>
                  <td>{FootAnklePaindata.sittingR}</td>
                  <td>{FootAnklePaindata.sittingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_pain_q15}:</td>
                  <td>{FootAnklePaindata.standingR}</td>
                  <td>{FootAnklePaindata.standingL}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function AdultKneeFunction({
  adultFunction,
  AllData,
  adultNorwich,
  initialAssesment,
}) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
            3. {translations.patient_adult_knee_function}
          </div>

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Knee}</th>
                    <th>{translations.Left_Knee}</th>

                    {/* {AllData.evlAdultKnee === "1" || AllData.evlAdultKnee === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.evlAdultKnee === "2" || AllData.evlAdultKnee === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q1} :</td>
                    <td> {adultFunction.goUpStairsR}</td>
                    <td> {adultFunction.goUpStairsL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_pedia_knee_function_q2} :</td>
                    <td> {adultFunction.goDownStairsR}</td>
                    <td> {adultFunction.goDownStairsL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_pedia_knee_function_q3} :</td>
                    <td> {adultFunction.kneelFrontR}</td>
                    <td> {adultFunction.kneelFrontL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_pedia_knee_pain_q4} :</td>
                    <td> {adultFunction.straighteningR}</td>
                    <td> {adultFunction.straighteningL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q5} :</td>
                    <td> {adultFunction.bendingKneeR}</td>
                    <td> {adultFunction.bendingKneeL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q6} :</td>
                    <td> {adultFunction.squatR}</td>
                    <td> {adultFunction.squatL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q7} :</td>
                    <td> {adultFunction.sitKneeBentR}</td>
                    <td> {adultFunction.sitKneeBentL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q8}</td>
                    <td> {adultFunction.riseFromChairR}</td>
                    <td> {adultFunction.riseFromChairL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q9} :</td>
                    <td> {adultFunction.riseFromBedR}</td>
                    <td> {adultFunction.riseFromBedL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q10} :</td>
                    <td> {adultFunction.lyingInBedR}</td>
                    <td> {adultFunction.lyingInBedL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q11} :</td>
                    <td> {adultFunction.bendingToFloorR}</td>
                    <td> {adultFunction.bendingToFloorL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q12} :</td>
                    <td> {adultFunction.puttingOnSocksR}</td>
                    <td> {adultFunction.puttingOnSocksL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q13} :</td>
                    <td> {adultFunction.takingOffSocksR}</td>
                    <td> {adultFunction.takingOffSocksL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q14} :</td>
                    <td> {adultFunction.standingR}</td>
                    <td> {adultFunction.standingL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q15} :</td>
                    <td> {adultFunction.walkingR}</td>
                    <td> {adultFunction.walkingL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q16} :</td>
                    <td> {adultFunction.runStraightAheadR}</td>
                    <td> {adultFunction.runStraightAheadL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q17} :</td>
                    <td> {adultFunction.jumpAndLandR}</td>
                    <td> {adultFunction.jumpAndLandL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q18} :</td>
                    <td> {adultFunction.stopAndStartR}</td>
                    <td> {adultFunction.stopAndStartL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q19} :</td>
                    <td> {adultFunction.twistingR}</td>
                    <td> {adultFunction.twistingL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q20} :</td>
                    <td> {adultFunction.publicTransportR}</td>
                    <td> {adultFunction.publicTransportL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q21} :</td>
                    <td> {adultFunction.gettingBathR}</td>
                    <td> {adultFunction.gettingBathL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q22} :</td>
                    <td> {adultFunction.gettingToiletR}</td>
                    <td> {adultFunction.gettingToiletL}</td>
                  </tr>
                  {String(AllData.age) !== "1" ? (
                    <>
                      <tr>
                        <td>
                          {translations.patient_adult_knee_function_q23} :
                        </td>
                        <td> {adultFunction.washingR}</td>
                        <td> {adultFunction.washingL}</td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}

                  <tr>
                    {String(AllData.age) !== "1" ? (
                      <td>{translations.patient_adult_knee_function_q24} :</td>
                    ) : (
                      <td>
                        {translations.patient_adolescent_knee_function_q23} :
                      </td>
                    )}
                    <td> {adultFunction.shoppingR}</td>
                    <td> {adultFunction.shoppingL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_hip_function_q32} :</td>
                    <td> {adultFunction.heavyDutiesR}</td>
                    <td> {adultFunction.heavyDutiesL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_hip_function_q33} :</td>
                    <td> {adultFunction.lightDutiesR}</td>
                    <td> {adultFunction.lightDutiesL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q27} :</td>
                    <td>{adultFunction.rateKneeFunctionR}</td>
                    <td>{adultFunction.rateKneeFunctionL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q29} :</td>
                    <td>
                      {adultFunction.activityLevelR === "0"
                        ? translations.patient_adult_knee_pain_q1_a5
                        : adultFunction.activityLevelR === "1"
                        ? translations.patient_adult_knee_pain_q1_a4
                        : adultFunction.activityLevelR === "2"
                        ? translations.patient_adult_knee_pain_q1_a3
                        : adultFunction.activityLevelR === "3"
                        ? translations.patient_adult_knee_pain_q1_a2
                        : adultFunction.activityLevelR === "4"
                        ? translations.patient_adult_knee_pain_q1_a1
                        : ""}
                    </td>
                    <td>
                      {adultFunction.activityLevelL === "0"
                        ? translations.patient_adult_knee_pain_q1_a5
                        : adultFunction.activityLevelL === "1"
                        ? translations.patient_adult_knee_pain_q1_a4
                        : adultFunction.activityLevelL === "2"
                        ? translations.patient_adult_knee_pain_q1_a3
                        : adultFunction.activityLevelL === "3"
                        ? translations.patient_adult_knee_pain_q1_a2
                        : adultFunction.activityLevelL === "4"
                        ? translations.patient_adult_knee_pain_q1_a1
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_hip_function_q44} :</td>
                  </tr>

                  {initialAssesment === 1 || String(initialAssesment )=== "undefined" ? (
                    <tr>
                      <td>{translations.patient_hip_function_q44_q1} :</td>
                      <td>
                        {adultFunction.highestActivity === "0"
                          ? translations.patient_hip_function_q44_q1_a5
                          : adultFunction.highestActivity === "1"
                          ? translations.patient_hip_function_q44_q1_a4
                          : adultFunction.highestActivity === "2"
                          ? translations.patient_hip_function_q44_q1_a3
                          : adultFunction.highestActivity === "3"
                          ? translations.patient_hip_function_q44_q1_a2
                          : adultFunction.highestActivity === "4"
                          ? translations.patient_hip_function_q44_q1_a1
                          : ""}

                        {adultFunction.highestActivity === "4" && (
                          <p>
                            {adultFunction.sports === "1"
                              ? translations.patient_adult_knee_function_q30_q1_a1_o1
                              : adultFunction.sports === "2"
                              ? translations.patient_adult_knee_function_q30_q1_a1_o2
                              : adultFunction.sports === "3"
                              ? translations.patient_adult_knee_function_q30_q1_a1_o3
                              : ""}
                          </p>
                        )}
                        {adultFunction.highestActivity === "3" && (
                          <p>
                            {adultFunction.sports === "4"
                              ? translations.patient_adult_knee_function_q30_q1_a2_o1
                              : adultFunction.sports === "5"
                              ? translations.patient_adult_knee_function_q30_q1_a2_o2
                              : adultFunction.sports === "6"
                              ? translations.patient_adult_knee_function_q30_q1_a2_o3
                              : adultFunction.sports === "7"
                              ? translations.patient_adult_knee_function_q30_q1_a2_o4
                              : adultFunction.sports === "8"
                              ? translations.patient_adult_knee_function_q30_q1_a2_o5
                              : ""}
                          </p>
                        )}
                        {adultFunction.highestActivity === "2" && (
                          <p>
                            {adultFunction.sports === "9"
                              ? translations.patient_adult_knee_function_q30_q2_a3_o1
                              : adultFunction.sports === "10"
                              ? translations.patient_adult_knee_function_q30_q2_a3_o2
                              : adultFunction.sports === "11"
                              ? translations.patient_adult_knee_function_q30_q2_a3_o3
                              : adultFunction.sports === "12"
                              ? translations.patient_adult_knee_function_q30_q2_a3_o4
                              : ""}
                          </p>
                        )}
                        {adultFunction.highestActivity === "1" && (
                          <p>
                            {adultFunction.sports === "13"
                              ? translations.patient_adult_knee_function_q30_q2_a4_o1
                              : adultFunction.sports === "14"
                              ? translations.patient_adult_knee_function_q30_q2_a4_o2
                              : adultFunction.sports === "15"
                              ? translations.patient_adult_knee_function_q30_q2_a4_o3
                              : ""}
                          </p>
                        )}
                      </td>
                    </tr>
                  ):""}

                  <tr>
                    <td>{translations.patient_foot_ankle_function_q52_q2} :</td>
                    <td>
                      {adultFunction.highestActivityL === "0"
                        ? translations.patient_adult_knee_function_q30_q1_a5
                        : adultFunction.highestActivityL === "1"
                        ? translations.patient_adult_knee_function_q30_q1_a4
                        : adultFunction.highestActivityL === "2"
                        ? translations.patient_adult_knee_function_q30_q1_a3
                        : adultFunction.highestActivityL === "3"
                        ? translations.patient_adult_knee_function_q30_q1_a2
                        : adultFunction.highestActivityL === "4"
                        ? translations.patient_adult_knee_function_q30_q1_a1
                        : ""}
                      {adultFunction.highestActivityL === "4" && (
                        <p>
                          {adultFunction.currsports === "16"
                            ? translations.patient_adult_knee_function_q30_q1_a1_o1
                            : adultFunction.currsports === "17"
                            ? translations.patient_adult_knee_function_q30_q1_a1_o2
                            : adultFunction.currsports === "18"
                            ? translations.patient_adult_knee_function_q30_q1_a1_o3
                            : ""}
                        </p>
                      )}
                      {adultFunction.highestActivityL === "3" && (
                        <p>
                          {adultFunction.currsports === "19"
                            ? translations.patient_adult_knee_function_q30_q1_a2_o1
                            : adultFunction.currsports === "20"
                            ? translations.patient_adult_knee_function_q30_q1_a2_o2
                            : adultFunction.currsports === "21"
                            ? translations.patient_adult_knee_function_q30_q1_a2_o3
                            : adultFunction.currsports === "22"
                            ? translations.patient_adult_knee_function_q30_q1_a2_o4
                            : adultFunction.currsports === "23"
                            ? translations.patient_adult_knee_function_q30_q1_a2_o5
                            : ""}
                        </p>
                      )}
                      {adultFunction.highestActivityL === "2" && (
                        <p>
                          {adultFunction.currsports === "24"
                            ? translations.patient_adult_knee_function_q30_q1_a3_o1
                            : adultFunction.currsports === "25"
                            ? translations.patient_adult_knee_function_q30_q1_a3_o2
                            : adultFunction.currsports === "26"
                            ? translations.patient_adult_knee_function_q30_q1_a3_o3
                            : adultFunction.currsports === "27"
                            ? translations.patient_adult_knee_function_q30_q1_a3_o4
                            : ""}
                        </p>
                      )}
                      {adultFunction.highestActivityL === "1" && (
                        <p>
                          {adultFunction.currsports === "28"
                            ? translations.patient_adult_knee_function_q30_q1_a4_o1
                            : adultFunction.currsports === "29"
                            ? translations.patient_adult_knee_function_q30_q1_a4_o2
                            : adultFunction.currsports === "30"
                            ? translations.patient_adult_knee_function_q30_q1_a4_o3
                            : ""}
                        </p>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_function_q31}</td>

                    <td>
                      {" "}
                      {adultFunction.patellarInstability === "0"
                        ? translations.patient_adult_knee_function_q31_a1
                        : adultFunction.patellarInstability === "1"
                        ? translations.patient_adult_knee_function_q31_a2
                        : ""}
                    </td>

                    <td>
                      {" "}
                      {adultFunction.patellarInstabilityL === "0"
                        ? translations.patient_adult_knee_function_q31_a1
                        : adultFunction.patellarInstabilityL === "1"
                        ? translations.patient_adult_knee_function_q31_a2
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="treatment-row">
                        <div className="treatment-col">
                            <label>Diagnosis of Patellar Instability:</label>
                            <p>{adultFunction.patellarInstability === "0" ? "No" : adultFunction.patellarInstability === "1" ? "Yes" : ""}</p>
                        </div>
                    </div> */}

          {adultFunction.patellarInstability === "1" ||
          adultFunction.patellarInstabilityL === "1" ? (
            <>
              <div className="treatment-row-header bd-none mb-0">
                {translations.patient_adult_knee_patellofemoral_instability}
              </div>
              <div className="treatment-row">
                <div className="treatment-table ">
                  <table cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{translations.Right_Knee}</th>
                        <th>{translations.Left_Knee}</th>

                        {/* {AllData.evlAdultKnee === "1" || AllData.evlAdultKnee === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.evlAdultKnee === "2" || AllData.evlAdultKnee === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )} */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q1
                          }{" "}
                          :
                        </td>
                        {adultNorwich.twcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q1_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q1_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.twcheck && (
                          <>
                            <td> {adultNorwich.twistingR}</td>
                            <td> {adultNorwich.twistingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q2
                          }{" "}
                          :
                        </td>
                        {adultNorwich.directioncheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q2_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q2_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.directioncheck && (
                          <>
                            <td> {adultNorwich.changeDirectionR}</td>
                            <td> {adultNorwich.changeDirectionL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q3
                          }{" "}
                          :
                        </td>
                        {adultNorwich.straightcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q3_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q3_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.straightcheck && (
                          <>
                            <td> {adultNorwich.runningStraightlineR}</td>
                            <td> {adultNorwich.runningStraightlineL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q4
                          }{" "}
                          :
                        </td>
                        {adultNorwich.slipperycheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q4_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q4_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.slipperycheck && (
                          <>
                            <td> {adultNorwich.walkingSlipperyR}</td>
                            <td> {adultNorwich.walkingSlipperyL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q5
                          }{" "}
                          :
                        </td>
                        {adultNorwich.sidewayscheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q5_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q5_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.sidewayscheck && (
                          <>
                            <td> {adultNorwich.runningSidewaysR}</td>
                            <td> {adultNorwich.runningSidewaysL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q6
                          }{" "}
                          :
                        </td>
                        {adultNorwich.Hoppingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q6_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q6_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Hoppingcheck && (
                          <>
                            <td> {adultNorwich.hoppingR}</td>
                            <td> {adultNorwich.hoppingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q7
                          }{" "}
                          :
                        </td>
                        {adultNorwich.Jumpingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q7_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q7_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Jumpingcheck && (
                          <>
                            <td> {adultNorwich.jumpingR}</td>
                            <td> {adultNorwich.jumpingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q8
                          }{" "}
                          :
                        </td>
                        {adultNorwich.scaleLabelcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q8_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q8_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.scaleLabelcheck && (
                          <>
                            <td> {adultNorwich.runningOnEvenSurfaceR}</td>
                            <td> {adultNorwich.runningOnEvenSurfaceL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q9
                          }{" "}
                          :
                        </td>
                        {adultNorwich.downcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q9_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q9_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.downcheck && (
                          <>
                            <td> {adultNorwich.goingDownStairsR}</td>
                            <td> {adultNorwich.goingDownStairsL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q10
                          }
                          :
                        </td>
                        {adultNorwich.Squattingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q10_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q10_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Squattingcheck && (
                          <>
                            <td> {adultNorwich.squattingR}</td>
                            <td> {adultNorwich.squattingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q11
                          }{" "}
                          :
                        </td>
                        {adultNorwich.Kneelingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q11_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q11_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Kneelingcheck && (
                          <>
                            <td> {adultNorwich.kneelingR}</td>
                            <td> {adultNorwich.kneelingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q12
                          }
                          :
                        </td>
                        {adultNorwich.Surfacescheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q12_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q12_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Surfacescheck && (
                          <>
                            <td> {adultNorwich.walkingUnevenSurfacesR}</td>
                            <td> {adultNorwich.walkingUnevenSurfacesL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q13
                          }{" "}
                          :
                        </td>
                        {adultNorwich.Climbingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q13_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q13_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Climbingcheck && (
                          <>
                            <td> {adultNorwich.climbingStairsR}</td>
                            <td> {adultNorwich.climbingStairsL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q14
                          }{" "}
                          :
                        </td>
                        {adultNorwich.Steppingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q14_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q14_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Steppingcheck && (
                          <>
                            <td> {adultNorwich.steppingOverHighR}</td>
                            <td> {adultNorwich.steppingOverHighL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q15
                          }
                          :
                        </td>
                        {adultNorwich.Crossingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q15_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q15_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Crossingcheck && (
                          <>
                            <td> {adultNorwich.crossingWhenSittingR}</td>
                            <td> {adultNorwich.crossingWhenSittingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q16
                          }{" "}
                          :
                        </td>
                        {adultNorwich.walkingEvencheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q16_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q16_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.walkingEvencheck && (
                          <>
                            <td> {adultNorwich.walkingEvenSurfaceR}</td>
                            <td> {adultNorwich.walkingEvenSurfaceL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q17
                          }
                          :
                        </td>
                        {adultNorwich.IntoCarcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q17_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q17_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.IntoCarcheck && (
                          <>
                            <td> {adultNorwich.gettingIntoCarR}</td>
                            <td> {adultNorwich.gettingIntoCarL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q18
                          }{" "}
                          :
                        </td>
                        {adultNorwich.heavycheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q18_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q18_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.heavycheck && (
                          <>
                            <td> {adultNorwich.supermarketAisleR}</td>
                            <td> {adultNorwich.supermarketAisleL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {
                            translations.patient_adult_knee_patellofemoral_instability_q19
                          }
                          :
                        </td>
                        {adultNorwich.turningcheck && (
                          <>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q19_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_adult_knee_patellofemoral_instability_q19_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.turningcheck && (
                          <>
                            <td> {adultNorwich.turningYourShoulderR}</td>
                            <td> {adultNorwich.turningYourShoulderL}</td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

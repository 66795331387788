import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";

import {
  updateFootAnkle,
  updateFootAnklePainFieldScore,
  updateFootAnklePainData,
  updateFootQolData,
  updateFootSatisfactionData,
  updateFootInstabilityData,
  updateFootSymptomsData,
  updateFootFeedbackfootankle,
  updateFootFunctionData,
  updateFootComberlandData,
  updateAllData,
} from "../../../../redux/actions/footAnkle";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

// import Button from "../../custom/button/Button";

function Pain() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const [isLoading, setIsLoading] = useState(true);
  // const [anklePain, setAnklePAin] = useState("1");
  let isdoctorForm = searchParams.get("is_doctor_form");
 let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
   useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  const [painRating, setPainRating] = useState(0);
  const [painRatingL, setPainRatingL] = useState(0);
  const [descripL, setDescripL] = useState("0");
  const [descripR, setDescripR] = useState("0");
  const [oftenL, setOftenL] = useState("0");
  const [oftenR, setOftenR] = useState("0");
  const [restL, setRestL] = useState("0");
  const [restR, setRestR] = useState("0");
  const [normalL, setNormalL] = useState("0");
  const [normalR, setNormalR] = useState("0");
  const [firstL, setFirstL] = useState("0");
  const [firstR, setFirstR] = useState("0");
  const [twistedL, setTwistedL] = useState("0");
  const [twistedR, setTwistedR] = useState("0");
  const [straightL, setStraightL] = useState("0");
  const [straightR, setStraightR] = useState("0");
  const [bendingL, setBendingL] = useState("0");
  const [bendingR, setBendingR] = useState("0");
  const [walkingL, setWalkingL] = useState("0");
  const [walkingR, setWalkingR] = useState("0");
  const [goingL, setGoingL] = useState("0");
  const [goingR, setGoingR] = useState("0");
  const [nightL, setNightL] = useState("0");
  const [nightR, setNightR] = useState("0");
  const [sittingL, setSittingL] = useState("0");
  const [sittingR, setSittingR] = useState("0");
  const [standingL, setStandingL] = useState("0");
  const [standingR, setStandingR] = useState("0");
  const [Hindfootb, setHindfootb] = useState("0");
  const [Midfoot, setMidfoot] = useState("0");
  const [Hallux, setHallux] = useState("0");
  const [Lessertoes, setLessertoes] = useState("0");
  const [genPainR, setGenPainR] = useState("0");
  const [genPainL, setGenPainL] = useState("0");
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState({});
  const FootPain = useSelector((state) => state.footAnkle.painScore[0]);
  const json = useSelector((state) => state.footAnkle);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backDoc, setBackDoc] = useState("");
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);
  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);
  useEffect(() => {
    setPainRating(FootPain.painRating);
    setPainRatingL(FootPain.painRatingL);
    setDescripL(FootPain.descripL);
    setDescripR(FootPain.descripR);
    setOftenL(FootPain.oftenL);
    setOftenR(FootPain.oftenR);
    setRestL(FootPain.restL);
    setRestR(FootPain.restR);
    setNormalL(FootPain.normalL);
    setNormalR(FootPain.normalR);
    setFirstL(FootPain.firstL);
    setFirstR(FootPain.firstR);
    setTwistedL(FootPain.twistedL);
    setTwistedR(FootPain.twistedR);
    setStraightL(FootPain.straightL);
    setStraightR(FootPain.straightR);
    setBendingL(FootPain.bendingL);
    setBendingR(FootPain.bendingR);
    setWalkingL(FootPain.walkingL);
    setWalkingR(FootPain.walkingR);
    setGoingL(FootPain.goingL);
    setGoingR(FootPain.goingR);
    setNightL(FootPain.nightL);
    setNightR(FootPain.nightR);
    setSittingL(FootPain.sittingL);
    setSittingR(FootPain.sittingR);
    setStandingL(FootPain.standingL);
    setStandingR(FootPain.standingR);
    setHindfootb(FootPain.Hindfootb);
    setMidfoot(FootPain.Midfoot);
    setHallux(FootPain.Hallux);
    setLessertoes(FootPain.Lessertoes);
    setGenPainR(FootPain.genPainR);
    setGenPainL(FootPain.genPainL);
  }, [FootPain]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [FootPain, updateTotal]);

  let doctordata =localStorage.getItem("isdoctorForm");
  const navigate = useNavigate();
  const continueRedirect = (e) => {
    dispatch(
      updateFootAnklePainData([
        {
          painRating: painRating,
          painRatingL: painRatingL,
          descripL: descripL,
          descripR: descripR,
          oftenL: oftenL,
          oftenR: oftenR,
          restL: restL,
          restR: restR,
          normalL: normalL,
          normalR: normalR,
          firstL: firstL,
          firstR: firstR,
          twistedL: twistedL,
          twistedR: twistedR,
          straightL: straightL,
          straightR: straightR,
          bendingL: bendingL,
          bendingR: bendingR,
          walkingL: walkingL,
          walkingR: walkingR,
          goingL: goingL,
          goingR: goingR,
          nightL: nightL,
          nightR: nightR,
          sittingL: sittingL,
          sittingR: sittingR,
          standingL: standingL,
          standingR: standingR,
          Hindfootb: Hindfootb,
          Midfoot: Midfoot,
          Hallux: Hallux,
          Lessertoes: Lessertoes,
          genPainR: genPainR,
          genPainL: genPainL,
        },
      ])
    );
    navigate("/foot-ankle-instability/" + rId+`?is_doctor_form=${doctordata}`);
  };
  const handleAnklePain = (e) => {
    dispatch(updateFootAnkle(e));
  };
  const handlePainRating = (e) => {
    setPainRating(e);

    dispatch(
      updateFootAnklePainFieldScore({
        key: "painRating",
        value: e,
      })
    );
  };
  const handlePainRatingL = (e) => {
    setPainRatingL(e);

    dispatch(
      updateFootAnklePainFieldScore({
        key: "painRatingL",
        value: e,
      })
    );
  };
  const handleDescripL = (e) => {
    setDescripL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "descripL",
        value: e,
      })
    );
  };
  const handleDescripR = (e) => {
    setDescripR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "descripR",
        value: e,
      })
    );
  };
  const handleOftenL = (e) => {
    setOftenL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "oftenL",
        value: e,
      })
    );
  };
  const handleOftenR = (e) => {
    setOftenR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "oftenR",
        value: e,
      })
    );
  };
  const handleRestL = (e) => {
    setRestL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "restL",
        value: e,
      })
    );
  };
  const handleRestR = (e) => {
    setRestR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "restR",
        value: e,
      })
    );
  };
  const handleNormalL = (e) => {
    setNormalL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "normalL",
        value: e,
      })
    );
  };
  const handleNormalR = (e) => {
    setNormalR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "normalR",
        value: e,
      })
    );
  };
  const handleFirstL = (e) => {
    setFirstL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "firstL",
        value: e,
      })
    );
  };
  const handleFirstR = (e) => {
    setFirstR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "firstR",
        value: e,
      })
    );
  };
  const handleTwistedL = (e) => {
    setTwistedL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "twistedL",
        value: e,
      })
    );
  };
  const handleTwistedR = (e) => {
    setTwistedR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "twistedR",
        value: e,
      })
    );
  };
  const handleStraightL = (e) => {
    setStraightL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "straightL",
        value: e,
      })
    );
  };
  const handleStraightR = (e) => {
    setStraightR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "straightR",
        value: e,
      })
    );
  };
  const handleBendingL = (e) => {
    setBendingL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "bendingL",
        value: e,
      })
    );
  };
  const handleBendingR = (e) => {
    setBendingR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "bendingR",
        value: e,
      })
    );
  };
  const handleWalkingL = (e) => {
    setWalkingL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "walkingL",
        value: e,
      })
    );
  };
  const handleWalkingR = (e) => {
    setWalkingR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "walkingR",
        value: e,
      })
    );
  };
  const handleGoingL = (e) => {
    setGoingL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "goingL",
        value: e,
      })
    );
  };
  const handleGoingR = (e) => {
    setGoingR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "goingR",
        value: e,
      })
    );
  };
  const handleNightL = (e) => {
    setNightL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "nightL",
        value: e,
      })
    );
  };
  const handleNightR = (e) => {
    setNightR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "nightR",
        value: e,
      })
    );
  };
  const handleSittingL = (e) => {
    setSittingL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "sittingL",
        value: e,
      })
    );
  };
  const handleSittingR = (e) => {
    setSittingR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "sittingR",
        value: e,
      })
    );
  };
  const handleStandingL = (e) => {
    setStandingL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "standingL",
        value: e,
      })
    );
  };
  const handleStandingR = (e) => {
    setStandingR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "standingR",
        value: e,
      })
    );
  };

  const handleGenPainR = (e) => {
    setGenPainR(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "genPainR",
        value: e,
      })
    );
  };
  const handleGenPainL = (e) => {
    setGenPainL(e);
    dispatch(
      updateFootAnklePainFieldScore({
        key: "genPainL",
        value: e,
      })
    );
  };

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_foot_ankle_pain}
              </h4>

              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  {/* <div className="row">
                                        <div className="mb_20">
                                            <FullDropdown
                                                data={[
                                                    {
                                                        value: "1",
                                                        label: "Right",
                                                    },
                                                    {
                                                        value: "2",
                                                        label: "Left",
                                                    },
                                                    {
                                                        value: "3",
                                                        label: "Both",
                                                    },
                                                ]}
                                                label="Evaluated foot/ankle"
                                                styleClass=""
                                                value={anklePain}
                                                onChange={handleAnklePain}
                                            />
                                        </div>

                                    </div> */}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className="mt-5 mb_20">
                      <RangeSlider
                        id="painRating"
                        min="0"
                        max="10"
                        step="1"
                        type="both"
                        leftTitle={translation.patient_foot_ankle_pain_q1_0}
                        rightTitle={translation.patient_foot_ankle_pain_q1_1}
                        label={
                          translation.patient_foot_ankle_pain_q1 +
                          LeftTrans(anklePain, translation)
                        }
                        value={painRatingL}
                        onChange={handlePainRatingL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className="mb_20">
                      <RangeSlider
                        id="painRating"
                        min="0"
                        max="10"
                        step="1"
                        type="both"
                        leftTitle={translation.patient_foot_ankle_pain_q1_0}
                        rightTitle={translation.patient_foot_ankle_pain_q1_1}
                        label={
                          translation.patient_foot_ankle_pain_q1 +
                          RigthTrans(anklePain, translation)
                        }
                        value={painRating}
                        onChange={handlePainRating}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row mt-5">
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6" + RightSide()}>
                        <div className="mb_10">
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q2 +
                              RigthTrans(anklePain, translation)
                            }
                            styleClass=""
                            min="0"
                            max="3"
                            value={descripR}
                            onChange={handleDescripR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a4,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6" + LeftSide()}>
                        <div className="mb_10">
                          <KneeScoreRangeSlider
                            min="0"
                            max="3"
                            label={
                              translation.patient_foot_ankle_pain_q2 +
                              LeftTrans(anklePain, translation)
                            }
                            styleClass=""
                            value={descripL}
                            onChange={handleDescripL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q2_a4,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mt-5">
                    {anklePain === "1" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6" + RightSide()}>
                        <div className="mb_10">
                          <KneeScoreRangeSlider
                            min="0"
                            max="3"
                            label={
                              translation.patient_foot_ankle_pain_q3 +
                              RigthTrans(anklePain, translation)
                            }
                            styleClass=""
                            value={oftenR}
                            placeholder={translation.Never}
                            onChange={handleOftenR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a5,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className={"col-md-6 col-sm-6" + LeftSide()}>
                        <div className="mb_10">
                          <KneeScoreRangeSlider
                            min="0"
                            max="3"
                            label={
                              translation.patient_foot_ankle_pain_q3 +
                              LeftTrans(anklePain, translation)
                            }
                            styleClass=""
                            value={oftenL}
                            placeholder={translation.Never}
                            onChange={handleOftenL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q3_a5,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="function_formtop">
                    <div className="row">
                      <div className="col-md-10 col-sm-10 mb_10">
                        <strong className="d-inline-block mb_15">
                          {translation.patient_foot_ankle_pain_instruction}
                        </strong>
                        <br />
                        <span dir={lg === "ara" ? "rtl" : " "}>
                          (<strong>0</strong> ={" "}
                          {translation.patient_foot_ankle_pain_instruction_0},{" "}
                          <strong>1</strong> ={" "}
                          {translation.patient_foot_ankle_pain_instruction_1},{" "}
                          <strong>2</strong> ={" "}
                          {translation.patient_foot_ankle_pain_instruction_2},{" "}
                          <strong>3</strong> ={" "}
                          {translation.patient_foot_ankle_pain_instruction_3},{" "}
                          <strong>4</strong> ={" "}
                          {translation.patient_foot_ankle_pain_instruction_4})
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q4 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={genPainR}
                            onChange={handleGenPainR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q4 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={genPainL}
                            onChange={handleGenPainL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q4_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q5 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={restR}
                            onChange={handleRestR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q5 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={restL}
                            onChange={handleRestL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q5_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q6 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={normalR}
                            onChange={handleNormalR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q6 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={normalL}
                            onChange={handleNormalL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q7 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={firstR}
                            onChange={handleFirstR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q7 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={firstL}
                            onChange={handleFirstL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q8 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={twistedR}
                            onChange={handleTwistedR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q8 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={twistedL}
                            onChange={handleTwistedL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q9 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={straightR}
                            onChange={handleStraightR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q9 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={straightL}
                            onChange={handleStraightL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q9_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q10 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={bendingR}
                            onChange={handleBendingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q10 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={bendingL}
                            onChange={handleBendingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q11 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingR}
                            onChange={handleWalkingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q11 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={walkingL}
                            onChange={handleWalkingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q12 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={goingR}
                            onChange={handleGoingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q12 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={goingL}
                            onChange={handleGoingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q13 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={nightR}
                            onChange={handleNightR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q13 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={nightL}
                            onChange={handleNightL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q14 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={sittingR}
                            onChange={handleSittingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q14 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={sittingL}
                            onChange={handleSittingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q14_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {anklePain === "1" || anklePain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q15 +
                              RigthTrans(anklePain, translation)
                            }
                            classes=""
                            value={standingR}
                            onChange={handleStandingR}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {anklePain === "2" || anklePain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_foot_ankle_pain_q15 +
                              LeftTrans(anklePain, translation)
                            }
                            classes=""
                            value={standingL}
                            onChange={handleStandingL}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_foot_ankle_pain_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {doctorEnable === "true" &&  (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
               {doctorEnable !== "true" && viewicon!=="true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc !== 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${doctordata}&doctorvalue=0`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pain;
